import { Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { SnackBarMessage } from "../model/base/snack-bar-message";
import { Helper } from "../util/helper.util";

  
@Injectable()
export class SnackBarService implements OnDestroy
{
	private matSnackBarConfig: MatSnackBarConfig = { duration: 2000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar'};
	private snackBarRef:  MatSnackBarRef<SimpleSnackBar>;
	private msgQueue = [];
	private isInstanceVisible = false;
	private subscription: Subscription;
	
	constructor(private snackBar: MatSnackBar, private _helper: Helper){ }
	
	showNext() {
		if (!this.msgQueue) 
			return;
		
		let message = this.msgQueue.shift();

		if (!message) 
			return;

		this.isInstanceVisible = true;
		this.snackBarRef = this.snackBar.open(message.message, message.action, this.matSnackBarConfig);

		this.subscription = this.snackBarRef.afterDismissed()
			.subscribe(() => {
				this.isInstanceVisible = false;
				this.showNext();
			});
	}
	
	add(message: string, action?: string, config?: MatSnackBarConfig): void{

		let sbMessage = new SnackBarMessage();
		sbMessage.message = message;
		sbMessage.action = !this._helper.isEmptyOrNull(action) ? action : 'Close';

		this.msgQueue.push(sbMessage);

		if (!this.isInstanceVisible) {
			this.showNext(); 
		}
	}
	
	ngOnDestroy() {
        if(this.subscription)
    		this.subscription.unsubscribe();
	}
}
