import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap, shareReplay } from 'rxjs/operators';
import { Helper } from '../../util/helper.util';
import { IDoctor } from '../../model/api/doctor';  
import { APP_CONFIG  } from '../../app.config/app.config'; 
import { IApiResponseCache } from '../../model/base/response-cache';
import { IMessageResponse, IResponse, ISmileboxResponse } from '../../model/api/response'; 
import { DoctorPatientService } from '../../service/api/doctor-patient.service';
import { ISmilebox } from '../../model/api/smilebox';

@Injectable({
	providedIn: 'root'
})

export class DoctorService   {
    url = "/admin/doctor-list";
    urlPostDoctor = "/admin/update-order";
    urlPutDoctor = "/admin/update-doctor";
	 
    public cacheValidForSeconds = 360;
    public responseCache$ = new Map<string, IApiResponseCache<IResponse<IDoctor[]>>>();
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient, private _helper: Helper) {
       // super(config, http)
    }

    index() {
        var finalUrl = `${this._config.api_url}${this.url}`;
        const responseFromCache = this.responseCache$.get(finalUrl);
        const cacheDateTimeCheck = new Date(Date.now() - (this.cacheValidForSeconds * 1000));

        if (responseFromCache && responseFromCache.datetime > cacheDateTimeCheck) {
            return responseFromCache.response$;
        }
        
        const request$ = this.indexAPI(finalUrl).pipe(shareReplay(1));

        let cachedResponse:IApiResponseCache<IResponse<IDoctor[]>> = { datetime: new Date(), response$: request$ }

        this.responseCache$.set(finalUrl, cachedResponse);
		
		return request$;
	}

	indexAPI(url:string): Observable<IResponse<IDoctor[]>> {      
         return this._http.get<IResponse<IDoctor[]>>(url)
            .pipe(
                retry(3), 
                    map((data: IResponse<IDoctor[]>) => { 
                        if(data) {
                            return data as IResponse<IDoctor[]>;
                        }
                    }),         
            ); 
    }
 

    postDoctor<IDoctorRequest>(userId: number, doctor: IDoctorRequest): Observable<ISmileboxResponse<ISmilebox>> {
        let urlFinal = `${this._config.api_url}${this.urlPostDoctor}/${userId}`; 
   
        return this._http.put(urlFinal, doctor)
            .pipe(
                map((res: ISmileboxResponse<ISmilebox>) => { 
                    if (res) {                          
                        return res;
                    }
                }), 
            );
    }

    putDoctor<IDoctorRequest>(userId: number, doctor: IDoctorRequest): Observable<IMessageResponse> {
        let urlFinal = `${this._config.api_url}${this.urlPutDoctor}/${userId}`; 
   
        return this._http.put(urlFinal, doctor)
            .pipe(
                map((res: IMessageResponse) => { 
                    if (res) { 
                        return res;
                    }
                }), 
            );
    }
 
}

