<div class="content-wrap"> 
    <div #content class="content">
        <app-header [(user)]="user" [(reportUserInfo)]="reportUserInfo" [(userStatusColor)]="userStatusColor"></app-header>  
        <app-sub-menu></app-sub-menu>

        <mat-card> 
            <mat-card-content>
                <router-outlet></router-outlet> 
            </mat-card-content> 
        </mat-card>
    </div>
</div> 

 