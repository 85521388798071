import { Component, OnInit, Inject } from '@angular/core'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { Helper } from '../../../../util/helper.util';   
import { IFile, IFileDeleteDialog } from '../../../../model/api/file';
import { AdminFileService } from '../../../../service/api/admin-file.service';
import { AppConstants } from '../../../../settings/app-constants';
import { Message } from '../../../../model/base/message';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-file-dialog',
  templateUrl: './delete-file-dialog.component.html',
  styleUrls: ['./delete-file-dialog.component.less']
})
export class DeleteFileDialogComponent implements OnInit {

    file: IFile;
    userId: number; 
    readonly CLOSE_BUTTON:string = AppConstants.CLOSE_BUTTON;
    readonly DELETE_BUTTON:string = AppConstants.DELETE_BUTTON; 
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
    
    constructor(private helper: Helper, public dialogRef: MatDialogRef<DeleteFileDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IFileDeleteDialog, private _adminFileService: AdminFileService, private _snackBar: MatSnackBar) {}

    ngOnInit() { 
        this.file = this.data.file;
        this.userId = this.data.userId; 
    }
   
    onDelete():void {
         this._adminFileService.deleteFile(this.userId, this.file).subscribe(response => {
            if(response.success)
                this.dialogRef.close(true);
        },
		error =>{ error  
            this._snackBar.open(error.message, 'Close', this.matSnackBarConfig);  
		}); 
         
     }

    onClose():void {
        this.dialogRef.close();
    }
 
  
}
