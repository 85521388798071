import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/app.config/app.config';  
import { BaseService } from '../base/base.service'; 
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs'; 
import { IMessageBroadcast } from 'src/app/model/api/message-broadcast';
import { IMessageDataResponse } from 'src/app/model/api/response';


@Injectable({
  	providedIn: 'root'
})
 
export class MessageBroadcastService extends BaseService<IMessageBroadcast> {
 
    protected urlV2 =  "/admin-v02/message-broadcast";  
    protected urlV1 =  "/admin/message-broadcast";  
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    
    // Comment - not used anymore
    // saveData(data: IMessageBroadcast, formData: FormData): Observable<IMessageDataResponse<IMessageBroadcast>>  {

	// 	let url = `${this._config.api_url}${this.urlV1}`; 

	// 	return this._http.post<IMessageDataResponse<IMessageBroadcast>>(url, formData).pipe(
	// 		map(response => {
	// 			this._responseCache$.clear();
	// 			return response as IMessageDataResponse<IMessageBroadcast>;
	// 		}), 
    //         //catchError( err =>  { return this.handleError(err)})
    //     );
	// }

    private handleError(httpError:any) {
 
        /* if(httpError.status == 404)
            return throwError('404 error returned'); */
 
        let errorMessage = httpError; 
        return throwError(errorMessage);
 
    }
	

}
