<mat-card> 
    <mat-card-content>
        <div fxLayout="row" fxFlexFillfxLayoutGap="20px" *ngIf="navigationSettings">
            <div fxFlex="100" class="button-box space-right" fxLayoutAlign="end start"> 
                <button mat-raised-button mat-button color="warn" (click)="deleteAppBannerNotification()" *ngIf="appBannerNotification">{{ DELETE_BUTTON }}</button> 
                <button mat-raised-button mat-button color="primary" (click)="saveAppBannerNotification()" *ngIf="appBannerNotification">{{ SAVE_BUTTON }}</button> 
                <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.parentUrl">{{ CANCEL }}</a>
            </div> 
        </div> 
    </mat-card-content> 
</mat-card>
<mat-card> 
    <mat-card-content>
        <div  fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill fxLayoutGap="20px">    
            <div fxFlex="100" *ngIf="appBannerNotification" fxLayoutGap="20px">
                <div fxLayout="column" fxLayout.lt-sm="column" >
                    <div fxFlex="100">
                        <mat-form-field class="example-full-width">
                            <mat-label>Title</mat-label>
                            <input matInput [(ngModel)]="appBannerNotification.title" placeholder="Title">
                        </mat-form-field> 
                    </div> 
                    <div fxFlex="100">
                        <mat-form-field class="example-full-width">
                            <mat-label>Text</mat-label>
                            <textarea matInput [(ngModel)]="appBannerNotification.text" rows="10" placeholder="Text"></textarea>
                        </mat-form-field> 
                    </div>
                    <div fxFlex="100">
                        <mat-form-field class="example-full-width">
                            <mat-label>Visible to user</mat-label>
                            <input matInput [(ngModel)]="appBannerNotificationData.visible_to_user" placeholder="Visible to user">
                        </mat-form-field> 
                    </div> 
                    <div fxFlex="100">
                        <div fxLayout="row" fxLayout.lt-sm="column"  fxLayoutGap="20px">
                            <div fxFlex="50" *ngIf="appBannerNotification"> 
                                <mat-form-field>
                                    <input matInput [ngxMatDatetimePicker]="visibleFromDatePicker" placeholder="Choose a date" [(ngModel)]="appBannerNotification.visible_from_date" [disabled]="disabled">
                                    <mat-datepicker-toggle matSuffix [for]="visibleFromDatePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #visibleFromDatePicker [showSpinners]="true" [disableMinute]="disableMinute" ></ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field>
                                    <input matInput [ngxMatDatetimePicker]="visibleToDatePicker" placeholder="Choose a date" [(ngModel)]="appBannerNotification.visible_to_date" [disabled]="disabled">
                                    <mat-datepicker-toggle matSuffix [for]="visibleToDatePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #visibleToDatePicker [showSpinners]="true" [disableMinute]="disableMinute" ></ngx-mat-datetime-picker>
                                </mat-form-field> 
                            </div>
                        </div>
                        
                    </div>
                    <div fxFlex="100">
                        <mat-selection-list [(ngModel)]="selectedStatuses" >
                            <mat-list-option checkboxPosition="before" *ngFor="let status of statuses" [value]="status" style="width: auto; display: inline; float: left;">
                                {{status.name}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div fxFlex="100">
                        <mat-divider [inset]="true"></mat-divider><br />
                    </div>
                    <div fxFlex="100">
                        <div fxLayout="row" fxLayout.lt-sm="column"  fxLayoutGap="20px">
                            <div fxFlex="25">
                                <div *ngIf="appBannerNotificationData.image && appBannerNotificationData.image.base != null">
                                    <img src="{{ appBannerNotificationData.image.base }}" style="width: 100%; margin-bottom: 5px;" /> 
                                </div> 
                                <div *ngIf="(appBannerNotificationData.image == null || appBannerNotificationData.image.base == null) && appBannerNotification.image != null">
                                    <img src="{{ appBannerNotification.image }}" style="width: 100%; margin-bottom: 5px;" /> 
                                </div> 
                                <button mat-raised-button mat-button color="accent" (click)="appBannerNotificationImage.click()">
                                    <span class="material-icons">add</span> {{ appBannerNotification.image == null ? ADD_IMAGE : CHANGE_IMAGE }} 
                                    <span style="display: none;">
                                        <input #appBannerNotificationImage (change)="addAppBannerNotificationImage(appBannerNotificationImage)" [accept]="allowedUploadExtensions" type="file" style="display: none;" >
                                    </span> 
                                </button> 
                            </div>
                            <div fxFlex="25" style="padding-top: 10px;">
                                <mat-checkbox [(ngModel)]="appBannerNotification.active"> Active </mat-checkbox> 
                            </div>
                            <div fxFlex="50">
                                <p>Location</p>
                                <mat-radio-group [value]="appBannerNotification.is_country_uk" [(ngModel)]="appBannerNotification.is_country_uk" name="isCountryUk" required>
                                    <mat-radio-button [value]="true">UK</mat-radio-button> 
                                    <mat-radio-button [value]="false">ROW</mat-radio-button>
                                    <mat-radio-button [value]="null">All</mat-radio-button>
                                </mat-radio-group> 
                            </div>  
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </mat-card-content> 
</mat-card>