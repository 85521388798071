import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IMaintenanceAnnouncement, IMaintenanceAnnouncementData } from 'src/app/model/api/maintenance-announcement';
import { MaintenanceAnnouncementService } from 'src/app/service/api/maintenance-announcement.service';
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { StatusService } from 'src/app/service/status.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { Helper } from 'src/app/util/helper.util';
import { DetailBaseComponent } from '../../../inc/base/detail-base-componenty'; 
import { MenuService } from '../../../../service/state/menu.service';
import { SettingsService } from '../../../../service/state/settings.service';  
 
@Component({
    selector: 'app-maintenance-announcement-detail',
    templateUrl: './maintenance-announcement-detail.component.html',
    styleUrls: ['./maintenance-announcement-detail.component.scss']
})
export class MaintenanceAnnouncementDetailComponent extends DetailBaseComponent implements OnInit {

    menuId: number = 2020;  
    maintenanceAnnouncement: IMaintenanceAnnouncement;
    moment: any = moment; 
    maintenanceAnnouncementId: string;
    readonly SAVE_BUTTON: string = AppConstants.SAVE_BUTTON;
    readonly ADD_IMAGE: string = AppConstants.ADD_IMAGE;
    readonly CHANGE_IMAGE: string = AppConstants.CHANGE_IMAGE; 
    readonly CANCEL:string = AppConstants.CANCEL;
    readonly DELETE_BUTTON:string = AppConstants.DELETE_BUTTON;

    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _reportCurrentUserService: ReportCurrentUserService, private _route: ActivatedRoute, private _maintenanceAnnouncementService: MaintenanceAnnouncementService, protected _statusService: StatusService, private _spinnerService: SpinnerService, public _helper: Helper, private _snackBar: MatSnackBar, private _cd: ChangeDetectorRef, private _zone: NgZone, private _router: Router) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }
 

    ngOnInit(): void {
        super.ngOnInit();

        this.maintenanceAnnouncementId = this.route.snapshot.paramMap.get('id'); 
 
        this.getMaintenanceAnnouncement()
    }

    

    saveMaintenanceAnnouncement() {
        if(this._helper.isNullOrUndefined(this.maintenanceAnnouncement) || this._helper.isEmptyOrNull(this.maintenanceAnnouncement.text)) {
            this._snackBar.open('Enter Maintenance announcement text, please.', 'Close', this.matSnackBarConfig);
            return;
        }
        
        this._spinnerService.startSpinner(); 

        let formData: FormData = new FormData();

        formData.append('id', this.maintenanceAnnouncement.id > 0 ? this.maintenanceAnnouncement.id.toString(): null) ;
        formData.append('text', this.maintenanceAnnouncement.text);

        formData.append('visible_from_date', this.maintenanceAnnouncement.visible_from_date != null ? moment.utc(this.maintenanceAnnouncement.visible_from_date).format('yyyy-MM-DTHH:mm:ss') : '');
        formData.append('visible_to_date', this.maintenanceAnnouncement.visible_to_date != null ? moment.utc(this.maintenanceAnnouncement.visible_to_date).format('yyyy-MM-DTHH:mm:ss') : '');
 
        formData.append('active', this.maintenanceAnnouncement.active ? this.maintenanceAnnouncement.active.toString() : 'false' ); 		
		
		this._maintenanceAnnouncementService.save(this.maintenanceAnnouncement, formData)
            .subscribe(maintenanceAnnouncement => {     
                if(maintenanceAnnouncement) 
                    this._router.navigateByUrl(this.navigationSettings.parentUrl);
  
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 

    }
 
    editMaintenanceAnnouncement(maintenanceAnnouncement: IMaintenanceAnnouncement) {

        this.maintenanceAnnouncement = Object.assign({}, maintenanceAnnouncement);

        this.maintenanceAnnouncement.visible_from_date = !this._helper.isNullOrUndefined(this.maintenanceAnnouncement.visible_from_date) ? moment.utc(this.maintenanceAnnouncement.visible_from_date).toDate() : null; 
        this.maintenanceAnnouncement.visible_to_date = !this._helper.isNullOrUndefined(this.maintenanceAnnouncement.visible_to_date) ? moment.utc(this.maintenanceAnnouncement.visible_to_date).toDate() : null; 
        
    }

    addNewMaintenanceAnnouncement() {
        this.maintenanceAnnouncement = { id: null, text: '' };
    }

    getMaintenanceAnnouncement(): void {  
        if(this._helper.isEmptyOrNull(this.maintenanceAnnouncementId)) {
 
            this.addNewMaintenanceAnnouncement();
            return;
        }

        this._spinnerService.startSpinner();  

        this._maintenanceAnnouncementService.detail(this.maintenanceAnnouncementId)
			.subscribe(response => { 

                if(response) {
                    this.maintenanceAnnouncement = response; 
                    this.editMaintenanceAnnouncement(this.maintenanceAnnouncement);
                } 
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
 
	}

    deleteMaintenanceAnnouncement() {
        if(this._helper.isNullOrUndefined(this.maintenanceAnnouncement))
            return;

        this._spinnerService.startSpinner(); 

        this.maintenanceAnnouncement.marked_deleted_at = moment(new Date()).toDate();

        this._maintenanceAnnouncementService.save({ id: this.maintenanceAnnouncement.id, marked_deleted_at: this.maintenanceAnnouncement.marked_deleted_at })
            .subscribe(maintenanceAnnouncement => {     
                if(maintenanceAnnouncement) 
                    this._router.navigateByUrl(this.navigationSettings.parentUrl);
 
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
        
    }
    
}
