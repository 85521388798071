import { Component, OnInit, ChangeDetectorRef } from '@angular/core'; 
import * as moment from 'moment';  
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { SpinnerService } from '../../../service/spinner.service';
import { InvitationService } from '../../../service/api/invitation.service';
import { IInvitation } from '../../../model/api/invitation';
import { UserService } from '../../../service/api/user.service';
import { IUser } from '../../../model/api/user';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { UserStateService } from '../../../service/state/user-state.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent extends DetailUserBaseComponent implements OnInit {

    menuId: number = 111;  
    moment: any = moment; 
    invitations: IInvitation[];
    //user: IUser; 
    
    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _invitationService: InvitationService, private _menuService: MenuService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _route: ActivatedRoute, public _helper: Helper, private _cd: ChangeDetectorRef, private _spinnerService: SpinnerService) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
        //console.log('InvitationsComponent ngOnInit()');
        super.ngOnInit();  
        this.getInvitations();
        //this.getUser(); 
    }

    getInvitations() {
        this._spinnerService.startSpinner();
        this._invitationService.index({ user_id: this.userId})
            .subscribe(invitations => {
                this.invitations = invitations.results;
                this._spinnerService.stopSpinner();                 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
    }

    /* getUser(): void { 
        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
			});
	} */

}
