import { NgModule } from '@angular/core';
import { MessageComponent } from './message.component'; 
import { AppBannerNotificationFilterStateService } from '../../service/state/app-banner-notification-filter-state.service';
import { MaintenanceAnnouncementFilterStateService } from '../../service/state/maintenance-announcement-filter-state.service';
import { ReportConversationService } from '../../service/api/report-conversation.service'; 
import { InfiniteScrollModule } from 'ngx-infinite-scroll'; 
import { ConversationMessageFileService } from '../../service/api/conversation-message-file.service';
import { MessagesRoutingModule } from './message-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MessageListComponent } from './message-list/message-list.component';
import { CustomCoreModule } from 'src/app/custom-core.module';
import { LinkyModule } from 'ngx-linky';

@NgModule({
    declarations: [  
        MessageComponent, MessageListComponent
        ],
    exports:  [ ],
    imports: [
        CustomCoreModule,
        InfiniteScrollModule,
        MessagesRoutingModule,
        SharedModule,
        LinkyModule
    ],
    providers: [
        AppBannerNotificationFilterStateService,
        MaintenanceAnnouncementFilterStateService, 
        ReportConversationService,
        ConversationMessageFileService
    ]
})
export class MessageModule { }
