import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/app.config/app.config';  
import { BaseService } from '../base/base.service';
import { IUserResponse } from 'src/app/model/api/response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IMaintenanceAnnouncement } from 'src/app/model/api/maintenance-announcement';


@Injectable({
  	providedIn: 'root'
})
 
export class MaintenanceAnnouncementService extends BaseService<IMaintenanceAnnouncement> {
 
    protected urlV2 =  "/admin-v02/maintenance-announcement";  
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    
 

}
