import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service';
import { ComplaintService } from '../../../service/api/complaint.service';
import { ConversationService } from '../../../service/api/conversation.service';
import { forkJoin } from 'rxjs';
import { IComplaint } from '../../../model/api/complaint';
import { IConversation } from '../../../model/api/conversation';
import { AppConstants } from '../../../settings/app-constants';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import * as moment from 'moment'; 
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { SpinnerService } from '../../../service/spinner.service';
import { UserService } from 'src/app/service/api/user.service';
import { UserStateService } from '../../../service/state/user-state.service';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent extends DetailUserBaseComponent implements OnInit {

    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 102; 
    complaints: IComplaint[]; 
    conversations: IConversation[]; 
    moment: any = moment;
    readonly ADD_NEW:string = AppConstants.ADD_NEW;
    readonly ADD_NEW_LINK:string = AppConstants.ADD_NEW_LINK;
    
	constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _complaintService: ComplaintService, private _conversationService: ConversationService, private _route: ActivatedRoute, private _helper: Helper, private _cd: ChangeDetectorRef, private _spinnerService: SpinnerService) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

	ngOnInit(): void {
        super.ngOnInit();

        this.getComplaintsAndConversations();

	} 

    getComplaintsAndConversations() {
        this._spinnerService.startSpinner();

        forkJoin([this._complaintService.index({user: this.userId, page: 1, page_size: 50 }), this._conversationService.index({patient_id: this.userId, page: 1, page_size: 50 })]).subscribe(items => {
            this.complaints = items[0].results;
            this.conversations = items[1].results;

            this.complaints.sort((a, b) => b.id - a.id);

            this.complaints.forEach(complaint => {
                if(complaint == null) 
                    return true;

                complaint.created_at = !this._helper.isNullOrUndefined(complaint.created_at) ? moment.utc(complaint.created_at).toDate() : null;
                complaint.received_at = !this._helper.isNullOrUndefined(complaint.received_at) ?  moment.utc(complaint.received_at).toDate() : null;

                let foundConversation = this.conversations.find(conversation => conversation.id == complaint.conversation )

                if(foundConversation)
                    complaint.thread_name = foundConversation.subject;
            });

            this._spinnerService.stopSpinner();  

        },
        error =>{  
            this._spinnerService.stopSpinner();  
        });
    }
 
}
