import { Component, OnInit, EventEmitter, Output, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { UserFilterStateService } from '../../../service/state/user-filter-state.service';
import { takeUntil } from 'rxjs/operators';	
import { Subject } from "rxjs";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Helper } from '../../../util/helper.util';
import { UserListServiceV1 } from '../../../service/api/user-v1.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort'; 
import { IApiFilter, IUserFilterSettings } from '../../../model/base/filter-settings';
import { Filter } from '../../../model/base/filter';
import { IReportUserSubmission } from '../../../model/api/user'; 
import * as moment from 'moment'; 
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service'; 
import { Subscription } from 'rxjs/internal/Subscription';
import { UserStatusName } from '../../../settings/user-status';
import { ReportUserSubmissionService } from '../../../service/api/report-user-submission.service';
import { IApiResponse } from '../../../model/api/response';
import { SpinnerService } from '../../../service/spinner.service';
import { ColorStatusService } from '../../../service/color-status.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

 export class UserListComponent  implements OnInit  {

    @Input() filter: Filter;
    userFilterSettings: IUserFilterSettings;
    reportUserSubmissions: IApiResponse<IReportUserSubmission>; 
    dataSource = new MatTableDataSource<IReportUserSubmission>();
    apiFilter: IApiFilter;
    private ngUnsubscribe: Subject<any> = new Subject();
    totalItems: number = 0;
    moment: any = moment; 
    @ViewChild(MatSort) sort: MatSort;
    navigationSettings: INavigationSettings;
    mainMenuId:number = 1;
    menuId: number = 1;
    userSearchApiSubscription: Subscription;
    userStatusName = UserStatusName;
    isFilterSet: boolean;  
    matTableSort: { sortActive: string; sortDirection: string; };
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _userFilterStateService: UserFilterStateService, private _userListServiceV1: UserListServiceV1, private _reportUserSubmissionService: ReportUserSubmissionService, public _helper: Helper, private _route: ActivatedRoute, private _router: Router, private _spinnerService: SpinnerService, private colorStatusService: ColorStatusService) { }

    ngOnInit() { 
         this.navigationSettings = this._menuService.getNavigationByMenuId(this.menuId); 
        this._settingsService.setNavigationSettings(this.navigationSettings); 
        
        this._userFilterStateService.currentFilterSettings
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async(userFilterSettings) => {
                this.userFilterSettings = userFilterSettings as IUserFilterSettings;
                this.checkIfFilterIsSet();
  
                this.get(this.userFilterSettings);
			});
    }
 
    get(userFilterSettings: IUserFilterSettings)  {

        this._spinnerService.startSpinner(); 

        if (this.userSearchApiSubscription) 
            this.userSearchApiSubscription.unsubscribe();   

        this.userSearchApiSubscription = this._reportUserSubmissionService.index(userFilterSettings.apiFilter)
            .subscribe(items => {
                    this.reportUserSubmissions = items;                    
                    this._spinnerService.stopSpinner();
                }, 
                error =>{ error  
                    this._spinnerService.stopSpinner(); 
                },
                () => { 
                    this.setMatTableSortAttributes();
                    this.userFilterSettings.paging = { pageIndex: userFilterSettings.paging.pageIndex, pageSize: userFilterSettings.paging.pageSize, length: this.reportUserSubmissions.count, pagingStart: null, pagingEnd: null };
                    let reportUserSubmissionsUserList: IReportUserSubmission[] = this.setUserColorStatus(this.reportUserSubmissions.results); 
 
                    this.dataSource.data = reportUserSubmissionsUserList; 

                    this._spinnerService.stopSpinner(); 
                 }
            ); 
    }
 
    setMatTableSortAttributes() { 
        if(this._helper.isEmptyOrNull(this.userFilterSettings.ordering)) {
            this.matTableSort = { sortActive: 'id', sortDirection: 'asc' };
            return;
        } 

        let filterOrdering = this.userFilterSettings.ordering;
        this.matTableSort = { sortActive: '', sortDirection: '' }; 

        this.matTableSort.sortActive = filterOrdering.startsWith('-') ? filterOrdering.substring(1, filterOrdering.length) : filterOrdering;
        this.matTableSort.sortDirection = filterOrdering.startsWith('-') ? 'desc' : 'asc'; 
         
    }

    setUserColorStatus(reportUserSubmissionItems: IReportUserSubmission[]): IReportUserSubmission[] {
            if(!reportUserSubmissionItems) 
                return;
 
            reportUserSubmissionItems.forEach(reportUserSubmissionItem => {
                reportUserSubmissionItem.userStatusColor = this.colorStatusService.getUserColorStatus(reportUserSubmissionItem.user_details__status_id);
        })
 
        return reportUserSubmissionItems;
    } 
 
    onPaginateChange(event){ 
        this._userFilterStateService.updateApiFilterPageing({ pageIndex: event.pageIndex +1, pageSize: event.pageSize });
    }

    sortData(event) { 
        let ordering: string = event.direction == 'asc' ? event.active : `-${event.active}`;
 
        this._userFilterStateService.updateApiFilterOrdering(ordering);
    }
      
    showHideDisplayedColumns() {         
        this._userFilterStateService.setCurrentFilterSettings(this.userFilterSettings);
    } 

    resetColumnsView() { 
        this._userFilterStateService.resetDisplayedColumnsView(this.userFilterSettings);
    } 

    resetFilter() {
        this._userFilterStateService.resetFilter(); 
    }

    checkIfFilterIsSet() {
        this.isFilterSet = false;
         
        if(this._helper.isNullOrUndefined(this.userFilterSettings) || this._helper.isNullOrUndefined(this.userFilterSettings.filter)) 
            return;

        for (let key of Object.keys(this.userFilterSettings.filter)) {
            if(this.userFilterSettings.filter[key] != null) {
                this.isFilterSet = true;
                break;
            }
        }
    }

    ngOnDestroy() {   
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
        if (this.userSearchApiSubscription) 
            this.userSearchApiSubscription.unsubscribe();   
    }
 
 
}
