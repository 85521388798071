import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { IDoctorPatient } from '../../model/api/doctor-patient';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IResponse } from '../../model/api/response';  
import { BaseService } from '../base/base.service';
import { SmileboxService } from './smilebox.service';

@Injectable({
  	providedIn: 'root'
})

export class DoctorPatientService extends BaseService<IDoctorPatient>  { 
     
    protected urlV2 = "/admin-v02/doctor-patient"; 
    protected urlPatientDoctorRelation = "/admin/patient-doctor"; 
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient, private _smileboxService: SmileboxService,) {
        super(_config, _http)
    }

    addDoctorPatientRelation<IDoctorPatientRequest>(userId: number, doctorPatientRequest: IDoctorPatientRequest): Observable<IResponse<IDoctorPatient>> {
        let urlFinal = `${this._config.api_url}${this.urlPatientDoctorRelation}/${userId}`; 
   
        return this._http.post(urlFinal, doctorPatientRequest)
            .pipe(
                map((res: IResponse<IDoctorPatient>) => { 
                    if (res) {                         
                        this._responseDetailCache$.clear(); 
                        return res;
                    }
                }), 
                //catchError( err =>  { return this.handleError(err)})
            );
    }

}
