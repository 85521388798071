import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ILightboxFile, ICustomLightboxSettings } from "../model/custom-lightbox";
import { Helper } from "../util/helper.util";
import { IUserPicture } from '../model/api/user-picture';
import { IConversationFile } from '../model/api/conversation-file'; 

@Injectable()
export class CustomLightboxService {
    private _customLightboxSettings: BehaviorSubject<ICustomLightboxSettings> = new BehaviorSubject(null);
    customLightboxSettings: Observable<ICustomLightboxSettings> = this._customLightboxSettings.asObservable();

    constructor(private _helper: Helper) { }

    setLightboxSettings(customLightboxSettings: ICustomLightboxSettings): void { 

        this._customLightboxSettings.next(customLightboxSettings);
	}
    
    showLightbox(files: any[], item) {

        if(this._helper.isNullOrUndefined(files) || files.length == 0)
            return;

        if(this._isIUserPicture(files[0])) 
            this.setLightboxSettings({item: item, files: this._prepareUserPictureForLightbox(files, item)});
        
        if(this._isIConversationFile(files[0])) 
            this.setLightboxSettings({item: item, files: this._prepareConversationFileForLightbox(files, item)});
       
    }

    private _prepareUserPictureForLightbox(files: any[], item): ILightboxFile[] {
        let lightboxImages: ILightboxFile[] = [];

        files.map(file => lightboxImages.push({ path: file.image, date: file.created_at, isImage: file.isImage, isVideo: file.isVideo, isAudio: file.isAudio, isPDF: file.isPDF }));

        return lightboxImages;
    }

    private _prepareConversationFileForLightbox(files: any[], item): ILightboxFile[] {
        let lightboxImages: ILightboxFile[] = [];

        files.map(file => lightboxImages.push({ path: file.file, date: file.created_at, isImage: file.isImage, isVideo: file.isVideo, isAudio: file.isAudio, isPDF: file.isPDF }));

        return lightboxImages;
    }

    private _isIUserPicture(object: IUserPicture): object is IUserPicture { 
        return 'image' in object;
    }

    private _isIConversationFile(object: IConversationFile): object is IConversationFile { 
        return 'file' in object;
    }
}
