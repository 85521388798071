<div class="dialog-container">
    <div fxLayout="column" fxFlexFill>  
        <div fxFlex="100">
            <div fxLayout="row" fxFlexFill fxLayout.xs="column">  
                <div fxFlex="50" fxLayout.xs="column">
                    <h1 mat-dialog-title>Maintainer plan</h1> 
                </div>
                <div fxFlex="50" fxLayout.xs="column">
                    <mat-dialog-actions align="end">
                        <button *ngIf="data.closeButton" mat-raised-button mat-button color="primary" mat-dialog-close>Close</button> 
                        <button *ngIf="data.saveButton" mat-raised-button mat-button color="primary" (click)="saveMaintainerPlanDate()">Save</button>
                    </mat-dialog-actions>
                </div>
            </div>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                 <mat-label>Maintainer plan date</mat-label> 
                <input matInput [matDatepicker]="datepickerMaintainerPlan" [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}" required>
                <mat-datepicker-toggle matSuffix [for]="datepickerMaintainerPlan"></mat-datepicker-toggle>
                <mat-datepicker #datepickerMaintainerPlan></mat-datepicker>
            </mat-form-field> 
        </div> 
    </div>
 
</div>
