import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUserExport } from 'src/app/model/api/user-export';
import { IUserExportFilterSettings } from 'src/app/model/base/filter-settings';
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';
import { UserExportService } from 'src/app/service/api/user-export.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { MenuService } from 'src/app/service/state/menu.service';
import { SettingsService } from 'src/app/service/state/settings.service';
import { UserExportFilterStateService } from 'src/app/service/state/user-export-filter-state.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { Helper } from 'src/app/util/helper.util';
import { DetailBaseComponent } from '../../inc/base/detail-base-componenty';
import { PermissionService } from "../../../service/security/permission.service";
import { SnackBarService } from '../../../service/snackbar.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent extends DetailBaseComponent implements OnInit {

    menuId: number = 301; 
    userExports: IUserExport[];
    uploadFile: File;
    dataSource = new MatTableDataSource<IUserExport>();
    moment: any = moment;     
    filterSettings: IUserExportFilterSettings;
    milisecondsToReload: number = 30000;
    readonly SAVE_BUTTON: string = AppConstants.SAVE_BUTTON;  
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _reportCurrentUserService: ReportCurrentUserService, private _route: ActivatedRoute, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar, private _userExportService: UserExportService, private _userExportFilterStateService: UserExportFilterStateService, private _permissionService: PermissionService, private _snackBarService: SnackBarService) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();

        this._permissionService.checkPermissions(['app.userexport.add_userexport']).then(hasPermission => {
            if (!hasPermission) {
                this._snackBarService.add('You have no permission for this action');
                return false;
            }

            this._loadData();
        });
    }

    private _loadData(){
        this._userExportFilterStateService.currentFilterSettings
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(async(filterSettings) => {
                this.filterSettings = filterSettings as IUserExportFilterSettings;  

                this.getUserExports();
            });

        const reloadUserExports: Observable<number> = timer(this.milisecondsToReload, this.milisecondsToReload);

        reloadUserExports
            .pipe(takeUntil(this.ngUnsubscribe))    
            .subscribe(val => { 
                    this.getUserExports();
                }
            );
    }

    getUserExports(): void {  

        this._spinnerService.startSpinner();  

        this._userExportService.index({ page: 1, page_size: 50 }) 
            .subscribe(response => { 

                if(response && response.results) {
                    this.userExports = response.results;

                    this.userExports.forEach(userExports => {
                        userExports.created_at =  !this._helper.isNullOrUndefined(userExports.created_at) ? moment.utc(userExports.created_at).toDate() : null;
                        userExports.prettyName = !this._helper.isEmptyOrNull(userExports.file) ? this._helper.getPrettyName(userExports.file) : '';
                    }); 
                    
                } 
    
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            },
            () => { 
                this.dataSource.data = this.userExports;   
            }
        ); 
    }

    saveUserExports(): void {      
        
        this._spinnerService.startSpinner();

        if(this._helper.isNullOrUndefined(this.reportCurrentUser) || this.reportCurrentUser.id == 0) 
            this._snackBar.open('User permission error', 'Close', this.matSnackBarConfig); 

        this._userExportService.save({id: null, created_by: this.reportCurrentUser.id})
            .subscribe(response => {
                if(response) { 
                    this.ngOnInit();
                }            

            this._spinnerService.stopSpinner(); 
        },
		error =>{ error 
            this._snackBar.open('Error creating an export file.', 'Close', this.matSnackBarConfig); 
            this._spinnerService.stopSpinner(); 
		}); 
 
    }

}
