
<div fxLayout="row" fxFlexFill fxLayoutGap="0px" *ngIf="navigationSettings">
    <div fxFlex="50" fxLayoutGap="0px">
        <div fxLayout="column" fxFlexFill fxLayoutGap="0px" fxLayoutAlign="start start" class="button-box space-right vertical-space">
            <div fxFlex="100" fxLayoutGap="0px" *ngIf="reportUserInfo.status == userStatus.AlignersStarted" > 
                <button mat-raised-button mat-button color="primary" (click)="confirmFinishTreatment()">Finish Treatment</button>
            </div>
            <div fxFlex="100" fxLayoutGap="0px" class="space-right vertical-space" *ngIf="alignerFirstWarning">
                <span>Missed aligner progress reminder <span *ngIf="!alignerFirstWarning.notified_user">will be checked on the</span>
                    <span *ngIf="alignerFirstWarning.notified_user">was checked on the</span>: {{ moment(alignerFirstWarning.remind_at).format('DD.MM.Y') }}
                </span>
                <button  mat-raised-button mat-button color="warn" (click)="deleteReminder(alignerFirstWarning)" *ngIf="!alignerFirstWarning.notified_user && !alignerFirstWarning.completed">Cancel</button>
            </div>
            <div fxFlex="100" fxLayoutGap="0px" *ngIf="alignerSecondWarning">
                <span>Missed aligner progress reminder 2 <span *ngIf="!alignerSecondWarning.notified_user">will be checked on the</span>
                    <span *ngIf="alignerSecondWarning.notified_user">was checked on the</span>: {{ moment(alignerSecondWarning.remind_at).format('DD.MM.Y') }}
                </span>
                <button mat-raised-button mat-button color="warn" (click)="deleteReminder(alignerSecondWarning)" *ngIf="!alignerSecondWarning.notified_user && !alignerSecondWarning.completed">Cancel</button>
            </div>
            <div fxFlex="100" fxLayoutGap="0px" *ngIf="alignerLastWarning">
                <span>Final reminder message <span *ngIf="!alignerLastWarning.notified_user">will be sent</span>
                    <span *ngIf="alignerLastWarning.notified_user">was sent</span>: {{ moment(alignerLastWarning.remind_at).format('DD.MM.Y') }}
                </span>
                <button mat-raised-button mat-button color="warn" (click)="deleteReminder(alignerLastWarning)" *ngIf="!alignerLastWarning.notified_user && !alignerLastWarning.completed">Cancel</button>
            </div>
        </div>
    </div>
    <div fxFlex="50" fxLayoutGap="0px">
        <div fxLayout="column" fxLayoutGap="0px">
            <div fxFlex="100" fxLayoutGap="0px" class="button-box space-left vertical-space align-right"> 
                <span class="vertical-space" *ngIf="remindersMissedCount > 1">User missed {{ remindersMissedCount }} aligner reviews.</span>
                <button mat-raised-button mat-button color="warn" (click)="toggleReminderPause(true)" *ngIf="userDetail && !userDetail.pause_reminders">Pause reminders</button> 
                
                <button mat-raised-button mat-button color="warn" (click)="toggleReminderPause(false)" *ngIf="userDetail && userDetail.pause_reminders">Unpause reminders</button> 
            </div>
            <div fxFlex="100" class="button-box space-left vertical-space align-right"> 
                <button mat-raised-button mat-button color="primary" (click)="saveReminder()">Create new reminder</button> 
                <button mat-raised-button mat-button color="primary" (click)="createMultipleReminders()">Create 10 reminders</button> 
                <button mat-raised-button mat-button color="primary" (click)="saveClinicalManagement()">{{ SAVE_BUTTON }}</button> 
            </div> 
        </div> 
    </div>    
</div> 
 
<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>    
    <div fxFlex="60" *ngIf="reminders">
        <h3>Aligner reviews</h3>
        <div fxLayout="column" fxLayoutGap="20px"> 
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px" >
                <div fxFlex="14">Review date</div>
                <div fxFlex="17">Msg sent to user</div> 
                <div fxFlex="17">Status</div> 
                <div fxFlex="52"></div> 
            </div> 
            <div fxLayout="row" *ngFor="let reminder of reminders; let i = index;" fxLayout.lt-sm="column" fxLayoutGap="5px">
                <div *ngIf="!reminder.notified_user"  fxFlex="15" class="date-picker-remind-at">
                    <mat-form-field  appearance="none">
                        <span style="display: block;"  (click)="datepickerRemindAt.open(selectedDate = reminder.remind_at)">{{ moment(reminder.remind_at).format('DD.MM.Y')}}</span>            
                        <input style="display: none;" date="true" time="true"  matInput [matDatepicker]="datepickerRemindAt" [(ngModel)]="selectedDate" (dateChange)="saveReminder(reminder, selectedDate)">  
                        <mat-datepicker #datepickerRemindAt (opened)="openMatDatePicker(reminder)"></mat-datepicker>
                        <div style="display: none">
                            <div class="datepicker-footer" #datepickerFooter>
                                <button mat-raised-button color="primary" (click)="today()" #todayButton>
                                    Today
                                </button> 
                            </div>
                        </div> 
                    </mat-form-field>
                    
                </div>

                <div fxFlex="14" *ngIf="reminder.notified_user">
                    <span>{{ moment(reminder.remind_at).format('DD.MM.Y')}}</span>
                </div> 

                <div fxFlex="3" > 
                    <mat-icon mat-list-icon class="done" *ngIf="reminder.notified_user">done</mat-icon> 
                </div>
                <div fxFlex="14">
                    <span  *ngIf="reminder.notified_user && reminder.notified_user_at">
                        {{ moment(reminder.notified_user_at).format('DD.MM.Y') }}
                    </span> 
                </div>

                <div fxFlex="3" *ngIf="reminder.completed"> 
                    <mat-icon mat-list-icon class="done">done</mat-icon>
                </div> 
                <div fxFlex="14" *ngIf="reminder.completed && reminder.completed_at">
                    {{ moment(reminder.completed_at).format('DD.MM.Y') }}
                </div> 

                <div fxFlex="69" *ngIf="!reminder.completed">
                    <button *ngIf="!reminder.user_missed_review && reminder.notified_user" mat-raised-button mat-button color="accent" (click)="markAsComplete(reminder)">{{ MARK_AS_COMPLETE_BUTTON }}</button> 
                     <span *ngIf="reminder.user_missed_review" mat-raised-button mat-button>Missed review</span> 
                     <button *ngIf="!reminder.notified_user"  mat-raised-button mat-button color="warn" (click)="deleteReminder(reminder)">{{ REMOVE_BUTTON }}</button> 
                 </div> 

                <div fxFlex="52" *ngIf="reminder.completed && reminder.notified_user"> 
                    U:
                    <input type="text" class="tinyTextbox" [(ngModel)]="reminder.upper_arch" (blur)="saveReminder(reminder)" />
                    <input type="text" class="tinyTextbox" [(ngModel)]="reminder.aligner_fit_upper" (blur)="saveReminder(reminder)" />
                    L:
                    <input type="text" class="tinyTextbox" [(ngModel)]="reminder.lower_arch" (blur)="saveReminder(reminder)" />
                    <input type="text" class="tinyTextbox" [(ngModel)]="reminder.aligner_fit_lower" (blur)="saveReminder(reminder)" />
                 </div> 
                
            </div> 
        </div>
    </div>
    <div *ngIf="userDetail" fxFlex="40">
        <h3>Current status</h3>
       <div fxLayout="column" fxLayoutGap="20px"> 
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="30px" >
                <div fxFlex="50">
                    <strong>Aligner progress</strong>                      
                </div>
                <div fxFlex="50">
                    <section>
                        <mat-checkbox [(ngModel)]="userDetail.in_refinement" >In refinement</mat-checkbox> 
                    </section>                    
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="custom-control" >
                <div fxFlex="48">
                    Top 
                    <mat-form-field> 
                        <mat-select [(ngModel)]="userDetail.aligner_progress_top" >
                            <mat-option value="null">{{ SELECT_ALIGNER_SELECT_OPTION }}</mat-option>
                            <mat-option *ngFor="let aligner of alignerListForDropdown" [value]="aligner">                                     
                                {{ aligner }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
                <div fxFlex="48">
                    Top aligner fit
                    <mat-form-field>
                        <mat-select [(ngModel)]="userDetail.aligner_fit_upper">
                            <mat-option value="null">{{ ALIGNER_FIT_SELECT_OPTION }}</mat-option>
                            <mat-option *ngFor="let alignerFit of alignerFitGradeListForDropDown" [value]="alignerFit">
                                {{ alignerFit }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="custom-control" >
                <div fxFlex="48">
                    Bottom
                    <mat-form-field>
                        <mat-select [(ngModel)]="userDetail.aligner_progress_bottom">
                            <mat-option value="null">{{ SELECT_ALIGNER_SELECT_OPTION }}</mat-option>
                            <mat-option *ngFor="let aligner of alignerListForDropdown" [value]="aligner">
                                {{ aligner }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
                <div fxFlex="48">
                    Bottom aligner fit
                    <mat-form-field> 
                        <mat-select [(ngModel)]="userDetail.aligner_fit_lower">
                            <mat-option value="null">{{ ALIGNER_FIT_SELECT_OPTION }}</mat-option>
                            <mat-option *ngFor="let alignerFit of alignerFitGradeListForDropDown" [value]="alignerFit">
                                {{ alignerFit }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
            </div>
            <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="20px" >
                <div fxFlex="100">
                    <strong>Aligner progress options</strong>                      
                </div>
                <div fxFlex="100" *ngIf="straightbox">
                    <mat-form-field> 
                        <mat-select [(ngModel)]="straightbox.aligner_progress_options">
                            <mat-option [value]="null">-</mat-option>
                            <mat-option *ngFor="let alignerProgressOption of alignerProgressOptionsForDropDown" [value]="alignerProgressOption.key">
                                {{ alignerProgressOption.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>         
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="20px" >
                <mat-divider [inset]="true"></mat-divider>
            </div>
 
            <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="20px" class="custom-control" >
                <div fxFlex="100">
                    <strong>Progress</strong>                      
                </div>

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" >
                    <div fxFlex="50">
                        <div class="image-box" *ngIf="straightbox && straightbox.review_image_first">
                            <img src="{{ straightbox.review_image_first }}" />
                        </div>  
                        <button mat-raised-button mat-button color="primary" (click)="reviewStatusImageFirst.click()">
                            <span class="material-icons">add</span> {{ UPLOAD }}
                            <span style="display: none;">
                                <input #reviewStatusImageFirst (change)="uploadReviewStatusImage($event, 'review_image_first')" [accept]="allowedExtensions" type="file" style="display: none;" >
                            </span>                                    
                        </button> 
                    </div>
                    <div fxFlex="50">
                        <div class="image-box" *ngIf="straightbox && straightbox.review_image_last">
                            <img src="{{ straightbox.review_image_last }}" />
                        </div>  
                        <button mat-raised-button mat-button color="primary" (click)="reviewStatusImageLast.click()">
                            <span class="material-icons">add</span> {{ UPLOAD }}
                            <span style="display: none;">
                                <input #reviewStatusImageLast (change)="uploadReviewStatusImage($event, 'review_image_last')" [accept]="allowedExtensions" type="file" style="display: none;" >
                            </span>                                    
                        </button> 
                    </div> 
                </div> 
            </div> 
            <div fxLayout="column" fxLayoutGap="20px" >
                <mat-divider [inset]="true"></mat-divider>
            </div>
            <div fxLayout="column" fxLayoutGap="20px" >
                <div fxFlex="100">
                    <strong>Forecast / Treatment notes</strong> 
                </div>
                <div fxFlex="100">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Treatment notes</mat-label>
                        <textarea matInput [(ngModel)]="userDetail.treatment_notes" rows="5" placeholder="Enter treatment notes"></textarea>
                    </mat-form-field> 
                </div>
            </div>
            <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="20px" >
                <div fxFlex="100">
                    <strong>Lost & Broken aligner tracker</strong> 
                </div>
                <div fxFlex="100">
                    <div fxLayout="row" fxLayoutGap="20px"  style="margin-bottom: 10px;">
                        <div fxFlex="25">Nb reported</div>
                        <div fxFlex="75" *ngIf="lostAlignerTrackers">
                             <div fxLayout="row">
                                <div fxFlex="25" *ngFor="let lostAlignerTracker of lostAlignerTrackers">
                                    <input type="number" min="1" class="smallTextbox" [(ngModel)]="lostAlignerTracker.number_of_lost_or_broken_aligners" [disabled]="lostAlignerTracker.date_of_entry_lost_or_broken_aligners" />
                                    <br />
                                    <span class="smallDate" *ngIf="lostAlignerTracker.date_of_entry_lost_or_broken_aligners">
                                        {{ moment(lostAlignerTracker.date_of_entry_lost_or_broken_aligners).format('DD.MM.Y') }}
                                    </span> 
                                </div> 
                                <div fxFlex="25" *ngIf="(lostAlignerTrackers == null || lostAlignerTrackers.length < 3) && newLostAlignerTracker">
                                     <input type="number" min="1" class="smallTextbox" [(ngModel)]="newLostAlignerTracker.number_of_lost_or_broken_aligners" /> 
                                </div> 
                            </div> 
                        </div> 
                    </div>  
                    <div fxLayout="row" fxLayoutGap="20px" >
                        <div fxFlex="25">Nb free replacements</div>
                        <div fxFlex="75" *ngIf="lostAlignerTrackers">
                            <div fxLayout="row">
                                <div fxFlex="25" *ngFor="let lostAlignerTracker of lostAlignerTrackers">
                                    <input type="number" min="1" class="smallTextbox" [(ngModel)]="lostAlignerTracker.number_of_free_replacements" [disabled]="lostAlignerTracker.date_of_entry_free_replacements"  />
                                    <br />
                                    <span class="smallDate" *ngIf="lostAlignerTracker.date_of_entry_free_replacements">
                                        {{ moment(lostAlignerTracker.date_of_entry_free_replacements).format('DD.MM.Y') }}
                                    </span> 
                                </div> 
                                <div fxFlex="25" *ngIf="(lostAlignerTrackers == null || lostAlignerTrackers.length < 3) && newLostAlignerTracker">
                                    <input type="number" min="1" class="smallTextbox" [(ngModel)]="newLostAlignerTracker.number_of_free_replacements" /> 
                                </div>
                            </div> 
                        </div>  
                    </div> 
                </div>
            </div>
           
        </div> 
    </div> 
    
</div>
<br />
<mat-divider></mat-divider>
<br />
<div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill> 
    <div fxFlex="100">
        <h3>Clinical issues</h3>
    </div>
    <div fxFlex="100">
        <div fxLayout="column"  fxLayout.lt-sm="column" fxLayoutGap="0px">
            <mat-list role="list" *ngFor="let clinicalIssue of clinicalIssues; let i = index;">
                <mat-list-item role="listitem" *ngIf="clinicalIssue.created_at">                    
                    <div mat-line *ngIf="clinicalIssue.created_at"><strong>{{ moment(clinicalIssue.created_at).format('DD.MM.Y') }}</strong></div>
                    <div mat-line> {{clinicalIssue.text}} </div> 
                </mat-list-item> 
                <mat-divider></mat-divider>
            </mat-list> 
        </div>
    </div>
    <div fxFlex="100" *ngIf="clinicalIssue">
        <mat-form-field style="width: 100%;">
            <mat-label>Clinical issue</mat-label>
            <textarea matInput [(ngModel)]="clinicalIssue.text" rows="5" placeholder="Record new issue"></textarea>
        </mat-form-field> 
    </div>
</div>

