<div fxLayout="row" fxFlexFill fxLayoutAlign="end end" fxLayoutGap="20px" *ngIf="navigationSettings && smilebox">
    <div fxFlex="100" class="button-box" *ngIf="reportUserInfo" fxLayoutAlign="end start">
        <div fxLayout="column" fxLayoutGap="20px"> 
            <button *ngIf="smileBoxAdminReviews && smileBoxAdminReviews.length > 0 && !straightbox && !userStatusHelper.isTreatmentPlanSent(reportUserInfo.status)"  mat-raised-button mat-button color="primary" (click)="createStraightbox()">Send Treatment Plan</button> 
            <div *ngIf="straightbox" class="button-box space-left;" > 
                <button mat-raised-button mat-button color="primary" *ngIf="[userStatus.TreatmentPlanSent].includes(reportUserInfo.status) && straightbox.treatment_plan_message_sent_at == null" (click)="treatmentPlanReviewMessage()">Send</button> 
                <button mat-raised-button mat-button color="primary" *ngIf="[userStatus.TreatmentPlanSent].includes(reportUserInfo.status) && straightbox.treatment_plan_message_sent_at == null && !straightbox.treatment_plan_message_sent_manually" (click)="treatmentPlanReviewMessageSentManually()">Sent manually</button>
            </div>  
            <div *ngIf="straightbox" style="text-align: right;">                 
                <div *ngIf="reportUserInfo.status == userStatus.TreatmentPlanSent && (straightbox.treatment_plan_message_sent_at == null || straightbox.treatment_plan_message_sent_at == null && !straightbox.treatment_plan_message_sent_manually)">Treatment plan message</div> 
                <div *ngIf="straightbox.treatment_plan_message_sent_at && !straightbox.treatment_plan_message_sent_manually">Treatment plan message sent</div> 
                <div *ngIf="straightbox.treatment_plan_message_sent_manually">Treatment plan message sent manually</div> 
                <div *ngIf="straightbox.treatment_plan_message_sent_at"><strong>{{ straightbox.treatment_plan_message_sent_at != null ? moment(straightbox.treatment_plan_message_sent_at).format('DD.MM.Y') : '' }}</strong></div>             
            </div>
        </div>
    </div>    
</div> 
<h3>User Details</h3>
<div *ngIf="smilebox" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill fxLayoutGap="20px">    
    <div fxFlex="50">
         <div fxLayout="column" fxLayoutGap="20px"> 
 
            <div fxLayout="row"   fxLayoutGap="20px" >
                <div fxFlex="20">Full name</div>
                <div fxFlex="80"> {{ smilebox.first_name }} {{ smilebox.last_name }}</div> 
            </div>
            <div fxLayout="row" fxLayout.lt-xs="column"  fxLayoutGap="40px"  *ngIf="(smilebox && smilebox.doctor == null)">
                <div fxFlex="100"><strong>User has no dentist assigned.</strong></div> 
            </div> 
            <mat-divider [inset]="true"></mat-divider>
        </div>
    
        <div fxLayout="column">   
            <mat-list *ngFor="let smileBoxAdminReview of smileBoxAdminReviews; let iter = index" >
                <mat-list-item> 
                    <a class="example-ripple-container mat-elevation-z4 download" matRipple [matRippleCentered]="true" href="{{ smileBoxAdminReview.doctor_analysis }}" target="_blank" ><span class="material-icons">download</span> Download treatment plan</a> 
                    <span>{{ smileBoxAdminReview.created_at != null ? moment(smileBoxAdminReview.created_at).format('DD.MM.Y HH:mm') : '' }}</span> 
                </mat-list-item>
                <mat-list-item> 
                    <h4 *ngIf="smileBoxAdminReview.admin_seen && smileBoxAdminReview.admin_approved">
                        {{ CURRENT_STATUS }}: <strong class="accent-color">V{{ iter +1 }} APPROVED</strong>
                    </h4>
                    <h4 *ngIf="!smileBoxAdminReview.admin_seen">
                        {{ CURRENT_STATUS }}: <strong style="color:#4A7CB1">NOT REVIEWED</strong>
                    </h4>
                    <h4 *ngIf="smileBoxAdminReview.admin_seen && !smileBoxAdminReview.admin_approved">
                        {{ CURRENT_STATUS }}: <strong class="warn-color">NOT APPROVED</strong>
                    </h4>
                </mat-list-item>
                <mat-list-item *ngIf="!smileBoxAdminReview.admin_seen || iter == smileBoxAdminReviews.length-1"> 
                    <div *appUserPermissionCheck="['app.smileboxadminreview.change_smileboxadminreview']">
                        <button *ngIf="!smileBoxAdminReview.admin_approved" mat-raised-button mat-button type="button" color="primary" (click)="approveTreatmentPlan(smileBoxAdminReview, false)">{{ REJECT_BUTTON }}</button> 
                        <button *ngIf="!smileBoxAdminReview.admin_approved" mat-raised-button mat-button type="button" color="primary" (click)="approveTreatmentPlan(smileBoxAdminReview, true)">{{ APPROVE_BUTTON }}</button>
                    </div> 
      
                    <button *ngIf="iter == smileBoxAdminReviews.length-1"  mat-raised-button mat-button type="button" color="warn" (click)="deleteTreatmentPlanFile(smileBoxAdminReview)">{{ DELETE_BUTTON }}</button> 
                </mat-list-item>
                <mat-divider [inset]="true"></mat-divider>
            </mat-list>
            <mat-list *ngIf="(smilebox && smilebox.doctor) && (addNewTreatmentPlanDoctor || (lastAdminApproved || addNewTreatmentPlanAdmin))">
                <mat-list-item>
                    <button mat-raised-button mat-button color="accent" (click)="uploadNewTreatmentPlan.click()">
                        <span class="material-icons">add</span> Upload treatment plan
                        <span style="display: none;">
                            <input #uploadNewTreatmentPlan (change)="uploadTreatmentPlanFile($event)" [accept]="allowedExtensions"  id="uploadNewTreatmentPlan" type="file" style="display: none;" >
                        </span> 
                    </button> 
                </mat-list-item>
                <mat-divider [inset]="true"></mat-divider>
            </mat-list> 
        </div>
    </div>
    <div fxFlex="50" *appUserPermissionCheck="['app.doctorpatients.add_doctorpatients']">
        <div fxLayout="column" fxLayoutGap="20px" *ngIf="smilebox && smilebox.smilebox_shipped_date">   
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
                <div fxFlex="100">
                    ASSIGNED DENTIST: 
                    <strong *ngIf="doctorPatient && !_helper.isEmptyOrNull(doctorPatient.user__full_name)">{{ doctorPatient.user__full_name }}</strong>
                    <strong *ngIf="!doctorPatient || _helper.isEmptyOrNull(doctorPatient.user__full_name)">{{ NOT_ASSIGNED }}</strong>
                </div> 
            </div> 
            <div fxLayout="column" fxLayoutGap="20px" >
                <div fxFlex="100" >
                    <mat-form-field *ngIf="doctors" > 
                        <mat-select [(ngModel)]="seletedDoctor"> 
                          <mat-option *ngFor="let doctor of doctors" [value]="doctor">
                            {{ doctor.full_name }}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="100" >
                    <button mat-raised-button mat-button color="primary" (click)="saveDoctor()">{{ smilebox.doctor ? 'Change dentist' : 'Assign dentist' }}</button> 
                </div> 
            </div> 
        </div> 
        
    </div> 
</div>
 

