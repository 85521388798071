import {Injectable} from '@angular/core';
import {HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {SecurityService} from "../service/security/security.service";
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from  'rxjs/operators';
import {throwError} from 'rxjs';
import { SpinnerService } from '../service/spinner.service';


@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(private _securityService: SecurityService, private router: Router, private _spinnerService: SpinnerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(!this._securityService.isLoggedIn())  {
            this._securityService.logoutUser();
            this.router.navigate(['/login']);
            return next.handle(request);  
        }
 
        request = request.clone({
            setHeaders: { 
                'Authorization': this._securityService.retrieveToken()
            }
        });
        
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    this._securityService.logoutUser();
                    this._spinnerService.stopSpinner(); 
                    this.router.navigate(['/login']);
                }
                return throwError(err);
            }),
        );
    }
 
}