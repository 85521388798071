import { ChangeDetectorRef, Component, OnInit, ViewChild, TrackByFunction } from '@angular/core';
import { forkJoin, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { ConversationService } from '../../../service/api/conversation.service'; 
import { IConversation } from '../../../model/api/conversation'; 
import { IConversationMessage } from '../../../model/api/conversation-message'; 
import { IConversationMessageFile } from "../../../model/api/conversation-message-file";
import * as moment from 'moment'; 
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util';
import { HelperReplaceVariablesInText } from '../../../util/helper.replace.variables.in.text';
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service';
import { MatAccordion } from '@angular/material/expansion'; 
import { AppConstants }   from '../../../settings/app-constants'; 
import { ConversationMessageService } from '../../../service/api/conversation-message.service';
import { ConversationMessageFileService } from '../../../service/api/conversation-message-file.service';
import { IUserProfile } from '../../../model/api/user-profile';
import { IConversationTemplate } from '../../../model/api/conversation-template';
import { ConversationTemplateService } from '../../../service/api/conversation-template.service';
import { SpinnerService } from '../../../service/spinner.service';
import { UserService } from '../../../service/api/user.service';
import { IUser } from '../../../model/api/user';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { UserType } from '../../../settings/user-type';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { takeUntil } from 'rxjs/operators';
import { UserFileService } from '../../../service/api/user-file.service';
import { IMessageResponse } from '../../../model/api/response';
import { StraightboxService } from '../../../service/api/straightbox.service';
import { IFile } from '../../../model/api/file';
import { UserStateService } from '../../../service/state/user-state.service';

@Component({
	selector: 'app-conversation',
	templateUrl: './conversation.component.html',
	styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent extends DetailUserBaseComponent implements OnInit {
 
    conversations: IConversation[];
    conversationTemplates: IConversationTemplate[];
    userId: number;
    conversationId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 104;
    expand: boolean;    
    moment: any = moment;
    @ViewChild('matAccordion',{static:false}) matAccordion: MatAccordion;
    userProfile: IUserProfile;
    openedExpansionPanels: number = 0;    
    expandAll: boolean;
    openedConversationIds: number[] = [];
    user: IUser;
    userType = UserType;
    secondsAllowedToDeleteMessage: number = 300; 
    canAddAfterImage: boolean = true;
    readonly EXPAND_ALL_LINK:string = AppConstants.EXPAND_ALL_LINK;
    readonly ADD_NEW_LINK:string = AppConstants.ADD_NEW_LINK;
    readonly SHRINK_ALL:string = AppConstants.SHRINK_ALL;
    readonly EXPAND_ALL:string = AppConstants.EXPAND_ALL;
	readonly ADD_NEW:string = AppConstants.ADD_NEW;
    readonly EXPORT:string = AppConstants.EXPORT;
    readonly REPLY:string = AppConstants.REPLY;
    readonly DELETE_BUTTON:string = AppConstants.DELETE_BUTTON;
    readonly CONVERSATION_FILES_MODEL:string = AppConstants.CONVERSATION_FILES_MODEL; 
    readonly ADD_AFTER_FILE_TYPE:string = AppConstants.ADD_AFTER_FILE_TYPE;

	constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _conversationService: ConversationService, private _conversationMessageService: ConversationMessageService, private _conversationMessageFileService: ConversationMessageFileService, private _conversationTemplateService: ConversationTemplateService, private _userFileService: UserFileService, private _straightboxService: StraightboxService, private _route: ActivatedRoute, private _helper: Helper, private _helperReplaceVariablesInText: HelperReplaceVariablesInText, private _cd: ChangeDetectorRef, private _spinnerService: SpinnerService, private _snackBar: MatSnackBar) {
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
     }

	ngOnInit(): void { 
        super.ngOnInit();

        this.route.params.subscribe(params => {
            this.checkAndOpenConversations();
        });
        
        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
                this.getConversationTemplates(this.user); 
			});

        this.getConversations();
  
	}

	getConversationTemplates(user: IUser): void { 
        this._conversationTemplateService.index({ category: 'reply'})
			.subscribe(conversationTemplates => { 
                // this.conversationTemplates = this._helper.replaceConversationPatterns(conversationTemplates.results, user);
                this.conversationTemplates = conversationTemplates.results;
			});
	}

    getConversations(): void {  
        this._spinnerService.startSpinner();
        this._conversationService.index({patient_id: this.userId, page: 1, page_size: 200})
			.subscribe(data => {
                this.conversations = data.results;
                this.conversations.forEach(conversation => {
                        conversation.last_message_at = !this._helper.isNullOrUndefined(conversation.last_message_at) ? moment.utc(conversation.last_message_at).toDate() : null;
                });  
                this.checkAndOpenConversations();
                this._spinnerService.stopSpinner(); 
			},
            error =>{  
                this._spinnerService.stopSpinner();  
            });
	}

    checkAndOpenConversations(){

        if(!this.conversations)
            return;

        this._spinnerService.startSpinner();

        let tmpStatus = this.route.snapshot.paramMap.get('status');
        this.expandAll = (tmpStatus == this.EXPAND_ALL_LINK) ? true : false;

        this.openedConversationIds = [];
        if(tmpStatus && !this.expandAll) {
            this.openedConversationIds = tmpStatus.split(',').filter(item => { return Number(item) }).map(Number);
        }
        
        let conversationIdsForMessageAndFileRequest = [];

        this.conversations.forEach(conversation => {
            conversation.isReplyButtonVisible = true;
            
            if (this.expandAll || this.openedConversationIds.includes(conversation.id)){
                conversationIdsForMessageAndFileRequest.push(conversation.id);
                conversation.isExpanded = true;
                return true;
            }

            conversation.messages = null;
            conversation.isExpanded = false;

            // if (!this.expandAll && !this.openedConversationIds.includes(conversation.id)){
            //     conversation.messages = null;
            //     conversation.isExpanded = false;
            //     //this._spinnerService.stopSpinner(); 
            //     return true;
            // }

            // conversationIdsForMessageAndFileRequest.push(conversation.id);

            //this.getConversationMessagesAndFiles(conversation.id);
            //this._spinnerService.stopSpinner(); 
        },
        error =>{  
            this._spinnerService.stopSpinner();  
        });
        this._spinnerService.stopSpinner();  
        this.getConversationMessagesAndFilesForMultipleConversations(conversationIdsForMessageAndFileRequest);
    }

    getConversationMessagesAndFiles(conversationId:number, isReplyMessageSent = false) {

        if(this._helper.isNullOrUndefined(this.conversations)) 
            return; 

        this._spinnerService.startSpinner();

        let selectedConversation: IConversation = this.conversations.find(conversation => conversation.id == conversationId);

        forkJoin([this._conversationMessageService.index({conversation_id: selectedConversation.id}), this._conversationMessageFileService.index({conversation_id: selectedConversation.id})]).subscribe(conversationItems => {
            let conversationMessages: IConversationMessage[] = conversationItems[0].results;
            let conversationMessageFiles: IConversationMessageFile[] = conversationItems[1].results;

            selectedConversation.messages = conversationMessages != null ? conversationMessages : null; 

            let conversationMessagesHash: { [id: number] : IConversationMessage; } = conversationMessages.reduce((a,x) => ({...a, [x.id]: x}), {});
            
            selectedConversation.messages.forEach(conversationMessage => { 
                conversationMessage.text = conversationMessage.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                conversationMessage.created_at = !this._helper.isNullOrUndefined(conversationMessage.created_at) ? moment.utc(conversationMessage.created_at).toDate() : null;

                this.showDeleteButton(selectedConversation, conversationMessage);

                conversationMessage.files = null;
            });

            conversationMessageFiles.forEach(conversationMessageFile => {
                let conversationMessage = conversationMessagesHash[conversationMessageFile.message];

                if(this._helper.isNullOrUndefined(conversationMessage.files)) {                                   
                    conversationMessage.files = [];
                }
                conversationMessage.files.push(conversationMessageFile);
            });

            selectedConversation.isExpanded = true;
            this._spinnerService.stopSpinner(); 
        },
        error =>{  
            this._spinnerService.stopSpinner();  
        });

        
    }

    getConversationMessagesAndFilesForMultipleConversations(conversationIds:number[]) {

        if(conversationIds.length == 0) 
            return; 

        this._spinnerService.startSpinner();

        forkJoin([this._conversationMessageService.index({conversation_id__in: conversationIds, page_size: 1000}), this._conversationMessageFileService.index({conversation_id__in: conversationIds, page_size: 1000})]).subscribe(conversationItems => {
            let conversationMessages: IConversationMessage[] = conversationItems[0].results;
            let conversationMessageFiles: IConversationMessageFile[] = conversationItems[1].results;


            conversationMessages.forEach(conversationMessage => {
                conversationMessage.files = null;
            });

            let conversationsHash: { [id: number] : IConversation; } = this.conversations.reduce((a,x) => ({...a, [x.id]: x}), {});
            let conversationMessagesHash: { [id: number] : IConversationMessage; } = conversationMessages.reduce((a,x) => ({...a, [x.id]: x}), {});

            conversationMessageFiles.forEach(conversationMessageFile => {
                let conversationMessage = conversationMessagesHash[conversationMessageFile.message];

                if(this._helper.isNullOrUndefined(conversationMessage.files)) {                                   
                    conversationMessage.files = [];
                }
                
                /* conversationMessageFile.prettyName = this._helper.getPrettyName(conversationMessageFile.file);
                conversationMessageFile.extension = this._helper.getExtension(conversationMessageFile.file);
                conversationMessageFile.isImage = this._helper.isImage(conversationMessageFile.extension);
                conversationMessageFile.isVideo = this._helper.isVideo(conversationMessageFile.extension);
                conversationMessageFile.isPDF = conversationMessageFile.extension === 'pdf';
                console.log('conversationMessageFile');
                console.log(conversationMessageFile); */
                conversationMessage.files.push(conversationMessageFile);
            });

            let conversationIdSeen: number[] = [];

            conversationMessages.forEach(conversationMessage => {
                //let conversation = this.conversations.find(conversation => conversation.id == conversationMessage.conversation);

                let conversation = conversationsHash[conversationMessage.conversation];

                if(conversationIdSeen.indexOf(conversationMessage.conversation) === -1) {
                    conversationIdSeen.push(conversationMessage.conversation);
                    conversation.messages = [];
                }

                conversationMessage.text = conversationMessage.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                conversationMessage.created_at = !this._helper.isNullOrUndefined(conversationMessage.created_at) ? moment.utc(conversationMessage.created_at).toDate() : null;

                if(this._helper.isNullOrUndefined(conversation.messages)) {                                   
                    conversation.messages = [];
                }
                conversation.messages.push(conversationMessage);

                this.showDeleteButton(conversation, conversationMessage);

                conversation.isExpanded = true;
            });



            /*
            selectedConversation.messages = conversationMessages != null ? conversationMessages : null; 
            
            selectedConversation.messages.forEach(conversationMessage => { 
                conversationMessage.text = conversationMessage.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
                conversationMessage.created_at = !this._helper.isNullOrUndefined(conversationMessage.created_at) ? moment.utc(conversationMessage.created_at).toDate() : null;

                this.showDeleteButton(selectedConversation, conversationMessage);

                if(this._helper.isNullOrUndefined(conversationMessageFiles))
                    return;

                conversationMessageFiles.find(conversationMessageFile => {
                    if(conversationMessageFile.message === conversationMessage.id) {
                        if(this._helper.isNullOrUndefined(conversationMessage.files)) {                                   
                            conversationMessage.files = [];
                        }
                        conversationMessage.files.push(conversationMessageFile);
                    }
                }); 
            });

            selectedConversation.isExpanded = true;
            */
            this._spinnerService.stopSpinner(); 
        },
        error =>{  
            this._spinnerService.stopSpinner();  
        });

        
    }

    showDeleteButton(conversation: IConversation, conversationMessage: IConversationMessage):void {

        if(conversation.user_seen || !conversationMessage.created_at)
            return; 
        
        let now = moment();
        let createdAtDelayAdded: moment.MomentInput = moment(conversationMessage.created_at).add(this.secondsAllowedToDeleteMessage, 'seconds');
        
        if(moment(now).isAfter(createdAtDelayAdded))
            return; 

        conversation.isDeleteMessageAllowed = true;
        
        let timeRemainingForDelete: number = moment.duration(createdAtDelayAdded.diff(now)).asMilliseconds();
        const timeRemainedForDelete: Observable<number> = timer(timeRemainingForDelete);

        timeRemainedForDelete
            .pipe(takeUntil(this.ngUnsubscribe))    
            .subscribe(val => { 
                    conversation.isDeleteMessageAllowed = false;
                }
            );
    }

    conversationTrackByFunction(index, conversation: IConversation) { 
        return conversation.id.toString() + conversation.isExpanded;
        //return conversation.id.toString() + (conversation.id ?? false).toString();
        // return conversation.id.toString() + (conversation.messages ?? false).toString(); 
    }

    toggleConversationMessages(conversationId) {
        let indexOfOpenedConversation = this.openedConversationIds.indexOf(conversationId);

        if (indexOfOpenedConversation === -1) {
            this.openedConversationIds.push(conversationId);
        } else {
            this.openedConversationIds.splice(indexOfOpenedConversation, 1);
        }

        this._router.navigate([this.navigationSettings.url, this.openedConversationIds.join(',')]);
        return false;
        //return this.navSettings.url + '/' + this.openedConversationIds.join(',');
    }

    replyConversationMessageChange(selectedConversation: IConversation) {
        if(selectedConversation.isReplyMessageCanceled) {

            this.conversations.find(conversation => { 
                if(conversation.id == selectedConversation.id) {                    
                    this.toggleConversationMessages(selectedConversation.id);
                }
            });
  
            return;
        } 

        // COMMENT: User has not seen message yet. To show delete button set user_seen to false.
        selectedConversation.user_seen = false;

        this._conversationService.clearCache();
        this._conversationMessageService.clearCache();
        this._conversationMessageFileService.clearCache();

        this.getConversationMessagesAndFiles(selectedConversation.id, true);
        //this.checkAndOpenConversations();
    } 

    deleteConversationMessage(conversation: IConversation): void {

        if(this._helper.isNullOrUndefined(conversation) || !conversation.messages || conversation.messages.length == 0)
            return;
  
        conversation.messages.sort((a, b) => b.id - a.id);
        let lastConversationMessage: IConversationMessage = conversation.messages[0];

        this._spinnerService.startSpinner();

        this._conversationMessageService.delete(lastConversationMessage)
            .subscribe(response => { 
                    this._snackBar.open('The message was deleted successfully.', 'Close', this.matSnackBarConfig);

                    this._conversationService.clearCache();
                    this._conversationMessageService.clearCache();
                    this._conversationMessageFileService.clearCache();
                    
                    this.ngOnInit(); 
          
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            });  
        
    }

    exportConversationsAsCsv() {

        this._spinnerService.startSpinner();
        this._conversationService.getConversationsExport<boolean>(this.userId).subscribe(res => {            
            let filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, ''); 
            let contentType = res.headers.get('content-type');
            this.downloadExport(res.body, filename, contentType );
            this._spinnerService.stopSpinner();  
        },
        error =>{ 
            this._snackBar.open('Error downloading file.', 'Close', this.matSnackBarConfig);
            this._spinnerService.stopSpinner();  
        });
	}


    downloadExport(fileContent: any, fileName: string, contentType: string): void {
       
        var linkElement = document.createElement('a');
            try {
                var blob = new Blob([fileContent], { type: contentType });
                var url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute("download", fileName);
                linkElement.click();
                window.URL.revokeObjectURL(url);
                linkElement.remove(); 
            } catch (ex) {
                this._snackBar.open('Error downloading file.', 'Close', this.matSnackBarConfig);
            }  
    }

    addAfterImage(selectedFile:IFile, model: string, type: string): void {
 
        if(this._helper.isNullOrUndefined(selectedFile))
            return;

        this._spinnerService.startSpinner();

        this._userFileService.addBeforeAfterFile<IMessageResponse>(this.userId, { image_id: selectedFile.id.toString(), review_image_type: type, model: model }).subscribe(
            response => { 
                 if(!response.success) 
                    return;

                    this._straightboxService.clearCache();

                this._snackBar.open( type + ' image is added.', 'Close', this.matSnackBarConfig);
                this._spinnerService.stopSpinner();
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
        

    }

}
