<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill fxLayoutAlign="end end" fxLayoutGap="20px" *ngIf="navigationSettings && smilebox">
    <div fxFlex="50" *ngIf="smilebox && !_helper.isNullOrUndefined(smilebox.smilebox_shipped_date)">   
        <div *ngIf="smilebox.impressions_received">
            {{ IMPRESSIONS_RECEIVED }}: <strong>{{ moment(smilebox.impressions_received).format('DD.MM.Y') }}</strong>
        </div> 
    </div> 
    <div fxFlex="50" fxLayoutAlign="end start" class="button-box space-right">
         <button *ngIf="smilebox.status == orderStatus.One" mat-raised-button mat-button color="primary" (click)="shipArchive(orderStatus.Two)"><span class="material-icons">done</span> {{ smilebox.ordered_3d_scan ? SCAN_REFERRAL_DONE : SHIPPED }}</button> 
        <section *ngIf="smilebox && !_helper.isNullOrUndefined(smilebox.smilebox_shipped_date)">
            <mat-checkbox style="margin-right: 10px;" [(ngModel)]="smilebox.bite_photos_received" (change)="bitePhotos()">Bite pics received</mat-checkbox> 
        </section>
        <button *ngIf="smilebox && !_helper.isNullOrUndefined(smilebox.smilebox_shipped_date) && !smilebox.impressions_received && reportUserInfo" mat-raised-button mat-button color="primary" (click)="sendImpressionsDate()">
            {{ reportUserInfo.userorderssmilebox__ordered_3d_scan ? DIGITAL_IMPRESSIONS_RECEIVED : IMPRESSIONS_RECEIVED  }}</button> 
    </div> 
</div> 
<h3 *ngIf="smilebox">{{ smilebox.ordered_3d_scan ? 'User Details' : 'Smilebox Shipping Address'}}</h3>
<div *ngIf="smilebox" fxLayout="column" fxFlexFill>   
    <div fxFlex="100" >
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            <div fxFlex="50" style="margin-bottom: 20px;">
                <div fxLayout="column" fxLayoutGap="20px" *ngIf="user && userDetail"> 
                    <div class="copy" fxLayout="row" fxLayoutGap="20px" *ngIf="user">
                        <div class="copy-attribute" fxFlex="25">ID</div>
                        <div class="copy-value" fxFlex="65" >{{ user.id }}</div> 
                        <div fxFlex="10"> 
                            <span class="copy-user-data">      
                                <button class="copy-button" mat-icon-button (click)="copyUserDataToClipboard(element.nativeElement.querySelectorAll('.copy'))">
                                    <mat-icon matTooltip='Copy user data'>content_copy</mat-icon>
                                </button> 
                            </span> 
                        </div> 
                    </div>
                    <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                        <div class="copy-attribute" fxFlex="25">Name</div>
                        <div class="copy-value break-word" fxFlex="75"> {{ user.full_name }}</div>                         
                    </div>
                    <div class="copy" fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']; IfStatement user">
                        <div class="copy-attribute" fxFlex="25">Email</div>
                        <div class="copy-value break-word" fxFlex="75">{{ user.email }}</div> 
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px"   *appUserPermissionCheck="['app.usermodel.can_view_details']">
                        <div fxFlex="25">Phone</div>
                        <div fxFlex="75">{{ userDetail.country_code != null ? '+' + userDetail.country_code  + userDetail.phone : userDetail.phone }}</div> 
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                        <div fxFlex="25">Street</div>
                        <div fxFlex="75">{{ userDetail.address }}</div> 
                    </div>                    
                    <div fxLayout="row" fxLayoutGap="20px"   *appUserPermissionCheck="['app.usermodel.can_view_details']">
                        <div fxFlex="25">City</div>
                        <div fxFlex="75">{{ userDetail.city }}</div> 
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px"   *appUserPermissionCheck="['app.usermodel.can_view_details']">
                        <div fxFlex="25">Zip code</div>
                        <div fxFlex="75">{{ userDetail.postalCode }}</div> 
                    </div>
                    <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                        <div class="copy-attribute" fxFlex="25">Country</div>
                        <div class="copy-value" fxFlex="75">{{ userDetail.country__name }}</div> 
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" >
                        <div fxFlex="25">Date paid</div>
                        <div fxFlex="75" *ngIf="smilebox.created_at">{{ moment(smilebox.created_at).format('DD.MM.Y') }}
                        </div> 
                    </div>  
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div fxFlex="25">Payment plan</div>
                        <div fxFlex="75">
                            <div fxLayout="row" fxLayoutGap="10px">
                                <div fxFlex="30">
                                    <span *ngIf="smilebox.product_payment__price">{{ smilebox.product_payment__price / 100 | number : '1.2-2'}} {{ smilebox.product_payment__currency.toUpperCase() }}</span>
                                 <!--
                                     <span *ngIf="smilebox.payed" class="text-uppercase text-success">YES</span>
                                    <span class="text-uppercase text-warning" *ngIf="!smilebox.payed">NO</span>-->
                                </div>  
                                <div fxFlex="70" style="text-align: left;" *ngIf="smilebox.product_payment__price_group != null">
                                    <span *ngIf="smilebox.product_payment__price_group != 'general'" style="margin: 0 10px;">Discount code: {{ smilebox.product_payment__price_group.replace('-invalid', '') }}</span>
                                </div> 
                            </div> 
                        </div>
                    </div>                 
                    
                </div>
            </div>
            <div fxFlex="50"> 
                <div fxLayout="row" fxLayoutGap="20px" >
                    <div fxFlex="100" *ngIf="smilebox.status == orderStatus.One && !smilebox.ordered_3d_scan" >
                        <mat-form-field>
                            <mat-label>Tracking number</mat-label>
                            <input matInput [(ngModel)]="smilebox.tracking_number" name="trackingNumber" placeholder="Tracking number">
                        </mat-form-field> 
                    </div> 
                </div>
                <div fxLayout="row" fxLayoutGap="20px" >
                    <div fxFlex="100" *ngIf="smilebox.status == orderStatus.Two" >
                        <mat-card style="margin-bottom: 35px;">
                            Order status: <strong  class="accent-color">{{ smilebox.ordered_3d_scan ? 'RECEIVED' :  'SHIPPED'}}</strong><br /><br />
                            <span>{{ smilebox.ordered_3d_scan ? 'Date referred' :  'Date shipped'}}: <strong>{{ smilebox.smilebox_shipped_date ? moment(smilebox.smilebox_shipped_date).format('DD.MM.Y') : '' }}</strong><br /><br /></span>
                            <span>Impressions received: <strong>{{ smilebox.impressions_received ? moment(smilebox.impressions_received).format('DD.MM.Y') : '' }}</strong><br /><br /></span>
                            <span>Bite pics recievied: <strong>{{ smilebox.bite_photos_received ? 'TRUE' : 'FALSE' }}</strong><br /><br /></span>
                            <span *ngIf="smilebox.tracking_number">Tracking number: <a href="https://mydhl.express.dhl/gb/en/tracking.html#/results?id={{smilebox.tracking_number.split(' ').join('')}}"><strong class="warn-color">{{ smilebox.tracking_number }}</strong></a></span>
                        </mat-card>
                    </div> 
                </div> 
               
            </div>  
        </div> 
    </div> 
      
    <div fxFlex="100">
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px" *ngIf="fileTypes"> 
            <div fxFlex="50">
                <div fxLayout="column" fxLayoutGap="20px" *ngFor="let fileType of fileTypes; let i = index"> 
                    <span *ngIf="fileType.show_to_admin  && fileType.id !== FileType.EconsultationResults"> 
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill> 
                            <div fxFlex="100"><h3>{{ fileType.name }}</h3></div> 
                        </div>
                        
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill *ngIf="fileType.file_upload_enabled || fileType.has_files" >    
                            <div fxFlex="100">
                                <button *ngIf="fileType.file_upload_enabled" mat-raised-button mat-button color="accent" (click)="addNewUpload.click()">
                                    <span class="material-icons">add</span> {{ ADD_NEW }}
                                    <span style="display: none;">
                                        <input #addNewUpload (change)="addNewUploadFile($event, fileType)" [accept]="fileType.id == FileType.VideoSimulation ? videoExtensions : allExtensions" id="addNewUpload" type="file" style="display: none;">  
                                    </span> 
                                </button>
                                <span *ngIf="fileType.file_upload_enabled"><br /><br /></span>
                                 
                                <span *ngFor="let file of files; let i = index"> 
                                    <button *ngIf="fileType.id == file.file_type" mat-raised-button mat-button (click)="openDeleteFileDialog(file)" class="uploaded-file">
                                        {{ file.file_name }}
                                    </button> 
                                </span> 
                            </div> 
                        </div> 
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill *ngIf="[FileType.DentalRecords, FileType.OPGXRay, FileType.BPEGumScores].includes(fileType.id) && smileboxFiles"> 
                            <span *ngFor="let smileboxFile of smileboxFiles; let i = index"> 
                                <a *ngIf="fileType.id == smileboxFile.file_type" href="{{ smileboxFile.medical_documentation }}" target="_blank">
                                    <span class="material-icons">file_download</span>
                                    <span *ngIf="smileboxFile.file_type === fileType.OPGXRay">OPG x-ray</span>
                                    <span *ngIf="smileboxFile.file_type === fileType.BPEGumScores">BPE gum scores</span>
                                </a> 
                            </span>
                        </div>
                        <br />
                        <mat-divider [inset]="true"></mat-divider>
                    </span>
                          
                </div>
            </div>
        </div>
    </div>
</div>