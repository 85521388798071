import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFileType } from '../../model/api/file-type';  
import { APP_CONFIG, IAppConfig, AppConfig } from '../../app.config/app.config';
import { BaseService } from '../base/base.service';

@Injectable({
	providedIn: 'root'
})

export class FileTypeService extends BaseService<IFileType> {
    protected urlV2 = "/admin-v02/file-type";

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

}
