<form #addNewForm="ngForm" (ngSubmit)="addNewComplaint(complaintRequest)" *ngIf="complaintRequest">
    <div fxLayout="column" fxFlexFill fxLayoutGap="20px"> 
        <div fxFlex="100" class="button-box space-left" fxLayoutAlign="end start" *ngIf="navigationSettings"> 
            <button mat-raised-button mat-button color="primary">{{ CONFIRM }}</button>
            <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.parentUrl">{{ CANCEL }}</a>
        </div>
        <div fxFlex="100"> 
            <mat-form-field> 
                <mat-select [(ngModel)]="complaintRequest.category" name="complaintCategories" placeholder="Complaint category" required> 
                    <mat-option *ngFor="let complaintCategory of complaintCategories" [value]="complaintCategory.id">
                        {{ complaintCategory.name }} 
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field>
                <!-- <mat-label>Date</mat-label> -->
                <input matInput [matDatepicker]="datepickerReceivedAt" [(ngModel)]="complaintRequest.received_at" [ngModelOptions]="{standalone: true}" required>
                <mat-datepicker-toggle matSuffix [for]="datepickerReceivedAt"></mat-datepicker-toggle>
                <mat-datepicker #datepickerReceivedAt></mat-datepicker>
            </mat-form-field> 
            <mat-form-field> 
                <mat-select [(ngModel)]="complaintRequest.conversation" [(value)]="complaintRequest.conversation" name="conversations" placeholder="{{ THREAD_CATEGORY }}" [required]="complaintRequest.conversation == null && !complaintRequest.is_from_mail ? true : false"> 
                    <mat-option *ngFor="let conversation of conversations" [value]="conversation.id" (onSelectionChange)="complaintRequest.is_from_mail = false">
                        {{ conversation.subject }} 
                    </mat-option>
                </mat-select>                
            </mat-form-field>           
            <mat-checkbox [(ngModel)]="complaintRequest.is_from_mail" (click)="isConversationFromEmail(!complaintRequest.is_from_mail)" [ngModelOptions]="{standalone: true}">{{ FROM_EMAIL }}</mat-checkbox>        
        </div>
 
        <div fxFlex="100">
            <mat-form-field class="fullLength"> 
                <textarea matInput [(ngModel)]="complaintRequest.text" name="text" rows="15" placeholder="Enter complaint" required></textarea>
            </mat-form-field>  
        </div>
        <div fxFlex="100" >
            <!-- <mat-radio-group [(ngModel)]="conversationRequest.conversation_type" name="conversationType" required>
                <mat-radio-button value="2">Admin</mat-radio-button>
            </mat-radio-group> -->
        </div>
        <br />
    </div>
</form>
