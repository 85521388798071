import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap, shareReplay } from 'rxjs/operators';
import { IFile } from '../../model/api/file'; 
import { APP_CONFIG, IAppConfig, AppConfig } from '../../app.config/app.config';
import { IApiResponseCache } from '../../model/base/response-cache';
import { IFileResponseV1 } from '../../model/api/response';
import { IFileType } from '../../model/api/file-type';
import { BaseService } from '../base/base.service';

@Injectable({
	providedIn: 'root'
})

export class AdminFileService extends BaseService<IFile> {
    protected urlV2 = "/admin-v02/admin-user-document";
    private _urlV1 = this._config.api_url + "/admin/admin-upload-files"; 	
    private _urlDelete = this._config.api_url + "/admin/delete-upload-files"; 	

    public cacheValidForSeconds = 360;
    public responseCache$ = new Map<string, IApiResponseCache<IFileResponseV1<IFile>>>();
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    

    saveFile<T>(userId: number, fileTypeId: IFileType, formData: FormData): Observable<any> {
        let urlFinal = `${this._urlV1}/${userId}/${fileTypeId.id}`; 
 
        return this._http.post(urlFinal, formData)
            .pipe(
                map((res: any) => {                    
                    if (res && res.success) {  
                        return res;
                    }
                }), 
                //catchError( err =>  { return this.handleError(err)})
            ); 
    }

    deleteFile<T>(userId: number, file: IFile): Observable<any> {
        let urlFinal = `${this._urlDelete}/${userId}/${file.id}`; 
 
        return this._http.delete(urlFinal)
            .pipe(
                map((res: any) => {                    
                    if (res && res.success) {  
                        return res;
                    }
                }), 
                //catchError( err =>  { return this.handleError(err)})
            ); 
    }


}

