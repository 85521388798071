import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config/app.config'; 
import { IReportUserInfo } from 'src/app/model/api/report-user-info';  
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})
export class ReportUserInfoService extends BaseService<IReportUserInfo> {

    protected urlV2 = "/admin-v02/report-user-info";

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
}
