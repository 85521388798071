import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { PermissionService } from "../service/security/permission.service";

@Directive({
	selector: '[appUserPermissionCheck]'
})
export class UserPermissionCheckDirective implements OnInit {

	// COMMENT: Example:  
    // *appUserPermissionCheck="['api.file.add_file']"
    // *appUserPermissionCheck="['app.usermodel.can_view_details', 'app.userorderssmilebox.can_set_paid_offline']; Condition 'OR'"
    // *appUserPermissionCheck="['app.usermodel.can_view_details', 'app.userorderssmilebox.can_set_paid_offline']; IfStatement reportUserInfo.status == userStatus.AlignersStarted"
    // *appUserPermissionCheck="['app.usermodel.can_view_details', 'app.userorderssmilebox.can_set_paid_offline']; Condition 'OR'; IfStatement reportUserInfo.status == userStatus.AlignersStarted"
	private needPermission: string[];
    private condition = 'AND';
    private ifStatement: boolean = true;
    private permissionsAll: string[];
    
	constructor(
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef,
		private _permissionService: PermissionService) { }

	@Input()
	set appUserPermissionCheck(permission: string[]) {
		this.needPermission = permission;
	}

    @Input()
	set appUserPermissionCheckCondition(val) {        
		this.condition = val;
	}

    @Input()
	set appUserPermissionCheckIfStatement(val) {  
        
		this.ifStatement = val;

        this.checkIfStatement();
        this.checkPermissions(this.permissionsAll); 
        
	}

	ngOnInit(): void {
		this._permissionService.permission.subscribe(
			response => {
				if (!(response === undefined) && !(response.permission.length == 0)) {
                    this.permissionsAll = response.permission;
                    
					this.checkPermissions(response.permission);
				}
			}
		);
	}


    checkIfStatement() { 
        if (this.ifStatement || !this._viewContainer || this._viewContainer.length === 0) 
            return;

        this._viewContainer.clear();  
    }

	checkPermissions(permissions:string[]) {
        
		let hasPermission = true;
             
        if(!permissions || !this.ifStatement)
            return;

        for (const permision of this.needPermission) {
			const permissionFound = permissions.includes(permision);

            hasPermission = permissionFound ? true : false;
        
            if (hasPermission && this.condition === 'OR') {
                break;
            }

            if (!hasPermission && this.condition === 'AND') {
                break;
            }
		}

		if (!hasPermission)
			return;
 
		this._viewContainer.createEmbeddedView(this._templateRef);

	}
}
