import { NgModule } from '@angular/core';
import { AdminRoutingModule } from '../admin/admin-routing.module';
import { UserAccessDirective} from '../directive/user-access.directive';
import { UserProfileService } from '../service/api/user-profile.service';
import { MenuService } from '../service/state/menu.service';
import { StatusService } from '../service/status.service';
import { UserStateService } from '../service/state/user-state.service';
import { UserFilterStateService } from '../service/state/user-filter-state.service';
import { UserService } from '../service/api/user.service';
import { ReportUserInfoService } from '../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../service/state/report-user-info-state.service';
import { UserModule } from './user/user.module';
import { MessageModule } from './message/message.module';
import { NotificationCenterModule } from './notification-center/notification-center.module';
import { TreatmentPercentageService } from '../service/api/treatment-percentage.service';
import { TreatmentProgressService } from '../service/api/treatment-progress.service';
import { TreatementStatusV1 } from '../service/api/treatment-status.service';
import { ConversationService } from '../service/api/conversation.service';
import { ConversationMessageService } from '../service/api/conversation-message.service';
import { MainMenuComponent } from '../shared/main-menu/main-menu.component';
import { AdminComponent } from '../admin/admin.component';
import { SeverityStatusService } from '../service/severity-status.service';
import { ReportCurrentUserService } from '../service/api/report-current-user.service';
import { ConversationTemplateService } from '../service/api/conversation-template.service';
import { CustomCoreModule } from '../custom-core.module';
import { UserExportModule } from './user-export/user-export.module';
import { LocalStorageUserFilterService } from '../service/base/local-storage-filter.service'
import { ReportUserSubmissionService }  from '../service/api/report-user-submission.service';
import { CustomLightboxComponent } from '../admin/shared/custom-lightbox/custom-lightbox.component'; 
import { CustomLightboxService } from '../service/custom-lightbox.service';
import { CurrentUserComponent } from './shared/current-user/current-user.component'; 

@NgModule({
    declarations: [
        UserAccessDirective,
        AdminComponent,
        MainMenuComponent,
        CustomLightboxComponent,
        CurrentUserComponent
        ],
    exports:  [
        UserAccessDirective,
        CustomLightboxComponent
    ],
    imports: [
        CustomCoreModule,
        AdminRoutingModule,
        UserModule,
        MessageModule,
        NotificationCenterModule,
        UserExportModule
    ],
    providers: [
        UserProfileService,
        MenuService,
        UserService,
        StatusService,
        UserStateService,
        UserFilterStateService,
        SeverityStatusService,
        ReportUserInfoService,
        ReportUserInfoStateService,  
        ReportCurrentUserService, 
        TreatmentPercentageService,
        TreatmentProgressService,
        TreatementStatusV1,
        ConversationService,
        ConversationMessageService,
        ConversationTemplateService,
        LocalStorageUserFilterService,
        ReportUserSubmissionService,
        CustomLightboxService
    ]
})
export class AdminModule { }
