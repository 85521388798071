import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject}  from "rxjs";
import { INavigationSettings, ISettings, Settings } from '../../model/settings';

@Injectable({
  	providedIn: 'root'
}) 

export class SettingsService {
    private _settings: BehaviorSubject<ISettings> = new BehaviorSubject(null);
    settings: Observable<ISettings> = this._settings.asObservable();
	  
	constructor() { }

	setSettingsUserLogged(value: boolean): void {
		let newSettings:ISettings = this._getSettings();         
		newSettings.isUserLogged = value;		
        this._settings.next(newSettings);
	}
	
	setNavigationSettings(navSettings: INavigationSettings): void {
		let newSettings:ISettings = this._getSettings(); 
		newSettings.navigationSettings = navSettings; 
        this._settings.next(newSettings);
	}

	setSettingsIsMobileTablet(value: boolean): void {
		let newSettings:ISettings = this._getSettings(); 
		newSettings.isMobileTablet = value;	
        this._settings.next(newSettings);
	}
		
	private _getSettings(): ISettings {
		let settings:ISettings;

		if(this._settings.value == null) {			
			settings  = new Settings();
			//settings.isUserLogged = this.securityService.isLoggedIn();
			return settings;
	   	}
 		
		settings = this._settings.value;			
 
	   	return settings;
	}

}
