import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/app.config/app.config';  
import { BaseService } from '../base/base.service'; 
import { IUserExport } from 'src/app/model/api/user-export';


@Injectable({
  	providedIn: 'root'
})
 
export class UserExportService extends BaseService<IUserExport> {
 
    protected urlV2 =  "/admin-v02/user-export";   
    protected _cacheValidForSeconds = 0;

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }	

}
