<div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="0px" *ngIf="navigationSettings && reportUserInfo">  
    <div fxFlex="50" class="button-box space-right vertical-space">
        <span *appUserPermissionCheck="['app.userorderssmilebox.can_set_paid_offline']; IfStatement reportUserInfo.status == userStatus.EconsultationAccepted" class="button-box space-right vertical-space">
            <button mat-raised-button mat-button color="primary" (click)="smileboxOfflinePayment()">SmileBox Paid Offline</button>
            <button mat-raised-button mat-button color="primary" (click)="threeDScanOfflinePayment()">3D Scan Paid Offline</button>
        </span>
        <button mat-raised-button mat-button color="primary" *appUserPermissionCheck="['app.userstraightbox.can_set_paid_offline']; IfStatement reportUserInfo.status == userStatus.TreatmentPlanSent" (click)="straightboxOfflinePayment()">StraightBox Paid Offline</button>
        <a mat-raised-button mat-button color="primary" *ngIf="!reportUserInfo.has_complaint" [routerLink]="[navigationSettings.parentUrl, 'complaints', 'add-new']">Add complaint</a>        
    </div>
    <div fxFlex="50" fxLayoutAlign="end start" class="button-box space-left vertical-space align-right"> 
         <span *ngIf="reportUserInfo.status == userStatus.EconsultationNotReviewed">  
            <button mat-raised-button mat-button color="primary" (click)="changeUserStatus(userStatus.EconsultationAccepted)">Approve</button> 
        </span>
        <span *ngIf="reportUserInfo.status == userStatus.EconsultationNotReviewed"> 
            <button mat-raised-button mat-button color="primary"(click)="changeUserStatus(userStatus.EconsultationRejected)">Reject</button>
        </span>
        <button mat-raised-button mat-button color="primary" (click)="saveUserDetails()">Save</button>         
    </div> 
</div> 
<br/>
<div *ngIf="user && userDetail" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill fxLayoutGap="0px">    
    <div fxFlex="50" fxLayoutGap="0px">
        <div fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 20px;"> 
            <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                <div class="copy-attribute" fxFlex="25">ID</div>
                <div class="copy-value" fxFlex="65">{{ user.id }}</div> 
                <div fxFlex="10"> 
                    <span class="copy-user-data"> 
                        <button class="copy-button" mat-icon-button (click)="copyUserDataToClipboard(element.nativeElement.querySelectorAll('.copy'))">
                            <mat-icon matTooltip='Copy user data'>content_copy</mat-icon>
                        </button> 
                    </span> 
                </div>
            </div>
            <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                <div class="copy-attribute" fxFlex="25">Name</div>
                <div class="copy-value break-word" fxFlex="65">{{ user.full_name }}</div>                  
            </div>
            <div class="copy" fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div class="copy-attribute" fxFlex="25">Email</div>
                <div class="copy-value break-word" fxFlex="75">{{ user.email }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">Phone</div>
                <div fxFlex="75">
                    {{ userDetail.country_code != null ? '+' + userDetail.country_code  + userDetail.phone : userDetail.phone }}
                </div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">Street</div>
                <div fxFlex="75">{{ userDetail.address }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">City</div>
                <div fxFlex="75">{{ userDetail.city }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">Zip code</div>
                <div fxFlex="75">{{ userDetail.postalCode }}</div> 
            </div>
            <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                <div class="copy-attribute" fxFlex="25">Country</div>
                <div class="copy-value" fxFlex="75">{{ userDetail.country__name }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px" *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">How did you hear about us</div>
                <div fxFlex="75">{{ userDetail.hearAboutUs }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px" >
                <div fxFlex="25">Date of birth</div>
                <div fxFlex="75"> 
                    {{ userDetail.dateOfBirth != null ? moment(userDetail.dateOfBirth).format('DD.MM.Y') : '' }}
                </div> 
            </div>
            <!-- <div fxLayout="row" fxLayoutGap="20px" >
                <div fxFlex="100">
                    <button mat-icon-button (click)="copyUserDataToClipboard(element.nativeElement.querySelectorAll('.copyData'))">
                        <mat-icon matTooltip='Copy user data'>content_copy</mat-icon>
                    </button>
                </div> 
            </div>  -->
        </div>
    </div>
    <div fxFlex="50">
       <div fxLayout="column" fxLayoutGap="20px"> 
           <div fxLayout="column" fxLayoutGap="20px"> 
                <mat-form-field class="example-full-width">
                    <mat-label>User Journey</mat-label>
                    <textarea matInput [(ngModel)]="userDetail.user_notes" rows="5" placeholder="Enter user journey"></textarea>
                </mat-form-field> 
            </div>
            <div fxLayout="column" fxLayoutGap="20px"> 
                <p>Policy breach</p>
                <mat-radio-group [(ngModel)]="policyBreachStatus" [value]="policyBreachStatus" name="policyBreachStatus" required>
                    <mat-radio-button [value]="true">On</mat-radio-button> 
                    <mat-radio-button [value]="false">Off</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="cardWrap" fxLayout="row" fxLayout.lt-sm="column"    fxLayoutGap="20px" >
                <mat-checkbox [(ngModel)]="userDetail.is_sensitive_user"> Sensitive user </mat-checkbox>                
            </div>
            <!-- <h4>Record new complaint</h4>
            <div fxLayout="row" fxLayoutGap="20px" style="align-items: flex-end;">
                    <mat-form-field class="example-full-width">
                        <mat-select>
                            <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label> 
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
            </div> 
            <div fxLayout="row" fxLayoutGap="20px" style="align-items: center;"> 
                <mat-form-field class="example-full-width">
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                          {{food.viewValue}}
                        </mat-option>
                      </mat-select>
                </mat-form-field> 
                <mat-checkbox [(ngModel)]="user_detail.is_sensitive_user"> From email</mat-checkbox>
            </div> 
            <div fxLayout="column" fxLayoutGap="20px"> 
                <mat-form-field class="example-full-width">
                    <mat-label>Complaint</mat-label>
                    <textarea matInput [(ngModel)]="user_detail.user_notes" rows="3" placeholder="Enter complaint"></textarea>
                </mat-form-field> 
            </div> -->
            
        </div> 
    </div>    
</div>
