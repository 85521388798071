
<mat-card> 
    <mat-card-content>
        <div fxLayout="row" fxFlexFillfxLayoutGap="20px" *ngIf="navigationSettings">
            <div fxFlex="100"  fxLayoutAlign="end start"> 
                <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.url + '/' + ADD_NEW_LINK">{{ ADD_NEW }}</a>
            </div>
        </div>
    </mat-card-content>
</mat-card>
 
<div class="mat-table-holder" *ngIf="maintenanceAnnouncementFilterSettings && maintenanceAnnouncementFilterSettings.displayedColumns"> 
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">        

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
            <td mat-cell *matCellDef="let element"> {{ element.id.toString() }} 
                <a fxFlexFill [routerLink]="[navigationSettings.url, element.id.toString()]" class="mat-row-link"> </a> 
            </td>
        </ng-container>

        <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef> Text</th>l
            <td mat-cell *matCellDef="let element"> {{ element.text.length > 50 ? element.text.substring(0,50) : element.text }} 
                <a fxFlexFill [routerLink]="[navigationSettings.url, element.id.toString()]" class="mat-row-link"> </a> 
            </td>
        </ng-container>

        <ng-container matColumnDef="visible_from">
            <th mat-header-cell *matHeaderCellDef> Visible from </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.visible_from_date">{{ moment(element.visible_from_date).format('DD.MM.Y') }}</span></td>
        </ng-container>

        <ng-container matColumnDef="visible_to">
            <th mat-header-cell *matHeaderCellDef> Visible to </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.visible_to_date">{{ moment(element.visible_to_date).format('DD.MM.Y') }}</span></td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Active </th>
            <td mat-cell *matCellDef="let element"> {{ element.active ? 'Yes' : 'No' }} </td>
        </ng-container>
 
        <tr mat-header-row *matHeaderRowDef="maintenanceAnnouncementFilterSettings.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: maintenanceAnnouncementFilterSettings.displayedColumns"></tr> 
    </table> 
    <mat-paginator showFirstLastButtons [pageIndex]='maintenanceAnnouncementFilterSettings.paging.pageIndex-1' [length]='maintenanceAnnouncementFilterSettings.paging.length' [pageSize]='maintenanceAnnouncementFilterSettings.paging.pageSize' [pageSizeOptions]="[5, 10, 20]" (page)="onPaginateChange($event)"></mat-paginator>
</div> 