import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { IUserDetail } from '../../../model/api/user-detail';
import { UserStatus } from '../../../settings/user-status';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { Subject } from 'rxjs';
import { IReportUserInfo } from '../../../model/api/report-user-info';
import { MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { UserService } from '../../../service/api/user.service';
import { UserStateService } from '../../../service/state/user-state.service';
import { IUser } from '../../../model/api/user';
import { SpinnerService } from '../../../service/spinner.service';

@Component({
    template: ''
  })
export class DetailUserBaseComponent implements OnInit {
    
    reportUserInfo: IReportUserInfo;    
    userDetail: IUserDetail;
    user: IUser;
    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = null; 
    userStatus = UserStatus;
    currentPath: string;
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
    protected ngUnsubscribe: Subject<any> = new Subject();
    
    constructor(private settingsService: SettingsService, private userService: UserService, private userStateService: UserStateService, private userDetailService: UserDetailService, protected menuService: MenuService, protected route: ActivatedRoute, private reportUserInfoService: ReportUserInfoService, private reportUserInfoStateService: ReportUserInfoStateService, private router: Router, private spinnerService: SpinnerService, ) { }

    ngOnInit(): void {

        this.currentPath = this.route.snapshot.url[0].path;

        this.userId = Number(this.route.parent.snapshot.paramMap.get('userId'));
        
        this.getUser(); 
        this.getUserDetail(); 
        this.getReportUserInfo(); 
    }

    getUserDetail(): void { 
 
        if(Number.isNaN(this.userId) || this.userId <= 0)
            return; 

            this.spinnerService.startSpinner(); 
 
        this.userDetailService.detail(this.userId.toString())
            .subscribe(userDetail => {
                this.userDetail = userDetail;
                this.spinnerService.stopSpinner(); 			 
            },
            error =>{ error 
                this.spinnerService.stopSpinner(); 
            });        
	}

    private getReportUserInfo(): void { 

        if(Number.isNaN(this.userId) || this.userId <= 0)
            return; 

        this.spinnerService.startSpinner(); 

        this.reportUserInfoService.detail(this.userId.toString())
            .subscribe(reportUserInfo => { 
                this.reportUserInfo = reportUserInfo;

                this.handleRedirect(); 

                this.reportUserInfoStateService.setReportUserInfo(this.reportUserInfo);
                this.navigationSettings = this.menuService.getNavigationByMenuId(this.menuId, this.userId, this.reportUserInfo);

                if(!this.navigationSettings)
                    return;
                
                this.settingsService.setNavigationSettings(this.navigationSettings);            

                this.spinnerService.stopSpinner(); 			 
            },
            error =>{ error 
                this.spinnerService.stopSpinner(); 
            });     
	} 
 
    private handleRedirect() {    
 
        if(!this.reportUserInfo || !this.reportUserInfo.status)
            return; 

        switch(this.reportUserInfo.status) { 
            case this.userStatus.EconsultationNotReviewed:
            case this.userStatus.EconsultationAccepted:
            case this.userStatus.EconsultationRejected:
            case this.userStatus.EconsultationCountryRejected: { 
                if(['smilebox-order', 'treatment-plan-processing', 'straightbox-order', 'clinical-management'].includes(this.currentPath)) 
                    this.router.navigate(['admin', 'user', this.userId, 'details']);
                break; 
            } 

            case this.userStatus.SmileBoxOrdered:
            case this.userStatus.SmileBoxShipped: 
            case this.userStatus.ImpressionsReceived: 
            case this.userStatus.ThreeDScanOrdered: 
            case this.userStatus.ThreeDScanReferred:
            case this.userStatus.TreatmentPlanProcessing:
            case this.userStatus.TreatmentPlanSent:
            case this.userStatus.RejectedUser: { 
                if(['straightbox-order', 'clinical-management'].includes(this.currentPath)) 
                    this.router.navigate(['admin', 'user', this.userId, 'details']); 
                break; 
            } 
            case this.userStatus.AlignersStarted:
            case this.userStatus.TreatmentFinished: 
            default: 
                break;
        }
         
    }

    copyUserDataToClipboard(copiedData: any) {
 
        if(!copiedData || copiedData.length == 0)
            return;
 
        let copiedDataFormated: string = '';

        copiedData.forEach(childElement => {
            let attribute = childElement.querySelector('.copy-attribute') ? childElement.querySelector('.copy-attribute').innerText : '';
            let value = childElement.querySelector('.copy-value') ? childElement.querySelector('.copy-value').innerText : '';
            copiedDataFormated += `${attribute}: ${value}\r\n`; 
        });
 
        navigator.clipboard.writeText(copiedDataFormated).then().catch(e => console.error(e));
    }

    getUser(): void { 

        this.spinnerService.startSpinner(); 

        this.userService.detail(this.userId.toString())
			.subscribe(user => { 
                this.user = user;
                this.userStateService.setUser(user); 
                this.spinnerService.stopSpinner(); 			 
            },
            error =>{ error 
                this.spinnerService.stopSpinner(); 
            }); 
	}

    ngOnDestroy(): any { 
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    } 
 

}
