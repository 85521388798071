import { Injectable } from '@angular/core';
import { UserStatusName } from 'src/app/settings/user-status';
import { IStatus } from '../model/status';
import { Helper } from '../util/helper.util';

@Injectable({
  providedIn: 'root'
})

export class StatusService {
  
    private statuses: IStatus[] = [];

    constructor(private __helper: Helper) { }

    getStatuses(): IStatus[] {

       if(this.statuses && this.statuses.length > 0)
            return this.statuses; 

        this.statuses = (Object.keys(UserStatusName) as Array<keyof typeof UserStatusName>)
        .filter(userStatusName => typeof UserStatusName[userStatusName] === "string") 
        .map(userStatusName => ({ 
            id: +userStatusName.replace('status-', ''), 
            name: UserStatusName[userStatusName] 
        })); 

        // COMMENT: Remove statuses 'SmileBox Ordered Records Uploaded', '3D Scan Ordered Records Uploaded'
        // because they are not supposed to be visible in list of statuses.
        this.statuses = this.statuses.filter(status => status.id !== 501 && status.id !== 511);
 
        return this.statuses;
    } 
 
    
 
  
}
