import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../app.config/app.config'; 
import { BaseService } from '../base/base.service';
import { ISmileBoxFiles } from '../../model/api/smile-box-files';


@Injectable({
  	providedIn: 'root'
})
 
export class SmileboxFileService extends BaseService<ISmileBoxFiles> {
    protected urlV2 = "/admin-v02/smilebox-file"; 

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

    
}
