import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators'; 
import { Helper } from '../../util/helper.util'; 
import { IUserProfile } from '../../model/api/user-profile';  
import { APP_CONFIG } from '../../app.config/app.config'; 
 
@Injectable({
	providedIn: 'root'
})
 
export class UserProfileService { 
    url = this._config.api_url + "/admin/mobile-user";
    private cache$: Observable<Array<IUserProfile>>;       
 

    constructor(@Inject(APP_CONFIG) public _config, private helper: Helper, private _http: HttpClient) {} 
  
    index(userId:number): Observable<IUserProfile> {
        
        var urlFinal: string = `${this.url}/${userId}`; 
     
        return this._http.get<IUserProfile>(urlFinal)
            .pipe(
                retry(3), 
                    map((data: any) => {  
                        if(data) {
                            return data as IUserProfile;
                        }
                    }),
                    catchError( err =>  { return this.handleError(err)})               
            ); 
    }



    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);

        /* let error = httpError.error;
        let errorMessage = error.detail || error.message || error.msg || 'An error occured!';
        return throwError(errorMessage); */
    }

 
}
 

