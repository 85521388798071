<div class="content-wrap">
    <div class="content">
        <app-header></app-header>
        <mat-card>
            <mat-card-content>
                <div fxLayout="column" fxFlexFill>
                    <div fxFlex="100">
                        <mat-form-field class="example-full-width" style="margin-right: 20px;">
                            <mat-label>Search user</mat-label>
                            <input matInput placeholder="Enter text" (keyup)="searchTextKeyUp($event)" [(ngModel)]="searchMessages.searchUser">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Search content</mat-label>
                            <input matInput placeholder="Search content" (keyup)="searchTextKeyUp($event)" [(ngModel)]="searchMessages.searchContent">
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <div class="infinite-scroll" infinite-scroll [infiniteScrollDistance]="0"
            [infiniteScrollThrottle]="0" (scrolled)="onScroll()" [scrollWindow]="false">
            <mat-accordion *ngIf="reportConversations && navigationSettings" #matAccordion="matAccordion" class="example-headers-align" multi>
                <mat-expansion-panel *ngFor="let reportConversation of reportConversations; let i = index; trackBy: conversationMessageTrackByFunction" [expanded]="reportConversation.messages"> 
                    <mat-expansion-panel-header style="padding: 10px;" (click)="toggleReportConversations(reportConversation)">
                        <mat-panel-title>
                            <div fxLayout="column" fxFlexFill>
                                <div fxFlex="100">
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <div fxFlex="5" fxLayout="column" fxLayoutAlign="start center">
                                            <mat-icon
                                                *ngIf="[userType.Admin, userType.ClinicalSupport, userType.AlignerSuccess].includes(+reportConversation.doctor__user_type_id)"
                                                [color]="'primary'">account_circle</mat-icon>
                                            <mat-icon
                                                *ngIf="reportConversation.doctor__user_type_id == userType.Doctor"
                                                [color]="'accent'">account_circle</mat-icon>
                                            <span
                                                *ngIf="reportCurrentUser && reportConversation.user_reply && reportCurrentUser.id == +reportConversation.doctor">
                                                <mat-icon [color]="'primary'">redo</mat-icon>
                                            </span>
                                        </div>
                                        <div fxFlex="5" fxLayoutAlign="center start">
                                            <span class="severity-status green"
                                                *ngIf="reportConversation.patient__user_details__severity_status == userSeverityStatus.None.toString() || reportConversation.patient__user_details__severity_status == userSeverityStatus.Mild.toString()">
                                            </span>
                                            <span class="severity-status yellow"
                                                *ngIf="reportConversation.patient__user_details__severity_status == userSeverityStatus.Moderate.toString()">
                                            </span>
                                            <span class="severity-status red"
                                                *ngIf="reportConversation.patient__user_details__severity_status == userSeverityStatus.Advanced.toString()">
                                            </span>
                                        </div>
                                        <div fxFlex="20">
                                            <div>{{ reportConversation.patient__full_name }}
                                                <span
                                                    *ngIf="reportConversation.patient__user_details__is_vip"
                                                    class="vip-status"> VIP</span>
                                            </div>
                                        </div>
                                        <div fxFlex="50">
                                            <span *ngIf="reportConversation.last_conversation_message_text"></span>{{ reportConversation.last_conversation_message_text.slice(0, 150) }}
                                        </div>
                                        <div fxFlex="20" fxLayoutAlign="center start">
                                            <span *ngIf="reportConversation.created_at">{{ moment(reportConversation.last_message_at).format('DD.MM.Y HH:mm') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-panel-title> 
                        <!-- <a fxFlexFill (click)="toggleReportConversations(reportConversation)" class="mat-row-link"></a> -->
                    </mat-expansion-panel-header> 
                    
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div *ngIf="reportConversation.isReplyButtonVisible && reportConversation.isExpanded"
                            style="display: flex; justify-content: flex-end;">
                            <a mat-raised-button mat-button color="primary" class="reply-button"
                                (click)="reportConversation.isReplyButtonVisible = false; selectedReportConversation = reportConversation; ">{{
                                REPLY }}</a>
                        </div>
                        <div *ngIf="selectedReportConversation && !reportConversation.isReplyButtonVisible" fxFlex="100"> 
                            <app-message-reply [(selectedConversation)]="selectedReportConversation" [(conversationTemplates)]="conversationTemplates" [(userId)]="selectedReportConversation.patient" (conversationChange)="replyConversationMessageChange($event)">
                            </app-message-reply> 
                        </div>
                        <div *ngFor="let message of reportConversation.messages; let i = index; let isLast = last;"
                            fxFlex="100">
                            <h3
                                *ngIf="[userType.Admin, userType.ClinicalSupport, userType.AlignerSuccess].includes(+message.user__user_type_id)">
                                {{ message.user__full_name }}</h3>
                            <h3 *ngIf="message.user__user_type_id == userType.Doctor">DR. {{
                                message.user__full_name }}</h3>
                            <h3 *ngIf="message.user__user_type_id == userType.User">
                                <a
                                    [routerLink]="['/admin/user/', reportConversation.patient, 'details']">{{ reportConversation.patient__full_name }}</a>
                            </h3>
                            <h5>{{ message.created_at != null ?
                                moment(message.created_at).format('DD.MM.Y HH:mm') : '' }}</h5>
                            <p [innerHTML]="message.text | linky"></p>
                           <!--  message.files lenght: {{ message.files != undefined || message.files.length > 0 ? message.files.length.toString() : 'prazno' }} -->
                            <app-file-preview [(conversationMessageFilesPreview)]="message.files"></app-file-preview>
                            <!--  <div class="attachment-wrap">
                                <div class="attachment"
                                    *ngFor="let file_src of message.files; let i = index">
                                    <img [attr.src]='file_src.file' class="image-preview">
                                </div>
                            </div>  -->
                            <mat-divider *ngIf="!isLast"></mat-divider>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </div>
</div>
