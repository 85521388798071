import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { Helper } from '../../util/helper.util'; 
import { IConversationTemplate } from '../../model/api/conversation-template';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { BaseService } from '../base/base.service';
  
@Injectable({
  	providedIn: 'root'
})
export class ConversationTemplateService extends BaseService<IConversationTemplate> {

  	/* url = this._config.api_url + "/admin/conversation-templates"; 
    constructor(@Inject(APP_CONFIG) public _config, private helper: Helper, private _http: HttpClient) {}  */
    protected urlV2 = "/admin-v02/conversation-template";    
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http);
    }
  
    /* index(category?:string): Observable<IConversationTemplate[]> { 
        var urlTemp: string = `${this.url}`; 
        if(category) {
            urlTemp += `?category=${category}`; 
        } 

        return this._http.get<IConversationTemplate[]>(urlTemp)
            .pipe(
                retry(3), 
                    map((data: any) => { 
                        if(data) {
                            return data.templates as IConversationTemplate[];
                        }
                    }),
                    catchError( err =>  { return this.handleError(err)})           
            ); 
    }
 

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);
    } */


}
