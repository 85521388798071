import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SecurityGuard} from './service/security/security-guard.service';

const routes: Routes = [
    
    { path: 'login', component: LoginComponent },  
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [SecurityGuard] },      
    { path: '**', redirectTo: '/login' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
