

<div class="attachment-wrap" *ngIf="conversationMessageFilesPreview">
    <div class="attachment" *ngFor="let file of conversationMessageFilesPreview; let i = index">
        <a *ngIf="file.isImage" href="{{ file.file }}" target="_blank" class="image-box">
            <img [attr.src]='file.file' class="image-preview">    
            <span class="ad-after" (click)="addAfterImage(file, CONVERSATION_FILES_MODEL, ADD_AFTER_FILE_TYPE); $event.preventDefault()" *ngIf="reportUserInfo && userStatusHelper.isAlignersStarted(reportUserInfo.status) && canAddAfterImage">
                <i class="material-icons">add</i>
            </span>
        </a> 
        <a *ngIf="!file.isImage" href="{{ file.file }}" target="_blank" class="file-box">
            <span class="title" *ngIf="file.prettyName" >{{file.prettyName.substr(0, 100)}}</span>
            <mat-icon mat-list-icon *ngIf="file.isPDF" >topic</mat-icon> 
            <mat-icon mat-list-icon *ngIf="file.isVideo">videocam</mat-icon> 
        </a>
    </div>
</div>