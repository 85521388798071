import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ISettings, Settings } from '../../../model/settings';
import { SettingsService } from "../../../service/state/settings.service";
import { IMenu } from '../../../model/menu'; 
import { UserStateService } from '../../../service/state/user-state.service';
import { IUser } from '../../../model/api/user';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { IReportUserInfo } from '../../../model/api/report-user-info';
import { SpinnerService } from '../../../service/spinner.service';
import { Helper } from '../../../util/helper.util';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UserStatus, UserStatusName } from '../../../settings/user-status';  
import { DialogProgressStatusComponent } from '../dialog-progress-status/dialog-progress-status.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() user: IUser;
    @Input() reportUserInfo: IReportUserInfo; 
    @Input() userStatusColor: string; 
	settings$: Observable<Settings>;    
    userStatusName = UserStatusName; 
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
    
    
	constructor(private _settingsService: SettingsService, private _userStateService: UserStateService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar, public _dialog: MatDialog) { }

	ngOnInit(): void {
		this.settings$ = this._settingsService.settings;
 
	}

    openProgressStatusDialog(): void { 
        const dialogRef = this._dialog.open(DialogProgressStatusComponent, {
            width: '600px',
            height: '600px', 
            data: { status: this.reportUserInfo.status, user: this.user.id, closeButton: true, saveButton: true },
            disableClose: true
        });
    
        dialogRef.afterClosed()
            .subscribe(result => { 
                this.getReportUserInfo(); 
            }); 
    }
    
    getReportUserInfo(): void {
 
        if(this._helper.isNullOrUndefined(this.user) || this.user.id == 0)
            return;
 
        this._spinnerService.startSpinner();
        this._reportUserInfoService.detail(this.user.id.toString())
            .subscribe(reportUserInfo => { 
                if(!this._helper.isNullOrUndefined(reportUserInfo)) {
                    this.reportUserInfo = reportUserInfo;
                }

                this._spinnerService.stopSpinner(); 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
    } 


    

}
