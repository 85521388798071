import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UserExportComponent } from './user-export.component';
import { ExportComponent } from './export/export.component';
import { SecurityGuard } from 'src/app/service/security/security-guard.service';



const routesUserExport: Routes = [
	{ 
        path: '',  component: UserExportComponent,       
		children: [
            { path: '', redirectTo: 'export', pathMatch: 'full' }, 
            { path: 'export', component: ExportComponent, canActivate: [SecurityGuard]  },
            { path: '**', redirectTo: '/login' },
        ] 
	}
];

@NgModule({ 
    imports: [RouterModule.forChild(routesUserExport)],
	exports: [RouterModule]
})
export class UserExportRoutingModule { }
