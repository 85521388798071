<div class="dialog-container">
    <h1 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h1> 
    <mat-dialog-content class="mat-typography">
        <div fxLayout="column" fxFlexFill fxLayoutGap="20px"> 
            <div fxFlex="100" *ngIf="data.message"> 
                    {{ data.message }}
            </div>
            <div fxFlex="100" *ngIf="conversationTemplates">
                <mat-form-field> 
                    <mat-select name="conversationTemplate" placeholder="select template" (selectionChange)="changeTemplate($event)" class="mat-small" panelClass="mat-small"> 
                        <mat-option *ngFor="let conversationTemplate of conversationTemplates" [value]="conversationTemplate">
                            {{conversationTemplate.admin_name}} 
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>            
            <div fxFlex="100" *ngIf="data.showTextarea && data.textPlaceholder">
                <mat-form-field class="example-full-width">
                    <textarea matInput placeholder="{{ data.textPlaceholder }}" [(ngModel)]="data.text" rows="16"></textarea>
                </mat-form-field>
            </div>
        </div>
        
        
        
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button *ngIf="!data.hideCloseButton" mat-raised-button mat-button color="primary" mat-dialog-close>Close</button>
        <button *ngIf="!data.hideOkButton" mat-raised-button mat-button color="primary" [mat-dialog-close]="data">OK</button>
    </mat-dialog-actions>
</div>
