import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config/app.config';
import { ITreatmentProgress } from 'src/app/model/api/treatment-progress'; 
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})
export class TreatmentProgressService extends BaseService<ITreatmentProgress> {

    protected urlV2 = "/admin-v02/treatment-progress";    
    protected _cacheValidForSeconds = 360;

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
}
