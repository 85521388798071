import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {INavigationSettings, ISettings, Settings } from '../model/settings';
import { SettingsService } from "../service/state/settings.service";
import { MenuService } from '../service/state/menu.service';
import { IMenu } from '../model/menu';
import { ChangeDetectorRef } from '@angular/core';



@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
 
    settings$: Observable<Settings>;
    menu: IMenu;
    menuId: number = 1;
    navSettings: INavigationSettings; 
    private ngUnsubscribe: Subject<any> = new Subject();
    
	constructor(private _settingsService: SettingsService, private _menuService: MenuService, private cd: ChangeDetectorRef) { }
	
	ngOnInit() {
        this.settings$ = this._settingsService.settings;
    }

    /* ngAfterViewInit() {
        console.log('admin component -> ngAfterViewInit()')
        // COMMENT: DetectChanges solves problem with error ExpressionChangedAfterItHasBeenCheckedError that appears only in debug mode
        this.cd.detectChanges();
        
    } 

    ngAfterContentInit() {
        console.log('admin component -> ngAfterContentInit()')
        this.cd.detectChanges();
        
    }  
     */

}
