import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators';  
import { ISmileBoxAdminReview } from '../../model/api/smile-box-admin-review';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})

export class SmileBoxAdminReviewService extends BaseService<ISmileBoxAdminReview>  { 
     
    protected urlV2 = "/admin-v02/smile-box-admin-review";
    protected urlV1 =  this._config.api_url + "/admin/order-review";
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

    uploadNewTreatmentPlan(userId: number, formData: FormData) {

		let url = `${this.urlV1}/${userId}`; 

		return this._http.post<ISmileBoxAdminReview>(url, formData).pipe(
			map(response => {
				this._responseCache$.clear();
				return response as ISmileBoxAdminReview;
			})
		);
	}
    
    updateTreatmentPlan(smileBoxAdminReview: ISmileBoxAdminReview, formData: FormData) {

		let url = `${this.urlV1}/${smileBoxAdminReview.id}`; 

		return this._http.put<ISmileBoxAdminReview>(url, formData).pipe(
			map(response => {
				this._responseCache$.clear();
				return response as ISmileBoxAdminReview;
			})
		);
	}

    deleteTreatmentPlanFile(smileBoxAdminReview: ISmileBoxAdminReview, userId: number) {
        
		let url = `${this.urlV1}/${userId}/${smileBoxAdminReview.id}`; 

		return this._http.delete<ISmileBoxAdminReview>(url).pipe(
			map(response => {
				this._responseCache$.clear(); 
			})
		);
	}

    /* post<T>(userId: number, complaintRequest: IComplaint): Observable<IUserResponse<IComplaint>> {
        let urlFinal = `${this.urlV1}?user_id=${userId}`; 
   
        return this._http.post(urlFinal, complaintRequest)
            .pipe(
                map((res: IUserResponse<IComplaint>) => {
 
                    this._responseCache$.clear();
                    if (res) { 
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);
 
    } */


}
