<h3>User Details</h3>
<div *ngIf="user" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>    
    <div fxFlex="50">
        <div fxLayout="column" fxLayoutGap="20px"> 
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" >
                <div fxFlex="25">Full name</div>
                <div fxFlex="75">{{ user.full_name }}</div> 
            </div>
        </div>
    </div>
</div>

<h3 *ngIf="maintainerPlan" >Maintainer Plan</h3>
<div *ngIf="maintainerPlan && maintainerPlan.active" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>    
    <div fxFlex="50">
        <div fxLayout="column" fxLayoutGap="20px"> 
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" >
                 <div fxFlex="25">Joined</div>
                <div fxFlex="75" (click)="openMaintainerPlanDialog()">{{ moment(maintainerPlan.joined_at).format('DD.MM.Y') }} </div>
            </div>
        </div>
    </div>
</div>