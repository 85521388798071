import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';
import { Helper } from '../../util/helper.util';
import { IUserSubmissionsResponse  } from '../../model/api/user'; 
import { APP_CONFIG } from '../../app.config/app.config';

@Injectable({
	providedIn: 'root'
})

export class UserListServiceV1 { 
    url = this._config.api_url + "/admin/list-users";  
    exportToCsv = this._config.api_url + "/admin/export-to-csv-v2/";  

    constructor(@Inject(APP_CONFIG) public _config, private helper: Helper, private _http: HttpClient) {} 

 
    index(searchParams:object): Observable<IUserSubmissionsResponse> {

        return this._http.post<IUserSubmissionsResponse>(this.url, searchParams)
        .pipe(
            retry(3), 
                map((data: any) => {              
                    return data;
                })
            //catchError(this.handleError('getPosts', []))
        );
 
    }

    usersToCsvExport<T>(): Observable<any> {
        //let urlFinal = `${this._config.api_url}${this.exportToCsv}/`; 
   
        return this._http.get(this.exportToCsv, { responseType: 'arraybuffer', observe: 'response' });
    } 
 
 
}

 