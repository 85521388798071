// COMMENT: SmileQuiz changed to E-consultation
export enum UserStatus {
    EconsultationNotReviewed = 1,
    EconsultationAccepted = 2,
    EconsultationRejected = 3,
    EconsultationUnder18Rejected = 301,
    EconsultationCountryRejected = 4,
    SmileBoxOrdered = 5,
    //SmileBoxOrderedRecordsUploaded = 501,
    SmileBoxShipped = 502,
    ThreeDScanOrdered = 510, 
    //ThreeDScanOrderedRecordsUploaded = 511, 
    ThreeDScanReferred  = 512,
    ImpressionsReceived = 550,
    TreatmentPlanProcessing = 6,
    TreatmentPlanSent = 7,
    RejectedUser = 8,
    AlignersStarted = 9,
    TreatmentFinished = 10
} 

export enum UserStatusName {
    'status-1' = 'E-consultation Not Reviewed',
    'status-2' = 'E-consultation Accepted',
    'status-3' = 'E-consultation Rejected',
    'status-301' = 'E-consultation Under 18 Rejected',
    'status-4' = 'E-consultation Country Rejected',
    'status-5' = 'SmileBox Ordered',
    'status-501' = 'SmileBox Ordered Records Uploaded',
    'status-502' = 'SmileBox Shipped',
    'status-510' = '3D Scan Ordered',
    'status-511' = '3D Scan Ordered Records Uploaded',
    'status-512' = '3D Scan Referred',
    'status-550' = 'Impressions Received',
    'status-6' = 'Treatment Plan Processing',
    'status-7' = 'Treatment Plan Sent',
    'status-8' = 'Rejected User',
    'status-9' = 'Aligners Started',
    'status-10' = 'Treatment Finished',
} 

/* export const UserStatusKeyValue: {key: string, value: number}[] = [
    {key: 'Smile Quiz Not Reviewed', value: 1},
    {key: 'Smile Quiz Accepted', value: 2},
    {key: 'Smile Quiz Rejected', value: 3},
    {key: 'Smile Quiz Country Rejected', value: 4},
    {key: 'SmileBox Ordered', value: 5},
    {key: 'Treatment Plan Processing', value: 6},
    {key: 'Treatment Plan Sent', value: 7},
    {key: 'Rejected User', value: 8},
    {key: 'Aligners Started', value: 9},
    {key: 'Treatment Finished', value: 10}
]; */