import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, Subscription, timer } from 'rxjs';
import { DetailBaseComponent } from '../../inc/base/detail-base-componenty'; 
import { IReportConversation } from '../../../model/api/report-conversation';
import { IConversationMessageFile } from '../../../model/api/conversation-message-file'; 
import { ConversationMessageFileService } from '../../../service/api/conversation-message-file.service'; 
import { ConversationService } from '../../../service/api/conversation.service';
import { SpinnerService } from '../../../service/spinner.service';
import { Helper } from '../../../util/helper.util';
import { MenuService } from '../../../service/state/menu.service';
import { SettingsService } from "../../../service/state/settings.service";
import { UserType } from '../../../settings/user-type'; 
import { ReportConversationService } from '../../../service/api/report-conversation.service';
import { ConversationMessageService } from '../../../service/api/conversation-message.service';
import { IConversationMessage } from '../../../model/api/conversation-message';
import { ConversationTemplateService } from '../../../service/api/conversation-template.service';
import { IConversationTemplate } from '../../../model/api/conversation-template';
import { AppConstants } from '../../../settings/app-constants';
import { UserSeverityStatus } from '../../../settings/user-severity-status'; 
import { ReportCurrentUserService } from '../../../service/api/report-current-user.service';
import { ISearchMessages } from '../../../model/base/search-messages';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent extends DetailBaseComponent implements OnInit {

    menuId: number = 2;
    userSeverityStatus = UserSeverityStatus;
    reportConversations: IReportConversation[];
    userType = UserType;
    moment: any = moment;
    distance = 2; 
    pageSize: number = 50;
    page = 1;
    conversationTemplates: IConversationTemplate[];
    expandAll: boolean;
    openedConversationIds: number[] = [];
    readonly REPLY:string = AppConstants.REPLY;
    searchMessages: ISearchMessages;
    isNewSearch: boolean = true; 
    reportConversationSubscription: Subscription;
    ngUnsubscribeTimer: Subscription;
    milisecondsToReload: number = 250;


	constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _reportCurrentUserService: ReportCurrentUserService, private _conversationService: ConversationService, private _reportConversationService: ReportConversationService, private _conversationMessageService: ConversationMessageService, private _conversationTemplateService: ConversationTemplateService, private _conversationMessageFileService: ConversationMessageFileService, private _route: ActivatedRoute, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar, private _router: Router, private cdr: ChangeDetectorRef) { 
		super(_settingsService, _menuService, _route, _reportCurrentUserService);
	}

	ngOnInit(): void { 
		super.ngOnInit(); 

		this.searchMessages = { page: 1, pageSize: 1 };

		this.route.params.subscribe(params => {
			this.checkAndOpenConversations();
		});
		
		this.getReportConversations(this.page);
	
		this.getConversationTemplates(); 

	}

    searchTextKeyUp(event) { 

        if(this.ngUnsubscribeTimer) 
            this.ngUnsubscribeTimer.unsubscribe(); 
        
        const timerSearchText: Observable<number> = timer(this.milisecondsToReload);
 
        this.ngUnsubscribeTimer = timerSearchText
            .pipe(takeUntil(this.ngUnsubscribe))    
            .subscribe(val => { 
                this.getSearchMessages();
                }
            );
    }

	getSearchMessages() {   

		if((!this._helper.isEmptyOrNull(this.searchMessages.searchUser) && this.searchMessages.searchUser.length < 3) && (!this._helper.isEmptyOrNull(this.searchMessages.searchContent) && this.searchMessages.searchContent.length < 3))
			return;

		this.isNewSearch = true;
		this.page = 1;
		this.getReportConversations(this.page);
	}


	getReportConversations(page: number): void {  
		this._spinnerService.startSpinner();
		let searchUser: string;
		let searchContent: string; 

        if (this.reportConversationSubscription) 
            this.reportConversationSubscription.unsubscribe();   
	
		searchUser = !this._helper.isNullOrUndefined(this.searchMessages) && !this._helper.isEmptyOrNull(this.searchMessages.searchUser) ? encodeURIComponent(this.searchMessages.searchUser)  : ''; 
		searchContent =  !this._helper.isNullOrUndefined(this.searchMessages) && !this._helper.isEmptyOrNull(this.searchMessages.searchContent) ? this.searchMessages.searchContent : ''; 
		
		let searchFilter  = { page: page, page_size: this.pageSize, search_user: searchUser, search_content: searchContent };
	
		this.reportConversationSubscription = this._reportConversationService.index(searchFilter)
			.subscribe(data => {
				if(!this._helper.isNullOrUndefined(this.reportConversations) && !this.isNewSearch) {
					this.reportConversations.push(...data.results); 
				} else {
					this.reportConversations = data.results;
					this.isNewSearch = false;
				} 
				
				this.checkAndOpenConversations();
				this._spinnerService.stopSpinner(); 
	
			},
			error =>{ error  
				this._spinnerService.stopSpinner(); 
			}); 
	} 

	checkAndOpenConversations(){
	
		if(!this.reportConversations)
			return;
	
		let tmpStatus = this.route.snapshot.paramMap.get('status'); 

		this.openedConversationIds = [];
		if(tmpStatus) {
			this.openedConversationIds = tmpStatus.split(',').filter(item => { return Number(item) }).map(Number);
		} 

		this.reportConversations.forEach(reportConversation => {

			reportConversation.last_message_at = !this._helper.isNullOrUndefined(reportConversation.last_message_at) ? moment.utc(reportConversation.last_message_at).toDate() : null;
			
			reportConversation.isReplyButtonVisible = true;
			if (!this.openedConversationIds.includes(reportConversation.id)){
				reportConversation.messages = null;
				reportConversation.isExpanded = false;
				return true;
			}
 
            /* console.log('NOT includes reportConversation.id');
            console.log(reportConversation.id);
            console.log('NOT includes reportConversation.messages');
            console.log(reportConversation.messages); */
			this.getConversationMessagesAndFiles(reportConversation.id);
		});
	

	}

	

	getConversationMessagesAndFiles(conversationId:number, isReplyMessageSent = false) {

		if(this._helper.isNullOrUndefined(this.reportConversations)) 
			return; 
		this._spinnerService.startSpinner();

		let selectedReportConversation: IReportConversation = this.reportConversations.find(reportConversationMessage => reportConversationMessage.id == conversationId);

		forkJoin([this._conversationMessageService.index({conversation_id: conversationId}), this._conversationMessageFileService.index({conversation_id: conversationId})]).subscribe(conversationItems => {
			let conversationMessages: IConversationMessage[] = conversationItems[0].results;
			let conversationMessageFiles: IConversationMessageFile[] = conversationItems[1].results;

			let conversationMessagesHash: { [id: number] : IConversationMessage; } = conversationMessages.reduce((a,x) => ({...a, [x.id]: x}), {});
            
			selectedReportConversation.messages = conversationMessages != null ? conversationMessages : null;
			// COMMENT: conversationMessageFiles is V2. It should return IApiResponse but it doesnt. See with Zack.
			
			selectedReportConversation.messages.forEach(conversationMessage => { 
				conversationMessage.text = conversationMessage.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
				conversationMessage.created_at = !this._helper.isNullOrUndefined(conversationMessage.created_at) ?  moment.utc(conversationMessage.created_at).toDate() : null;
				
				if(this._helper.isNullOrUndefined(conversationMessageFiles))
					return;
                
                conversationMessage.files = [];

				
			});

			conversationMessageFiles.forEach(conversationMessageFile => {
                let conversationMessage = conversationMessagesHash[conversationMessageFile.message];

                if(this._helper.isNullOrUndefined(conversationMessage))
                    return true;

                if(this._helper.isNullOrUndefined(conversationMessage.files)) {                                   
                    conversationMessage.files = [];
                }
                conversationMessage.files.push(conversationMessageFile);
            });

			selectedReportConversation.isExpanded = true;
			
			this._spinnerService.stopSpinner(); 
		},
		error =>{  
			this._spinnerService.stopSpinner();  
		});

	}

	onScroll(): void { 
		this.getReportConversations(++this.page);
	}

	getConversationTemplates(): void { 
		this._conversationTemplateService.index({ category: 'reply'})
			.subscribe(conversationTemplates => { 
				// this.conversationTemplates = this._helper.replaceConversationPatterns(conversationTemplates.results);
				this.conversationTemplates = conversationTemplates.results;
			});
	}

	toggleReportConversations(selectedReportConversation: IReportConversation) {
		let indexOfOpenedConversation = this.openedConversationIds.indexOf(selectedReportConversation.id);
	
		if (indexOfOpenedConversation === -1) {
			this.openedConversationIds.push(selectedReportConversation.id);
		} else {
			this.openedConversationIds.splice(indexOfOpenedConversation, 1);
		}

		this._router.navigate(['admin', this.navigationSettings.url, this.openedConversationIds.join(',')]);
		return false;
		//return this.navSettings.url + '/' + this.openedConversationIds.join(',');
	}

	replyConversationMessageChange(selectedReportConversation: IReportConversation) {
 
		if(selectedReportConversation.isReplyMessageCanceled) {

			this.reportConversations.find(reportConversationMessage => { 
				if(reportConversationMessage.id == selectedReportConversation.id) {
					//this.matAccordion.closeAll();
					this.toggleReportConversations(selectedReportConversation);
				}
			}); 
	
			return;
		} 

        /* this._conversationMessageService._responseDetailCache$.clear();
        this._conversationMessageService._responseCache$.clear();
        this._conversationMessageFileService._responseCache$.clear();
        this._conversationMessageFileService._responseDetailCache$.clear(); */
        //this._reportConversationService.clearCache();
        this._conversationMessageService.clearCache();
        this._conversationMessageFileService.clearCache();
		this.getConversationMessagesAndFiles(selectedReportConversation.id, true);
        //this.getReportConversations(this.page);
	} 

	conversationMessageTrackByFunction(index, conversationMessage: IReportConversation) {
		return conversationMessage.id.toString() + (conversationMessage.id ?? false).toString();
	}

    ngOnDestroy() {   
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
        if(this.reportConversationSubscription)
            this.reportConversationSubscription.unsubscribe();
    }
	
}

 