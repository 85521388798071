import { Component, OnInit, Input } from '@angular/core';
import { debounceTime, take } from 'rxjs/operators'; 
import { UserFilterStateService } from '../../../../service/state/user-filter-state.service'; 
import { takeUntil } from 'rxjs/operators';	
import { Observable, Subject, timer } from "rxjs";
import { Helper } from '../../../../util/helper.util';
import { IApiFilter, IApiSearchFilter } from '../../../../model/base/filter-settings';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss']
})
export class SearchTextComponent implements OnInit {

    //apiSearchFilter: IApiSearchFilter;
    apiFilter: IApiFilter;
    searchText: string;
	searchTextArray: string[] = null; 
	private ngUnsubscribe: Subject<any> = new Subject();
    milisecondsToReload: number = 250; 
    ngUnsubscribeTimer: Subscription; 

	constructor(protected helper: Helper, private _userFilterStateService: UserFilterStateService) { }

	ngOnInit(): void {
        
        this._userFilterStateService.currentFilterSettings
			.pipe(
                take(1),
                takeUntil(this.ngUnsubscribe),
                debounceTime(this.milisecondsToReload)
            )
			.subscribe(async(filterSettings) => { 
                //this.apiSearchFilter = filterSettings.apiSearchFilter;
                this.apiFilter = filterSettings.apiFilter;
                this.searchText = filterSettings.textSearch;
			}); 
	}
 
    searchTextKeyUp(event) { 

        if(this.ngUnsubscribeTimer) 
            this.ngUnsubscribeTimer.unsubscribe(); 

        const timerSearchText: Observable<number> = timer(this.milisecondsToReload);
 
        this.ngUnsubscribeTimer = timerSearchText
            .pipe(takeUntil(this.ngUnsubscribe))    
            .subscribe(val => {  
                this.findSearchText();
                }
            );
    }

    findSearchText() {             
        if(!this.helper.isEmptyOrNull(this.searchText))
            this.searchText = this.searchText.replace(/ +(?=)/g, ' ');
 
        //this.apiSearchFilter.page = 1;
       // this.userFilterStateService.updateApiSearchFilter(this.helperSearch.setTextSearch(this.apiFilter, this.searchText, 'full_name_or_email__icontains'));
       //this.apiFilter['full_name_or_email__icontains'] = this.searchText; 
       this._userFilterStateService.updateApiFilterTextSearch(this.searchText);
	}

	ngOnDestroy() {   
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
    }
   
}
