import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { IConversationTemplate } from '../../../model/api/conversation-template';
import { IMessageBroadcast } from '../../../model/api/message-broadcast';
import { ConversationTemplateService } from '../../../service/api/conversation-template.service';
import { MessageBroadcastService } from '../../../service/api/message-broadcast.service';
import { ReportCurrentUserService } from '../../../service/api/report-current-user.service';
import { SpinnerService } from '../../../service/spinner.service';
import { AppConstants } from '../../../settings/app-constants';
import { Helper } from '../../../util/helper.util';
import { DetailBaseComponent } from '../../inc/base/detail-base-componenty'; 
import { MenuService } from '../../../service/state/menu.service';
import { SettingsService } from '../../../service/state/settings.service'; 
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { IMesageBroadcastFilterSettings } from '../../../model/base/filter-settings';
import { MessageBroadcatFilterStateService } from '../../../service/state/message-broascast-filter-state.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subscription, timer } from 'rxjs';
import { IApiResponse } from '../../../model/api/response';


@Component({
    selector: 'app-message-broadcast',
    templateUrl: './message-broadcast.component.html',
    styleUrls: ['./message-broadcast.component.scss']
})
export class MessageBroadcastComponent extends DetailBaseComponent implements OnInit {

    menuId: number = 203; 
    conversationTemplates: IConversationTemplate[];
    conversationTemplate: IConversationTemplate;
    messageBroadcasts: IApiResponse<IMessageBroadcast>;
    //messageBroadcast: IMessageBroadcast;
    uploadFile: File;
    dataSource = new MatTableDataSource<IMessageBroadcast>();
    moment: any = moment;     
    messageBroadcastFilterSettings: IMesageBroadcastFilterSettings;
    milisecondsToReload: number = 30000;
    readonly SAVE_BUTTON: string = AppConstants.SAVE_BUTTON;
    readonly ADD_FILE: string = AppConstants.ADD_FILE;   
    searchApiSubscription: Subscription; 
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _reportCurrentUserService: ReportCurrentUserService, private _messageBroadcatFilterStateService: MessageBroadcatFilterStateService, private _messageBroadcastService: MessageBroadcastService, private _conversationTemplateService: ConversationTemplateService, private _route: ActivatedRoute, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();

        this._messageBroadcatFilterStateService.currentFilterSettings
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async(filterSettings) => {
                this.messageBroadcastFilterSettings = filterSettings as IMesageBroadcastFilterSettings;   

                this.getMessageBroadcasts();
			});
        
        this.getConversationTemplates();

        const reloadMessageBroadcast: Observable<number> = timer(this.milisecondsToReload, this.milisecondsToReload);

        reloadMessageBroadcast
            .pipe(takeUntil(this.ngUnsubscribe))    
            .subscribe(val => { 
                    this.getMessageBroadcasts();
                }
            );
    }

    getMessageBroadcasts(): void {  
        this._spinnerService.startSpinner();  

        if (this.searchApiSubscription) 
            this.searchApiSubscription.unsubscribe();   

        this.searchApiSubscription = this._messageBroadcastService.index(this.messageBroadcastFilterSettings.apiFilter) 
			.subscribe(response => { 

                if(response && response.results) {
                    this.messageBroadcasts = response; 

                    this.messageBroadcasts.results.forEach(messageBroadcast => {
                        messageBroadcast.created_at =  !this._helper.isNullOrUndefined(messageBroadcast.created_at) ? moment.utc(messageBroadcast.created_at).toDate() : null;
 
                    }); 
                } 
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            },
            () => { 
                this.messageBroadcastFilterSettings.paging = { pageIndex: this.messageBroadcastFilterSettings.paging.pageIndex, pageSize: this.messageBroadcastFilterSettings.paging.pageSize, length: this.messageBroadcasts.count, pagingStart: null, pagingEnd: null };
                this.dataSource.data = this.messageBroadcasts.results;   
            }
        ); 
    }

    saveMessageBroadcast(): void { 
       
        if(this._helper.isNullOrUndefined(this.conversationTemplate)) {
            this._snackBar.open('Select template, please.', 'Close', this.matSnackBarConfig);
            return;
        }
            

        if(this._helper.isNullOrUndefined(this.uploadFile)) {
            this._snackBar.open('Select file, please.', 'Close', this.matSnackBarConfig);
            return;
        }            
 
        this._spinnerService.startSpinner();

        let formData: FormData = new FormData(); 
        formData.append('file', this.uploadFile);
        formData.append('conversation_template', this.conversationTemplate.id.toString());

        this._messageBroadcastService.save({id: null}, formData)
            .subscribe(response => {
                if(response) {
                    this.conversationTemplate = null;
                    this.uploadFile = null;
 
                    this.ngOnInit();
                }            

            this._spinnerService.stopSpinner(); 
        },
		error =>{ error 
            this._snackBar.open('Error uploading file.', 'Close', this.matSnackBarConfig); 
            this._spinnerService.stopSpinner(); 
		}); 
 
    }
 
    addNewCsvFile(event: any): void { 
        let uploadFiles: File[] = [];
        uploadFiles.push(event.target.files[0]);
 
		if(this._helper.isNullOrUndefined(uploadFiles) ) {         
            return;
        } 

        this.uploadFile = event.target.files[0]; 
	}

    getConversationTemplates(): void { 
        this._conversationTemplateService.index()
			.subscribe(conversationTemplates => {  
                this.conversationTemplates = conversationTemplates.results;
			});
	}

    onPaginateChange(event){  
        this._messageBroadcatFilterStateService.updateApiFilterPageing({ pageIndex: event.pageIndex +1, pageSize: event.pageSize });
    }

}
