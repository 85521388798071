<div fxLayout="row" fxFlexFill fxLayoutAlign="end end" fxLayoutGap="20px" *ngIf="navigationSettings && straightbox">
    <div fxFlex="100" class="button-box space-left" fxLayoutAlign="end start">
        <button *ngIf="!straightbox.shipped" mat-raised-button mat-button color="primary" (click)="shipStraightBox(false)">SHIP STRAIGHTBOX</button> 
        <button *ngIf="!straightbox.shipped" mat-raised-button mat-button color="primary" (click)="shipStraightBox(true)">SHIP STRAIGHTBOX & CTs</button> 
 
        <button mat-raised-button mat-button color="primary" *ngIf="reportUserInfo && _userStatusHelper.isAlignersStarted(reportUserInfo.status) && !reportUserInfo.maintainer_plan__joined" (click)="openMaintainerPlanDialog()">Maintainer Plan Joined</button> 
    </div>
</div> 
<h3>Straightbox Shipping Address</h3>
<div *ngIf="straightbox" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>    
    <div fxFlex="50" style="margin-bottom: 20px;">
        <div fxLayout="column" fxLayoutGap="20px" *ngIf="user && userDetail"> 
            <div class="copy" fxLayout="row" fxLayoutGap="20px">
                <div class="copy-attribute" fxFlex="25">ID</div>
                <div class="copy-value" fxFlex="65" >{{ user.id }}</div> 
                <div fxFlex="10"> 
                    <span class="copy-user-data">      
                        <button class="copy-button" mat-icon-button (click)="copyUserDataToClipboard(element.nativeElement.querySelectorAll('.copy'))">
                            <mat-icon matTooltip='Copy user data'>content_copy</mat-icon>
                        </button> 
                    </span> 
                </div> 
            </div>
            <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                <div class="copy-attribute" fxFlex="25">Name</div>
                <div class="copy-value break-word" fxFlex="75">{{ user.full_name }}</div>                 
            </div>
            <div class="copy" fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']; IfStatement user">
                <div class="copy-attribute" fxFlex="25">Email</div>
                <div class="copy-value break-word" fxFlex="75">{{ user.email }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"   *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">Phone</div>
                <div fxFlex="75">{{ userDetail.country_code != null ? '+' + userDetail.country_code  + userDetail.phone : userDetail.phone }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"   *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">Street</div>
                <div fxFlex="75">{{ userDetail.address }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">City</div>
                <div fxFlex="75">{{ userDetail.city }}</div> 
            </div>
            <div fxLayout="row" fxLayoutGap="20px"  *appUserPermissionCheck="['app.usermodel.can_view_details']">
                <div fxFlex="25">Zip code</div>
                <div fxFlex="75">{{ userDetail.postalCode }}</div> 
            </div>
            <div class="copy" fxLayout="row" fxLayoutGap="20px" >
                <div class="copy-attribute" fxFlex="25">Country</div>
                <div class="copy-value" fxFlex="75" *ngIf="userDetail">{{ userDetail.country__name }}</div> 
            </div> 
           
            <div fxLayout="row" fxLayoutGap="20px" >
                <div fxFlex="25">Date paid</div>
                <div fxFlex="75" *ngIf="straightbox.payed_date">{{ moment(straightbox.payed_date).format('DD.MM.Y') }}</div> 
            </div>  
            <div fxLayout="row" fxLayoutGap="20px" >
                <div fxFlex="25">Payment plan</div>
                <div fxFlex="75">
                    <span *ngIf="straightbox.product_payment__payment_type__name == 'Full payment'" class="space">Full</span>
                    <span *ngIf="straightbox.product_payment__payment_type__name == 'Rates' && straightbox.product_payment__installments_number == 8" class="space">Monthly</span>
                    <span *ngIf="straightbox.product_payment__payment_type__name == 'Rates' && straightbox.product_payment__installments_number == 2" class="space">3 installments</span>                        
                    <span *ngIf="straightbox.product_payment__price_group && straightbox.product_payment__price_group != 'general'">Discount code: {{ straightbox.product_payment__price_group }}</span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="25">User has had dental changes:</div>
                <div fxFlex="75">{{ !straightbox.dental_changes ? 'YES' : 'NO' }}</div> 
            </div>             
            <div fxLayout="row" fxLayoutGap="20px" >
                <div fxFlex="25">Updated at</div>
                <div fxFlex="75" *ngIf="straightbox.updated_at">{{ moment(straightbox.updated_at).format('DD.MM.Y') }}</div> 
            </div> 
            <div fxLayout="row" fxLayoutGap="20px" >
                <div fxFlex="25">Product</div>
                <div fxFlex="75">
                    <span *ngIf="straightbox.product == 2">StraightBox category 1</span>
                    <span *ngIf="straightbox.product == 3">StraightBox category 2</span> 
                </div> 
            </div> 
            <div fxLayout="row" fxLayoutGap="20px" *ngIf="straightbox.shipped_date">
                <div fxFlex="25">Date Shipped</div>
                <div fxFlex="75">{{ moment(straightbox.shipped_date).format('DD.MM.Y') }}</div> 
            </div> 
            <div fxLayout="row" fxLayoutGap="20px" *ngIf="user && straightbox.user_stripe_id" >
                <div fxFlex="25">Visit Stripe</div>
                <div fxFlex="75"><a href="https://dashboard.stripe.com/test/customers/{{straightbox.user_stripe_id}}" target="_blank">{{ user.full_name }}</a></div> 
            </div> 
        </div>
    </div>
    <div fxFlex="50">
         <div fxLayout="column" fxLayoutGap="20px">   
            <div fxLayout="row" fxLayoutGap="20px" *ngIf="!straightbox.shipped">
                <div fxFlex="100">
                    <mat-form-field>
                        <mat-label>Tracking number</mat-label>
                        <input matInput [(ngModel)]="straightbox.tracking_number" name="trackingNumber" placeholder="Tracking number">
                    </mat-form-field> 
                </div>               
            </div> 
            <div fxLayout="row" fxLayoutGap="20px"  *ngIf="straightbox.shipped">
                <div fxFlex="100">
                    <mat-card>
                        Order status: <strong sclass="accent-color">SHIPPED</strong><br /><br />
                        <span *ngIf="straightbox.shipped_date">Date shipped: <strong>{{ moment(straightbox.shipped_date).format('DD.MM.Y')  }}</strong></span>
                        <br /><br />
                        <span *ngIf="straightbox.tracking_number != null">
                            Tracking number: <a href="https://mydhl.express.dhl/gb/en/tracking.html#/results?id={{straightbox.tracking_number.split(' ').join('')}}"><strong>{{straightbox.tracking_number}}</strong></a>
                        </span> 
                    </mat-card>
                </div>               
            </div>
            <div fxLayout="row" fxLayoutGap="20px" *ngIf="dentistFirstAlignersReminder">
                <div fxFlex="100">
                    <mat-card style="margin-bottom: 35px;">
                        <span *ngIf="!dentistFirstAlignersReminder.notified_user">Dentist first message will be sent:
                            <strong *ngIf="!dentistFirstAlignersReminderIsSendingSoon" >{{ moment(dentistFirstAlignersReminder.remind_at).format('DD.MM.Y HH:mm') }}</strong>
                            <strong *ngIf="dentistFirstAlignersReminderIsSendingSoon" >SOON</strong>
                            </span><br/>
                            <button mat-raised-button mat-button color="primary" (click)="setDentistFirstAlignersReminderAsSoonAsPossible()" *ngIf="!dentistFirstAlignersReminder.notified_user && !dentistFirstAlignersReminderIsSendingSoon">SEND NOW</button>  

                            <span *ngIf="dentistFirstAlignersReminder.notified_user">Dentist first message was sent : <strong>{{ moment(dentistFirstAlignersReminder.notified_user_at).format('DD.MM.Y HH:mm') }}</strong></span><br/>
                        
                    </mat-card>
                </div>             
            </div> 
 
        </div> 
    </div>    
</div>
 