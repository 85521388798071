<div fxLayout="row" fxFlexFill fxLayoutAlign="end end" fxLayoutGap="20px" *ngIf="navigationSettings"> 
    <div fxFlex="100" class="button-box space-right" fxLayoutAlign="end start">
        <a mat-raised-button mat-button color="primary" [routerLink]="expandAll ? navigationSettings.url : navigationSettings.url + '/' + EXPAND_ALL_LINK">{{expandAll ? SHRINK_ALL : EXPAND_ALL}}</a>  
        <button mat-raised-button mat-button color="primary" (click)="exportConversationsAsCsv()">{{ EXPORT }}</button>
        <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.url + '/' + ADD_NEW_LINK">{{ ADD_NEW }}</a>
    </div>    
</div>

<br /><br /> 

<mat-accordion *ngIf="conversations && navigationSettings" #matAccordion="matAccordion" class="example-headers-align" multi>
    <mat-expansion-panel *ngFor="let conversation of conversations; let i = index; trackBy: conversationTrackByFunction" [expanded]="conversation.messages"> 
        <mat-expansion-panel-header (click)="toggleConversationMessages(conversation.id)" >
            <mat-panel-title>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill> 
                    <div fxFlex="30"  fxFlex.xs="100" class="doctor-full-name"><span *ngIf="conversation.doctor__user_type_id == userType.Doctor">DR.</span> {{ conversation.doctor__full_name }}</div>
                    <div class="conversation-subject">{{ conversation.subject }}</div>
                </div> 
            </mat-panel-title>
            <mat-panel-description>  
                {{ conversation.last_message_at != null ? moment(conversation.last_message_at).format('DD.MM.Y HH:mm') : '' }} 
                <mat-icon [color]="conversation.doctor__user_type_id == userType.Admin ? 'accent' : 'primary'">account_circle</mat-icon>
            </mat-panel-description> 
            <!-- <a fxFlexFill (click)="toggleConversationMessages(conversation.id)" class="mat-row-link"></a> -->
        </mat-expansion-panel-header> 
    
        <div fxLayout="column" fxLayoutGap="20px" >
            <div *ngIf="conversation.isReplyButtonVisible && conversation.isExpanded" fxLayoutAlign="end start" class="button-box space-right">
                <button mat-raised-button mat-button color="warn" (click)="deleteConversationMessage(conversation)" *ngIf="!conversation.user_seen && conversation.isDeleteMessageAllowed">{{ DELETE_BUTTON }}</button>
                <a mat-raised-button mat-button color="primary" [routerLink]="[navigationSettings.parentUrl, 'complaints', 'add-new', conversation.id]" style="margin-right: 10px;" >Record complaint</a>
                <a mat-raised-button mat-button color="primary" (click)="conversation.isReplyButtonVisible = false; selectedConversation = conversation; ">{{ REPLY }}</a>
            </div>
            <div *ngIf="selectedConversation && !conversation.isReplyButtonVisible" fxFlex="100"> 
                <app-message-reply [(selectedConversation)]="selectedConversation" [(conversationTemplates)]="conversationTemplates" [(userId)]="selectedConversation.patient" (conversationChange)="replyConversationMessageChange($event)">
                </app-message-reply> 
            </div>

            <div *ngFor="let message of conversation.messages; let i = index; let isLast = last;"  fxFlex="100"> 
                <h3 *ngIf="[userType.Admin, userType.ClinicalSupport, userType.AlignerSuccess].includes(+message.user__user_type_id)">{{ message.user__full_name }}</h3>
                <h3 *ngIf="message.user__user_type_id == userType.Doctor">DR. {{ message.user__full_name }}</h3>
                <h3 *ngIf="message.user__user_type_id == userType.User" >
                    <a [routerLink]="['/admin/user/', userId, 'details']">{{ user.full_name }}</a> 
                </h3> 
                <h5>{{ message.created_at != null ? moment(message.created_at).format('DD.MM.Y HH:mm') : '' }}</h5>
                <p [innerHTML]="message.text | linky"></p>
                <app-file-preview [(userId)]="userId" [(conversationMessageFilesPreview)]="message.files" [(reportUserInfo)]="reportUserInfo" [(canAddAfterImage)]="canAddAfterImage"></app-file-preview>              
                <mat-divider *ngIf="!isLast"></mat-divider> 
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>

