import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IBitePhotosReceivedRequest, IOrder, IOrderRequest, ISmilebox } from '../../model/api/smilebox';
import { IMessageResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';


@Injectable({
  	providedIn: 'root'
})
 
export class SmileboxService extends BaseService<ISmilebox> {
    protected urlV2 = "/admin-v02/user-smilebox";
    private urlUpdateSmilebox = "/admin/update-order";
    private urlSendShipDate = "/admin/send-ship-date";    
    private urlBitePhotosRceived = "/admin/bite-photos-received";

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }


	updateSmilebox(userId: number, orderRequest: IOrderRequest): Observable<IOrder>  { 
        let urlFinal = `${this._config.api_url}${this.urlUpdateSmilebox}/${userId}`; 
		return this._http.put<IOrder>(urlFinal, orderRequest).pipe(
			map(response => { 
				return response as IOrder;
			})
		);
	}
 
    sendShipDate(userId: number): Observable<IMessageResponse>  { 
        let urlFinal = `${this._config.api_url}${this.urlSendShipDate}/${userId}`; 
		return this._http.get<IMessageResponse>(urlFinal).pipe(
			map(response => { 
                this._responseDetailCache$.clear();
				return response as IMessageResponse;
			})
		);
	} 

    bitePhotosReceived(userId: number, bitePhotosReceivedRequest: IBitePhotosReceivedRequest): Observable<any>  { 
        let urlFinal = `${this._config.api_url}${this.urlBitePhotosRceived}/${userId}`; 
 
		return this._http.put<any>(urlFinal, bitePhotosReceivedRequest).pipe(
			map(response => { 
				return response as any;
			})
		);
	}

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage); 
    }
 

}
