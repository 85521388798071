import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { SecurityGuard } from '../service/security/security-guard.service';


const routes: Routes = [ 
    { 
        path: '', redirectTo: '/login', pathMatch: 'full'
    },  
	{ 
        path: '',  component: AdminComponent,
		children: [
            { 
                path: 'admin', redirectTo: '/admin/user', pathMatch: 'full'
            },  
            {
                path: 'user', loadChildren: '../admin/user/user.module#UserModule', canActivate: [SecurityGuard]
            },
            {
                path: 'message', loadChildren: '../admin/message/message.module#MessageModule', canActivate: [SecurityGuard]
            }, 
            {
                path: 'notification-center', loadChildren: '../admin/notification-center/notification-center.module#NotificationCenterModule', canActivate: [SecurityGuard]
            },
            {
                path: 'user-export', loadChildren: '../admin/user-export/user-export.module#UserExportModule', canActivate: [SecurityGuard]
            },
            { 
                path: '**', redirectTo: '/login' 
            },
            
		]
	},
];

@NgModule({ 
    imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdminRoutingModule { }
 