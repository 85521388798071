import { Injectable } from '@angular/core';
import { formatDate } from "@angular/common";
import { LOCALE_ID } from '@angular/core';
import * as moment from 'moment'; 
import { IConversationTemplate } from '../model/api/conversation-template';
import { AppConstants } from '../settings/app-constants';
import { IUser, IUserDetails } from '../model/api/user';
import { SmileboxService } from '../service/api/smilebox.service';
import { StraightboxService } from '../service/api/straightbox.service';
import { ISmilebox } from '../model/api/smilebox';
import { IStraightbox } from '../model/api/straightbox';
import { IUserDetail } from '../model/api/user-detail';
import { UserStatus } from '../settings/user-status';
import { SnackBarService } from '../service/snackbar.service'; 
import { Helper } from '../util/helper.util'
import { UserStatusHelper } from './user-status-helper.util';

@Injectable({
    providedIn: 'root'
})

export class HelperReplaceVariablesInText {

    constructor(private _helper: Helper, private _smileboxService: SmileboxService, private _straightboxService: StraightboxService, private _snackBarService: SnackBarService, private userStatusHelper: UserStatusHelper) {}

    readonly NEW_MESSAGE_TO: string = AppConstants.NEW_MESSAGE_TO;
    readonly SMILEBOX_TRACKING_NUMBER:string = AppConstants.SMILEBOX_TRACKING_NUMBER;
    readonly STRAIGHTBOX_TRACKING_NUMBER:string = AppConstants.STRAIGHTBOX_TRACKING_NUMBER;
    readonly USER_NAME:string = AppConstants.USER_NAME;
    readonly USER_FIRST_NAME:string = AppConstants.USER_FIRST_NAME;
    readonly USER_LAST_NAME:string = AppConstants.USER_LAST_NAME;
    readonly PATIENT_NAME:string = AppConstants.PATIENT_NAME;
    readonly PATIENT_FIRST_NAME:string = AppConstants.PATIENT_FIRST_NAME;
    readonly PATIENT_LAST_NAME:string = AppConstants.PATIENT_LAST_NAME;
    readonly PATIENT_EMAIL:string = AppConstants.PATIENT_EMAIL;
    readonly PATIENT_PROFILE_URL:string = AppConstants.PATIENT_PROFILE_URL; 
    readonly CURRENT_MONTH:string = AppConstants.CURRENT_MONTH; 
    readonly USER_ID:string = AppConstants.USER_ID; 
    readonly PATIENT_ID:string = AppConstants.PATIENT_ID;  
    moment: any = moment;

    async replaceSmileboxVariables(text: string, user: IUser, userDetail: IUserDetail, smilebox?: ISmilebox) : Promise<string> {

        if(!text.includes(this.SMILEBOX_TRACKING_NUMBER)){
            return text;
        }

        if(!this.userStatusHelper.isSmileBoxOr3DScanOrdered(userDetail.status)) {
            this._snackBarService.add('SMILEBOX_TRACKING_NUMBER found in text and user has no smilebox order.');

            return text;
        } 

        if (this._helper.isNullOrUndefined(smilebox)){
            smilebox = await this._smileboxService.detail(user.id.toString()).toPromise();
        }

        if (this._helper.isNullOrUndefined(smilebox) || this._helper.isNullOrUndefined(smilebox.tracking_number)){
            this._snackBarService.add('SMILEBOX_TRACKING_NUMBER found in text and user has no smilebox order tracking number.');
            return text;
        }

        text = text.replace(new RegExp(this.SMILEBOX_TRACKING_NUMBER, 'g'), smilebox.tracking_number);

        return text;
    }

    async replaceStraightboxVariables(text: string, user: IUser, userDetail: IUserDetail, straightbox?: IStraightbox) : Promise<string> {

        if(!text.includes(this.STRAIGHTBOX_TRACKING_NUMBER)){
            return text;
        }

        if(!this.userStatusHelper.isAlignersStarted(userDetail.status)) {
            this._snackBarService.add('STRAIGHTBOX_TRACKING_NUMBER found in text and user has no straightbox order.');

            return text;
        } 

        if (this._helper.isNullOrUndefined(straightbox)){
            straightbox = await this._straightboxService.detail(user.id.toString()).toPromise();
        }

        if (this._helper.isNullOrUndefined(straightbox) || this._helper.isNullOrUndefined(straightbox.tracking_number)){
            this._snackBarService.add('STRAIGHTBOX_TRACKING_NUMBER found in text and user has no straightbox order tracking number.');
            return text;
        }
        
        text = text.replace(new RegExp(this.STRAIGHTBOX_TRACKING_NUMBER, 'g'), straightbox.tracking_number.toString());

        return text;
    }

    async replace(text: string, user: IUser, userDetail: IUserDetail, smilebox?: ISmilebox, straightbox?: IStraightbox): Promise<string> {
        
        if(this._helper.isNullOrUndefined(text))
            return null;

        text = await this.replaceSmileboxVariables(text, user, userDetail, smilebox);
        text = await this.replaceStraightboxVariables(text, user, userDetail, straightbox);

        text = text.replace(new RegExp(this.PATIENT_ID, 'g'), user.id.toString());
        text = text.replace(new RegExp(this.PATIENT_NAME, 'g'), user.full_name);
        text = text.replace(new RegExp(this.PATIENT_FIRST_NAME, 'g'), user.first_name);
        text = text.replace(new RegExp(this.PATIENT_LAST_NAME, 'g'), user.last_name);
        // Disabled beacuse of security
        // text = text.replace(new RegExp(this.PATIENT_EMAIL, 'g'), user.email);
        text = text.replace(new RegExp(this.PATIENT_ID, 'g'), user.id.toString());
        text = text.replace(new RegExp(this.USER_ID, 'g'), user.id.toString());
        text = text.replace(new RegExp(this.USER_NAME, 'g'), user.full_name);
        text = text.replace(new RegExp(this.USER_FIRST_NAME, 'g'), user.first_name);
        text = text.replace(new RegExp(this.USER_LAST_NAME, 'g'), user.last_name);
        text = text.replace(new RegExp(this.PATIENT_PROFILE_URL, 'g'), '');

        text =  text.replace(new RegExp(this.CURRENT_MONTH, 'g'),this.moment().format('MMMM'));
        
        return text;
    }
 
}