import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { IReportUserInfo } from '../../model/api/report-user-info'; 

@Injectable({
  	providedIn: 'root'
})


export class ReportUserInfoStateService {
    private _reportUserInfo: BehaviorSubject<IReportUserInfo> = new BehaviorSubject(null);
    reportUserInfo: Observable<IReportUserInfo> = this._reportUserInfo.asObservable();
	  
	constructor() { }

	setReportUserInfo(newUser: IReportUserInfo): void { 
        this._reportUserInfo.next(newUser);              
	}
  
    
}
