import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { MenuService } from 'src/app/service/state/menu.service';
import { SettingsService } from 'src/app/service/state/settings.service';
import { Helper } from 'src/app/util/helper.util';
import { DetailBaseComponent } from '../inc/base/detail-base-componenty';

@Component({
  selector: 'app-user-export',
  templateUrl: './user-export.component.html',
  styleUrls: ['./user-export.component.scss']
})
export class UserExportComponent extends DetailBaseComponent implements OnInit {
     
    menuId: number = 13;

    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportCurrentUserService: ReportCurrentUserService, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();
 
    }

}