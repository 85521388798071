import { Injectable } from '@angular/core';
import { IReportUserInfo } from '../model/api/report-user-info';
import { UserStatus } from '../settings/user-status';

@Injectable({
    providedIn: 'root'
})

export class UserStatusHelper {
 
    isSmileBoxOr3DScanOrdered(statusId: number): boolean { 
        if([UserStatus.SmileBoxOrdered, UserStatus.SmileBoxShipped, UserStatus.ImpressionsReceived, UserStatus.ThreeDScanOrdered, UserStatus.ThreeDScanReferred, UserStatus.TreatmentPlanProcessing, UserStatus.TreatmentPlanSent, UserStatus.RejectedUser, UserStatus.AlignersStarted, UserStatus.TreatmentFinished].includes(statusId))
            return true;

        return false;
    }

    isTreatmentPlanSent(statusId: number): boolean { 
        if([UserStatus.TreatmentPlanSent, UserStatus.RejectedUser, UserStatus.AlignersStarted, UserStatus.TreatmentFinished].includes(statusId))
            return true;

        return false;
    }
    
    isAlignersStarted(statusId: number): boolean {
        if([UserStatus.AlignersStarted, UserStatus.TreatmentFinished].includes(statusId))
            return true;

        return false;
    }

    isTreatmentFinished(statusId: number): boolean {
        if([UserStatus.TreatmentFinished].includes(statusId))
            return true;

        return false;
    }
 
}