<div mat-dialog-actions [class.countries-holder]="filterCategoryId == filterCategory.Country"> 
    <div fxLayout="row" fxFlexFill fxLayout.xs="column" fxLayoutGap="0px">  
        <div fxFlex="50" > 
            <mat-form-field class="example-full-width" *ngIf="filterCategoryId == filterCategory.Country && availebleCountries">
                <mat-label>Search country</mat-label>
                <input matInput placeholder="Enter country" (keyup)="findSearchCountry()" [(ngModel)]="countrySearch" >
            </mat-form-field>
            <button mat-raised-button mat-button color="primary" (click)="onVeiwSet()" *ngIf="[filterCategory.SmileboxOrders, filterCategory.TreatmentPlanProcessing, filterCategory.StraightboxOrders].includes(filterCategoryId)">Set current view</button>
        </div>
        <div fxFlex="50" fxLayoutAlign="end start">
            <div class="button-box">                
                <button mat-raised-button mat-button color="primary" (click)="onNoClick()">No Thanks</button>
                <button mat-raised-button mat-button color="primary" (click)="onClose()" cdkFocusInitial>Ok</button>
            </div>
        </div> 
    </div>
    <div class="error-message warn-color">
        <span *ngIf="errorMessage">{{ errorMessage }}</span>  
    </div>  
</div> 

 
<div mat-dialog-content>        
    <div *ngIf="filterCategoryId == filterCategory.Country && availebleCountries">            
        <mat-selection-list [(ngModel)]="filterUserSubmission.user_details__country__in">
            <mat-list-option *ngFor="let country of availebleCountries" [value]="country.code" (click)="errorMessage = ''">
                {{country.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div *ngIf="filterCategoryId == filterCategory.Status">
        <mat-selection-list [(ngModel)]="filterUserSubmission.user_details__status_id__in">
            <mat-list-option *ngFor="let status of statuses" [value]="status.id">
                {{status.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div *ngIf="filterCategoryId == filterCategory.Score">
        <mat-selection-list [(ngModel)]="filterUserSubmission.user_details__severity_status__in">
            <mat-list-option *ngFor="let severityStatus of severityStatuses" [value]="severityStatus.id">
                {{severityStatus.name}}
            </mat-list-option>
        </mat-selection-list>
    </div> 

    <div *ngIf="[filterCategory.UserRegister].includes(filterCategoryId)" class="date-field"> 
        <div>Date</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpCreatedAtFrom" [(ngModel)]="filterUserSubmission.created_at__gte">
            <mat-datepicker-toggle matSuffix [for]="dpCreatedAtFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpCreatedAtFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpCreatedAtTo" [(ngModel)]="filterUserSubmission.created_at__lt">
            <mat-datepicker-toggle matSuffix [for]="dpCreatedAtTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpCreatedAtTo></mat-datepicker>
        </mat-form-field>  
    </div> 

    <div *ngIf="[filterCategory.SmileboxOrders].includes(filterCategoryId)" class="date-field"> 
        <div>Smilebox ordered</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpSmileboxOrderedFrom" [(ngModel)]="filterUserSubmission.userorderssmilebox__created_at__gte">
            <mat-datepicker-toggle matSuffix [for]="dpSmileboxOrderedFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpSmileboxOrderedFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpSmileboxOrderedTo" [(ngModel)]="filterUserSubmission.userorderssmilebox__created_at__lt">
            <mat-datepicker-toggle matSuffix [for]="dpSmileboxOrderedTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpSmileboxOrderedTo></mat-datepicker>
        </mat-form-field>  

        <div>Smilebox shipped</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpSmileboxShippedFrom" [(ngModel)]="filterUserSubmission.userorderssmilebox__smilebox_shipped_date__gte">
            <mat-datepicker-toggle matSuffix [for]="dpSmileboxShippedFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpSmileboxShippedFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpSmileboxShippedTo" [(ngModel)]="filterUserSubmission.userorderssmilebox__smilebox_shipped_date__lt">
            <mat-datepicker-toggle matSuffix [for]="dpSmileboxShippedTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpSmileboxShippedTo></mat-datepicker>
        </mat-form-field>  
    </div> 

    <div *ngIf="[filterCategory.TreatmentPlanProcessing].includes(filterCategoryId)" class="date-field"> 
        <div>Medical records uploaded</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpMedicalRecordsUploadedFrom" [(ngModel)]="filterUserSubmission.user_meta__smilebox_files_created_at__gte">
            <mat-datepicker-toggle matSuffix [for]="dpMedicalRecordsUploadedFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpMedicalRecordsUploadedFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpMedicalRecordsUploadedTo" [(ngModel)]="filterUserSubmission.user_meta__smilebox_files_created_at__lt">
            <mat-datepicker-toggle matSuffix [for]="dpMedicalRecordsUploadedTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpMedicalRecordsUploadedTo></mat-datepicker>
        </mat-form-field>  

        <div>Impressions received</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpImpressionsReceivedFrom" [(ngModel)]="filterUserSubmission.userorderssmilebox__impressions_received__gte">
            <mat-datepicker-toggle matSuffix [for]="dpImpressionsReceivedFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpImpressionsReceivedFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpImpressionsReceivedTo" [(ngModel)]="filterUserSubmission.userorderssmilebox__impressions_received__lt">
            <mat-datepicker-toggle matSuffix [for]="dpImpressionsReceivedTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpImpressionsReceivedTo></mat-datepicker>
        </mat-form-field>  

        <div>Treatment plan sent</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpTreatmentPlanSentFrom" [(ngModel)]="filterUserSubmission.user_straigthbox__created_at__gte">
            <mat-datepicker-toggle matSuffix [for]="dpTreatmentPlanSentFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpTreatmentPlanSentFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpTreatmentPlanSentTo" [(ngModel)]="filterUserSubmission.user_straigthbox__created_at__lt">
            <mat-datepicker-toggle matSuffix [for]="dpTreatmentPlanSentTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpTreatmentPlanSentTo></mat-datepicker>
        </mat-form-field>  
    </div> 

    <div *ngIf="[filterCategory.StraightboxOrders].includes(filterCategoryId)" class="date-field"> 
        <div>Straightbox ordered</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpStraightboxOrderedFrom" [(ngModel)]="filterUserSubmission.user_straigthbox__payed_date__gte">
            <mat-datepicker-toggle matSuffix [for]="dpStraightboxOrderedFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpStraightboxOrderedFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpStraightboxOrderedTo" [(ngModel)]="filterUserSubmission.user_straigthbox__payed_date__lt">
            <mat-datepicker-toggle matSuffix [for]="dpStraightboxOrderedTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpStraightboxOrderedTo></mat-datepicker>
        </mat-form-field> 
        <div>Straightbox shipped</div>
        <mat-form-field appearance="fill">
            <mat-label>From</mat-label> 
            <input matInput [matDatepicker]="dpStraightboxShippedFrom" [(ngModel)]="filterUserSubmission.user_straigthbox__shipped_date__gte">
            <mat-datepicker-toggle matSuffix [for]="dpStraightboxShippedFrom"></mat-datepicker-toggle> 
            <mat-datepicker #dpStraightboxShippedFrom></mat-datepicker>
        </mat-form-field> 
        <mat-form-field appearance="fill">
            <mat-label>To</mat-label> 
            <input matInput [matDatepicker]="dpStraightboxShippedTo" [(ngModel)]="filterUserSubmission.user_straigthbox__shipped_date__lt">
            <mat-datepicker-toggle matSuffix [for]="dpStraightboxShippedTo"></mat-datepicker-toggle> 
            <mat-datepicker #dpStraightboxShippedTo></mat-datepicker>
        </mat-form-field>  
    </div> 

    <div *ngIf="filterCategoryId == filterCategory.AdditionalFilters && filterUserSubmission">
        <div fxLayout="column" fxFlexFill fxLayout.xs="column" fxLayoutGap="20px">  
            <div fxFlex="100" > 
                <mat-checkbox [(ngModel)]="filterUserSubmission.user_details__is_vip"> VIP  </mat-checkbox>  
            </div>
            <div fxFlex="100" > 
                <mat-checkbox [(ngModel)]="filterUserSubmission.user_details__policy_breach_status"> Policy breach </mat-checkbox>
            </div>
            <div fxFlex="100" > 
                <mat-checkbox [(ngModel)]="filterUserSubmission.user_details__is_sensitive_user"> Sensitive user </mat-checkbox>
            </div>
            <div fxFlex="100" > 
                <mat-checkbox [(ngModel)]="filterUserSubmission.maintainer_plan__active"> Maintainer plan </mat-checkbox>
            </div>
            <!--
                // COMMENT search of archived users is disabled 
            <div fxFlex="100" > 
                <mat-checkbox [(ngModel)]="filterUserSubmission.search_not_active"> Include archived users in search </mat-checkbox>
            </div>
            -->
        </div>
    </div> 
</div>  

 