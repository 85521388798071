import { Component, OnInit, ViewChild, HostListener, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject, Observable, pipe, of } from "rxjs";
import { SecurityService } from "../../service/security/security.service"; 
import { SpinnerService } from "../../service/spinner.service";
import { SettingsService } from "../../service/state/settings.service";
import { UserListServiceV1 } from '../../service/api/user-v1.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

    @Output()
    menuClickChanged: EventEmitter<void> = new EventEmitter<void>();
    private ngUnsubscribe: Subject<any> = new Subject(); 
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};

    constructor(private _securityService: SecurityService, private _router: Router, private _spinnerService: SpinnerService, private settingsService: SettingsService, private _userListServiceV1: UserListServiceV1, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        
    }
 
    Logout() { 
        this._spinnerService.startSpinner();
            
        this._securityService.logoutUser()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(response => {   
                this._spinnerService.stopSpinner(); 
                this._router.navigateByUrl('/login'); 
                window.location.reload();
            },
            error => {  
                this._spinnerService.stopSpinner(); 
            })  
        
	}

    exportUsersToCsv() {
        //this.errorMessage = '';
        this._spinnerService.startSpinner();
        this._userListServiceV1.usersToCsvExport<boolean>().subscribe(res => {            
            let filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, ''); 
            let contentType = res.headers.get('content-type');
            this.downloadExport(res.body, filename, contentType );
            this._spinnerService.stopSpinner();  
        },
        error =>{ 
            this._snackBar.open('Error downloading file.', 'Close', this.matSnackBarConfig); 
            //this.errorMessage = error.message;
            this._spinnerService.stopSpinner();  
        });
	}


    downloadExport(fileContent: any, fileName: string, contentType: string): void {
       
        var linkElement = document.createElement('a');
            try {
                var blob = new Blob([fileContent], { type: contentType });
                var url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute("download", fileName);
                linkElement.click();
                window.URL.revokeObjectURL(url);
                linkElement.remove(); 
            } catch (ex) {
                //this.errorMessage = 'Error downloading file.';
            }  
    }

	
	ngOnDestroy() {   
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
    }
 
}

