<!-- Toolbar -->
<mat-sidenav-container>    
    <!-- Sidenav --> 
    <mat-sidenav #appDrawer mode="rail" [opened]="(settings$ | async).isMobileTablet ? true : false" expandedWidth="{{(settings$ | async).matSideNavWidth}}"> 
		<app-main-menu (menuClickChanged)="appDrawer.toggle();" class="wide" ></app-main-menu>
        <app-current-user class="wide"></app-current-user>
    </mat-sidenav>
 
    <!-- Main content -->
    <mat-sidenav-content> 
        <router-outlet></router-outlet>
    </mat-sidenav-content> 
</mat-sidenav-container>

<app-custom-lightbox></app-custom-lightbox>
