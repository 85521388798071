import { Injectable}  from '@angular/core';
import { IApiFilter, IAppBannerNotificationFilterSettings } from '../../model/base/filter-settings';
import { Helper } from '../../util/helper.util';
import { FilterStateService } from '../base/filter-state.service';


@Injectable({
  providedIn: 'root'
})

export class AppBannerNotificationFilterStateService extends FilterStateService<IAppBannerNotificationFilterSettings>  {

    protected filterSettingsName = 'notificationCenterFilterSettings';

    constructor(private __helper: Helper) {
        super(__helper); 
        this._currentFilterSettings.next(this.getFilterSettings()); 
    }

    setCurrentFilterSettings(filterSettings: IAppBannerNotificationFilterSettings): void { 
        filterSettings.apiFilter = this.setApiFilter(filterSettings);
        this._currentFilterSettings.next(filterSettings); 
	}
     
    protected getFilterSettings(): IAppBannerNotificationFilterSettings   {
        let filterSettings: IAppBannerNotificationFilterSettings = this._currentFilterSettings.getValue();
        if(!this.__helper.isNullOrUndefined(filterSettings))
            return filterSettings;

        this.defaultFilterSettings.displayedColumns = this.defaultFilterSettings.defaultReportColumns[0].columns;
        this.defaultFilterSettings.apiFilter = this.setApiFilter(this.defaultFilterSettings);
 
        return this.defaultFilterSettings;
    }
   

    setApiFilter(appBannerNotificationFilterSettings: IAppBannerNotificationFilterSettings): IApiFilter {	

        let apiFilter: IApiFilter = []; 

        return super.setApiFilter(appBannerNotificationFilterSettings, apiFilter);
    }
 
    private defaultFilterSettings: IAppBannerNotificationFilterSettings = { 
        id: null, 
        selectedColumns: [ 
            { name: "id", prettyName: "Id"},
            { name: "title", prettyName: "Title"},
            { name: "visible_from", prettyName: "Visible from"},
            { name: "visible_to", prettyName: "Visible to"},
            { name: "active", prettyName: "Active"}
        ],
        defaultReportColumns: [ 
            { key: "UserExport", columns: [ 'id', 'title', 'visible_from', 'visible_to', 'active'] } 
        ],
        displayedColumns: [],
        apiFilter: [],  
        paging: { pageIndex: 1, pageSize: 10, length: null, pagingStart: null, pagingEnd: null },
        ordering: '-id'
    } 
}
 