import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IApiResponseCache } from '../../model/base/response-cache';
import { IApiResponse } from '../../model/api/response'; 
import { IFileResponse } from '../../model/api/file'; 
import { IUserFileRequest } from '../../model/api/user-file';
import { AppConstants } from '../../settings/app-constants';
import { BaseService } from '../base/base.service';
 

@Injectable({
  providedIn: 'root'
})
export class UserFileService {
	
    //protected urlV2 = "/admin-v02/report-user-file";  
    private urlAddBeforeAfterImage = this._config.api_url + "/admin/set-before-and-after-photo";
    private urlUserFilesAsZip = this._config.api_url + "/admin/list-user-files-as-zip";
    private urlUploadUserPicture = this._config.api_url + "/admin/upload-user-picture";
    private urlUploadConversationFile = this._config.api_url + "/admin/upload-conversation-file";
    readonly ECONSULTATION_UPLOADS_VALUE: string = AppConstants.ECONSULTATION_UPLOADS_VALUE;    
    private urlRotateImage = this._config.api_url + "/admin-v02/action-rotate-image";
    public _responseCache$ = new Map<string, IApiResponseCache<IApiResponse<IFileResponse>>>();
    public _responseDetailCache$ = new Map<string, IApiResponseCache<IFileResponse>>();
	protected _cacheValidForSeconds = 360;
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
       
    }
    

    addBeforeAfterFile<T>(userId: number, userFileRequest: IUserFileRequest): Observable<T> {
        let urlFinal = `${this.urlAddBeforeAfterImage}/${userId}`; 
   
        return this._http.put(urlFinal, userFileRequest)
            .pipe(
                map((res: T) => {
                    if (res) { 
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    downloadAllUserUploadsFiles<T>(userId: number): Observable<any> {
        let urlFinal = `${this.urlUserFilesAsZip}/${userId}`; 
   
        return this._http.get(urlFinal, { responseType: 'arraybuffer', observe: 'response' });
    } 

    uploadUserPicture<T>(userId: number, category: string, formData: FormData): Observable<any> {
        let urlCategory = (category == this.ECONSULTATION_UPLOADS_VALUE) ? this.urlUploadUserPicture : this.urlUploadConversationFile;
        let urlFinal = `${urlCategory}/${userId}`;     
 
        return this._http.post(urlFinal, formData)
            .pipe(
                map((res: any) => {
                    this._responseCache$.clear();
                    if (res && res.success) {  
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            ); 
    }
    
    rotateImage<T>(imageId: string, objectModel: string, angle: string): Observable<T> {
        let urlFinal = `${this.urlRotateImage}/${objectModel}/${imageId}/${angle}`; 
   
        return this._http.post(urlFinal, {})
            .pipe(
                map((res: T) => {
                    if (res) { 
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    getRotatedImage<T>(urlPath: string): Observable<T> { 
        return this._http.post(urlPath, {headers: {'Cache-Control': 'no-cache'}})
            .pipe(
                map((res: T) => {
                    if (res) { 
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);

        /* let error = httpError.error;
        let errorMessage = error.detail || error.message || error.msg || 'An error occured!';
        return throwError(errorMessage); */
    }
}
