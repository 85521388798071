import { Injectable}  from '@angular/core';
import { IApiFilter, IApiSearchFilter, IUserFilterSettings } from '../../model/base/filter-settings';
import { Paging } from '../../model/base/paging';
import { Helper } from '../../util/helper.util';
import { StatusService } from '../status.service';
import { SeverityStatusService } from '../severity-status.service'; 
import { FilterStateService } from '../base/filter-state.service';
import { FilterCategory } from 'src/app/settings/filter-category';
import { LocalStorageUserFilterService } from '../base/local-storage-filter.service';



@Injectable({
  providedIn: 'root'
})

export class UserFilterStateService extends FilterStateService<IUserFilterSettings>  {

    protected filterSettingsName = 'userFilterSettings';
    private localstorageFilterVersion: number = 1.0;
 
    constructor(private __helper: Helper, private _statusService: StatusService, private _severityStatusService: SeverityStatusService, private _localStorageUserFilterService: LocalStorageUserFilterService) {
        super(__helper); 
        this._currentFilterSettings.next(this.getFilterSettings()); 
    }

    setCurrentFilterSettings(userFilterSettings: IUserFilterSettings): void { 
        userFilterSettings.apiFilter = this.setApiFilter(userFilterSettings);
        this.saveToLocalStorage(userFilterSettings);
        this._currentFilterSettings.next(userFilterSettings); 
	}
 

    updateApiFilterTextSearch(textSearch: string): void { 
        let newUserFilterSettings: IUserFilterSettings = this._currentFilterSettings.value;
        newUserFilterSettings.textSearch = textSearch;
        newUserFilterSettings.paging = { pageIndex: 1, pageSize: !this.__helper.isNullOrUndefined(newUserFilterSettings.paging) && newUserFilterSettings.paging.pageSize > 0 ? newUserFilterSettings.paging.pageSize : 10 };
        this.setCurrentFilterSettings(newUserFilterSettings);
	}  


    updateApiFilterOrdering(ordering: string): void { 
        let newUserFilterSettings: IUserFilterSettings = this._currentFilterSettings.value;
        newUserFilterSettings.ordering = ordering;
        this.setCurrentFilterSettings(newUserFilterSettings);
	}  

    saveFilterSettings(userFilterSettings: IUserFilterSettings): void {  
        userFilterSettings.paging = { pageIndex: 1, pageSize: !this.__helper.isNullOrUndefined(userFilterSettings.paging) && userFilterSettings.paging.pageSize > 0 ? userFilterSettings.paging.pageSize : 10 };
        this.setCurrentFilterSettings(userFilterSettings);
	} 
  
    protected getFromLocalStorage(defaultUserFilterSettings: IUserFilterSettings): IUserFilterSettings {

        let filterSettingsFromStorage: IUserFilterSettings = this._localStorageUserFilterService.get(this.filterSettingsName);

        if(this.__helper.isNullOrUndefined(filterSettingsFromStorage) || filterSettingsFromStorage.version !== this.localstorageFilterVersion) {
            filterSettingsFromStorage = null;
            this.saveToLocalStorage({id: null, displayedColumns: null, version: null, textSearch: null, filter: null, ordering: null });
        }

        if(this.__helper.isNullOrUndefined(filterSettingsFromStorage))
            return null;
  
        defaultUserFilterSettings.displayedColumns = this.loadAndValidateDisplayedColumns(filterSettingsFromStorage.displayedColumns, defaultUserFilterSettings.displayedColumns);
        defaultUserFilterSettings.textSearch = filterSettingsFromStorage.textSearch;
        defaultUserFilterSettings.filter = filterSettingsFromStorage.filter;
        defaultUserFilterSettings.ordering = filterSettingsFromStorage.ordering;
        
        return defaultUserFilterSettings;
    }

    // COMMENT Validate all columns stored in localstorage exist on mat-table definition
    private loadAndValidateDisplayedColumns(displayedColumnsFromStorage: string [], displayedColumnsDefault: string[]){
        let ret: string[] = [];

        var tmpDisplayedColumns = displayedColumnsFromStorage ? displayedColumnsFromStorage : displayedColumnsDefault;
        
        let tmpValidDispayColumnNames = []
        this.defaultUserFilterSettings.selectedColumns.forEach(column => {
            tmpValidDispayColumnNames.push(column.name)
        });

        tmpDisplayedColumns.forEach(column => {
            if(tmpValidDispayColumnNames.includes(column))
                ret.push(column);
        })

        return ret;
    }

    private saveToLocalStorage(userFilterSettings: IUserFilterSettings): void { 
        
        let filterSettingsForLocalStorage: IUserFilterSettings = {
                id: null,
                displayedColumns: userFilterSettings.displayedColumns,
                version: this.localstorageFilterVersion,
                textSearch: userFilterSettings.textSearch,
                filter: userFilterSettings.filter,
                ordering: userFilterSettings.ordering
        };
        
        this._localStorageUserFilterService.set(this.filterSettingsName, filterSettingsForLocalStorage);
    }
    
    resetFilter() {
        let userFilterSettings: IUserFilterSettings = this._currentFilterSettings.getValue();
        userFilterSettings.filter = null;
        userFilterSettings.textSearch = null;
        userFilterSettings.filterCategoryId = null;
        userFilterSettings.paging = { pageIndex: 1, pageSize: 10 };
        //userFilterSettings.ordering = null;
        this.setCurrentFilterSettings(userFilterSettings); 
    }
 
    changeDisplayedColumnsView(userFilterSettings: IUserFilterSettings): void { 
        this.updateSelectedColumns(userFilterSettings)
        //this.setCurrentFilterSettings(userFilterSettings);
	} 

    resetDisplayedColumnsView(userFilterSettings: IUserFilterSettings): void { 
        userFilterSettings.filterCategoryId = null;
        userFilterSettings.ordering = null;
        this.updateSelectedColumns(userFilterSettings)
        this.setCurrentFilterSettings(userFilterSettings);
	} 

    private updateSelectedColumns(userFilterSettings: IUserFilterSettings): IUserFilterSettings {          

        if(userFilterSettings.filterCategoryId != null && [FilterCategory.Country, FilterCategory.UserRegister, FilterCategory.Score, FilterCategory.Status].includes(userFilterSettings.filterCategoryId))  
            return userFilterSettings; 

        switch(userFilterSettings.filterCategoryId) {
            case 5: { 
                userFilterSettings.displayedColumns = userFilterSettings.defaultReportColumns[1].columns;
                break;
            } 
            case 6: { 
                userFilterSettings.displayedColumns = userFilterSettings.defaultReportColumns[2].columns;
                break;
            } 
            case 7: { 
                userFilterSettings.displayedColumns = userFilterSettings.defaultReportColumns[3].columns;
                break;
            }             
            default: { 
                userFilterSettings.displayedColumns = userFilterSettings.defaultReportColumns[0].columns;
                break;
            } 
        } 

        return userFilterSettings;
    }

    protected getFilterSettings(): IUserFilterSettings   {
 
        let userFilterSettings: IUserFilterSettings = this._currentFilterSettings.getValue();
        if(!this.__helper.isNullOrUndefined(userFilterSettings))
            return userFilterSettings;
  
        this.defaultUserFilterSettings.displayedColumns = this.defaultUserFilterSettings.defaultReportColumns[0].columns;

        let userFilterSettingsFromLocalStorage: IUserFilterSettings = this.getFromLocalStorage(this.defaultUserFilterSettings);

        if(!this.__helper.isNullOrUndefined(userFilterSettingsFromLocalStorage)) {
            userFilterSettingsFromLocalStorage.apiFilter = this.setApiFilter(this.defaultUserFilterSettings);
            return userFilterSettingsFromLocalStorage;
        } 

        return this.defaultUserFilterSettings;
    }

    setApiFilter(userFilterSettings: IUserFilterSettings): IApiFilter {	

        let apiFilter: IApiFilter = [];
 
        if(!this.__helper.isEmptyOrNull(userFilterSettings.textSearch)) {
            apiFilter['full_name_or_email__icontains'] = userFilterSettings.textSearch;
        } 
        
        return super.setApiFilter(userFilterSettings, apiFilter);
    }

    

    private defaultUserFilterSettings: IUserFilterSettings = { 
        id: null,
        selectedColumns: [
            { name: "full_name", prettyName: "User" },
            { name: "id", prettyName: "Id" },
            { name: "user_details__status_id", prettyName: "Status" },
            { name: "user_details__country__name", prettyName: "Country" },
            { name: "doctor_processing_patient__user__full_name", prettyName: "Dentist" },
            { name: "user_meta__last_treatment_progress_percentage", prettyName: "Tr. Pr" },
            { name: "al_status", prettyName: "Al. Status" },
            { name: "user_meta__last_interaction_date", prettyName: "Last login" },
            { name: "created_at", prettyName: "User register" },
            { name: "userorderssmilebox__created_at", prettyName: "Smilebox ordered" },
            { name: "userorderssmilebox__smilebox_shipped_date", prettyName: "Smilebox shipped" },
            { name: "user_meta__smilebox_files_created_at", prettyName: "Medical records uploaded" },
            { name: "userorderssmilebox__impressions_received", prettyName: "Impressions received" },
            { name: "user_straigthbox__created_at", prettyName: "Treatment plan sent" },
            { name: "user_straigthbox__payed_date", prettyName: "Straightbox ordered" }, 
            { name: "user_straigthbox__shipped_date", prettyName: "Straightbox shipped" },
            { name: "user_meta__count_conversation_message_sent_to", prettyName: "Msg. rec" },
            { name: "user_meta__count_conversation_message_sent_by", prettyName: "Msg. sent" }
        ],
        defaultReportColumns: [ 
            { key: "UserSubmission", columns: [ 'full_name', 'id', 'user_details__status_id', 'user_details__country__name', 'doctor_processing_patient__user__full_name', 'user_meta__last_treatment_progress_percentage', 'al_status', 'user_meta__last_interaction_date', 'created_at' ] },
            { key: "SmileboxOrder", columns: [ 'full_name', 'id', 'userorderssmilebox__created_at', 'userorderssmilebox__smilebox_shipped_date', 'user_details__status_id' ] },
            { key: "TreatmentPlanProcessing", columns: [ 'full_name', 'id', 'user_meta__smilebox_files_created_at', 'userorderssmilebox__impressions_received', 'user_straigthbox__created_at', 'user_details__status_id' ] },
            { key: "StraightboxOrders", columns: [ 'full_name', 'id', 'user_straigthbox__payed_date', 'user_straigthbox__shipped_date', 'user_details__status_id' ] }
        ],
        displayedColumns: [],
        filterCategories: [ 
            {id: 1, name: "Country", attribute: "user_details__country__in", operator: "", data: [], category: 1},
            {id: 2, name: "Status", attribute: "user_details__status_id__in", operator: "", data: [], category: 2},
            {id: 3, name: "Score", attribute: "user_details__severity_status__in", operator: "", data: [], category: 3},
            {id: 4, name: "User register", attribute: "date", operator: "", data: [], category: 4},
            {id: 5, name: "Smilebox orders", attribute: "smilebox_orders", operator: "", data: [], category: 5},
            {id: 6, name: "Treatment plan processing", attribute: "treatment_plan_processing", operator: "", data: [], category: 6},
            {id: 7, name: "Straightbox orders", attribute: "straightbox_orders", operator: "", data: [], category: 7},
            {id: 8, name: "Additional filters", attribute: "additional", operator: "", data: [], category: 8}
        ],
        filter: { 
            id: null
        },
        apiFilter: [],
        paging: { pageIndex: 1, pageSize: 10, length: null, pagingStart: null, pagingEnd: null },
        filterCategoryId: null,
        version: this.localstorageFilterVersion
    } 
}
 