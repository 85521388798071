import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { UserStateService } from '../../../service/state/user-state.service';
import { IMaintainerPlan } from '../../../model/api/maintainer-plan';
import { IUser } from '../../../model/api/user';
import { INavigationSettings } from '../../../model/settings';
import { MaintainerPlanService } from '../../../service/api/maintainer-plan.service';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { UserService } from '../../../service/api/user.service';
import { SpinnerService } from '../../../service/spinner.service';
import { MenuService } from '../../../service/state/menu.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { SettingsService } from '../../../service/state/settings.service';
import { Helper } from '../../../util/helper.util';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { DialogMaintainerPlanComponent } from '../../shared/dialog-maintainer-plan/dialog-maintainer-plan.component';

@Component({
  selector: 'app-maintainer-plan',
  templateUrl: './maintainer-plan.component.html',
  styleUrls: ['./maintainer-plan.component.scss']
})
export class MaintainerPlanComponent extends DetailUserBaseComponent implements OnInit {

	moment: any = moment; 
    navigationSettings: INavigationSettings; 
    menuId: number = 112;
    user: IUser;  
    maintainerPlan: IMaintainerPlan;
    
    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _helper: Helper, private _spinnerService: SpinnerService, private _snackBar: MatSnackBar, public _dialog: MatDialog, private maintainerPlanService: MaintainerPlanService) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        //this.getUser();

        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => {
                this.reportUserInfo = reportUserInfo;

                if(!this.reportUserInfo)
                    return;

                if(!this.reportUserInfo.maintainer_plan__joined)
                    this._router.navigate(['admin', 'user', this.userId, 'details']); 

                this.getMaintainerPlan(); 
            });        
    }

    /* getUser(): void { 
        this._spinnerService.startSpinner(); 

        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
			    this._spinnerService.stopSpinner(); 			 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
	} */

    openMaintainerPlanDialog(): void { 
        const dialogRef = this._dialog.open(DialogMaintainerPlanComponent, {
            width: '600px',
            height: '600px', 
            data: { status: this.reportUserInfo.status, user: this.user.id, maintainerPlan: this.maintainerPlan, closeButton: true, saveButton: true },
            disableClose: true
        });
    
        dialogRef.afterClosed()
            .subscribe(result => { 
                this._reportUserInfoService.clearCache();
                this.ngOnInit();
            });
    }

    getMaintainerPlan(): void {
        this._spinnerService.startSpinner();

        if(this._helper.isNullOrUndefined(this.reportUserInfo) || !this.reportUserInfo.maintainer_plan__joined || this._helper.isNullOrUndefined(this.userId))
            return;
 
        this.maintainerPlanService.detail(this.userId.toString())
			.subscribe(maintainerPlan => { 

                if(!maintainerPlan) {
                    this._spinnerService.stopSpinner(); 
                    return;
                }
                    
                this.maintainerPlan = maintainerPlan;

                this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
    }
    
    
}
