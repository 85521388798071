
import { throwError, Observable, Observer } from 'rxjs';
import { Injectable, Inject } from "@angular/core"; 
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ILoginRequest } from "../../model/api/login-request";
import { APP_CONFIG } from '../../app.config/app.config';
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Helper } from '../../util/helper.util';
import { SettingsService } from "../state/settings.service"; 


@Injectable()
export class SecurityService {
 
    private authTokenName: string = 'stpro_auth_token';
    private _loginUserUrl: string = this._config.api_url + '/admin/login';
    private _logoutUserUrl: string = this._config.api_url + '/admin/logout';

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient, private router: Router, private helper: Helper, private settingsService: SettingsService) {
    }


    loginUser(data: ILoginRequest): Observable<any> {
        const headers = new HttpHeaders()
           .set("Content-Type", "application/json")
           .set("Accept", "application/json");

        return this._http.post(this._loginUserUrl, data, { headers: headers })
        .pipe(
            map((res:any)  => { 
                if(!this.helper.isEmptyOrNull(res.token)){ 
        			this.settingsService.setSettingsUserLogged(true);
                    this.saveToken(res.token); 
                }
            }),
            catchError( err =>  { return this.handleError(err)}),
        )
    }

    logoutUser(): Observable<any> {
        return new Observable((observer) => {
            this.deleteToken();
			this.settingsService.setSettingsUserLogged(false);
            observer.next();
          });
    }

    retrieveToken(): string {
        return localStorage[this.authTokenName];
    }

    isLoggedIn(): boolean {
        if (typeof localStorage !== 'undefined' && localStorage.getItem(this.authTokenName)) {
            return true;
        }
        return false;
    }

    private saveToken(token: string): void {
        localStorage[this.authTokenName] = token;
    }

    private deleteToken(): void {
        if (typeof localStorage === 'undefined') {
            return;
        }

        delete localStorage[this.authTokenName];
    }

    private isFirstTime(): boolean {
        if (typeof localStorage === 'undefined') {
            return true;
        }

        if (!localStorage.getItem('std')) {
            return true;
        }
        return false;
    }

    private setFirstTime(): void {
        localStorage['std'] = true;
    }

    
    private handleError(httpError:any) {
        let error = httpError.error;
        let errorMessage = error.detail || error.message || error.msg || 'An error occured!';
        return throwError(errorMessage);
    } 
 
}
