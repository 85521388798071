import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ILightboxFile, ICustomLightboxSettings } from 'src/app/model/custom-lightbox';
import { CustomLightboxService } from 'src/app/service/custom-lightbox.service';
import { Helper } from 'src/app/util/helper.util';

@Component({
  selector: 'app-custom-lightbox',
  templateUrl: './custom-lightbox.component.html',
  styleUrls: ['./custom-lightbox.component.scss'],
  host: { "(window:resize)": "onResize($event)"}
})
export class CustomLightboxComponent implements OnInit {

	customLightboxSettings: ICustomLightboxSettings; 
	lightboxFiles: ILightboxFile[] = []; 
	slideIndex: number = 0;
    windowHeight: number;
	firstItem: number = 1;
	moment: any = moment;
	protected ngUnsubscribe: Subject<any> = new Subject();
	
	constructor(private _helper: Helper, private cdr: ChangeDetectorRef, private _customLightboxService: CustomLightboxService) { }

	ngOnInit(): void { 
		
		this._customLightboxService.customLightboxSettings
			.pipe(takeUntil(this.ngUnsubscribe))	
			.subscribe(customLightboxSettings => {
					this.openModal(customLightboxSettings);
			});
	}

    openModal(customLightboxSettings: ICustomLightboxSettings) {

		if(this._helper.isNullOrUndefined(customLightboxSettings))
			return;

		this.customLightboxSettings = customLightboxSettings; 

		this.windowHeight = window.innerHeight;
		this.lightboxFiles = customLightboxSettings.files;
        
		this.currentSlide(customLightboxSettings.item);
    }
    
    closeModal() {
		this.lightboxFiles = [];
 
        document.getElementById('imgModal').style.display = "none";
		this.customLightboxSettings = null;
    }
        
    slideItem(item) {
		this.customLightboxSettings.item = item;
        this.showSlides(this.slideIndex += item);
    }
 
	currentSlide(item) {
        this.showSlides(this.slideIndex = item + this.firstItem);
    }

    showSlides(item) {

		this.cdr.detectChanges();

		document.getElementById('imgModal').style.height = this.windowHeight + 'px';
        document.getElementById('imgModal').style.display = "block";
		
        this.slideIndex = !this._helper.isNumber(this.slideIndex) ? this.firstItem : this.slideIndex;

        const slides = document.getElementsByClassName("slide") as HTMLCollectionOf<HTMLElement>;
        const dots = document.getElementsByClassName("dots")as HTMLCollectionOf<HTMLElement>;
		const titles = document.getElementsByClassName("image-title")as HTMLCollectionOf<HTMLElement>;
 
		for (let slide = 0; slide < slides.length; slide++) {
            slides[slide].style.display = "none";
			dots[slide].className = dots[slide].className.replace(" active", "");
			titles[slide].style.display = "none";
        }

		this.slideIndex = item > slides.length ? this.firstItem : this.slideIndex;         
		this.slideIndex = item < this.firstItem ? slides.length : this.slideIndex;   

		slides[this.slideIndex-1].style.display = "block";
		titles[this.slideIndex-1].style.display = "block";

        if (dots && dots.length > 0) {
          	dots[this.slideIndex-1].className += " active";
        }		
	 
    }

	onResize(event) {

		if(this._helper.isNullOrUndefined(this.customLightboxSettings))
			return;

        this.windowHeight = window.innerHeight;
		this.currentSlide(this.customLightboxSettings.item);
	}

	ngOnDestroy(): any { 
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    } 


}
