import { Component, OnInit } from '@angular/core';
import { INavigationSettings } from '../../../../model/settings';
import { AppConstants } from '../../../../settings/app-constants';
import { MenuService } from '../../../../service/state/menu.service';
import { DetailUserBaseComponent } from '../../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../../util/helper.util';
import { SettingsService } from '../../../../service/state/settings.service';
import { UserDetailService } from '../../../../service/api/user-detail.service';
import { UserFileService } from '../../../../service/api/user-file.service';
import { ReportUserInfoService } from '../../../../service/api/report-user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportUserInfoStateService } from '../../../../service/state/report-user-info-state.service';
 import { SpinnerService } from '../../../../service/spinner.service';
import { ReportUserFileService } from '../../../../service/api/report-user-file.service';
import { UserService } from '../../../../service/api/user.service';
import { UserStateService } from '../../../../service/state/user-state.service';
import { HelperExtension } from 'src/app/util/helper.extension';

@Component({
	selector: 'app-image-upload',
	templateUrl: './image-upload.component.html',
	styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent extends DetailUserBaseComponent implements OnInit {

	navigationSettings: INavigationSettings; 
    userId: number; 
    menuId: number = 1050;  
    econsultationAllowedExtensions: Array<string> = this._helperExtension.image();
    multipleFileUpload: boolean = false; 
    filesToUpload: any[]; 
	readonly UPLOAD:string = AppConstants.UPLOAD;
	readonly CANCEL:string = AppConstants.CANCEL;
	readonly ERROR_FILL_ALL_FIELDS:string = AppConstants.ERROR_FILL_ALL_FIELDS;
	readonly ECONSULTATION_UPLOADS: string = 'E-consultation Uploads'; 
	readonly CONVERSATION_UPLOADS: string = 'Conversation Uploads'; 
	readonly ECONSULTATION_UPLOADS_VALUE: string = AppConstants.ECONSULTATION_UPLOADS_VALUE;
	readonly CONVERSATION_UPLOADS_VALUE: string = AppConstants.CONVERSATION_UPLOADS_VALUE;
    UPLOAD_FILE: string = AppConstants.UPLOAD_FILE;
	categories: string[] = [ this.CONVERSATION_UPLOADS_VALUE, this.ECONSULTATION_UPLOADS_VALUE ];
	selectedCategory: string = this.CONVERSATION_UPLOADS_VALUE;

	constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute,  private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _spinnerService: SpinnerService, private _helper: Helper, private _userFileService: UserFileService, private _snackBar: MatSnackBar,  private _reportuserFileService: ReportUserFileService, private _helperExtension: HelperExtension) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

	ngOnInit(): void {
		super.ngOnInit();
	}

    fileChange(fileSrcs) { 
        this.filesToUpload = fileSrcs; 
    }

    catgegoryChange() {
        this.filesToUpload = []; 
    }

    
	uploadImage(): void {
		if(this._helper.isNullOrUndefined(this.filesToUpload) ) { 
            this._snackBar.open(this.ERROR_FILL_ALL_FIELDS, 'Close', this.matSnackBarConfig);
            return;
        } 

        this._spinnerService.startSpinner(); 
		let formData: FormData = new FormData();
 
		this.filesToUpload.forEach(file => { 
            this.selectedCategory == this.ECONSULTATION_UPLOADS_VALUE ? formData.append('image', file.file) :  formData.append('file', file.file);
			} 
		); 
 
		this._userFileService.uploadUserPicture(this.userId, this.selectedCategory, formData).subscribe(response => { 
			if(response) { 
                this._reportuserFileService._responseCache$.clear();
				this._router.navigateByUrl(this.navigationSettings.parentUrl);  
			} 
            
            this._spinnerService.stopSpinner(); 
		},
		error =>{ error 
			this._spinnerService.stopSpinner(); 
		}); 

	}
 

}
