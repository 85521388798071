import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { IMaintenanceAnnouncement } from '../../../model/api/maintenance-announcement';
import { MaintenanceAnnouncementService } from '../../../service/api/maintenance-announcement.service';
import { SpinnerService } from '../../../service/spinner.service';
import { StatusService } from '../../../service/status.service';
import { AppConstants } from '../../../settings/app-constants';
import { Helper } from '../../../util/helper.util';
import { DetailBaseComponent } from '../../inc/base/detail-base-componenty'; 
import { MenuService } from '../../../service/state/menu.service';
import { SettingsService } from '../../../service/state/settings.service';  
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaintenanceAnnouncementFilterStateService } from '../../../service/state/maintenance-announcement-filter-state.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMaintenanceAnnouncementFilterSettings } from '../../../model/base/filter-settings';
import { ReportCurrentUserService } from '../../../service/api/report-current-user.service';
import { IApiResponse } from '../../../model/api/response';

@Component({
    selector: 'app-maintenance-announcement',
    templateUrl: './maintenance-announcement.component.html',
    styleUrls: ['./maintenance-announcement.component.scss']
})
export class MaintenanceAnnouncementComponent extends DetailBaseComponent implements OnInit {

    menuId: number = 202; 
    maintenanceAnnouncements: IApiResponse<IMaintenanceAnnouncement>; 
    dataSource = new MatTableDataSource<IMaintenanceAnnouncement>();
    moment: any = moment;  
    maintenanceAnnouncementFilterSettings: IMaintenanceAnnouncementFilterSettings;
    readonly ADD_NEW: string = AppConstants.ADD_NEW; 
    readonly ADD_NEW_LINK: string = AppConstants.ADD_NEW_LINK; 
    searchApiSubscription: Subscription;
 
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _reportCurrentUserService: ReportCurrentUserService, private _route: ActivatedRoute, private _maintenanceAnnouncementService: MaintenanceAnnouncementService, private _maintenanceAnnouncementFilterStateService: MaintenanceAnnouncementFilterStateService, protected _statusService: StatusService, private _spinnerService: SpinnerService, public _helper: Helper, private _snackBar: MatSnackBar, private _cd: ChangeDetectorRef, private _zone: NgZone) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();

        this._maintenanceAnnouncementFilterStateService.currentFilterSettings
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async(filterSettings) => {
                this.maintenanceAnnouncementFilterSettings = filterSettings as IMaintenanceAnnouncementFilterSettings;  
 
                this.getMaintenanceAnnouncement();
			});

    }
 

    getMaintenanceAnnouncement(): void {  
        this._spinnerService.startSpinner();  

        if (this.searchApiSubscription) 
            this.searchApiSubscription.unsubscribe();   

        this.searchApiSubscription = this._maintenanceAnnouncementService.index(this.maintenanceAnnouncementFilterSettings.apiFilter) 
			.subscribe(async(response) => {
 
                if(response && response.results) {
                    this.maintenanceAnnouncements = response; 
                } 

                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            },
            () => { 
                this.maintenanceAnnouncementFilterSettings.paging = { pageIndex: this.maintenanceAnnouncementFilterSettings.paging.pageIndex, pageSize: this.maintenanceAnnouncementFilterSettings.paging.pageSize, length: this.maintenanceAnnouncements.count, pagingStart: null, pagingEnd: null };
                this.dataSource.data = this.maintenanceAnnouncements.results; 
            }
        ); 
    }

    onPaginateChange(event){  
        this._maintenanceAnnouncementFilterStateService.updateApiFilterPageing({ pageIndex: event.pageIndex +1, pageSize: event.pageSize });
    }
 

}
