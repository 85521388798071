import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { IUserDetailsData, ISensitiveUserRequest, IUserDetailsRequest, IUserProfile } from '../../../model/api/user-profile'; 
import * as moment from 'moment';  
import { Helper } from '../../../util/helper.util'; 
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service';
import { IMessage, Message } from '../../../model/base/message';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { IUserDetail } from '../../../model/api/user-detail';
import { UserStatus } from '../../../settings/user-status';
import { IReportCurrentUser } from 'src/app/model/api/report-current-user';
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Component({
    template: ''
})
export class DetailBaseComponent implements OnInit {
    
    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = null; 
    userDetail: IUserDetail;
    userStatus = UserStatus; 
    reportCurrentUser: IReportCurrentUser;
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
    protected ngUnsubscribe: Subject<any> = new Subject();
    
    constructor(private settingsService: SettingsService, protected menuService: MenuService, protected route: ActivatedRoute, private reportCurrentUserService: ReportCurrentUserService) { }

    ngOnInit(): void {
        
        this.navigationSettings = this.menuService.getNavigationByMenuId(this.menuId);
        this.settingsService.setNavigationSettings(this.navigationSettings);       

        this.getCurentUser();
    }

    private getCurentUser(): void { 
        this.reportCurrentUserService.detail()
            .subscribe(reportCurrentUser => {  
                this.reportCurrentUser = reportCurrentUser;
            });
    }

    ngOnDestroy(): any { 
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    } 
}
