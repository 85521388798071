<div fxLayout="row" fxFlexFill fxLayoutAlign="end end" fxLayoutGap="20px" *ngIf="navigationSettings">  
    <div fxFlex="100" class="button-box space-left vertical-space align-right">
        <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.url + '/' + ADD_NEW_LINK">{{ ADD_NEW }}</a>
    </div>
</div> 

<div *ngIf="complaints">
    <mat-list *ngFor="let complaint of complaints; let i = index">
        <mat-list-item>
            <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="0px">    
                <div fxFlex="35"><span>Complaint category: <span class="accent-color">{{ complaint.category__name }}</span></span></div>
                <div fxFlex="20" *ngIf="complaint.created_at != null"><span>Created: {{ complaint.created_at | date:'dd.MM.yyyy' }}</span></div>
                <div fxFlex="20" *ngIf="complaint.received_at != null"><span>Received: {{ complaint.received_at | date:'dd.MM.yyyy' }}</span></div>
                <div fxFlex="25"><a *ngIf="complaint.conversation != null" [routerLink]="['/admin/user/', complaint.user, 'message', complaint.conversation]">{{ !complaint.is_from_mail ? complaint.thread_name : '' }}</a> <span *ngIf="complaint.is_from_mail">{{ complaint.is_from_mail ? 'From mail' : '' }}</span></div>
            </div> 
        </mat-list-item>
        <mat-list-item>        
            <div fxLayout="row" fxLayout.xs="column" fxFlexFill> 
                <div fxFlex="100" *ngIf="complaint.text != null"><span [innerHTML]="complaint.text| linky"></span></div>
            </div>  
        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>
</div>

