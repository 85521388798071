<form #addNewForm="ngForm" (ngSubmit)="sendNewMessage(conversationMessageRequest)" *ngIf="conversationMessageRequest" >
    <div fxLayout="column" fxFlexFill fxLayoutGap="20px grid" class="message-reply" > 
        <div fxFlex="100" fxLayoutAlign="end end" fxLayoutGap="20px">  
            <button mat-raised-button mat-button color="accent">{{ CONFIRM }}</button>
            <a mat-raised-button mat-button color="warn" (click)="cancelMessage()">{{ CANCEL }}</a>
        </div>
        <div fxFlex="100">
            <H2>{{ REPLY_MESSAGE_TO }} <span *ngIf="user">{{ user.full_name }}</span></H2>
        </div>         
        <div fxFlex="100" *ngIf="conversationTemplates"> 
            <mat-form-field> 
                <mat-select [(ngModel)]="conversationTemplate" name="conversationTemplate" placeholder="select template" (selectionChange)="changeTemplate($event)" class="mat-small"> 
                    <mat-option *ngFor="let conversationTemplate of conversationTemplates" [value]="conversationTemplate">
                        {{conversationTemplate.admin_name}} 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <!-- <mat-label>Subject</mat-label> -->
                <textarea matInput [(ngModel)]="conversationMessageRequest.text" name="text" rows="16" placeholder="Write your message..." required></textarea>
            </mat-form-field> 
        </div> 
        <div fxFlex="100">
            <app-file-upload [(isMultiple)]="multipleFileUpload" [(buttonTitle)]="UPLOAD_FILES" (fileChange)="fileChange($event)"></app-file-upload>
        </div> 
    </div> 
</form>