import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UserDialogComponent } from './dialog/dialog.component' 
import { IApiFilter, IUserFilterSettings } from '../../../../model/base/filter-settings';
import { UserFilterStateService } from '../../../../service/state/user-filter-state.service';
import { Helper } from '../../../../util/helper.util'; 
import { takeUntil } from 'rxjs/operators';	
import { combineLatest, forkJoin, Observable, Subject } from "rxjs";
import { ISearchDialogData } from 'src/app/model/base/search-dialog-data';
import * as moment from 'moment';
import { CountryService } from 'src/app/service/api/country.service';
import { ICountry } from 'src/app/model/api/country';
import { StatusService } from 'src/app/service/status.service';
import { IStatus } from 'src/app/model/status';
import { SeverityStatusService } from 'src/app/service/severity-status.service';
import { ISeverityStatus } from 'src/app/model/severity-status';
import { IFilterUserSubmission } from 'src/app/model/base/filter-user-submission';
import { IFilterChip } from 'src/app/model/base/filter-chip';

@Component({
  selector: 'app-search-user',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'] 
})

export class SearchUserComponent implements OnInit {

    removable = true; 
    searchDialogData: ISearchDialogData;
    userFilterSettings: IUserFilterSettings;
    apiFilter: IApiFilter;
    private ngUnsubscribe: Subject<any> = new Subject();
    moment: any = moment;
    countries: ICountry[] = []; 
    statuses: IStatus[] = [];
    severityStatuses: ISeverityStatus[] = [];
    filterChips: IFilterChip[];

    constructor(public _dialog: MatDialog, private _userFilterStateService: UserFilterStateService, private _countryService: CountryService, public _helper: Helper, protected _statusService: StatusService, protected _severityStatusService: SeverityStatusService) { }

    ngOnInit() {  
       
        this.statuses = this._statusService.getStatuses();
        this.severityStatuses = this._severityStatusService.getSeverityStatuses();
 
        combineLatest([this._countryService.index(), this._userFilterStateService.currentFilterSettings])
            .pipe(takeUntil(this.ngUnsubscribe)) 
            .subscribe(async(items) => {
                this.countries = Object.assign(items[0].results as ICountry[], {}); 
                this.userFilterSettings = items[1] as IUserFilterSettings;
                this.renderChips();
 
                this.apiFilter = this.userFilterSettings.apiFilter;
            });
  
    }


    renderChips() { 
 
        this.filterChips = [];
        let filter: IFilterUserSubmission = this.userFilterSettings.filter;
 
        if(this._helper.isNullOrUndefined(filter)) 
            return null;

        if(filter.user_details__country__in) {
            this.countries.filter(country => {
                if(filter.user_details__country__in.indexOf(country.code) > -1) 
                    this.filterChips.push({ title: `Country: ${country.name}`, removeFunction: this.removeFilterArray, key: 'user_details__country__in', value: country.code });
            });          
        }

        if(filter.user_details__status_id__in) {
            this.statuses.filter(status => {
                if(filter.user_details__status_id__in.includes(status.id)) 
                    this.filterChips.push({ title: `Status: ${status.name}`, removeFunction: this.removeFilterArray, key: 'user_details__status_id__in', value: status.id });
            }); 
        }

        if(filter.user_details__severity_status__in) {
            this.severityStatuses.filter(severityStatus => {
                if(filter.user_details__severity_status__in.includes(severityStatus.id)) 
                    this.filterChips.push({ title: `Score: ${severityStatus.name}`, removeFunction: this.removeFilterArray, key: 'user_details__severity_status__in', value: severityStatus.id });                  
            });
        }

        if(filter.created_at__gte) {
            this.filterChips.push({ title: `Date from: ${moment(filter.created_at__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'created_at__gte', value: filter.created_at__gte });
        }

        if(filter.created_at__lt) {
            this.filterChips.push({ title: `Date to: ${moment(filter.created_at__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'created_at__lt', value: filter.created_at__lt });
        }

        if(filter.userorderssmilebox__created_at__gte) {
            this.filterChips.push({ title: `Smilebox ordered from: ${moment(filter.userorderssmilebox__created_at__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'userorderssmilebox__created_at__gte', value: filter.userorderssmilebox__created_at__gte });
        }

        if(filter.userorderssmilebox__created_at__lt) {
            this.filterChips.push({ title: `Smilebox ordered to: ${moment(filter.userorderssmilebox__created_at__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'userorderssmilebox__created_at__lt', value: filter.userorderssmilebox__created_at__lt });
        }

        if(filter.userorderssmilebox__smilebox_shipped_date__gte) {
            this.filterChips.push({ title: `Smilebox shipped from: ${moment(filter.userorderssmilebox__smilebox_shipped_date__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'userorderssmilebox__smilebox_shipped_date__gte', value: filter.userorderssmilebox__smilebox_shipped_date__gte });
        }

        if(filter.userorderssmilebox__smilebox_shipped_date__lt) {
            this.filterChips.push({ title: `Smilebox shipped to: ${moment(filter.userorderssmilebox__smilebox_shipped_date__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'userorderssmilebox__smilebox_shipped_date__lt', value: filter.userorderssmilebox__smilebox_shipped_date__lt });
        }

        if(filter.user_meta__smilebox_files_created_at__gte) {
            this.filterChips.push({ title: `Medical records uploaded from: ${moment(filter.user_meta__smilebox_files_created_at__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_meta__smilebox_files_created_at__gte', value: filter.user_meta__smilebox_files_created_at__gte });
        }

        if(filter.user_meta__smilebox_files_created_at__lt) {
            this.filterChips.push({ title: `Medical records uploaded to: ${moment(filter.user_meta__smilebox_files_created_at__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_meta__smilebox_files_created_at__lt', value: filter.user_meta__smilebox_files_created_at__lt });
        }

        if(filter.userorderssmilebox__impressions_received__gte) {
            this.filterChips.push({ title: `Impressions received from: ${moment(filter.userorderssmilebox__impressions_received__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'userorderssmilebox__impressions_received__gte', value: filter.userorderssmilebox__impressions_received__gte });
        }

        if(filter.userorderssmilebox__impressions_received__lt) {
            this.filterChips.push({ title: `Impressions received to: ${moment(filter.userorderssmilebox__impressions_received__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'userorderssmilebox__impressions_received__lt', value: filter.userorderssmilebox__impressions_received__lt });
        }

        if(filter.user_straigthbox__created_at__gte) {
            this.filterChips.push({ title: `Treatment plan sent from: ${moment(filter.user_straigthbox__created_at__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_straigthbox__created_at__gte', value: filter.user_straigthbox__created_at__gte });
        }

        if(filter.user_straigthbox__created_at__lt) {
            this.filterChips.push({ title: `Treatment plan sent to: ${moment(filter.user_straigthbox__created_at__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_straigthbox__created_at__lt', value: filter.user_straigthbox__created_at__lt });
        }

        if(filter.user_straigthbox__payed_date__gte) {
            this.filterChips.push({ title: `Straightbox ordered from: ${moment(filter.user_straigthbox__payed_date__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_straigthbox__payed_date__gte', value: filter.user_straigthbox__payed_date__gte });
        }

        if(filter.user_straigthbox__payed_date__lt) {
            this.filterChips.push({ title: `Straightbox ordered to: ${moment(filter.user_straigthbox__payed_date__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_straigthbox__payed_date__lt', value: filter.user_straigthbox__payed_date__lt });
        }

        if(filter.user_straigthbox__shipped_date__gte) {
            this.filterChips.push({ title: `Straightbox shipped from: ${moment(filter.user_straigthbox__shipped_date__gte).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_straigthbox__shipped_date__gte', value: filter.user_straigthbox__shipped_date__gte });
        }

        if(filter.user_straigthbox__shipped_date__lt) {
            this.filterChips.push({ title: `Straightbox shipped to: ${moment(filter.user_straigthbox__shipped_date__lt).format('DD.MM.Y')}`, removeFunction: this.removeFilter, key: 'user_straigthbox__shipped_date__lt', value: filter.user_straigthbox__shipped_date__lt });
        }

        if(filter.user_details__is_vip) {
            this.filterChips.push({ title: 'VIP', removeFunction: this.removeFilter, key: 'user_details__is_vip', value: filter.user_details__is_vip });
        }

        if(filter.user_details__policy_breach_status) { 
            this.filterChips.push({ title: 'Policy breach', removeFunction: this.removeFilter, key: 'user_details__policy_breach_status', value: filter.user_details__policy_breach_status });
        } 

        if(filter.user_details__is_sensitive_user) { 
            this.filterChips.push({ title: 'Sensitive user', removeFunction: this.removeFilter, key: 'user_details__is_sensitive_user', value: filter.user_details__is_sensitive_user });
        }

        if(filter.maintainer_plan__active) { 
            this.filterChips.push({ title: 'Maintainer plan', removeFunction: this.removeFilter, key: 'maintainer_plan__active', value: filter.maintainer_plan__active });
        }

        // COMMENT search of archived users is disabled
        // if(filter.search_not_active) { 
        //     this.filterChips.push({ title: 'Include archived users in search', removeFunction: this.removeFilter, key: 'search_not_active', value: filter.search_not_active });
        // }
 
    }
 
    // COMMENT: Method/Function called from object as removeFilter/removeFilterArray has to be anonymous because in that case this.userFilterSettings is valid.
    // If function would be named this.someObject would be null because context of this changes in named function.
    removeFilter = (chip: IFilterChip):void => {
        this.userFilterSettings.filter[chip.key] = null;
        this._userFilterStateService.saveFilterSettings(this.userFilterSettings);
    }
 
    removeFilterArray = (chip: IFilterChip): void => { 
        let values = this.userFilterSettings.filter[chip.key].filter(item => item !== chip.value);

        this.userFilterSettings.filter[chip.key] = values.length > 0 ? values : null;
        this._userFilterStateService.saveFilterSettings(this.userFilterSettings);
    }
 
    openDialog(filterCategoryId: number): void { 
 
        const dialogRef = this._dialog.open(UserDialogComponent, {
            width: '600px',
            height: '600px',  
            data: {  defaultFilter: this.userFilterSettings.filter, filterCategoryId: filterCategoryId }, 
            disableClose: true
        });
    
        dialogRef.afterClosed().subscribe(result => { 
            this.searchDialogData = result;

            this.userFilterSettings.filter = this.searchDialogData.defaultFilter;

            if(!this._helper.isEmptyOrNull(this.searchDialogData.ordering))
                this.userFilterSettings.ordering = this.searchDialogData.ordering;

            this._userFilterStateService.saveFilterSettings(this.userFilterSettings);
        });
    }
  
    ngOnDestroy() {   
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
    }

    
}
 