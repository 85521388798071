import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { Helper } from '../../util/helper.util';
import  *  as  menu  from  '../../settings/menu.json';
import { IMenu } from '../../model/menu';
import { INavigationSettings } from '../../model/settings';
import { IReportUserInfo } from 'src/app/model/api/report-user-info';
import { UserStatusHelper } from 'src/app/util/user-status-helper.util';

@Injectable({
  	providedIn: 'root'
})

export class MenuService { 
    private mainMenu: IMenu[];
    private _menu: BehaviorSubject<IMenu> = new BehaviorSubject<IMenu>(null);
    menu: Observable<IMenu> = this._menu.asObservable();
     
	constructor(private _helper: Helper, private userStatusHelper: UserStatusHelper) { }
    
    private setCurrentMenu(navigationSettings: INavigationSettings, reportUserInfo?: IReportUserInfo) { 
        
        if(!this.mainMenu)
            this.mainMenu = menu.mainMenu.filter(menu => menu.visible);
 
        let rootMenu: IMenu = this.getRootMenu(this.mainMenu, navigationSettings.pageId); 
 
        let rootMenuWithPermissions: IMenu = this.getMenuWithPermissions(rootMenu, navigationSettings, reportUserInfo);
 
        this._menu.next(rootMenuWithPermissions); 
    } 

    private getRootMenu(menus: IMenu[], menuId: number): IMenu {
        
        let foundMenu: IMenu = menus.find(menu => { 

            if(menu.id == menuId)
                return menu;
            
            if(!this._helper.isNullOrUndefined(menu.child) && menu.child.length > 0)
                return this.getRootMenu(menu.child, menuId);
        });
         
        return foundMenu;
    }

    private getMenuWithPermissions(menu: IMenu, navigationSettings: INavigationSettings, reportUserInfo?: IReportUserInfo): IMenu {

        if(this._helper.isNullOrUndefined(menu) || this._helper.isNullOrUndefined(menu.child) )
            return;

        let newMenu: IMenu = Object.assign({}, menu);
        newMenu.child = [];
        menu.child.forEach(submenu => {
                // COMMENT: Show Invitations tab 
                submenu = this.setMenuVisibility(submenu, reportUserInfo); 
                
                if(!submenu.visible)
                    return; 

                submenu = this.setMenuTitles(submenu, reportUserInfo); 

                if(submenu.showIfUserStatusIn.length == 0 || submenu.showIfUserStatusIn.includes(navigationSettings.statusId)) 
                    newMenu.child.push(submenu);

            } 
        );  
 
        return newMenu;
    }

    private setMenuVisibility(submenu: IMenu, reportUserInfo?: IReportUserInfo): IMenu {

        if(!reportUserInfo)
            return submenu;
            
        if(submenu.url === 'complaints') {
            submenu.visible = reportUserInfo.has_complaint ? true : false;
        }

        if(submenu.url === 'invitations') 
            submenu.visible = reportUserInfo.has_invitation_relation ? true : false;

        if(submenu.url === 'maintainer-plan') 
            submenu.visible = reportUserInfo.maintainer_plan__joined ? true : false;

        return submenu;
    }

    private setMenuTitles(submenu: IMenu, reportUserInfo?: IReportUserInfo): IMenu {

        if(!reportUserInfo)
            return submenu;

        if(submenu.url === "smilebox-order" && !reportUserInfo.userorderssmilebox__ordered_3d_scan) {
            submenu.name = !reportUserInfo.userorderssmilebox__smilebox_shipped ? 'SmileBox Order' : 'SmileBox Shipped';
        }

        if(submenu.url === "smilebox-order" && reportUserInfo.userorderssmilebox__ordered_3d_scan) {
            submenu.name = !reportUserInfo.userorderssmilebox__smilebox_shipped ? '3D Scan Order' : '3D Scan Referred';
        }
        
        if(submenu.url === "treatment-plan-processing") {
            submenu.name = this.userStatusHelper.isTreatmentPlanSent(reportUserInfo.status) ? 'Treatment Plan Sent' : 'Treatment Plan Processing';  
        }
        
        if(submenu.url === 'straightbox-order') {
            submenu.name = !reportUserInfo.user_straigthbox__shipped ? 'StraightBox Order' : 'StraightBox Shipped';
        }
 
        return submenu;
    }

    getNavigationByMenuId(menuId: number, userId?: number, reportUserInfo?: IReportUserInfo): INavigationSettings {
 
        let navigationSettings: INavigationSettings = this.setNavigationMenu(menu.mainMenu, menuId, userId, 0);
        navigationSettings.statusId = reportUserInfo != undefined && reportUserInfo != null && reportUserInfo.status > 0 ? reportUserInfo.status : null;
        this.setCurrentMenu(navigationSettings, reportUserInfo); 

        return navigationSettings;
    }

    private setNavigationMenu(menu: IMenu[], menuId: number, userId: number, menuLevel: number = 0): INavigationSettings {
        
        let navSettings: INavigationSettings;
        menuLevel +=1;
        

        let foundMenu: IMenu = menu.find(menu => { 
            
            if(menu.id == menuId ) 
                return menu; 
          
            if(menu.id != menuId && menu.child != null) { 
                navSettings = this.setNavigationMenu( menu.child, menuId, userId, menuLevel);
                return navSettings;
            }
        });

        if(this._helper.isNullOrUndefined(foundMenu))
            return;  

        if(this._helper.isNullOrUndefined(navSettings)) {      
            let navSettings: INavigationSettings = { pageId: foundMenu.id, pageTitle: foundMenu.name, pageUrl: foundMenu.url, url: foundMenu.url, parentUrl: '', userId: userId, parentId: foundMenu.parentId};
            return navSettings;
        }        
        let adminMenu = (menuLevel == 1) ? '/admin/' : '';
        let rootMenu: IMenu = this.getRootMenu(this.mainMenu, foundMenu.id);
        if(rootMenu.id == 1 && menuLevel == 1) {
            navSettings.url = `${adminMenu}${foundMenu.url}/${userId}/${navSettings.url}`;
            navSettings.parentUrl = `${adminMenu}${foundMenu.url}/${userId}/${navSettings.parentUrl}`;
            return navSettings;
        }

        navSettings.url = `${adminMenu}${foundMenu.url}/${navSettings.url}`;
        navSettings.parentUrl = this._helper.isNullOrUndefined(navSettings.parentUrl) ? `${adminMenu}${foundMenu.url}` :  `${adminMenu}${foundMenu.url}/${navSettings.parentUrl}`;
        return navSettings;
    }
 

}
