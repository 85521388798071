import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserQuestion } from '../../model/api/user-question'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { BaseService } from '../base/base.service';

@Injectable({
	providedIn: 'root'
})

export class UserQuestionService extends BaseService<IUserQuestion> {
    protected urlV2 = "/admin-v02/user-question";

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

}
