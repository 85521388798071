import { Component, OnInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as moment from 'moment';  
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service'; 
import { UserDetailService } from '../../../service/api/user-detail.service';
import { SpinnerService } from '../../../service/spinner.service';
import { StraightboxService } from '../../../service/api/straightbox.service';
import { IStraightbox } from '../../../model/api/straightbox';
import { DoctorPatientService } from '../../../service/api/doctor-patient.service';
import { UserService } from '../../../service/api/user.service';
import { IUser } from '../../../model/api/user';
import { ReminderService } from '../../../service/api/reminder.service';
import { IReminder } from '../../../model/api/reminder';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { UserStatusHelper } from '../../../util/user-status-helper.util';
import { MatDialog } from '@angular/material/dialog';
import { DialogMaintainerPlanComponent } from '../../shared/dialog-maintainer-plan/dialog-maintainer-plan.component';
import { takeUntil } from 'rxjs/operators';
import { IMaintainerPlan } from '../../../model/api/maintainer-plan';
import { ConversationService } from '../../../service/api/conversation.service';
import { UserStateService } from '../../../service/state/user-state.service';

@Component({
	selector: 'app-straightbox-order',
	templateUrl: './straightbox-order.component.html',
	styleUrls: ['./straightbox-order.component.scss']
})
export class StraightboxOrderComponent extends DetailUserBaseComponent implements OnInit {

    menuId: number = 109; 
    moment: any = moment;
    straightbox: IStraightbox;
    user: IUser;
    dentistFirstAlignersReminder: IReminder;
    dentistFirstAlignersReminderIsSendingSoon: boolean = false;  
    maintainerPlan: IMaintainerPlan;
    
    constructor(private _settingsService: SettingsService, private _userDetailService: UserDetailService, private _userStateService: UserStateService, private _menuService: MenuService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _straightboxService: StraightboxService, private _userService: UserService, private _reminderService: ReminderService, private _doctorPatientService: DoctorPatientService, private _route: ActivatedRoute, public _helper: Helper, private _cd: ChangeDetectorRef, private _spinnerService: SpinnerService, private _userStatusHelper: UserStatusHelper, public _dialog: MatDialog, protected element: ElementRef, private _conversationService: ConversationService) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
        //console.log('StraightboxOrderComponent ngOnInit()');
        super.ngOnInit();         
        //this.getUser();
        this.getReminders();        
        
        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => {
                this.reportUserInfo = reportUserInfo;
                this.getStraightbox(); 
                //this.getMaintainerPlan();
            });
		        
    }

	getStraightbox(): void { 
        if(!this.reportUserInfo || !this._userStatusHelper.isAlignersStarted(this.reportUserInfo.status))
            return; 
        
        this._spinnerService.startSpinner(); 
        this._straightboxService.detail(this.userId.toString()) 
			.subscribe(straightbox => {                

                if(straightbox) {
                    this.straightbox = straightbox;
                    this.straightbox.created_at = !this._helper.isNullOrUndefined(this.straightbox.created_at) ? moment.utc(this.straightbox.created_at).toDate() : null;
                }
				this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getReminders(): void {  
        this._spinnerService.startSpinner(); 
        this._reminderService.index({ user: this.userId, reminder_type: 'dentist_first_aligners', page: 1, page_size: 50 }) 
			.subscribe(reminders => { 
                if(this._helper.isNullOrUndefined(reminders) || reminders.count == 0 ) {
                    this._spinnerService.stopSpinner(); 
                    return;
                }

                this.dentistFirstAlignersReminder = reminders.results[reminders.results.length -1]; 
                this.dentistFirstAlignersReminder.notified_user_at = !this._helper.isNullOrUndefined(this.dentistFirstAlignersReminder.notified_user_at) ? moment.utc(this.dentistFirstAlignersReminder.notified_user_at).toDate() : null;
                this.dentistFirstAlignersReminderIsSendingSoon = moment(this.dentistFirstAlignersReminder.created_at).isAfter(this.dentistFirstAlignersReminder.remind_at);
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
	}

    /* getUser(): void { 
        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
			});
	} */

    shipStraightBox(isShippedWithCts: boolean): void {
        this._spinnerService.startSpinner();

        this._straightboxService.shipStraightbox(this.userId, { shipped: true, tracking_number: this.straightbox.tracking_number, straightbox_shipped_with_cts : isShippedWithCts }) 
            .subscribe(straightboxResponse => { 

                if(straightboxResponse && straightboxResponse.success) {
                    this._conversationService.clearCache();
                    this.ngOnInit(); 
                } 
                    
                this._spinnerService.stopSpinner();
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
    }

    setDentistFirstAlignersReminderAsSoonAsPossible(): void {
        
        if(!this.dentistFirstAlignersReminder)
            return;

        this._spinnerService.startSpinner();

        var remind_at = new Date(this.dentistFirstAlignersReminder.remind_at);
        remind_at.setDate(remind_at.getDate()-1);
        this.dentistFirstAlignersReminder.remind_at = remind_at;
        /* var remindAt = moment(this.dentistFirstAlignersReminder.remind_at);
        remindAt = remindAt.subtract(1, "days");
        this.dentistFirstAlignersReminder.remind_at = moment(remindAt).toDate();  */
        this.dentistFirstAlignersReminderIsSendingSoon = moment(this.dentistFirstAlignersReminder.created_at).isAfter(this.dentistFirstAlignersReminder.remind_at);
 

        this._reminderService.saveReminder(this.dentistFirstAlignersReminder) 
            .subscribe(reminder => {                
                this.ngOnInit(); 
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
    }
 
    openMaintainerPlanDialog(): void { 
        
        this.maintainerPlan = { id: null, active: null, created_at: null, joined_at: null, updated_at: null, user: this.userId };
        const dialogRef = this._dialog.open(DialogMaintainerPlanComponent, {
            width: '600px',
            height: '600px', 
            data: { status: this.reportUserInfo.status, user: this.user.id, maintainerPlan: this.maintainerPlan, closeButton: true, saveButton: true },
            disableClose: true
        });
    
        dialogRef.afterClosed()
            .subscribe(result => { 
                this._reportUserInfoService.clearCache();
                this.ngOnInit();
            });
    }
 

}
