import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';
import { Helper } from '../../util/helper.util';
import { IReportUserSubmission, IUserSubmissions, IUserSubmissionsResponse  } from '../../model/api/user'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { BaseService } from '../base/base.service';

@Injectable({
	providedIn: 'root'
})

export class ReportUserSubmissionService extends BaseService<IReportUserSubmission>  {
    protected urlV2 = "/admin-v02/report-user-submission"; 
    protected _cacheValidForSeconds = 0; 
    //exportToCsv = this._config.api_url + "/admin/export-to-csv-v2/";  
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
  
   /*  usersToCsvExport<T>(): Observable<any> {
        //let urlFinal = `${this._config.api_url}${this.exportToCsv}/`; 
   
        return this._http.get(this.exportToCsv, { responseType: 'arraybuffer', observe: 'response' });
    }  
  */
}

 