import { Injectable}  from '@angular/core';
import { IApiFilter, IMaintenanceAnnouncementFilterSettings } from '../../model/base/filter-settings';
import { Helper } from '../../util/helper.util';
import { FilterStateService } from '../base/filter-state.service';


@Injectable({
  providedIn: 'root'
})

export class MaintenanceAnnouncementFilterStateService  extends FilterStateService<IMaintenanceAnnouncementFilterSettings>  {

    protected filterSettingsName = 'notificationCenterFilterSettings';

    constructor(private __helper: Helper) {
        super(__helper); 
        this._currentFilterSettings.next(this.getFilterSettings()); 
    }
 
    setCurrentFilterSettings(filterSettings: IMaintenanceAnnouncementFilterSettings): void { 
        filterSettings.apiFilter = this.setApiFilter(filterSettings);
        this._currentFilterSettings.next(filterSettings); 
	}
 
    protected getFilterSettings(): IMaintenanceAnnouncementFilterSettings   {
        let filterSettings: IMaintenanceAnnouncementFilterSettings = this._currentFilterSettings.getValue();
        if(!this.__helper.isNullOrUndefined(filterSettings))
            return filterSettings;

        this.defaultFilterSettings.displayedColumns = this.defaultFilterSettings.defaultReportColumns[0].columns; 
        this.defaultFilterSettings.ordering = '-id';
        this.defaultFilterSettings.apiFilter = this.setApiFilter(this.defaultFilterSettings);
        
        return this.defaultFilterSettings;
    }

    setApiFilter(appBannerNotificationFilterSettings: IMaintenanceAnnouncementFilterSettings): IApiFilter {	

        let apiFilter: IApiFilter = []; 

        return super.setApiFilter(appBannerNotificationFilterSettings, apiFilter);
    }

    private defaultFilterSettings: IMaintenanceAnnouncementFilterSettings = { 
        id: null, 
        selectedColumns: [ 
            { name: "id", prettyName: "Id"},
            { name: "text", prettyName: "Title"},
            { name: "visible_from", prettyName: "Visible from"},
            { name: "visible_to", prettyName: "Visible to"},
            { name: "active", prettyName: "Active"}
        ],
        defaultReportColumns: [ 
            { key: "UserExport", columns: [ 'id', 'text', 'visible_from', 'visible_to', 'active'] } 
        ],
        displayedColumns: [],
        apiFilter: [],  
        paging: { pageIndex: 1, pageSize: 10, length: null, pagingStart: null, pagingEnd: null },
        ordering: '-id'
    }    
}
 