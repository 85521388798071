import { Component, OnInit } from '@angular/core';
import { IReportCurrentUser } from 'src/app/model/api/report-current-user';
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';

@Component({
	selector: 'app-current-user',
	templateUrl: './current-user.component.html',
	styleUrls: ['./current-user.component.scss']
})
 
export class CurrentUserComponent implements OnInit {
    
    reportCurrentUser: IReportCurrentUser;
    
	constructor(private reportCurrentUserService: ReportCurrentUserService) { }

	ngOnInit(): void {
        this.getCurentUser();
	}

    private getCurentUser(): void { 
        this.reportCurrentUserService.detail()
            .subscribe(reportCurrentUser => {  
                this.reportCurrentUser = reportCurrentUser;
            });
    }

}
