import { Injectable}  from '@angular/core';
import { IApiFilter, IMesageBroadcastFilterSettings } from '../../model/base/filter-settings';
import { Helper } from '../../util/helper.util';
import { FilterStateService } from '../base/filter-state.service';


@Injectable({
  providedIn: 'root'
})

export class MessageBroadcatFilterStateService extends FilterStateService<IMesageBroadcastFilterSettings> {

    protected filterSettingsName = 'messabeBroadcastFilterSettings';

    constructor(private __helper: Helper) {
        super(__helper); 
        this._currentFilterSettings.next(this.getFilterSettings()); 
    }
 
    setCurrentFilterSettings(filterSettings: IMesageBroadcastFilterSettings): void { 
        filterSettings.apiFilter = this.setApiFilter(filterSettings);
        this._currentFilterSettings.next(filterSettings); 
	}
 
    protected getFilterSettings(): IMesageBroadcastFilterSettings   {
        let filterSettings: IMesageBroadcastFilterSettings = this._currentFilterSettings.getValue();
        if(!this.__helper.isNullOrUndefined(filterSettings))
            return filterSettings;
 
        this.defaultFilterSettings.displayedColumns = this.defaultFilterSettings.defaultReportColumns[0].columns; 
        this.defaultFilterSettings.ordering = '-id';
        this.defaultFilterSettings.apiFilter = this.setApiFilter(this.defaultFilterSettings);

        return this.defaultFilterSettings;
    }

    setApiFilter(appBannerNotificationFilterSettings: IMesageBroadcastFilterSettings): IApiFilter {	

        let apiFilter: IApiFilter = []; 

        return super.setApiFilter(appBannerNotificationFilterSettings, apiFilter);
    }

    private defaultFilterSettings: IMesageBroadcastFilterSettings  = { 
        id: null, 
        selectedColumns: [ 
            { name: "id", prettyName: "Id"},
            { name: "created_at", prettyName: "Created at"},
            { name: "is_processing", prettyName: "Is processing"},
            { name: "is_done", prettyName: "Is done"},
            { name: "number_of_processed_messages", prettyName: "Processed messages"},
            { name: "conversation_template__subject", prettyName: "Template name"}
        ],
        defaultReportColumns: [ 
            { key: "UserExport", columns: [ 'id', 'created_at', 'is_processing', 'is_done', 'number_of_processed_messages', 'conversation_template__subject'] } 
        ],
        displayedColumns: [],
        apiFilter: [],  
        paging: { pageIndex: 1, pageSize: 10, length: null, pagingStart: null, pagingEnd: null },
        ordering: '-id'
    }    
}
 