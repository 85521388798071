import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../app.config/app.config';
import { BaseService } from '../base/base.service';
import { IReportStaf } from '../../model/api/report-staff'

@Injectable({
	providedIn: 'root'
})

export class ReportStaffService  extends BaseService<IReportStaf> {
 
    protected urlV2 = "/admin-v02/report-staff"; 
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
 
}

