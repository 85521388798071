import { Injectable}  from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { SecurityService } from "./security.service";  

@Injectable()
export class SecurityGuard implements CanActivate {

    constructor(private securityService: SecurityService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
 
        if(this.securityService.isLoggedIn())
            return true;
 
        this.router.navigate(['/login']);
            return false;

    }
 
}
