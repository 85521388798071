import { Component, OnInit } from '@angular/core';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserProfile } from '../../../model/api/user-profile';
import { IUserPicture } from '../../../model/api/user-picture';
import { INavigationSettings } from '../../../model/settings';
import { MenuService } from '../../../service/state/menu.service';
import { SettingsService } from '../../../service/state/settings.service'; 
import { Helper } from '../../../util/helper.util';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ColunmNumber } from '../../../settings/column-number';
import { IConversationFile } from '../../../model/api/conversation-file'; 
import { AppConstants } from '../../../settings/app-constants';
import { IMessageResponse, IRotateImageResponse } from '../../../model/api/response';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../../../service/spinner.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { UserFileService } from '../../../service/api/user-file.service';
import { UserPictureService } from '../../../service/api/user_picture.service';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StraightboxService } from '../../../service/api/straightbox.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { ReportUserFileService } from '../../../service/api/report-user-file.service';
import { UserStatusHelper } from '../../../util/user-status-helper.util';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { CustomLightboxService } from '../../../service/custom-lightbox.service';
import { UserService } from '../../../service/api/user.service';
import { UserStateService } from '../../../service/state/user-state.service';
 

@Component({
  	selector: 'app-user-upload',
	templateUrl: './user-upload.component.html',
	styleUrls: ['./user-upload.component.scss']
})
export class UserUploadComponent extends DetailUserBaseComponent implements OnInit {

    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 105; 
    userProfile: IUserProfile;
    conversationFiles: IConversationFile[];
	conversationFilesByAdmin: IConversationFile[];
	userPictures: IUserPicture[]; 
	imageBreakpoint: number;
    moment: any = moment;  
    readonly ROTATE_IMAGE_LEFT:string = AppConstants.ROTATE_IMAGE_LEFT;
    readonly ROTATE_IMAGE_RIGHT:string = AppConstants.ROTATE_IMAGE_RIGHT;
    readonly USER_PICTURES_ROTATION_MODEL:string = AppConstants.USER_PICTURES_ROTATION_MODEL;
    readonly CONVERSATION_FILES_ROTATION_MODEL:string = AppConstants.CONVERSATION_FILES_ROTATION_MODEL;
    readonly USER_PICTURES_MODEL:string = AppConstants.USER_PICTURES_MODEL;
    readonly CONVERSATION_FILES_MODEL:string = AppConstants.CONVERSATION_FILES_MODEL;
    readonly ADD_BEFORE_FILE_TYPE:string = AppConstants.ADD_BEFORE_FILE_TYPE;
    readonly ADD_AFTER_FILE_TYPE:string = AppConstants.ADD_AFTER_FILE_TYPE;
    readonly IMAGE_UPLOAD_LINK:string = AppConstants.IMAGE_UPLOAD_LINK;
    

	constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _helper: Helper, private _userFileService: UserFileService, private _reportuserFileService: ReportUserFileService,  private _breakpointObserver: BreakpointObserver, public _dialog: MatDialog, private _spinnerService: SpinnerService, private _snackBar: MatSnackBar, private _straightboxService: StraightboxService, public userStatusHelper: UserStatusHelper, private _http: HttpClient, private _userPictureService: UserPictureService,  private _customLightboxService: CustomLightboxService) {
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
     }

	ngOnInit(): void { 
		super.ngOnInit();
		this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe( (state: BreakpointState) => {
			this.imageBreakpoint = state.breakpoints[Breakpoints.XSmall] ? ColunmNumber.One : state.breakpoints[Breakpoints.Small] ? ColunmNumber.Three : ColunmNumber.Five; 
		 });

        this.getUserFiles(); 
 
	}

    getUserFiles(): void { 
        this._spinnerService.startSpinner();
        this.userPictures = [];
        this._reportuserFileService.index({user_id: this.userId.toString()})
			.subscribe(response => { 
                this.userPictures = response.results[1].user_pictures;
                this.conversationFiles = response.results[2].conversation_files;  

                this.userPictures.forEach(userPicture => {

                    if(!userPicture.image)
                        return;
 
                    userPicture.prettyName = this._helper.getPrettyName(userPicture.image);
                    userPicture.extension = this._helper.getExtension(userPicture.image);
                    userPicture.isImage = this._helper.isImage(userPicture.extension);
                    userPicture.isVideo = this._helper.isVideo(userPicture.extension);
                    userPicture.isAudio = this._helper.isAudio(userPicture.extension);
                    userPicture.isPDF = userPicture.extension === 'pdf';
                });

                this.conversationFiles.forEach(conversationFile => {

                    if(!conversationFile.file)
                        return;
  
                    conversationFile.prettyName = this._helper.getPrettyName(conversationFile.file);
                    conversationFile.extension = this._helper.getExtension(conversationFile.file);
                    conversationFile.isImage = this._helper.isImage(conversationFile.extension);
                    conversationFile.isVideo = this._helper.isVideo(conversationFile.extension);
                    conversationFile.isAudio = this._helper.isAudio(conversationFile.extension);
                    conversationFile.isPDF = conversationFile.extension === 'pdf';
                });
                
                this._spinnerService.stopSpinner();              
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}
 
    addBeforeAfterImage(selectedFile:any, model: string, type: string): void {
 
        if(this._helper.isNullOrUndefined(selectedFile))
            return;

        this._spinnerService.startSpinner();

        this._userFileService.addBeforeAfterFile<IMessageResponse>(this.userId, { image_id: selectedFile.id.toString(), review_image_type: type, model: model }).subscribe(
            response => { 
                 if(!response.success) 
                    return;

                    this._straightboxService.clearCache();

                this._snackBar.open( type + ' image is added.', 'Close', this.matSnackBarConfig);
                this._spinnerService.stopSpinner();
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
        

    }

    rotateImage(selectedFile:any, model: string, angle: string): void {
 
        if(this._helper.isNullOrUndefined(selectedFile))
            return;

        this._spinnerService.startSpinner();
  
        this._userFileService.rotateImage<IRotateImageResponse>(selectedFile.id.toString(), model, angle).subscribe(response => { 
 
                if(!response.detail || !selectedFile.isImage) {
                    this._spinnerService.stopSpinner();
                    return;  
                }

                if(model == this.USER_PICTURES_ROTATION_MODEL) 
                    selectedFile.image = response.image_url;
                
                if(model == this.CONVERSATION_FILES_ROTATION_MODEL) 
                    selectedFile.file = response.image_url;
                
                
                this._straightboxService.clearCache();
                this._userPictureService.clearCache();
                
                // let filePath = model == this.USER_PICTURES_ROTATION_MODEL ? selectedFile.image : selectedFile.file;

                // this._userFileService.getRotatedImage(filePath).subscribe(response => {
                //     filePath =  filePath.indexOf('#') > 0 ? filePath.substring(0, filePath.indexOf('#')) : filePath;

                //     if(model == this.USER_PICTURES_ROTATION_MODEL) 
                //         selectedFile.image = `${filePath}#${moment.now()}`; 
                    
                //     if(model == this.CONVERSATION_FILES_ROTATION_MODEL) 
                //         selectedFile.file = `${filePath}#${moment.now()}`; 
                        
                //     this._spinnerService.stopSpinner();
                // });

                /* this._http.get(filePath, {headers: {'Cache-Control': 'no-cache'}}).subscribe(response => { 
                    filePath =  filePath.indexOf('#') > 0 ? filePath.substring(0, filePath.indexOf('#')) : filePath;

                    if(model == this.USER_PICTURES_ROTATION_MODEL) 
                        selectedFile.image = `${filePath}#${moment.now()}`; 
                    
                    if(model == this.CONVERSATION_FILES_ROTATION_MODEL) 
                        selectedFile.file = `${filePath}#${moment.now()}`; 
                        
                    this._spinnerService.stopSpinner();
                }); */

                this._spinnerService.stopSpinner();
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            });         

    }

    downloadAllUploadsAndZip() {
        this._spinnerService.startSpinner();
        this._userFileService.downloadAllUserUploadsFiles<boolean>(this.userId).subscribe(res => {            
            let filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, ''); 
            let contentType = res.headers.get('content-type');
            this.zipAndDownload(res.body, filename, contentType );
            this._spinnerService.stopSpinner();  
        },
        error =>{  
            this._snackBar.open('Error downloading file.', 'Close', this.matSnackBarConfig); 
            this._spinnerService.stopSpinner();  
        });
	}


    zipAndDownload(fileContent: any, fileName: string, contentType: string): void {
       
        var linkElement = document.createElement('a');
            try {
                var blob = new Blob([fileContent], { type: contentType });
                var url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute("download", fileName);
                linkElement.click();
                window.URL.revokeObjectURL(url);
                linkElement.remove(); 
            } catch (ex) {
                this._snackBar.open('Error downloading file.', 'Close', this.matSnackBarConfig);
            }  
    }
    
    openLightbox(images: any, item: number) {
        this._customLightboxService.showLightbox(images, item);
    }
}
