import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config/app.config';
import { ITreatmentPercentage } from 'src/app/model/api/treatment-percentage'; 
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})
export class TreatmentPercentageService extends BaseService<ITreatmentPercentage> {

    protected urlV2 = "/admin-v02/treatment-percentage";    

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
}
