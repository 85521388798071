import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { SecurityService } from "../service/security/security.service";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from  'rxjs/operators';
import { throwError } from 'rxjs';
import { SnackBarService } from '../service/snackbar.service'; 
import { Helper } from '../util/helper.util';


export interface IErrorData {
    status: number;
    message: string;
}

export interface IErrorDataCache {
    datetime: Date;
    response$: IErrorData;
}


@Injectable()
export class ErrorInterceptor implements HttpInterceptor { 
    public _errorCache = new Map<string, IErrorDataCache>();
	protected _cacheValidForSeconds = 3;

    constructor(private _securityService: SecurityService, private _snackBarService: SnackBarService, private router: Router, private helper: Helper) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
        return next.handle(request).pipe(
          
            catchError(error => {
 
                this.cacheErrorData({ status: error.status, message: error && error.message ? error.message: '' });                

                return throwError(error);
            }),  
        );
        
    }


    private cacheErrorData(errorData: IErrorData): void {
        // COMMENT: I implemnted error cacheing to prevent mulitple calls.
        // Multiple requests appear because in base clas in pipe retry(3), is included. 
         
        if(this.helper.isNullOrUndefined(errorData))
            return; 

        if((errorData.status < 500 || errorData.status > 599) && errorData.status !== 404 && errorData.status !== 405 && errorData.status !== 0)
            return; 

        
        /* if(errorData.status >= 500 && errorData.status <= 599 || errorData.status == 0)
            errorData.message = 'Cannot access server. Check your internet connection.'; */

        /* let errorFromCache = this._errorCache.get(errorData.message);
        const cacheDateTimeCheck = new Date(Date.now() - (this._cacheValidForSeconds * 1000));

        if (errorFromCache && errorFromCache.datetime > cacheDateTimeCheck) 
            return;

        if(errorFromCache) 
            this._errorCache.delete(errorData.message);  */           
 
        this._snackBarService.add(errorData.message);

        /* let cachedError: IErrorDataCache = { datetime: new Date(), response$: errorData }
		this._errorCache.set(errorData.message, cachedError); */
    }

    
}