import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IApiResponseCache } from '../../model/base/response-cache';
import { IApiResponse } from '../../model/api/response'; 
import { IFileResponse } from '../../model/api/file'; 
import { IUserFileRequest } from '../../model/api/user-file';
import { AppConstants } from '../../settings/app-constants';
import { BaseService } from '../base/base.service';
 

@Injectable({
  providedIn: 'root'
})
export class ReportUserFileService extends BaseService<IFileResponse> {
	
    protected urlV2 = "/admin-v02/report-user-file"; 
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    
 
}
