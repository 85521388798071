import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { DetailBaseComponent } from '../inc/base/detail-base-componenty'; 
import { IReportConversation } from '../../model/api/report-conversation';
import { IConversationMessageFile } from '../../model/api/conversation-message-file'; 
import { ConversationMessageFileService } from '../../service/api/conversation-message-file.service'; 
import { ConversationService } from '../../service/api/conversation.service';
import { SpinnerService } from '../../service/spinner.service';
import { Helper } from '../../util/helper.util';
import { MenuService } from '../../service/state/menu.service';
import { SettingsService } from "../../service/state/settings.service";
import { UserType } from '../../settings/user-type'; 
import { ReportConversationService } from '../../service/api/report-conversation.service';
import { ConversationMessageService } from '../../service/api/conversation-message.service';
import { IConversationMessage } from '../../model/api/conversation-message';
import { ConversationTemplateService } from '../../service/api/conversation-template.service';
import { IConversationTemplate } from '../../model/api/conversation-template';
import { AppConstants } from '../../settings/app-constants';
import { UserSeverityStatus } from '../../settings/user-severity-status'; 
import { ReportCurrentUserService } from '../../service/api/report-current-user.service';
import { ISearchMessages } from '../../model/base/search-messages';




@Component({
	selector: 'app-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss']
})
export class MessageComponent extends DetailBaseComponent implements OnInit {
     
    //menuId: number = 11;

    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportCurrentUserService: ReportCurrentUserService, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();
 
    }

}
