<div class="content-wrap"> 
    <div class="content">
         <app-header></app-header>
         <mat-card> 
            <mat-card-content>
                <div class="filter-wrap">
                    <div class="search-filter">
                        <app-search-user></app-search-user>
                    </div>
                    <div class="table-column-filter" *ngIf="!_helper.isNullOrUndefined(userFilterSettings)">
                        <div fxLayout="column" fxLayout.xs="row" fxFlexFill fxLayoutGap="0px" fxLayoutAlign="end end">
                            <div fxFlex="100" fxFlex.xs="50" fxLayoutGap="0px" class="button-box space-left reset-box" [class.reset-box] = "(!_helper.isNullOrUndefined(userFilterSettings.filter) || userFilterSettings.filterCategoryId != null) || (userFilterSettings.displayedColumns[0] != 'full_name' || userFilterSettings.displayedColumns.length != userFilterSettings.defaultReportColumns[0].columns.length)"> 
                                <!-- isFilterSet || userFilterSettings.filterCategoryId != null -->
                                <button *ngIf="isFilterSet" mat-raised-button mat-button color="primary" (click)="resetFilter()">Reset Filter</button>
                                <button *ngIf="userFilterSettings.displayedColumns[0] != 'full_name' || userFilterSettings.displayedColumns.length != userFilterSettings.defaultReportColumns[0].columns.length" mat-raised-button mat-button color="primary" (click)="resetColumnsView()">Reset View</button>
                            </div>
                            <div fxFlex="100" fxFlex.xs="50" fxLayoutGap="0px" class="selected-column-box"> 
                                <!--  [class.fixed---drop-down] = "((!_helper.isNullOrUndefined(userFilterSettings.filter) || userFilterSettings.filterCategoryId != null) || (userFilterSettings.displayedColumns[0] != 'full_name' || userFilterSettings.displayedColumns.length != userFilterSettings.defaultReportColumns[0].columns.length)) && userFilterSettings.filter.length > 2" -->
                                <mat-form-field> 
                                    <mat-select [(ngModel)]="userFilterSettings.displayedColumns" multiple>
                                        <mat-option *ngFor="let option of userFilterSettings.selectedColumns" [value]="option.name" (click)="showHideDisplayedColumns()" class="my-alternate-theme">{{option.prettyName}}</mat-option>
                                    </mat-select>
                                </mat-form-field> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
             </mat-card-content>
        </mat-card> 
 
        <div *ngIf="userFilterSettings && matTableSort && matTableSort.sortActive"> 
            <div class="mat-table-holder"> 
                <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" class="mat-elevation-z8" (matSortChange)="sortData($event)" [matSortActive]="matTableSort.sortActive" [matSortDirection]="matTableSort.sortDirection" >
                    <ng-container matColumnDef="full_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.full_name }}<span *ngIf="element.user_details__is_vip" class="shorten warn-color">VIP</span> <span *ngIf="element.maintainer_plan__active" class="shorten maintainer-plan">MP</span>
                           <a fxFlexFill [routerLink]="'/admin/user/' + element.id + '/details'" class="mat-row-link"> </a> 
                        </td>
                    </ng-container>
 
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
                        <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_details__status_id">
                        <th mat-header-cell *matHeaderCellDef> Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span [ngStyle]="{'color': element.userStatusColor }">{{ userStatusName['status-' + element.user_details__status_id] }}</span> 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="user_details__country__name">
                        <th mat-header-cell *matHeaderCellDef> Country </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_details__country__name }} </td>
                    </ng-container>

                    <ng-container matColumnDef="doctor_processing_patient__user__full_name">
                        <th mat-header-cell *matHeaderCellDef> Dentist </th>
                        <td mat-cell *matCellDef="let element"> {{ element.doctor_processing_patient__user__full_name != null ? element.doctor_processing_patient__user__full_name : "Not asigned" }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_meta__last_treatment_progress_percentage">
                        <th mat-header-cell *matHeaderCellDef> Tr. Pr </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_meta__last_treatment_progress_percentage }} </td>
                    </ng-container>

                    <ng-container matColumnDef="al_status">
                        <th mat-header-cell *matHeaderCellDef> Al. Status </th>
                        <td mat-cell *matCellDef="let element"> T:{{ element.user_details__aligner_progress_bottom ? element.user_details__aligner_progress_bottom : 'X' }}, B:{{ element.user_details__aligner_progress_top ? element.user_details__aligner_progress_top : 'X' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_meta__last_interaction_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last login </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_meta__last_interaction_date != null ? moment(element.user_meta__last_interaction_date).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User register </th>
                        <td mat-cell *matCellDef="let element"> {{ element.created_at != null ? moment(element.created_at).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="userorderssmilebox__created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Smilebox ordered </th>
                        <td mat-cell *matCellDef="let element"> {{ element.userorderssmilebox__created_at != null ? moment(element.userorderssmilebox__created_at).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="userorderssmilebox__smilebox_shipped_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Smilebox shipped </th>
                        <td mat-cell *matCellDef="let element"> {{ element.userorderssmilebox__smilebox_shipped_date != null ? moment(element.userorderssmilebox__smilebox_shipped_date).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_meta__smilebox_files_created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Medical records uploaded </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_meta__smilebox_files_created_at != null ? moment(element.user_meta__smilebox_files_created_at).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="userorderssmilebox__impressions_received">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Impressions received </th>
                        <td mat-cell *matCellDef="let element"> {{ element.userorderssmilebox__impressions_received != null ? moment(element.userorderssmilebox__impressions_received).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_straigthbox__created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Treatment plan sent </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_straigthbox__created_at != null ? moment(element.user_straigthbox__created_at).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_straigthbox__payed_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Straightbox ordered </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_straigthbox__payed_date != null ? moment(element.user_straigthbox__payed_date).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_straigthbox__shipped_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Straightbox shipped </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_straigthbox__shipped_date != null ? moment(element.user_straigthbox__shipped_date).format('DD.MM.Y') : '' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_meta__count_conversation_message_sent_to">
                        <th mat-header-cell *matHeaderCellDef> Msg. rec </th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_meta__count_conversation_message_sent_to }} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_meta__count_conversation_message_sent_by">
                        <th mat-header-cell *matHeaderCellDef> Msg. sent</th>
                        <td mat-cell *matCellDef="let element"> {{ element.user_meta__count_conversation_message_sent_by }} </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="userFilterSettings.displayedColumns"></tr>
                    <!-- TODO: Allow opening link in new window. Add link on every td element -->
                    <!-- <tr mat-row *matRowDef="let row; columns: userFilterSettings.displayedColumns"  (click)="onClick('/admin/user', row)"></tr> -->
                    <!-- <tr mat-row *matRowDef="let row; columns: userFilterSettings.displayedColumns" [routerLink]="'/admin/user/' + row.id + '/details'"></tr> -->
                    <tr mat-row *matRowDef="let row; columns: userFilterSettings.displayedColumns"></tr>
                </table> 
                <mat-paginator showFirstLastButtons [pageIndex]='userFilterSettings.paging.pageIndex-1' [length]='userFilterSettings.paging.length' [pageSize]='userFilterSettings.paging.pageSize' [pageSizeOptions]="[5, 10, 20]" (page)="onPaginateChange($event)"></mat-paginator>
                
            </div>
        </div>
    </div>
</div>