<form #addNewForm="ngForm" (ngSubmit)="addNewConversation(conversationRequest)" >
    <div *ngIf="conversationRequest" fxLayout="column" fxFlexFill fxLayoutGap="20px" > 
        <div fxFlex="100" class="button-box space-left" fxLayoutAlign="end start" *ngIf="navigationSettings">
            <button mat-raised-button mat-button color="accent" >{{ CONFIRM }}</button>
            <a mat-raised-button mat-button color="warn" [routerLink]="navigationSettings.parentUrl">{{ CANCEL }}</a>
        </div> 
        <div fxFlex="100">
            <H2>{{ NEW_MESSAGE_TO }} <span *ngIf="userProfile && userProfile.data">{{ userProfile.data.full_name }}</span></H2>
        </div> 
        <div fxFlex="100"> 
            <mat-form-field> 
                <mat-select [(ngModel)]="conversationTemplate" name="conversationTemplate" placeholder="select template" (selectionChange)="changeTemplate($event)" class="mat-small" panelClass="mat-small"> 
                    <mat-option  *ngFor="let conversationTemplate of conversationTemplates" [value]="conversationTemplate">
                        {{conversationTemplate.admin_name}} 
                    </mat-option>
                </mat-select>
            </mat-form-field> 
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <!-- <mat-label>Subject</mat-label> -->
                <input matInput [(ngModel)]="conversationRequest.subject" name="subject" placeholder="Subject" value="Subject" required>
            </mat-form-field> 
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <!-- <mat-label>Message</mat-label> -->
                <textarea matInput [(ngModel)]="conversationRequest.text" name="text" rows="16" placeholder="Write your message..." required></textarea>
            </mat-form-field> 
        </div>
        <div fxFlex="100" >
            <mat-radio-group [value]="conversationRequest.conversation_type" name="conversationType" required>
                <!-- TODO: Admin-user permisions are needed to display conversation_type accordingly.
                    I hid Medical checkbox till permissions are available. 
                    $root.user_id==='2' display Medical and check
                    $root.user_id==='1' display Admin and check
                --> 
                <mat-radio-button style="display: none;" value="1" [checked]="conversationRequest.conversation_type == 1">Medical</mat-radio-button>
                <mat-radio-button value="2" [checked]="conversationRequest.conversation_type == 2">Admin</mat-radio-button>
            </mat-radio-group>
        </div>
        <div fxFlex="100">
            <app-file-upload [(isMultiple)]="multipleFileUpload" [(buttonTitle)]="UPLOAD_FILES" (fileChange)="fileChange($event)"></app-file-upload>
        </div>        
        <br />
    </div>
</form>
