import { Injectable } from '@angular/core';
import { formatDate } from "@angular/common";
import { LOCALE_ID } from '@angular/core';
import * as moment from 'moment'; 
import { IConversationTemplate } from '../model/api/conversation-template';
import { AppConstants } from '../settings/app-constants';
import { IUser } from '../model/api/user';
import { SmileboxService } from '../service/api/smilebox.service';
import { StraightboxService } from '../service/api/straightbox.service';
import { ISmilebox } from '../model/api/smilebox';
import { IStraightbox } from '../model/api/straightbox';
import { IUserDetail } from '../model/api/user-detail';
import { UserStatus } from '../settings/user-status';

@Injectable({
    providedIn: 'root'
})

export class Helper {

    // constructor(private _smileboxService: SmileboxService, private _straightboxService: StraightboxService) {}

    // readonly NEW_MESSAGE_TO: string = AppConstants.NEW_MESSAGE_TO;
    // readonly SMILEBOX_TRACKING_NUMBER:string = AppConstants.SMILEBOX_TRACKING_NUMBER;
    // readonly STRAIGHTBOX_TRACKING_NUMBER:string = AppConstants.STRAIGHTBOX_TRACKING_NUMBER;
    // readonly USER_NAME:string = AppConstants.USER_NAME;
    // readonly USER_FIRST_NAME:string = AppConstants.USER_FIRST_NAME;
    // readonly USER_LAST_NAME:string = AppConstants.USER_LAST_NAME;
    // readonly PATIENT_NAME:string = AppConstants.PATIENT_NAME;
    // readonly PATIENT_FIRST_NAME:string = AppConstants.PATIENT_FIRST_NAME;
    // readonly PATIENT_LAST_NAME:string = AppConstants.PATIENT_LAST_NAME;
    // readonly PATIENT_PROFILE_URL:string = AppConstants.PATIENT_PROFILE_URL; 
    // readonly CURRENT_MONTH:string = AppConstants.CURRENT_MONTH; 
    // readonly USER_ID:string = AppConstants.USER_ID; 
    // readonly PATIENT_ID:string = AppConstants.PATIENT_ID;  
    moment: any = moment;

    getTime(date: string): string {

        if (this.isNullOrUndefined(date)) {
            return;
        }

       // let tempDate = new Date(date);
       let tempDate = moment(date);
        //var hours = tempDate.getUTCHours(); // see with Zack .getHours() adds two hours
        //var minutes = tempDate.getMinutes();
        //var hours = tempDate.hours;
        //var minute = tempDate.minutes;
        //var retHours: string = (hours > 0) ? hours.toString() : "00";
        //var retMinutes: string = (minutes > 0) ? minutes.toString() : "00";
        //return retHours + ":" + retMinutes;
        return tempDate.format('H:mm');
    }

    formatDateTime(date: string, time?: string): string {

        if (this.isNullOrUndefined(date)) {
            return;
        }

        const format = 'yyyy-MM-ddTHH:mm:ss';
        const locale = 'en-US'; 
        //let tempDate = new Date(date);
        let tempDate = moment(date);
        if (this.isNullOrUndefined(time)) {
            return tempDate.toISOString(); //formatDate(tempDate, format, locale);
        }
         
        const timeSplitted = time.split(":");
      
        //tempDate.setHours(parseInt(timeSplitted[0]), parseInt(timeSplitted[1]));
        tempDate.set({hour: parseInt(timeSplitted[0]), minute:  parseInt(timeSplitted[1])}); //.setHours(parseInt(timeSplitted[0]), parseInt(timeSplitted[1]));
        return tempDate.toISOString(); // formatDate(tempDate, format, locale);
    }

    isEmptyOrNull(item: string): boolean {
        if (item == null || item == '') {
            return true;
        }
        return false;
    }

    // https://www.stevefenton.co.uk/2018/04/catching-null-and-undefined-without-side-effects/
    isNullOrUndefined(data: any): boolean {
        if (typeof data === 'undefined' || typeof data === undefined || data === null || data.length == 0) {
            return true;
        }
        return false;
    }

    isNumber(id: any): boolean {
        if (isNaN(id) || id <= 0) {
            return false;
        }
        return true;
    }

    getExtension(file: string) {
        const split = file.split('?')[0].split('.');
        return split[split.length - 1].toLocaleLowerCase();
    }

    isImage(extension: string) {
        const ext = extension.split('?')[0];
        return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'webp' || ext === 'tiff' || ext === 'bmp';
    }

    isVideo(extension: string) {
        const ext = extension.split('?')[0];
        return ext === 'mp4' || ext === 'webm' || ext === 'mov' || ext === 'mpg' || ext === 'mpeg' || ext === 'mpe' || ext === 'mpv' || ext === 'm4v' || ext === 'avi' || ext === 'wmv'; 
    }

    isAudio(extension: string) {
        const ext = extension.split('?')[0];
        return ext === 'flac' || ext === 'ogg' || ext === 'mp2' || ext === 'm4p'; 
    }

    getPrettyName(file: string) {
        let ret:string;
        let splitTemp: string[] = file.split('?')[0].split('/');
        ret = splitTemp[splitTemp.length-1];
        ret = ret.replace(/_/g, " ").replace(/-/g, " ").replace(/.pdf/g, "").replace(/.jpg/g, "");
        return ret;
    }

    // Moved to helper.replace.variables.in.text
    // replaceConversationPatterns(conversationTemplates: IConversationTemplate[], user?: IUser): IConversationTemplate[] {
    //     // TODO: SMILEBOX_TRACKING_NUMBER and STRAIGHTBOX_TRACKING_NUMBER include in the pattern
    //     let hasUser: Boolean = !this.isNullOrUndefined(user);

    //     if(this.isNullOrUndefined(conversationTemplates))
    //         return null;
    
    //     let ret:IConversationTemplate[] = []; 
    
    //     conversationTemplates.forEach(async conversationTemplateResponse => {
    
    //         let conversationTemplate = { ...conversationTemplateResponse };
    //         if(conversationTemplate.text.includes(this.SMILEBOX_TRACKING_NUMBER)) {

    //             // let smilebox = await this._smileboxService.detail(user.id.toString()).toPromise();
    //             // conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.SMILEBOX_TRACKING_NUMBER, 'g'), smilebox.tracking_number);
    //             // conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.SMILEBOX_TRACKING_NUMBER, 'g'), smilebox.tracking_number);
    //         }
    //         if(conversationTemplate.text.includes(this.STRAIGHTBOX_TRACKING_NUMBER)) {
    //             //console.log('STRAIGHTBOX_TRACKING_NUMBER has been found!!!!!!!!!!');
    //             // TODO: Check with Zack which api has that info
    //         }
    
    //         /* conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.SMILEBOX_TRACKING_NUMBER, 'g'), "");
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.SMILEBOX_TRACKING_NUMBER, 'g'), "");
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.STRAIGHTBOX_TRACKING_NUMBER, 'g'), "");
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.STRAIGHTBOX_TRACKING_NUMBER, 'g'), ""); */
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.USER_NAME, 'g'), hasUser ? user.full_name : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.USER_NAME, 'g'), hasUser ? user.full_name : '');
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.USER_FIRST_NAME, 'g'), hasUser ? user.first_name : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.USER_FIRST_NAME, 'g'), hasUser ? user.first_name : '');
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.USER_LAST_NAME, 'g'), hasUser ? user.last_name : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.USER_LAST_NAME, 'g'), hasUser ? user.last_name : '');
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.PATIENT_NAME, 'g'), hasUser ? user.full_name : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.PATIENT_NAME, 'g'), hasUser ? user.full_name : '');
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.PATIENT_FIRST_NAME, 'g'), hasUser ? user.first_name : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.PATIENT_FIRST_NAME, 'g'), hasUser ? user.first_name : '');
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.PATIENT_LAST_NAME, 'g'), hasUser ? user.last_name : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.PATIENT_LAST_NAME, 'g'), hasUser ? user.last_name : '');
    
    //         // conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.PATIENT_PROFILE_URL, 'g'), "");
    //         // conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.PATIENT_PROFILE_URL, 'g'), "");
            
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.CURRENT_MONTH, 'g'), this.moment().format('MMMM'));
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.CURRENT_MONTH, 'g'),this.moment().format('MMMM'));
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.USER_ID, 'g'), hasUser ? user.id.toString() : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.USER_ID, 'g'), hasUser ? user.id.toString() : '');
    
    //         conversationTemplate.subject = conversationTemplate.subject.replace(new RegExp(this.PATIENT_ID, 'g'), hasUser ? user.id.toString() : '');
    //         conversationTemplate.text = conversationTemplate.text.replace(new RegExp(this.PATIENT_ID, 'g'), hasUser ? user.id.toString() : '');
    
    //         ret.push(conversationTemplate)
    
    //     });
    
    //     return conversationTemplates;
    // }
 
}