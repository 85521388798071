import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {takeUntil} from 'rxjs/operators'; 
import {Subscription, Subject, Observable, pipe, of} from "rxjs";
import {SecurityService} from "../service/security/security.service";
import {ILoginRequest} from "../model/api/login-request";
import {SpinnerService} from "../service/spinner.service";
import {SettingsService} from "../service/state/settings.service";
import {ISettings, Settings } from '../model/settings';
 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginData: ILoginRequest; 
	errorMessage: string;
	private ngUnsubscribe: Subject<any> = new Subject();
	settings: ISettings;

    constructor( private router: Router, private spinnerService: SpinnerService, private securityService: SecurityService, private settingsService: SettingsService) {}

    ngOnInit() { 
		//console.log('LoginComponent -> ngOnInit()');
        this.loginData = <ILoginRequest>{};
    }
  

	login() { 
        if(this.securityService.isLoggedIn()){
            this.spinnerService.startSpinner();
            
			this.securityService.logoutUser()
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(response => {   
					this.spinnerService.stopSpinner();  
				},
				error => { 
					this.errorMessage = error;
					this.spinnerService.stopSpinner(); 
				}).add(() => {
					this.loginUser();
			   }); 
        }
		
		this.loginUser();
	} 
	
	loginUser(){ 
        this.spinnerService.startSpinner();
		this.securityService.loginUser(this.loginData) 
			.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(response => {  
				this.router.navigateByUrl('/admin/user');                             
                this.spinnerService.stopSpinner(); 		
            },
            error => {    
                this.errorMessage = error; 
                this.spinnerService.stopSpinner(); 
            });
	}

    ngOnDestroy() {   
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
    }
    

}
