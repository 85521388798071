<mat-toolbar color="primary" class="header"> 
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill>    
        <div fxFlex="85" class="header-col left">
            <h2>
                <span *ngIf="(settings$ | async) as settings">
                    <span *ngIf="settings.navigationSettings.parentId == null">{{ settings.navigationSettings.pageTitle }}</span>
                </span>  
                <span *ngIf="user">{{ user.id.toString() }} {{ user.full_name }}</span>
                <span *ngIf="reportUserInfo && userStatusColor" [ngStyle]="{'background-color': userStatusColor }" class="status-box">
                   {{ userStatusName['status-' + reportUserInfo.status] }} 
                </span> 
            </h2>
            <div class="status-icons" *ngIf="reportUserInfo">
                <div class="icons">
                    <span *ngIf="reportUserInfo.is_vip"><mat-icon mat-list-icon *ngIf="reportUserInfo.is_vip">perm_identity</mat-icon> <span class="warn-color">VIP</span></span>
                    <span *ngIf="reportUserInfo.policy_breach_status == 'PB'"><mat-icon mat-list-icon *ngIf="reportUserInfo.policy_breach_status == 'PB'">perm_identity</mat-icon> <span class="warn-color">Policy breach</span></span>
                    <span *ngIf="reportUserInfo.policy_breach_status == 'AS'"><mat-icon mat-list-icon *ngIf="reportUserInfo.policy_breach_status == 'AS'">perm_identity</mat-icon> <span class="warn-color">Account suspended</span></span>
                    <span *ngIf="reportUserInfo.is_sensitive_user"><mat-icon mat-list-icon *ngIf="reportUserInfo.is_sensitive_user">perm_identity</mat-icon> <span class="warn-color">Sensitive user</span></span>
                    <span *ngIf="reportUserInfo.maintainer_plan__active"><mat-icon mat-list-icon *ngIf="reportUserInfo.maintainer_plan__active">perm_identity</mat-icon> <span class="warn-color">Maintainer plan</span></span>
                </div>
            </div> 
        </div>
        <div fxFlex="15" class="header-col right">
            <div (click)="openProgressStatusDialog()" class="progress-status" *ngIf="reportUserInfo">
                <span class="percentage">{{ !_helper.isNullOrUndefined(reportUserInfo.treatment_progress_percentage) ? reportUserInfo.treatment_progress_percentage : 0 }}%</span>
                <mat-progress-bar color="accent" mode="determinate" [value]="!_helper.isNullOrUndefined(reportUserInfo.treatment_progress_percentage) ? reportUserInfo.treatment_progress_percentage : 0"></mat-progress-bar> 
            </div> 
        </div>
    </div> 
</mat-toolbar> 