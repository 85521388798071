import { Component, NgZone, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ConversationService } from '../../../../service/api/conversation.service';
import { ConversationTemplateService } from '../../../../service/api/conversation-template.service';
import { SettingsService } from '../../../../service/state/settings.service';
import { IConversationTemplate } from '../../../../model/api/conversation-template';
import { IConversationRequest } from '../../../../model/api/conversation'; 
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../../util/helper.util';
import { INavigationSettings, ISettings } from '../../../../model/settings';
import { AppConstants } from '../../../../settings/app-constants';
import { IUserProfile } from '../../../../model/api/user-profile';
import * as moment from 'moment'; 
import { MatSelectChange } from '@angular/material/select';
import { DetailUserBaseComponent } from '../../../inc/base/detail-user-base-component';
import { MenuService } from '../../../../service/state/menu.service'; 
import { UserService } from '../../../../service/api/user.service';
import { IUser } from '../../../../model/api/user';
import { IUserDetail } from '../../../../model/api/user-detail';
import { UserDetailService } from '../../../../service/api/user-detail.service';
import { ReportUserInfoService } from 'src/app/service/api/report-user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportUserInfoStateService } from 'src/app/service/state/report-user-info-state.service';
import { SpinnerService } from 'src/app/service/spinner.service'; 
import { forkJoin } from 'rxjs';
import { HelperReplaceVariablesInText } from '../../../../util/helper.replace.variables.in.text';
import { UserStateService } from '../../../../service/state/user-state.service';

@Component({
    selector: 'app-add-new',
    templateUrl: './add-new.component.html',
    styleUrls: ['./add-new.component.scss']
})
export class AddNewConversationComponent extends DetailUserBaseComponent implements OnInit {
    
    conversationTemplates: IConversationTemplate[];
	conversationRequest: IConversationRequest;
    userProfile: IUserProfile;
	userId: number; 
    navigationSettings: INavigationSettings; 
    multipleFileUpload: boolean = true; 
    filesToUpload: File[] = [];  
    moment: any = moment;
    menuId: number = 1040;
    user: IUser;    
    readonly CANCEL:string = AppConstants.CANCEL;
    readonly CONFIRM:string = AppConstants.CONFIRM;
    readonly ERROR_FILL_ALL_FIELDS:string = AppConstants.ERROR_FILL_ALL_FIELDS;
    readonly UPLOAD_FILES: string = AppConstants.UPLOAD_FILES;

    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _conversationService: ConversationService, private _conversationTemplateService: ConversationTemplateService, private _route: ActivatedRoute, private _helper: Helper, private _helperReplaceVariablesInText: HelperReplaceVariablesInText, private _cd: ChangeDetectorRef, private _zone: NgZone, private _snackBar: MatSnackBar, private _spinnerService: SpinnerService) {  
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
		super.ngOnInit();
        
		this.conversationRequest = { user: this.userId, file: null, subject: '', text: '', conversation_type: 2 };

        forkJoin([this._userService.detail(this.userId.toString()), this._userDetailService.detail(this.userId.toString())]).subscribe(userInfo => {
            this.user = userInfo[0];
            this.userDetail = userInfo[1];

            this.getConversationTemplates();
        });
            
        // this._userService.detail(this.userId.toString())
		// 	.subscribe(user => {
		// 		this.user = user; 
        //         this.getConversationTemplates();
		// 	});
    }
 

	getConversationTemplates(): void { 

        this._spinnerService.startSpinner();

        this._conversationTemplateService.index()
			.subscribe(conversationTemplates => {  
                // this.conversationTemplates = this._helper.replaceConversationPatterns(conversationTemplates.results, this.user);
                this.conversationTemplates = conversationTemplates.results;
                this._spinnerService.stopSpinner(); 
			},
            error =>{  
                this._spinnerService.stopSpinner();  
            });
	}    

	async changeTemplate(event: MatSelectChange) { 

        if(this._helper.isNullOrUndefined(event.value))
            return;

        this.conversationRequest.subject = await this._helperReplaceVariablesInText.replace(event.value.subject, this.user, this.userDetail);
        this.conversationRequest.text = await this._helperReplaceVariablesInText.replace(event.value.text, this.user, this.userDetail);
		// this.conversationRequest.subject = event.value.subject;
        // this.conversationRequest.text = event.value.text;
        this.conversationRequest.user = this.userId;
	}

	addNewConversation(conversationRequest: IConversationRequest): void {
  
        if(this._helper.isNullOrUndefined(conversationRequest) || this._helper.isEmptyOrNull(conversationRequest.subject) || this._helper.isEmptyOrNull(conversationRequest.text) || this._helper.isNullOrUndefined(conversationRequest.conversation_type)) { 
            this._snackBar.open(this.ERROR_FILL_ALL_FIELDS, 'Close', this.matSnackBarConfig);
            return;
        }

        this._spinnerService.startSpinner();
 
        this._conversationService.post(this.userId, conversationRequest, this.filesToUpload)
            .subscribe(response => { 
                     if(response) { 
                        this._router.navigateByUrl(this.navigationSettings.parentUrl);
                    }  
                    this._spinnerService.stopSpinner();  
                },
                error =>{  
                    this._spinnerService.stopSpinner();  
                });
	}

    fileChange(fileSrcs) { 
        this.filesToUpload = fileSrcs;
    }
      
}
