
<mat-card> 
    <mat-card-content style="text-align: right;"> 
        <div *ngIf="navigationSettings" fxLayoutAlign="end start">  
            <button mat-raised-button mat-button color="primary" (click)="saveMessageBroadcast()" [disabled]="!conversationTemplate">{{ SAVE_BUTTON }}</button> 
        </div> 
    </mat-card-content>
</mat-card>
<mat-card> 
    <mat-card-content> 
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill fxLayoutGap="20px">    
            <div fxFlex="100" fxLayoutGap="0px">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start" > 
                    <div fxFlex="100">  
                        <mat-form-field> 
                            <mat-select [(ngModel)]="conversationTemplate" name="conversationTemplate" placeholder="select template" class="mat-small"> 
                                <mat-option  *ngFor="let conversationTemplate of conversationTemplates" [value]="conversationTemplate">
                                    {{conversationTemplate.admin_name}} 
                                </mat-option>
                            </mat-select>
                        </mat-form-field> 
                
                        
                        <button mat-raised-button mat-button color="accent" (click)="addCsvFile.click()">
                            <span class="material-icons">add</span> {{ ADD_FILE }}
                            <span style="display: none;">
                                <input #addCsvFile (change)="addNewCsvFile($event)"  accept=".csv" id="addCsvFile" type="file" style="display: none;" >
                            </span>                                    
                        </button> 
                        <span class="image-box" *ngIf="uploadFile" style="margin-left: 10px;">
                            {{ uploadFile.name }}  
                        </span>  
                    
                    </div>
                </div>        
            </div>
        </div>
    </mat-card-content>
</mat-card>


<div class="mat-table-holder" *ngIf="messageBroadcastFilterSettings && messageBroadcastFilterSettings.displayedColumns">  
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">        

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
            <td mat-cell *matCellDef="let element"> {{ element.id.toString() }} 
                <a fxFlexFill [routerLink]="[navigationSettings.url, element.id.toString()]" class="mat-row-link"> </a> 
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> Created at</th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.created_at">{{ moment(element.created_at).format('DD.MM.Y HH:mm:ss') }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="is_processing">
            <th mat-header-cell *matHeaderCellDef> Is processing </th>
            <td mat-cell *matCellDef="let element">{{ element.is_processing ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="is_done">
            <th mat-header-cell *matHeaderCellDef> Is done </th>
            <td mat-cell *matCellDef="let element">{{ element.is_done ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="number_of_processed_messages">
            <th mat-header-cell *matHeaderCellDef>Processed messages </th>
            <td mat-cell *matCellDef="let element"> {{ element.number_of_processed_messages.toString()  }} </td>
        </ng-container>

        <ng-container matColumnDef="conversation_template__subject">
            <th mat-header-cell *matHeaderCellDef>Template subject </th>
            <td mat-cell *matCellDef="let element"> {{ element.conversation_template__subject }} </td>
        </ng-container>
 
        <tr mat-header-row *matHeaderRowDef="messageBroadcastFilterSettings.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: messageBroadcastFilterSettings.displayedColumns"></tr> 
    </table> 
    <mat-paginator showFirstLastButtons [pageIndex]='messageBroadcastFilterSettings.paging.pageIndex-1' [length]='messageBroadcastFilterSettings.paging.length' [pageSize]='messageBroadcastFilterSettings.paging.pageSize' [pageSizeOptions]="[5, 10, 20]" (page)="onPaginateChange($event)"></mat-paginator>
</div> 
 