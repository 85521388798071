import { Component, OnInit } from '@angular/core'; 
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from "../../service/state/settings.service";
import { MenuService } from '../../service/state/menu.service';
import { ChangeDetectorRef } from '@angular/core';
import { IMenu } from '../../model/menu';
import { Helper } from '../../util/helper.util';
import { INavigationSettings } from '../../model/settings'; 
import { UserStateService } from '../../service/state/user-state.service'; 
import { UserService } from '../../service/api/user.service';
import { IMenuPermissions } from '../../model/base/menu-permissions';
import { UserDetailService } from '../../service/api/user-detail.service';
import { IUserDetail } from '../../model/api/user-detail';
import { ReportUserInfoStateService } from '../../service/state/report-user-info-state.service';
import { IUser } from '../../model/api/user';
import { ReportUserInfoService } from '../../service/api/report-user-info.service';
import { IReportUserInfo } from '../../model/api/report-user-info';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ColorStatusService } from '../../service/color-status.service';
 

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit  {

    currentMenu: IMenu;
    userId: number;
    category: string; 
    navSettings: INavigationSettings;   
    menuId: number = 1;
    menuPermissions: IMenuPermissions;
    userDetail: IUserDetail;
    user: IUser;
    reportUserInfo: IReportUserInfo; 
    userStatusColor: string;
    protected ngUnsubscribe: Subject<any> = new Subject();
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _userService: UserService, private _userDetailService: UserDetailService, private _userStateService: UserStateService, private _activatedRoute: ActivatedRoute, private router: Router, private cd: ChangeDetectorRef,  private _helper: Helper, private _reportUserInfoStateService: ReportUserInfoStateService, private _reportUserInfoService: ReportUserInfoService, private colorStatusService: ColorStatusService) { }

    ngOnInit() { 

        this.navSettings = this._menuService.getNavigationByMenuId(this.menuId);        
        this._settingsService.setNavigationSettings(this.navSettings); 

        this._activatedRoute.paramMap.subscribe(routeParams => {			 
            this.userId = +routeParams.get('userId');
            this.getUser(this.userId);
            this.getReportUserInfo(this.userId);
		}); 

        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => { 
                this.reportUserInfo = reportUserInfo;

                if(reportUserInfo)
                    this.userStatusColor = this.colorStatusService.getUserColorStatus(reportUserInfo.status);
            }); 
        
    } 

    getUser(userId:number): void { 
        this._userService.detail(userId.toString())
			.subscribe(user => { 
                this.user = user;
			});
	}

    getReportUserInfo(userId:number): void {  
        this._reportUserInfoService.detail(userId.toString())
            .subscribe(reportUserInfo => {  
                this.reportUserInfo = reportUserInfo;                 
            }); 
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        //this._userStateService.setUser(null);
        //this._reportUserInfoStateService.setReportUserInfo(null);
        
    } 

 
}
