import { Injectable } from '@angular/core';
import { IComplaintCategory } from '../../model/api/complaint-category';  
import { BaseService } from '../base/base.service';


@Injectable({
  	providedIn: 'root'
})
 
export class ComplaintCategoryService extends BaseService<IComplaintCategory> {
 
    protected urlV2 =  "/admin-v02/user-complaint-category"; 

}
