import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../app.config/app.config';  
import { BaseService } from '../base/base.service'; 
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IAppBannerNotification } from '../../model/api/app-banner-notificaton';


@Injectable({
  	providedIn: 'root'
})
 
export class AppBannerNotificationService extends BaseService<IAppBannerNotification> {
 
    protected urlV2 =  "/admin-v02/banner-notification";  
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    
 

}
