import { Pipe, PipeTransform } from '@angular/core';
import { ICountry } from '../model/api/country';
import { IStatus } from '../model/status';

@Pipe({
  name: 'matchStatuses'
})
export class MatchesStatusesPipe implements PipeTransform {

    transform(statuses: IStatus[], selectedStatuses: number[]): IStatus[] {
        
        /*console.log('--------------------')
        console.log('statuses:');
        console.log(statuses);
        console.log('selectedStatuses:');
        console.log(selectedStatuses);*/
        let retStatuses: IStatus[] = statuses.filter(status => {
            if(selectedStatuses.includes(status.id))
                return status;
        })
        //console.log('retStatuses:');
        //console.log(retStatuses);
        return retStatuses;
    }

}
