import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, } from '@angular/core';
import { IUser } from '../../../model/api/user';
import { IUserDetail } from '../../../model/api/user-detail';
import { IConversation, IConversationBase } from '../../../model/api/conversation'; 
import { IConversationMessageRequest } from '../../../model/api/conversation-message';
import { IConversationTemplate } from '../../../model/api/conversation-template';
import { ConversationMessageService } from '../../../service/api/conversation-message.service';
import { ConversationService } from '../../../service/api/conversation.service';
import { AppConstants } from '../../../settings/app-constants';
import { Helper } from '../../../util/helper.util'
import { HelperReplaceVariablesInText } from '../../../util/helper.replace.variables.in.text'
import { UserService } from '../../../service/api/user.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


@Component({
    selector: 'app-message-reply',
    templateUrl: './message-reply.component.html',
    styleUrls: ['./message-reply.component.scss']
})
export class MessageReplyComponent implements OnInit {

    @Input() selectedConversation: IConversationBase;
    @Output() conversationChange = new EventEmitter();
	@Input() conversationTemplates: IConversationTemplate[];
	@Input() userId: number;
    user: IUser;
    userDetail: IUserDetail;
    conversationMessageRequest: IConversationMessageRequest;
    multipleFileUpload: boolean = true; 
    filesToUpload: File[] = [];
	readonly CANCEL:string = AppConstants.CANCEL;
    readonly CONFIRM:string = AppConstants.CONFIRM;
    readonly ERROR_FILL_ALL_FIELDS:string = AppConstants.ERROR_FILL_ALL_FIELDS;
    readonly REPLY_MESSAGE_TO: string = AppConstants.REPLY_MESSAGE_TO;
    readonly UPLOAD_FILES: string = AppConstants.UPLOAD_FILES;
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
    
    constructor(private conversationService: ConversationService, private _conversationMessageService: ConversationMessageService, private helper: Helper, private _helperReplaceVariablesInText: HelperReplaceVariablesInText, private cd: ChangeDetectorRef, private _zone: NgZone, private _userService: UserService, private _userDetailService: UserDetailService, private _spinnerService: SpinnerService, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {

    }
 
	async changeTemplate(event) {
        if(this.helper.isNullOrUndefined(event.value))
            return;

        this.conversationMessageRequest.text = await this._helperReplaceVariablesInText.replace(event.value.text, this.user, this.userDetail);
 
        // this.conversationMessageRequest.text = event.value.text;
        this.conversationMessageRequest.user = this.user.id;
	} 
    
	sendNewMessage(conversationMessageRequest: IConversationMessageRequest): void {

        if(this.helper.isNullOrUndefined(this.selectedConversation))
            return; 

        if(this.helper.isNullOrUndefined(conversationMessageRequest) || this.helper.isEmptyOrNull(conversationMessageRequest.text)) {
            this._snackBar.open(this.ERROR_FILL_ALL_FIELDS, 'Close', this.matSnackBarConfig);
            return;
        }
 
        this._spinnerService.startSpinner();
 
          
        this._conversationMessageService.post(this.selectedConversation.id, conversationMessageRequest, this.filesToUpload)
            .subscribe(response => { 
                if(response) { 
                    this.selectedConversation.isReplyButtonVisible = true;
                    
                    this.conversationChange.emit(this.selectedConversation);
                }   
                this._spinnerService.stopSpinner(); 
            },
            error =>{  
                this._snackBar.open(error.message, 'Close', this.matSnackBarConfig);
                this._spinnerService.stopSpinner();  
            });
            
	}

    cancelMessage() {
        this.selectedConversation.isReplyMessageCanceled = true;
        this.selectedConversation.isReplyButtonVisible = true;
        this.conversationChange.emit(this.selectedConversation);
    } 
    
    getUser(): void { 
        if(this.userId == undefined || this.userId == null || this.userId == 0)
            return;

        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
                this.conversationMessageRequest = { user: this.user.id, file: null, text: '' };
			});
	}

    getUserDetails(): void { 
        if(this.userId == undefined || this.userId == null || this.userId == 0)
            return;

        this._userDetailService.detail(this.userId.toString())
			.subscribe(userDetail => {
				this.userDetail = userDetail; 
			});
	}


    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("userId")){              
            this.userId = changes.userId.currentValue;            
            this.getUser();
            this.getUserDetails();
        }
    }

    fileChange(fileSrcs) { 
        this.filesToUpload = fileSrcs;
    }
}
