import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCoreModule } from 'src/app/custom-core.module';
import { UserExportRoutingModule } from './user-export-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StatusService } from 'src/app/service/status.service';
import { UserExportComponent } from '../user-export/user-export.component';
import { ExportComponent } from './export/export.component';
import { UserExportService } from 'src/app/service/api/user-export.service';
import { UserExportFilterStateService } from 'src/app/service/state/user-export-filter-state.service';


@NgModule({
    declarations: [ 
        UserExportComponent, 
		ExportComponent
    ],
    exports: [ ],
    imports: [
        CustomCoreModule, 
        UserExportRoutingModule,
        SharedModule
    ],
    providers: [  
        StatusService,
		UserExportService,
		UserExportFilterStateService
    ]
})
export class UserExportModule { }
