import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { Helper } from '../../util/helper.util'; 
import { IUserDetailsRequest, IUserV1, IUserProfileRequest } from '../../model/api/user-profile';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IUserResponse, IMessageResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';
import { IUserDetail } from '../../model/api/user-detail';  

@Injectable({
	providedIn: 'root'
})
 
export class UserDetailService extends BaseService<IUserDetail>  { 
    protected urlV2 = "/admin-v02/user-detail";
    private urlUserDetailsUpdate = this._config.api_url + "/admin/user-details-update";
    private urlUpdateStatus = this._config.api_url + "/admin/update-status"; 
    private urlFinishStatus = this._config.api_url + "/admin/finish-status"; 
    private urlSmileboxOfflinePayment = this._config.api_url + "/admin/smilebox-offline-payment";
    private urlStraightboxOfflinePayment = this._config.api_url + "/admin/straightbox-offline-payment"; 

    constructor(@Inject(APP_CONFIG) public _config, private _http: HttpClient, private helper: Helper) { 
        super(_config, _http);
    } 
 
    putUserDetails<T>(userId: number, requestBody: IUserDetailsRequest<T>): Observable<IUserResponse<IUserV1>> {
        let urlFinal = `${this.urlUserDetailsUpdate}/${userId}`;  

        return this._http.put(urlFinal, requestBody)
            .pipe(
                map((res: IUserResponse<IUserV1>) => {
                        if (res && res.success == true) { 
                            this._responseDetailCache$.clear();
                            return res;
                        } 
                    }), 
                    catchError( err =>  { return this.handleError(err)})
            );
    }
  
    updateStatus(userId: number, userV1Request: IUserProfileRequest): Observable<any>  { 
        let urlFinal = `${this.urlUpdateStatus}/${userId}`; 
		return this._http.put<any>(urlFinal, userV1Request)
            .pipe(
                map(response => {                     
                    return response as any;
                })
            );
	}

    smileboxOfflinePayment<T>(userId: number): Observable<IMessageResponse> {
        let url = `${this.urlSmileboxOfflinePayment}/${userId}`; 
   
        return this._http.post(url, null)
            .pipe(
                map((res: IMessageResponse) => { 
                    return res as IMessageResponse;                   
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    threeDScanOfflinePayment<T>(userId: number, productId: {product_id : number}): Observable<IMessageResponse> {
        let url = `${this.urlSmileboxOfflinePayment}/${userId}`; 
   
        return this._http.post(url, productId)
            .pipe(
                map((res: IMessageResponse) => {   
                    return res as IMessageResponse;                    
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    straightboxOfflinePayment<T>(userId: number): Observable<IMessageResponse> {
        let url = `${this.urlStraightboxOfflinePayment}/${userId}`; 
   
        return this._http.post(url, null)
            .pipe(
                map((res: IMessageResponse) => {  
                    if (res && res.success == true) { 
                        this._responseDetailCache$.clear();
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    finishStatus(userId: number): Observable<any>  { 
        let urlFinal = `${this.urlFinishStatus}/${userId}`; 
		return this._http.get<any>(urlFinal).pipe(
			map(response => {  
                this._responseDetailCache$.clear();
				return response as any;
			})
		);
	}

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);

        /* let error = httpError.error;
        let errorMessage = error.detail || error.message || error.msg || 'An error occured!';
        return throwError(errorMessage); */
    }

 
}
 

