import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IStraightbox, ISendTreatmentPlanMessageRequest, IShipStraightboxRequest, IAlignerProgressStraightboxOptionRequest } from '../../model/api/straightbox';
import { IMessageResponse, IStraightboxResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';
import { SmileboxService } from './smilebox.service';
import { UserDetailService } from './user-detail.service';


@Injectable({
  	providedIn: 'root'
})
 
export class StraightboxService extends BaseService<IStraightbox> {
    protected urlV2 = "/admin-v02/user-straightbox"; 
    protected urlSendTreatmentPlanMessage = "/admin/send-treatment-plan-message"; 
    protected urlStraightbox = "/admin/straightboxorder"; 
    protected urlReviewImageUpload = "/admin/review-images-upload";
    


    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

    sendTreatmentPlanMessage(userId: number, sendTreatmentPlanMessageRequest: ISendTreatmentPlanMessageRequest): Observable<IMessageResponse>  { 
        let urlFinal = `${this._config.api_url}${this.urlSendTreatmentPlanMessage}/${userId}`; 
		return this._http.put<IMessageResponse>(urlFinal, sendTreatmentPlanMessageRequest).pipe(
			map(response => { 
                this._responseDetailCache$.clear();
				return response as IMessageResponse;
			})
		);
	}
 
    createStraightbox(userId: number): Observable<IStraightboxResponse<IStraightbox>>  { 
        let urlFinal = `${this._config.api_url}${this.urlStraightbox}/${userId}`; 
		return this._http.post<IStraightboxResponse<IStraightbox>>(urlFinal, {}).pipe(
			map(response => { 
                this._responseDetailCache$.clear();
				return response as IStraightboxResponse<IStraightbox>;
			})
		);
	}
    
    shipStraightbox(userId: number, shipStraightboxRequest: IShipStraightboxRequest): Observable<IStraightboxResponse<IStraightbox>>  { 
        let urlFinal = `${this._config.api_url}${this.urlStraightbox}/${userId}`; 
		return this._http.put<IStraightboxResponse<IStraightbox>>(urlFinal, shipStraightboxRequest).pipe(
			map(response => { 
                this._responseDetailCache$.clear();
				return response as IStraightboxResponse<IStraightbox>;
			})
		);
	}

    saveAlignerProgressOption(userId: number, alignerProgressStraightboxOptionRequest: IAlignerProgressStraightboxOptionRequest): Observable<IStraightboxResponse<IStraightbox>>  { 
        let urlFinal = `${this._config.api_url}${this.urlStraightbox}/${userId}`; 
		return this._http.put<IStraightboxResponse<IStraightbox>>(urlFinal, alignerProgressStraightboxOptionRequest).pipe(
			map(response => { 
                this._responseDetailCache$.clear();
				return response as IStraightboxResponse<IStraightbox>;
			})
		);
	}

    saveReviewImage(userId: number, formData: FormData): Observable<IMessageResponse>  { 
        let urlFinal = `${this._config.api_url}${this.urlReviewImageUpload}/${userId}`; 
		return this._http.put<IMessageResponse>(urlFinal, formData).pipe(
			map(response => { 
                this._responseDetailCache$.clear();
				return response as IMessageResponse;
			})
		);
	}
    
}
