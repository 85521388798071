<mat-card> 
    <mat-card-content>
        <div fxLayout="row" fxFlexFillfxLayoutGap="20px" *ngIf="navigationSettings">
            <div fxFlex="100" class="button-box space-right" fxLayoutAlign="end start"> 
                <button mat-raised-button mat-button color="warn" (click)="deleteMaintenanceAnnouncement()" *ngIf="maintenanceAnnouncement">{{ DELETE_BUTTON }}</button> 
                <button mat-raised-button mat-button color="primary" (click)="saveMaintenanceAnnouncement()" *ngIf="maintenanceAnnouncement">{{ SAVE_BUTTON }}</button> 
                <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.parentUrl">{{ CANCEL }}</a>
            </div> 
        </div> 
    </mat-card-content> 
</mat-card>
<mat-card> 
    <mat-card-content>
        <div  fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlexFill fxLayoutGap="20px">    
            <div fxFlex="100" *ngIf="maintenanceAnnouncement" fxLayoutGap="20px">
                <div fxLayout="column" fxLayout.lt-sm="column" > 
                    <div fxFlex="100">
                        <mat-form-field class="example-full-width">
                            <mat-label>Text</mat-label>
                            <textarea matInput [(ngModel)]="maintenanceAnnouncement.text" rows="10" placeholder="Text"></textarea>
                        </mat-form-field> 
                    </div> 
                    <div fxFlex="100">
                        <div fxLayout="row" fxLayout.lt-sm="column"  fxLayoutGap="20px">
                            <div fxFlex="50">
                                <mat-form-field>
                                    <input matInput [ngxMatDatetimePicker]="visibleFromDatePicker" placeholder="Choose a date" [(ngModel)]="maintenanceAnnouncement.visible_from_date"
                                    [min]="minDate" [max]="maxDate" [disabled]="disabled">
                                    <mat-datepicker-toggle matSuffix [for]="visibleFromDatePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #visibleFromDatePicker [showSpinners]="true" [disableMinute]="disableMinute" ></ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field>
                                    <input matInput [ngxMatDatetimePicker]="visibleToDatePicker" placeholder="Choose a date" [(ngModel)]="maintenanceAnnouncement.visible_to_date"
                                    [min]="minDate" [max]="maxDate" [disabled]="disabled">
                                    <mat-datepicker-toggle matSuffix [for]="visibleToDatePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #visibleToDatePicker [showSpinners]="true" [disableMinute]="disableMinute" ></ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                        </div>                
                    </div>
            
                    <div fxFlex="100">
                        <div fxLayout="row" fxLayout.lt-sm="column"  fxLayoutGap="20px"> 
                            <div fxFlex="100">
                                <mat-checkbox [(ngModel)]="maintenanceAnnouncement.active"> Active </mat-checkbox>   
                            </div>
                        </div>                
                    </div>
                </div>
                
            </div>
        </div> 
    </mat-card-content>
</mat-card>
