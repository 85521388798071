import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';
import { Helper } from '../../util/helper.util';
import { ICountry } from '../../model/api/country'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { BaseService } from '../base/base.service';
 

@Injectable({
	providedIn: 'root'
})

export class CountryService extends BaseService<ICountry>  { 

    protected urlV2 = "/admin-v02/list-country";  
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

}

 