<form #addNewForm="ngForm" (ngSubmit)="uploadImage()">
    <div fxLayout="column" fxFlexFill fxLayoutGap="20px"> 
        <div fxFlex="100" class="button-box space-left" fxLayoutAlign="end start"  fxLayoutGap="0px" *ngIf="navigationSettings"> 
            <button mat-raised-button mat-button color="primary">{{ UPLOAD }}</button>
            <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.parentUrl">{{ CANCEL }}</a>
        </div>
        <div fxFlex="100"> 
            <label>Upload as:</label>
        </div> 
        <div fxFlex="100"> 
            <mat-radio-group [(ngModel)]="selectedCategory" [ngModelOptions]="{standalone: true}">
                <mat-radio-button *ngFor = "let category of categories" [value]="category" [checked]="selectedCategory == category"  (change)="catgegoryChange($event)">
                   {{ category == ECONSULTATION_UPLOADS_VALUE ? ECONSULTATION_UPLOADS : CONVERSATION_UPLOADS }}
                </mat-radio-button>
             </mat-radio-group> 
        </div>  
        <div fxFlex="100"> 
            <app-file-upload *ngIf="selectedCategory == ECONSULTATION_UPLOADS_VALUE" [(allowedExtensions)]="econsultationAllowedExtensions" [(isMultiple)]="multipleFileUpload" [(buttonTitle)]="UPLOAD_FILE" (fileChange)="fileChange($event)"></app-file-upload>
            <app-file-upload *ngIf="selectedCategory == CONVERSATION_UPLOADS_VALUE" [(isMultiple)]="multipleFileUpload" [(buttonTitle)]="UPLOAD_FILE" (fileChange)="fileChange($event)"></app-file-upload>
        </div> 
    </div>
</form>
