import { Component, OnInit } from '@angular/core'; 
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from "../../service/state/settings.service";
import { MenuService } from '../../service/state/menu.service';
import { Helper } from '../../util/helper.util';
import { DetailBaseComponent } from 'src/app/admin/inc/base/detail-base-componenty';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/service/spinner.service';
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';

@Component({
	selector: 'app-notification-center',
	templateUrl: './notification-center.component.html',
	styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent extends DetailBaseComponent implements OnInit {
     
    menuId: number = 11;

    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportCurrentUserService: ReportCurrentUserService, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();
 
    }

}
