import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';
import { Helper } from '../../util/helper.util';
import { IUserSubmissionsResponse  } from '../../model/api/user'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { ITreatmentStatusUpdateResponse } from 'src/app/model/api/response';
import { ITreatmentStatusRequest } from 'src/app/model/api/treatment-status';
import { ReportUserInfoService } from './report-user-info.service';

@Injectable({
	providedIn: 'root'
})

export class TreatementStatusV1 { 
    url = this._config.api_url + "/admin/treatment-status";   

    constructor(@Inject(APP_CONFIG) public _config, private helper: Helper, private _http: HttpClient) {} 
 
    saveTreatmentStatus(userId: number, treatmentStatusRequest: ITreatmentStatusRequest): Observable<any>  { 
        let urlFinal = `${this.url}/${userId}/`; 
  
		return this._http.post<ITreatmentStatusUpdateResponse>(urlFinal, treatmentStatusRequest).pipe(
			map(response => {   
				return response as ITreatmentStatusUpdateResponse;
			})
		);
	}
 
 
}

 