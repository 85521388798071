import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component'; 
import { IUserDetailsData, IUserDetailsRequest, IUserProfileRequest, IUserMessageSimple } from '../../../model/api/user-profile'; 
import * as moment from 'moment';  
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { UserService } from '../../../service/api/user.service';
import { IUser } from '../../../model/api/user';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../service/spinner.service';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { IMessageDialogData } from '../../../model/base/message-dialog-data';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { UserStatus } from '../../../settings/user-status';
import { takeUntil } from 'rxjs/operators';
import { SmileboxFileService } from '../../../service/api/smilebox-file.service';
import { UserStateService } from '../../../service/state/user-state.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends DetailUserBaseComponent implements OnInit {

	moment: any = moment; 
    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 101;
    user: IUser;    
    policyBreachStatus: boolean;
     
	constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService,  private _menuService: MenuService,   private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _helper: Helper, private _cd: ChangeDetectorRef, public _dialog: MatDialog, private _spinnerService: SpinnerService, private _snackBar: MatSnackBar, protected element: ElementRef, private _smileboxFileService: SmileboxFileService) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

	ngOnInit(): void { 
        super.ngOnInit();

        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => { 
                if(!reportUserInfo) 
                    return;
 
                this.policyBreachStatus = reportUserInfo.policy_breach_status == 'PB' ? true : false;
            });
 
	} 
 

    saveUserDetails() {
        this._spinnerService.startSpinner(); 

        let requestBody:IUserDetailsRequest<IUserDetailsData> = {        
            user_details: {
                is_sensitive_user: this.userDetail.is_sensitive_user != null ? this.userDetail.is_sensitive_user : null,
                user_notes: this.userDetail.user_notes != null ? this.userDetail.user_notes : null,
                policy_breach_status: this.policyBreachStatus == true ? 'PB' : null
            }
        };
        
        this._userDetailService.putUserDetails(this.user.id, requestBody)
            .subscribe(response => { 
                  
                    if(response.success) { 
                        this._reportUserInfoService.clearCache();   
                        this.ngOnInit();                       
                    }                        

                    this._spinnerService.stopSpinner();                     			 
                },
                error =>{ error  
                    this._spinnerService.stopSpinner(); 
                }); 
    }

    smileboxOfflinePayment(): void {
        if(!confirm("Really change user status to SmileBox ordered?")) 
            return; 

        this._spinnerService.startSpinner(); 

        this._userDetailService.smileboxOfflinePayment(this.user.id)
            .subscribe(response => { 
                    if(response.success) { 
                        this._snackBar.open(response.msg, 'Close', this.matSnackBarConfig); 
                        this._reportUserInfoService.clearCache();
                        this.ngOnInit();
                    }
                    this._spinnerService.stopSpinner(); 
                },
                error =>{ error  
                    this._spinnerService.stopSpinner(); 
                }); 
           
    }

    threeDScanOfflinePayment(): void { 
        if(!confirm("Really change user status to 3D Scan Ordered?")) 
            return; 

        this._spinnerService.startSpinner(); 

        this._userDetailService.threeDScanOfflinePayment(this.user.id, {product_id : 4})
            .subscribe(response => { 
                    if(response.success) { 
                        this._snackBar.open(response.msg, 'Close', this.matSnackBarConfig); 
                        this._reportUserInfoService.clearCache();
                        this.ngOnInit();
                    }
                    this._spinnerService.stopSpinner(); 
                },
                error =>{ error  
                    this._spinnerService.stopSpinner(); 
                }); 
           
    }

    straightboxOfflinePayment(): void {
        if(!confirm("Really change User status to Aligners started?")) 
            return; 

        this._spinnerService.startSpinner(); 

        this._userDetailService.straightboxOfflinePayment(this.user.id)
            .subscribe(response => { 
                    if(response.success) { 
                        this._snackBar.open(response.msg, 'Close', this.matSnackBarConfig); 
                        this._reportUserInfoService.clearCache();
                        this.ngOnInit();
                    }
                    this._spinnerService.stopSpinner(); 			 
                },
                error =>{ error 
                    this._spinnerService.stopSpinner(); 
                }); 
    }
 
    /* getUser(): void { 
        this._spinnerService.startSpinner(); 

        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
			    this._spinnerService.stopSpinner(); 			 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
	} */
 
    updateUserStatus(messageDialogData: IMessageDialogData) {

        this._spinnerService.startSpinner(); 

        let userV1Request: IUserProfileRequest = { 
            user_details: { status: messageDialogData.status, user: this.userId },
            user_messages: [{status: messageDialogData.status, user: this.userId, text: messageDialogData.text}]
        }
 
        this._userDetailService.updateStatus(this.userId, userV1Request)
            .subscribe(response => { 
                    if(response.success) {
                        this._reportUserInfoService.clearCache(); 
                        this._smileboxFileService.clearCache();
                        this.ngOnInit(); 
                    } 
                    this._spinnerService.stopSpinner(); 			 
                },
                error =>{ error 
                    this._spinnerService.stopSpinner(); 
                }); 
    }

    changeUserStatus(statusId: number): void { 

        //let messageDialogData: IMessageDialogData = { title: statusId == UserStatus.EconsultationAccepted ? 'ACCEPTED submission' : 'REJECT submission',  user: this.userId, status: statusId, text: '', textPlaceholder: statusId == UserStatus.EconsultationAccepted ? 'Enter accepted explanation' :  'Enter reject explanation', showTextarea: true };

        let messageDialogData: IMessageDialogData = { title: statusId == UserStatus.EconsultationAccepted ? 'ACCEPTED submission' : 'REJECT submission',  user: this.user, status: statusId, text: '', textPlaceholder: statusId == UserStatus.EconsultationAccepted ? 'Enter accepted explanation' :  'Enter reject explanation', showTextarea: true, userDetail: this.userDetail, showDropDown: true, conversationTemplateCategory: statusId == UserStatus.EconsultationAccepted ? 'e-consultation-approve' : 'e-consultation-reject' };

        const dialogRef = this._dialog.open(DialogMessageComponent, {
            width: '600px',
            height: '550px',
            disableClose: false,
            data: messageDialogData
        });
    
        dialogRef.afterClosed().subscribe(result => {  
            if(result) 
                this.updateUserStatus(result);            
        });
    }
 
}

