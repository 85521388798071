<div class="dialog-container">
    <h1 mat-dialog-title>
        <strong>Show file</strong>
        <!--  COMMENT: Hide until user permissions on V2 api are available -->
        <strong style="display: none;">File description</strong>     
    </h1>
    <mat-dialog-content> 
        <p>File name: {{file.file_name}}</p> 
        <p><a href="{{file.file}}" target="_blank"><strong>Click this LINK to open</strong></a></p>
         <button mat-raised-button mat-button color="primary" (click)="onClose()" cdkFocusInitial> {{ CLOSE_BUTTON }} </button> 
    </mat-dialog-content>
    <br />
    <hr>
    <p class="warn-color">To delete this file please press delete (this is irreversible!)</p> 
    <mat-dialog-actions> 
        <button mat-raised-button mat-button color="warn" (click)="onDelete()">{{ DELETE_BUTTON }}</button> 
    </mat-dialog-actions>     
    <br /> 
</div>
