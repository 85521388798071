import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map, tap, shareReplay } from 'rxjs/operators';
import { IApiResponse, IResponse } from '../../model/api/response'; 
import { IApiObject } from '../../model/base/api-object';  
import { IApiResponseCache } from '../../model/base/response-cache';  
import { APP_CONFIG } from '../../app.config/app.config';

@Injectable({
	providedIn: 'root'
})
export class BaseService<T extends IApiObject>  {  

	// depends on assoc id = true 
	protected urlV2 = 'add_path_here';
	public _responseCache$ = new Map<string, IApiResponseCache<IApiResponse<T>>>();
    public _responseDetailCache$ = new Map<string, IApiResponseCache<T>>();
	protected _cacheValidForSeconds = 360;
    protected useCache: boolean = true;

	constructor(@Inject(APP_CONFIG) private __config, private __http: HttpClient) {}

	protected getUrl() {
		return `${this.__config.api_url}${this.urlV2}`;
	} 

    detail(id?: string, deleteCache: boolean = false) {
        let detailId:string = id != undefined || id != null ? id : '';
		let url: string = `${this.getUrl()}/${detailId}`;
        
		let responseFromCache = this._responseDetailCache$.get(url);
		const cacheDateTimeCheck = new Date(Date.now() - (this._cacheValidForSeconds * 1000));

		if (!deleteCache && responseFromCache && responseFromCache.datetime > cacheDateTimeCheck) {
			return responseFromCache.response$;
		}

		const request$ = this._detailAPI(url).pipe(shareReplay(1));
		let cachedResponse: IApiResponseCache<T> = { datetime: new Date(), response$: request$ }
		this._responseDetailCache$.set(url, cachedResponse);
		return request$;
	}

	private _detailAPI(url: string): Observable<T> {
		return this.__http.get<T>(url).pipe(
            // COMMENT: Retry disabled
			//retry(3),
			map(response => { return response as T })
            //catchError( err =>  { return this.handleErrorTest(err)})
            
		);
	}
    

	index(filter:{[key: string]: any} = null, deleteCache: boolean = false): Observable<IApiResponse<T>> {
		let url: string = this.getUrl();

		if(filter){
			let filterList = [];
			
			for (let [key, value] of Object.entries(filter)) {
                if(value == undefined)
                    continue;

				value = encodeURIComponent(value);

				filterList.push(`${key}=${value}`);
			}
			
			url = `${url}?${filterList.join('&')}`
		}

		const responseFromCache = this._responseCache$.get(url);
		const cacheDateTimeCheck = new Date(Date.now() - (this._cacheValidForSeconds * 1000));

		if (!deleteCache && responseFromCache && responseFromCache.datetime > cacheDateTimeCheck) {
			return responseFromCache.response$;
		}
		const request$ = this._indexAPI(url).pipe(shareReplay(1));
		let cachedResponse: IApiResponseCache<IApiResponse<T>> = { datetime: new Date(), response$: request$ }
		this._responseCache$.set(url, cachedResponse);
		return request$;
	}

	private _indexAPI(url: string): Observable<IApiResponse<T>> {
		return this.__http.get<IApiResponse<T>>(url).pipe(
            //COMMENT: Retry disabled 
			//retry(3),
			map(response => { return response as IApiResponse<T> }),
            //catchError( err =>  { return this.handleErrorTest(err)}) 
		);
	}
	

	save(data: T,  formData?: FormData, upldateId?: number): Observable<T> {

        let id = upldateId > 0 ? upldateId : data.id;

		if (id) {
			return this.update(data, formData, id);
		}

		return this.create(data, formData);
	}

	update(data: T,  formData?: FormData, id?: number) {
		let url = `${this.__config.api_url}${this.urlV2}/${id}`;
        let objectData: any = formData != null && formData != undefined ? formData : data;

		return this.__http.put<T>(url, objectData).pipe(
			map(response => {
                this._responseDetailCache$.clear();
				this._responseCache$.clear();
				return response as T;
			})
		);
	}

	create(data: T,  formData?: FormData) {

        this.urlV2 = data && (data.id != null && data.id != undefined) ? this.urlV2 + '/' + data.id.toString() : this.urlV2;
		let url = `${this.__config.api_url}${this.urlV2}`; 
        let objectData: any = formData != null && formData != undefined ? formData : data;

		return this.__http.post<T>(url, objectData).pipe(
			map(response => {
                this._responseDetailCache$.clear();
				this._responseCache$.clear();
				return response as T;
			})
		);
	}

    delete(data: T) {
		let url = `${this.__config.api_url}${this.urlV2}/${data.id}`;  

		return this.__http.delete<T>(url).pipe(
			map(response => {
                this._responseDetailCache$.clear();
				this._responseCache$.clear();
				return response;
			})
		);
	}

	clearCache(){
		this._responseDetailCache$.clear();
		this._responseCache$.clear();
	}

    /* saveFormData(data: T, formData: FormData): Observable<T> {

		if (data && data.id) {
			return this.updateFormData(data, formData);
		}

		return this.createFormData(data, formData);
	}

	updateFormData(data: T, formData: FormData) {

		let url = `${this.__config.api_url}${this.urlV2}/${data.id}`; 

		return this.__http.put<T>(url, formData).pipe(
			map(response => {

				this._responseCache$.clear();
				return response as T;
			})
		);
	}

	createFormData(data: T, formData: FormData) {

		let url = `${this.__config.api_url}${this.urlV2}`; 

		return this.__http.post<T>(url, formData).pipe(
			map(response => {

				this._responseCache$.clear();
				return response as T;
			})
		);
	} */

	
 
    private handleErrorTest(httpError:any) {
 
        /* if(httpError.status == 404)
            return throwError('404 error returned'); */
        let errorMessage = httpError;       

        return throwError(errorMessage);
 
    }
	


}