<mat-card> 
    <mat-card-content>
        <div fxLayout="row" fxFlexFillfxLayoutGap="20px" *ngIf="navigationSettings">
            <div fxFlex="100" fxLayoutAlign="end start"> 
                <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.url + '/' + ADD_NEW_LINK">{{ ADD_NEW }}</a>
            </div>
        </div>
    </mat-card-content>
</mat-card>
 
<div class="mat-table-holder" *ngIf="appBannerNotificationFilterSettings && appBannerNotificationFilterSettings.displayedColumns">  
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">        

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
            <td mat-cell *matCellDef="let element"> {{ element.id.toString() }} 
                <a fxFlexFill [routerLink]="[navigationSettings.url, element.id.toString()]" class="mat-row-link"> </a> 
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title</th>
            <td mat-cell *matCellDef="let element"> {{ element.title }} 
                <a fxFlexFill [routerLink]="[navigationSettings.url, element.id.toString()]" class="mat-row-link"> </a> 
            </td>
        </ng-container>

        <ng-container matColumnDef="visible_from">
            <th mat-header-cell *matHeaderCellDef> Visible from </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.visible_from_date">{{ moment(element.visible_from_date).format('DD.MM.Y') }}</span></td>
        </ng-container>

        <ng-container matColumnDef="visible_to">
            <th mat-header-cell *matHeaderCellDef> Visible to </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.visible_to_date">{{ moment(element.visible_to_date).format('DD.MM.Y') }}</span></td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Active </th>
            <td mat-cell *matCellDef="let element"> {{ element.active ? 'Yes' : 'No' }} </td>
        </ng-container>
 
        <tr mat-header-row *matHeaderRowDef="appBannerNotificationFilterSettings.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: appBannerNotificationFilterSettings.displayedColumns"></tr> 
    </table> 
    <mat-paginator showFirstLastButtons [pageIndex]='appBannerNotificationFilterSettings.paging.pageIndex-1' [length]='appBannerNotificationFilterSettings.paging.length' [pageSize]='appBannerNotificationFilterSettings.paging.pageSize' [pageSizeOptions]="[5, 10, 20]" (page)="onPaginateChange($event)"></mat-paginator>
</div>
 