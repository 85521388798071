
<mat-nav-list>
    <a mat-list-item>
        <mat-icon (click)="menuClickChanged.emit()" >menu</mat-icon> 
    </a>
    <!-- [class.selected]="(settings | async).navigationSettings.mainMenuUrl  === 'user-submissions'" -->
    <a mat-list-item [routerLink] = "['/admin/user']" [routerLinkActive]="['selected']" >
        <mat-icon mat-list-icon>list</mat-icon> <span matLine>User submissions</span>
    </a>
    <a mat-list-item [routerLink] = "['/admin/message']" [routerLinkActive]="['selected']">
        <mat-icon mat-list-icon>message</mat-icon> <span matLine>Messages</span>
    </a>
    <a mat-list-item [routerLink] = "['/admin/notification-center']" *appUserPermissionCheck="['app.bannernotification.add_bannernotification']">
        <mat-icon mat-list-icon>edit_notifications</mat-icon> <span matLine>Notification center</span>
    </a>
    <a mat-list-item [routerLink] = "['/admin/user-export']" *appUserPermissionCheck="['app.userexport.add_userexport']">
        <mat-icon mat-list-icon>assignment</mat-icon> <span matLine>User export</span>
    </a>
    <!--
        <a mat-list-item (click)="exportUsersToCsv()" *appUserPermissionCheck="['app.userexport.add_userexport']">
        <mat-icon mat-list-icon>assignment</mat-icon> <span matLine>Export all</span>
    </a>
    -->
   <!--  <a mat-list-item [routerLink] = "['/admin/messages-inbox']">
        <mat-icon mat-list-icon>local_post_office</mat-icon> <span matLine>Messages inbox</span>
    </a>
    <a mat-list-item [routerLink] = "['/admin/smilebox-orders']">
        <mat-icon mat-list-icon>drafts</mat-icon> <span matLine>Smilebox orders</span>
    </a>
    <a mat-list-item [routerLink] = "['/admin/treatment-plan']">
        <mat-icon mat-list-icon>event</mat-icon> <span matLine>Treatment plan processing</span>
    </a>
    <a mat-list-item [routerLink] = "['/admin/straightbox-orders']">
        <mat-icon mat-list-icon>drafts</mat-icon> <span matLine>Straightbox orders</span>
    </a> -->
    <a mat-list-item  (click)="Logout()">
        <mat-icon mat-list-icon>power_settings_new</mat-icon> <span matLine>Log out</span>
    </a>
</mat-nav-list>
<!-- <div *ngIf="settings">
    {{ settings.mainMenuSettings.pageTitle }}
</div> -->

<!-- <div class="bottom-menu">
    <a  (click)="Logout()">Log out</a>
</div> -->
