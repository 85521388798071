<div fxLayout="row" fxFlexFill fxLayoutGap="20px" *ngIf="navigationSettings"> 
    <div fxFlex="100" class="button-box space-right" fxLayoutAlign="end start">
        <button mat-raised-button mat-button color="primary" (click)="downloadAllUploadsAndZip()">Download all uploads in a zip</button>
        <a mat-raised-button mat-button color="primary" [routerLink]="navigationSettings.url + '/' + IMAGE_UPLOAD_LINK">Upload images</a>
    </div>
</div>
<br />  
<div class="file-wrap">
    <h3>E-CONSULTATION UPLOADS</h3>
    <mat-list *ngIf="userPictures && userPictures.length > 0">
        <mat-grid-list cols="{{ imageBreakpoint }}" rowHeight="160px">
            <mat-grid-tile *ngFor="let userPicture of userPictures; let i = index;" >
                <a [ngClass]="userPicture.isImage ? 'image-box' : 'file-box'">
                    <img (click)="openLightbox(userPictures, i)" *ngIf="userPicture.isImage" src="{{ userPicture.image }}">  
                    <span class="title" *ngIf="!userPicture.isImage && userPicture.prettyName" >{{userPicture.prettyName.substr(0, 100)}}</span>
                    <mat-icon class="file-icon" mat-list-icon *ngIf="userPicture.isPDF" >{{ topic }}</mat-icon> 
                    <span class="ad-before-after" (click)="addBeforeAfterImage(userPicture, USER_PICTURES_MODEL, ADD_BEFORE_FILE_TYPE); $event.preventDefault()" *ngIf="reportUserInfo && userStatusHelper.isAlignersStarted(reportUserInfo.status) && userPicture.isImage">
                        <i class="material-icons">add</i>
                    </span>
                    <span class="rotate" *ngIf="userPicture.isImage">
                        <mat-icon class="left" mat-list-icon (click)="rotateImage(userPicture, USER_PICTURES_ROTATION_MODEL, ROTATE_IMAGE_LEFT); $event.preventDefault()">rotate_left</mat-icon> 
                        <mat-icon class="right" mat-list-icon (click)="rotateImage(userPicture, USER_PICTURES_ROTATION_MODEL, ROTATE_IMAGE_RIGHT); $event.preventDefault()">rotate_right</mat-icon> 
                    </span> 
                </a> 
            </mat-grid-tile>
      </mat-grid-list>
    </mat-list>

    <h3>CONVERSATION UPLOADS</h3>
    <mat-list *ngIf="conversationFiles && conversationFiles.length > 0">
        <mat-grid-list cols="{{ imageBreakpoint }}" rowHeight="160px">
            <mat-grid-tile *ngFor="let conversationFile of conversationFiles; let i = index;">
                <a [ngClass]="conversationFile.isImage ? 'image-box' : 'file-box'">
                    <img (click)="openLightbox(conversationFiles, i)" *ngIf="conversationFile.isImage" src="{{ conversationFile.file }}">
                    <span (click)="openLightbox(conversationFiles, i)" class="title" *ngIf="!conversationFile.isImage && conversationFile.prettyName" >{{conversationFile.prettyName.substr(0, 100)}}</span>
                    <mat-icon (click)="openLightbox(conversationFiles, i)" class="file-icon" mat-list-icon *ngIf="!conversationFile.isImage" >{{ conversationFile.isPDF ? 'topic' : 'videocam' }}</mat-icon> 
                    <span class="file-by-admin" *ngIf="conversationFile.message__user__user_type_id != null && conversationFile.message__user__user_type_id !== 3">
                        <mat-icon [color]="'accent'">account_circle</mat-icon>
                    </span>                    
                    <span class="ad-before-after" (click)="addBeforeAfterImage(conversationFile, CONVERSATION_FILES_MODEL, ADD_AFTER_FILE_TYPE); $event.preventDefault()" *ngIf="reportUserInfo && userStatusHelper.isAlignersStarted(reportUserInfo.status) && conversationFile.isImage">
                        <i class="material-icons">add</i>
                    </span>
                    <span class="rotate" *ngIf="conversationFile.isImage">
                        <mat-icon class="left" mat-list-icon (click)="rotateImage(conversationFile, CONVERSATION_FILES_ROTATION_MODEL, ROTATE_IMAGE_LEFT); $event.preventDefault()">rotate_left</mat-icon> 
                        <mat-icon class="right" mat-list-icon (click)="rotateImage(conversationFile, CONVERSATION_FILES_ROTATION_MODEL, ROTATE_IMAGE_RIGHT); $event.preventDefault()">rotate_right</mat-icon> 
                    </span>   
                </a> 
            </mat-grid-tile>
      </mat-grid-list>
    </mat-list>
 
</div>