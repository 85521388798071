
<mat-card *ngIf="rootMenu && settings"> 
    <mat-card-content>
        <div *ngIf="settings.navigationSettings">
            <span *ngFor="let subMenu of rootMenu.child; let i = index">  
               <button type="button" aria-label="Toggle sidenav" mat-button (click)="router.navigate([url, subMenu.url]);" [color]="subMenu.id == settings.navigationSettings.pageId || subMenu.id == settings.navigationSettings.parentId ? 'accent' : 'black' ">{{subMenu.name}}</button> 
            </span> 
        </div> 
    </mat-card-content>
</mat-card>

 