
<mat-card *ngIf="userExports"> 
    <mat-card-content style="text-align: right;"> 
        <div *ngIf="navigationSettings" fxLayoutAlign="end start">  
            <button mat-raised-button mat-button color="primary" (click)="saveUserExports()">Request new export</button> 
        </div> 
    </mat-card-content>
</mat-card>

<div class="mat-table-holder" *ngIf="userExports">  
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">        

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
            <td mat-cell *matCellDef="let element"> {{ element.id.toString() }} 
                <a fxFlexFill [routerLink]="[navigationSettings.url, element.id.toString()]" class="mat-row-link"> </a> 
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> Created at</th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.created_at">{{ moment(element.created_at).format('DD.MM.Y HH:mm:ss') }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="is_processing">
            <th mat-header-cell *matHeaderCellDef> Is processing </th>
            <td mat-cell *matCellDef="let element">{{ element.is_processing ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="is_done">
            <th mat-header-cell *matHeaderCellDef> Is done </th>
            <td mat-cell *matCellDef="let element">{{ element.is_done ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Link </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="!_helper.isEmptyOrNull(element.file)">
                    <a href="{{ element.file }}">{{ element.prettyName }} </a>
                </span>                
            </td>
        </ng-container>
 
        <tr mat-header-row *matHeaderRowDef="filterSettings.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: filterSettings.displayedColumns"></tr> 
    </table> 
</div> 
 