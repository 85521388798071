import { Component, OnInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component'; 
import * as moment from 'moment';  
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service'; 
import { ISmilebox } from '../../../model/api/smilebox';
import { AppConstants } from '../../../settings/app-constants';
import { SmileboxService } from '../../../service/api/smilebox.service'; 
import { OrderStatus } from '../../../settings/order-status';
import { UserDetailService } from '../../../service/api/user-detail.service'; 
import { SpinnerService } from '../../../service/spinner.service'; 
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { takeUntil } from 'rxjs/operators';
import { IFileType } from '../../../model/api/file-type';
import { IFile, IFileDeleteDialog } from '../../../model/api/file';
import { ISmileBoxFiles } from '../../../model/api/smile-box-files';
import { FileType } from '../../../settings/file-type';
import { UserType } from '../../../settings/user-type';
import { forkJoin } from 'rxjs';
import { AdminFileService } from '../../../service/api/admin-file.service';
import { FileTypeService } from '../../../service/api/file-type.service';
import { SmileboxFileService } from '../../../service/api/smilebox-file.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteFileDialogComponent } from '../treatment-plan-processing/delete-file-dialog/delete-file-dialog.component';
import { UserStatusHelper } from '../../../util/user-status-helper.util';
import { UserService } from '../../../service/api/user.service';
import { IUser } from '../../../model/api/user';
import { ConversationService } from '../../../service/api/conversation.service';
import { UserStateService } from '../../../service/state/user-state.service';
import { HelperExtension } from 'src/app/util/helper.extension';

@Component({
  selector: 'app-smilebox-order',
  templateUrl: './smilebox-order.component.html',
  styleUrls: ['./smilebox-order.component.scss']
})
export class SmileboxOrderComponent extends DetailUserBaseComponent implements OnInit {

	moment: any = moment;
    smilebox: ISmilebox;
    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 106; 
    readonly SCAN_REFERRAL_DONE:string = AppConstants.SCAN_REFERRAL_DONE;
    readonly SHIPPED:string = AppConstants.SHIPPED;
    orderStatus = OrderStatus; 
    readonly IMPRESSIONS_RECEIVED:string = AppConstants.IMPRESSIONS_RECEIVED; 
    readonly DIGITAL_IMPRESSIONS_RECEIVED:string = AppConstants.DIGITAL_IMPRESSIONS_RECEIVED; 
    readonly ADD_NEW:string = AppConstants.ADD_NEW; 
    readonly RECORDS_LEGACY_UPLOADS: string = AppConstants.RECORDS_LEGACY_UPLOADS;
    fileTypes: IFileType[];
    files: IFile[]; 
    smileboxFiles: ISmileBoxFiles[];
    FileType = FileType;
    userType = UserType;
    user: IUser;
    videoExtensions: string = this._helperExtension.videoAsExtensions();
    allExtensions: string = this._helperExtension.allAsExtensions();
    
    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _smileboxService: SmileboxService, private _spinnerService: SpinnerService, private _route: ActivatedRoute, private _location: Location, private _helper: Helper, private _cd: ChangeDetectorRef, private _adminFileService: AdminFileService, private _fileTypeService: FileTypeService, private _smileboxFileService: SmileboxFileService, public _dialog: MatDialog, private userStatusHelper: UserStatusHelper, protected element: ElementRef, private _conversationService: ConversationService, private _helperExtension: HelperExtension) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
		super.ngOnInit();

        //this.getUser();

        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => {
                this.reportUserInfo = reportUserInfo;
                this.getSmilebox();
                this.getFilesAndFileTypes();
            });
		
    }

	getSmilebox(deleteCache: boolean = false): void { 

        if(!this.reportUserInfo || !this.userStatusHelper.isSmileBoxOr3DScanOrdered(this.reportUserInfo.status))
            return; 
            
        this._spinnerService.startSpinner(); 
        this._smileboxService.detail(this.userId.toString(), deleteCache)
			.subscribe(smilebox => {
				this.smilebox = smilebox; 
                this.smilebox.created_at = !this._helper.isNullOrUndefined(this.smilebox.created_at) ? moment.utc(this.smilebox.created_at).toDate() : null;
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getFilesAndFileTypes(deleteCache: boolean = false) {
        
        this._spinnerService.startSpinner();
        forkJoin([this._fileTypeService.index(), this._adminFileService.index({user : this.userId}, deleteCache), this._smileboxFileService.index({user_id : this.userId}, deleteCache)]).subscribe(fileItems => {
            this.fileTypes = [];
            this.files = [];
            this.fileTypes = fileItems[0].results;
            this.files = fileItems[1].results; 
            this.smileboxFiles = fileItems[2].results; 
 
            if(!this.fileTypes || !this.files || !this.smileboxFiles)
                return; 
            
            this.fileTypes.forEach(fileType => {
                let numberOfFiles: Number = this.files.filter(item => item.file_type == fileType.id || (item.file_type == null && fileType.id == 9)).length;                 
                fileType.has_files = numberOfFiles > 0 ? true : false;
                fileType.max_number_of_files_reached = fileType.max_number_of_files > 0 && fileType.max_number_of_files >= numberOfFiles;
                fileType.file_upload_enabled = fileType.admin_can_upload && (fileType.max_number_of_files == null || fileType.max_number_of_files > numberOfFiles);
            })

            let hasRecordsLegacyUploads = this.fileTypes.find(x => x.name === this.RECORDS_LEGACY_UPLOADS);
            this._spinnerService.stopSpinner(); 
            if(hasRecordsLegacyUploads)
                return;
            // TODO: I haven't checked if "Records legacy uploads" works, because I haven't found user that has that data filled. Check with Car.
            this.fileTypes.push({ admin_can_upload: false, file_upload_enabled: false, has_files: false, id: 9, max_number_of_files: null, max_number_of_files_reached: false, name: this.RECORDS_LEGACY_UPLOADS, show_to_admin: true, show_to_user: true, user_can_upload: true });
 
        });
    }

    openDeleteFileDialog(file: IFile): void { 
        let deleteFileDialogData: IFileDeleteDialog = { userId: this.userId, file: file }
        const dialogRef = this._dialog.open(DeleteFileDialogComponent, {
            width: '600px',
            height: '600px',
            disableClose: true,
            data: deleteFileDialogData
        });
    
        dialogRef.afterClosed().subscribe(result => {  
            if(result) 
				this.getFilesAndFileTypes(true);
            
        });
    }

    addNewUploadFile(event: any, fileType: IFileType): void {
        this._spinnerService.startSpinner(); 
        let uploadFiles: File[] = [];
        uploadFiles.push(event.target.files[0]);
 
		if(this._helper.isNullOrUndefined(uploadFiles) )      
            return;
        

		let formData: FormData = new FormData();
        
		uploadFiles.forEach(file => { 
			formData.append('file', file);
			} 
		); 

		this._adminFileService.saveFile(this.userId, fileType, formData).subscribe(response => { 
   
			if(response.success) {  
				this.getFilesAndFileTypes(true);
			} 
            this._spinnerService.stopSpinner(); 
		},
		error =>{ error  
            this._spinnerService.stopSpinner(); 
		}); 

	}

    sendImpressionsDate(): void {
        this._spinnerService.startSpinner(); 
		this._smileboxService.sendShipDate(this.userId).subscribe(response => {
                if(response.success) {
                    this._reportUserInfoService.clearCache(); 
                    this._conversationService.clearCache(); 
                    this.ngOnInit();
                    this._spinnerService.stopSpinner(); 
                }
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	  
	}	

	bitePhotos(): void {
        this._smileboxService.bitePhotosReceived(this.userId, {bite_photos_received: this.smilebox.bite_photos_received.toString()}).subscribe(response => {
            if(response.success)
                this.ngOnInit();
            },
            error =>{ error  
            } 
        );
	}

	shipArchive(orderStatus: number): void {
        this._spinnerService.startSpinner(); 
        this._smileboxService.updateSmilebox(this.userId, { status: orderStatus, tracking_number: this.smilebox.tracking_number })
            .subscribe(order => { 
                this._reportUserInfoService.clearCache(); 
                this._conversationService.clearCache();
                this.getSmilebox(true);
                this.ngOnInit();
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    /* getUser(): void { 
        this._userService.detail(this.userId.toString())
			.subscribe(user => {
				this.user = user; 
			});
	} */

}
