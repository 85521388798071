import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppConstants } from 'src/app/settings/app-constants';
import { HelperExtension } from 'src/app/util/helper.extension';
import { Helper } from 'src/app/util/helper.util';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @Input() isMultiple: boolean = true; 
    @Input() allowedExtensions: Array<string> = this._helperExtension.all();
    @Input() buttonTitle: string = AppConstants.UPLOAD;
	@Output() fileChange = new EventEmitter(); 
    allowedUploadExtensions: string;
    filesToUpload: any[] = []; 
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
 
    
    constructor(private _cd: ChangeDetectorRef, private _zone: NgZone, private _snackBar: MatSnackBar, private _helperExtension: HelperExtension, private _helper: Helper) { }

    ngOnInit(): void { 
        this.allowedUploadExtensions = this._helperExtension.getAsExtensions(this.allowedExtensions);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("allowedExtensions")){          
            this.allowedExtensions = changes.allowedExtensions.currentValue;

            if(this._helper.isNullOrUndefined(this.allowedExtensions))
                return;

            this.allowedUploadExtensions = this._helperExtension.getAsExtensions(this.allowedExtensions);
        }

    }

    addFiles(event: any){  
 
        let fileList = [];
        let tempFileList = event.target.files;
        
       // let disallowedFileExtensions: string[] = [];

        if(!tempFileList || tempFileList.length == 0)
            return;
        
        for (var iter = 0; iter < tempFileList.length; iter++) {  
            /* var fileExtension = tempFileList[iter].name.split('.').pop().toLowerCase();
            if(!this.allowedExtensions.find(x => x === fileExtension)) {
                disallowedFileExtensions.push(fileExtension);
                continue;
            }   */
            fileList.push(tempFileList[iter]); 
        } 

        if(!fileList) 
            return;

        /* if(disallowedFileExtensions && disallowedFileExtensions.length > 0) {
            let errorMessage: string = disallowedFileExtensions.length > 1 ? `File type ${disallowedFileExtensions} are not allowed for upload.` : `File type ${disallowedFileExtensions} is not allowed for upload.`;
            this._snackBar.open(errorMessage, 'Close', this.matSnackBarConfig); 
        } */

        this.readFiles(fileList);
        event.target.value = '';
    }

    fileReadAndLoaded(result, index, files){
        let tmpFile = files[index];
        let tmpFileType = tmpFile.type.split('/')[0];
        
        let base64File = result.target.result;
        if(!this.isMultiple) {
            this.filesToUpload = [];
        }
        this.filesToUpload.push({"id":index,'base':base64File,'name':tmpFile.name,'type':tmpFileType,'file':tmpFile});

		this.fileChange.emit(this.filesToUpload);
		
        this.readFiles(files, index+1);// Read the next file;
    }

    readFiles(files, index=0){
        let me = this;
        // If there is no file
        if (!(index in files)) {
            this._cd.detectChanges();
            return;
        }
            
        // Hack for cordova/zone.js override bug for FileReader
        try{
            let FileReader: new() => FileReader = ((window as any).FileReader as any).__zone_symbol__OriginalDelegate;
            var reader = new FileReader();
        } catch(error){
            var reader = new FileReader();
        }
 
        let tmpFile = files[index];

        var fileExtension = tmpFile.name.split('.').pop().toLowerCase();
 
        if(!this.allowedExtensions.find(x => x === fileExtension)) { 
            this.readFiles(files, index+1);// Read the next file;
        }  else {
            reader.readAsDataURL(tmpFile);
        }

        reader.onload = (result) => {
            this._zone.run(() => {
                me.fileReadAndLoaded(result, index, files);
            });
        }; 
    }

    deleteFile(index){ 
        this.filesToUpload.splice(index,1);
        this.fileChange.emit(this.filesToUpload);
    }
}
