import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationCenterComponent } from './notification-center.component';
import { AppBannerNotificationComponent } from './app-banner-notification/app-banner-notification.component';
import { MaintenanceAnnouncementComponent } from './maintenance-announcement/maintenance-announcement.component';
import { MessageBroadcastComponent } from './message-broadcast/message-broadcast.component';
import { AppBannerNotificationDetailComponent } from './app-banner-notification/detail/app-banner-notification-detail.component';
import { MaintenanceAnnouncementDetailComponent } from './maintenance-announcement/detail/maintenance-announcement-detail.component';
import { SecurityGuard } from 'src/app/service/security/security-guard.service';



const routesNotificationCenter: Routes = [
	{ 
        path: '',  component: NotificationCenterComponent, 
		children: [
            { path: '', redirectTo: 'app-banner-notification', pathMatch: 'full' }, 
            { path: 'app-banner-notification', component: AppBannerNotificationComponent, canActivate: [SecurityGuard]  },
            { path: 'app-banner-notification/add-new', component: AppBannerNotificationDetailComponent, canActivate: [SecurityGuard]  },
            { path: 'app-banner-notification/:id', component: AppBannerNotificationDetailComponent, canActivate: [SecurityGuard]  },
            { path: 'maintenance-announcement', component: MaintenanceAnnouncementComponent, canActivate: [SecurityGuard]  },
            { path: 'maintenance-announcement/add-new', component: MaintenanceAnnouncementDetailComponent, canActivate: [SecurityGuard]  },
            { path: 'maintenance-announcement/:id', component: MaintenanceAnnouncementDetailComponent, canActivate: [SecurityGuard]  },
            { path: 'message-broadcast', component: MessageBroadcastComponent, canActivate: [SecurityGuard] },
            { path: '**', redirectTo: '/login' },
        ]
	},
];

@NgModule({ 
    imports: [RouterModule.forChild(routesNotificationCenter)],
	exports: [RouterModule]
})
export class NotificationCenterRoutingModule { }
