
<div fxLayout="column" fxFlexFill fxLayoutGap="20px" fxLayoutAlign="center center"> 
    <form class="login-form" (ngSubmit)="login();" #loginForm="ngForm" autocomplete="off">
        <mat-card>
            <mat-card-header style="text-align: center;">
                <img src="../../assets/images/logo-top-bar.svg"> 
            </mat-card-header> 
            <mat-card-content style="text-align: center;">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="loginData.email" name="email" placeholder="Email" required (keyup)="loginData.email=loginData.email.trim()">
                </mat-form-field>            
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" [(ngModel)]="loginData.password" name="password" placeholder="Password" required>
                </mat-form-field> 
                <button mat-flat-button type="submit" color="primary" [disabled]="!loginForm.form.valid">LOGIN</button> 
            </mat-card-content>
            <mat-card-footer>
                {{ errorMessage }}
            </mat-card-footer> 
        </mat-card> 
    </form>
</div>
 
 