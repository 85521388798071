import {Component, ViewChild, HostListener, ChangeDetectorRef} from '@angular/core';
import {retry, takeUntil} from 'rxjs/operators';	
import {Subject, interval } from "rxjs";
import {SpinnerService} from "./service/spinner.service";
import {SettingsService} from "./service/state/settings.service";
import {ISettings,Settings} from "./model/settings";
import {SecurityService} from "./service/security/security.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { "(window:resize)": "onResize($event)"}
})

export class AppComponent {
	runSpinner: boolean;
	private ngUnsubscribe: Subject<any> = new Subject();
	settings: ISettings;

	constructor(private _spinnerService: SpinnerService, private _settingsService: SettingsService, private _securityService: SecurityService, private _cd: ChangeDetectorRef, ) { }

	ngOnInit() {
		//console.log('AppComponent -> ngOnInit()');		
		
		this._settingsService.setSettingsUserLogged(this._securityService.isLoggedIn());
		this._settingsService.setSettingsIsMobileTablet(this.isMobileTablet());

		this.runSpinner = false; 

		this._spinnerService.spinnerStatusChange
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async(runSpinner) => {
                this.runSpinner = runSpinner;
                this._cd.detectChanges();
			});

	}
 
    onResize(event) { 
        this._settingsService.setSettingsIsMobileTablet(this.isMobileTablet());
	}
    
    isMobileTablet(): boolean {
        if (window.innerWidth < 1024) { 
            return false; 
		} 

		return true;
    }

    ngOnDestroy(): any { 
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    } 
  
}