<div class="dialog-container" *ngIf="data"> 
    
    <div fxLayout="column" fxFlexFill>  
        <div fxFlex="100" style="margin-bottom: 20px;">
            <div fxLayout="row" fxFlexFill fxLayout.xs="column" >  
                <div fxFlex="50" fxLayout.xs="column">
                    <h1 mat-dialog-title *ngIf="!userStatusHelper.isTreatmentFinished(data.status)">Update progress status</h1> 
                    <h1 mat-dialog-title *ngIf="userStatusHelper.isTreatmentFinished(data.status)">Treatment progress history</h1>
                </div>
                <div fxFlex="50" fxLayout.xs="column">
                    <mat-dialog-actions align="end">
                        <button *ngIf="data.closeButton" mat-raised-button mat-button color="primary" mat-dialog-close>Close</button> 
                        <button *ngIf="data.saveButton && !userStatusHelper.isTreatmentFinished(data.status)" mat-raised-button mat-button color="primary" (click)="saveProgressStatus()">Save</button>
                    </mat-dialog-actions>
                </div>
            </div>
        </div>
        <div fxFlex="100">
            <p *ngIf="userStatusHelper.isTreatmentFinished(data.status)">Review Aligner Progress</p>
            <p *ngIf="!userStatusHelper.isTreatmentFinished(data.status)">
                Please select the % according to how far the patient is currently in their treatment progress. <br />
                Please use the text box to add your clinical notes.
            </p>
        </div>  
        <div fxFlex="100" *ngIf="treatmentPercentages && treatmentStatusRequest && !userStatusHelper.isTreatmentFinished(data.status)">
            <mat-form-field style="width: 50%;">  
                <mat-select [(ngModel)]="treatmentStatusRequest.percentage" name="treatmentPercentage" placeholder="Choose">  
                    <mat-option [value]="0">Choose</mat-option>                  
                    <mat-option *ngFor="let treatmentPercentage of treatmentPercentages" [value]="treatmentPercentage.id.toString()">
                        {{ treatmentPercentage.percentage }} %
                    </mat-option>
                </mat-select>  
            </mat-form-field> 
        </div>
        <div fxFlex="100" *ngIf="treatmentStatusRequest && !userStatusHelper.isTreatmentFinished(data.status)">
             <mat-form-field class="example-full-width">
                <mat-label>Leave note</mat-label>
                <textarea matInput [(ngModel)]="treatmentStatusRequest.description" rows="5" placeholder="Leave note"></textarea>
            </mat-form-field>  
        </div>
        <div fxFlex="100" *ngIf="treatmentProgresses" class="userProces">
            <mat-list *ngFor="let treatmentProgress of treatmentProgresses; let i = index">
                <mat-list-item>
                    <div fxLayout="column" fxLayout.xs="column" fxFlexFill>    
                        <div fxFlex="100">
                            <div class="accent-color">{{ treatmentProgress.percentage__percentage }} %</div>
                            <p *ngIf="treatmentProgress.created_at">{{ moment(treatmentProgress.created_at).format('DD.MM.Y') }}<br />
                            {{ treatmentProgress.description }} </p>
                        </div> 
                    </div> 
                </mat-list-item> 
                <mat-divider></mat-divider>
            </mat-list>
        </div>
    </div>
    
</div>
