import { NgModule } from '@angular/core';
import { AppBannerNotificationFilterStateService } from '../../service/state/app-banner-notification-filter-state.service';
import { MaintenanceAnnouncementFilterStateService } from '../../service/state/maintenance-announcement-filter-state.service'; 
import { NotificationCenterComponent } from '../notification-center/notification-center.component';
import { AppBannerNotificationComponent } from '../notification-center/app-banner-notification/app-banner-notification.component';
import { MaintenanceAnnouncementComponent } from '../notification-center/maintenance-announcement/maintenance-announcement.component';
import { MessageBroadcastComponent } from '../notification-center/message-broadcast/message-broadcast.component';
import { AppBannerNotificationService } from '../../service/api/app-banner-notification.service';
import { MaintenanceAnnouncementService } from '../../service/api/maintenance-announcement.service';
import { MessageBroadcastService } from '../../service/api/message-broadcast.service';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter'; 
import { AppBannerNotificationDetailComponent } from '../notification-center/app-banner-notification/detail/app-banner-notification-detail.component';
import { MaintenanceAnnouncementDetailComponent } from '../notification-center/maintenance-announcement/detail/maintenance-announcement-detail.component';
import { NotificationCenterRoutingModule } from '../notification-center/notification-center-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StatusService } from '../../service/status.service';
import { CustomCoreModule } from '../../custom-core.module';

@NgModule({
    declarations: [ 
        NotificationCenterComponent,
        AppBannerNotificationComponent,
        MaintenanceAnnouncementComponent,
        MessageBroadcastComponent, 
        AppBannerNotificationDetailComponent,
        MaintenanceAnnouncementDetailComponent
        ],
    exports:  [ ],
    imports: [
        CustomCoreModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule, 
        NotificationCenterRoutingModule,
        SharedModule
    ],
    providers: [ 
        AppBannerNotificationFilterStateService,    
        MaintenanceAnnouncementFilterStateService,
        AppBannerNotificationService,
        MaintenanceAnnouncementService,
        MessageBroadcastService,
        StatusService
    ]
})
export class NotificationCenterModule { }
