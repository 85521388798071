import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class SpinnerService {
    spinnerStatusChange: Subject<boolean> = new Subject<boolean>();

    startSpinner(): void {
        this.spinnerStatusChange.next(true);
    }

    stopSpinner(): void {
        this.spinnerStatusChange.next(false);
    }
}
