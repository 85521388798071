import { Component, OnInit, Inject } from '@angular/core'; 
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { IConversationTemplate } from 'src/app/model/api/conversation-template';
import { ConversationTemplateService } from 'src/app/service/api/conversation-template.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { HelperReplaceVariablesInText } from 'src/app/util/helper.replace.variables.in.text';
import { Helper } from 'src/app/util/helper.util';
import { IMessageDialogData } from '../../../model/base/message-dialog-data';


@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent implements OnInit {

    conversationTemplates: IConversationTemplate[];
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: IMessageDialogData, private _spinnerService: SpinnerService, private _conversationTemplateService: ConversationTemplateService, private _helper: Helper, private _helperReplaceVariablesInText: HelperReplaceVariablesInText) {}

    ngOnInit(): void {
        this.getConversationTemplates();
    }

    getConversationTemplates(): void { 

        if(!this.data.showDropDown)
            return;

        this._spinnerService.startSpinner(); 
        this._conversationTemplateService.index({ category: this.data.conversationTemplateCategory })
			.subscribe(conversationTemplates => {  
                this.conversationTemplates = conversationTemplates.results;
                this._spinnerService.stopSpinner(); 
			},
            error =>{  
                this._spinnerService.stopSpinner(); 
            });
	}  

    async changeTemplate(event: MatSelectChange) { 

        if(this._helper.isNullOrUndefined(event.value))
            return;

        this.data.text = await this._helperReplaceVariablesInText.replace(event.value.text, this.data.user, this.data.userDetail);

	}

}
