import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IConversationMessageFile, IConversationMessageFilePreview } from 'src/app/model/api/conversation-message-file';
import { IFile } from 'src/app/model/api/file';
import { IReportUserInfo } from 'src/app/model/api/report-user-info';
import { IMessageResponse } from 'src/app/model/api/response';
import { StraightboxService } from 'src/app/service/api/straightbox.service';
import { UserFileService } from 'src/app/service/api/user-file.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { UserStatus } from 'src/app/settings/user-status';
import { Helper } from 'src/app/util/helper.util';
import { UserStatusHelper } from 'src/app/util/user-status-helper.util';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
	
	@Input() conversationMessageFilesPreview: IConversationMessageFilePreview[]; 
	@Input() userId: number; 
	@Input() reportUserInfo: IReportUserInfo = null;
    @Input() canAddAfterImage: boolean = false;
    userStatus = UserStatus;
	readonly CONVERSATION_FILES_MODEL:string = AppConstants.CONVERSATION_FILES_MODEL; 
    readonly ADD_AFTER_FILE_TYPE:string = AppConstants.ADD_AFTER_FILE_TYPE;
	matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};
	
    constructor(private _spinnerService: SpinnerService, private _userFileService: UserFileService, private _straightboxService: StraightboxService, private _helper: Helper, private _snackBar: MatSnackBar, public userStatusHelper: UserStatusHelper) { }

    ngOnInit(): void {
    }

	ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("conversationMessageFilesPreview")){    
            this.conversationMessageFilesPreview = [];          
            this.conversationMessageFilesPreview = changes.conversationMessageFilesPreview.currentValue;

            if(this._helper.isNullOrUndefined(this.conversationMessageFilesPreview))
                return;

            this.conversationMessageFilesPreview.forEach(conversationMessageFile => {

                if(this._helper.isNullOrUndefined(conversationMessageFile) || this._helper.isEmptyOrNull(conversationMessageFile.file))
                    return;

                conversationMessageFile.prettyName = this._helper.getPrettyName(conversationMessageFile.file);
                conversationMessageFile.extension = this._helper.getExtension(conversationMessageFile.file);
                conversationMessageFile.isImage = this._helper.isImage(conversationMessageFile.extension);
                conversationMessageFile.isVideo = this._helper.isVideo(conversationMessageFile.extension);
                conversationMessageFile.isPDF = conversationMessageFile.extension === 'pdf';
            });
        }
        /* if (changes.hasOwnProperty("userId")){              
            this.userId = changes.userId.currentValue;
			console.log('ngOnChanges userId ');
			console.log(this.userId );
        } 

		if (changes.hasOwnProperty("reportUserInfo")){              
            this.reportUserInfo = changes.reportUserInfo.currentValue;
			console.log('ngOnChanges reportUserInfo');
			console.log(this.reportUserInfo);
        }  */
    }

	addAfterImage(selectedFile:IFile, model: string, type: string): void {
 
        if(this._helper.isNullOrUndefined(selectedFile) || this.userId === null || this.userId === 0)
            return;

        this._spinnerService.startSpinner();

        this._userFileService.addBeforeAfterFile<IMessageResponse>(this.userId, { image_id: selectedFile.id.toString(), review_image_type: type, model: model }).subscribe(
            response => { 
                 if(!response.success) 
                    return;

                    this._straightboxService.clearCache();

                this._snackBar.open( type + ' image is added.', 'Close', this.matSnackBarConfig);
                this._spinnerService.stopSpinner();
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
        

    }

}
