import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from '../user/user.component'; 
import { UserListComponent } from '../user/user-list/user-list.component';
import { UserDetailsComponent } from '../user/user-details/user-details.component';
import { ConversationComponent } from '../user/conversation/conversation.component';
import { AddNewConversationComponent } from '../user/conversation/add-new/add-new.component'; 
import { ComplaintComponent } from '../user/complaint/complaint.component';
import { AddNewComplaintComponent } from '../user/complaint/add-new/add-new.component';
import { EconsultationComponent } from './econsultation/econsultation.component';
import { UserUploadComponent } from '../user/user-upload/user-upload.component';
import { ImageUploadComponent } from '../user/user-upload/image-upload/image-upload.component';
import { SmileboxOrderComponent } from '../user/smilebox-order/smilebox-order.component';
import { TreatmentPlanProcessingComponent } from '../user/treatment-plan-processing/treatment-plan-processing.component';
import { StraightboxOrderComponent } from '../user/straightbox-order/straightbox-order.component';
import { ClinicalManagementComponent } from '../user/clinical-management/clinical-management.component';
import { InvitationsComponent } from '../user/invitations/invitations.component';
import { SecurityGuard } from '../../service/security/security-guard.service';
import { MaintainerPlanComponent } from './maintainer-plan/maintainer-plan.component';

const routesUser: Routes = [

    { path: '', component: UserListComponent, canActivate: [SecurityGuard]  },
    { path: ':userId', component: UserComponent, canActivate: [SecurityGuard],
        children: [
            //{ path: '', redirectTo: 'details', pathMatch: 'full' },
            { path: 'details', component: UserDetailsComponent, canActivate: [SecurityGuard] },
            { path: 'complaints', component: ComplaintComponent, canActivate: [SecurityGuard] },
            { path: 'complaints/add-new', component: AddNewComplaintComponent, canActivate: [SecurityGuard] },
            { path: 'complaints/add-new/:conversationId', component: AddNewComplaintComponent, canActivate: [SecurityGuard] },
            { path: 'message', redirectTo: 'message/shrink-all', pathMatch: 'full' },  
            { path: 'message/add-new', component: AddNewConversationComponent, canActivate: [SecurityGuard] },
            { path: 'message/:status', component: ConversationComponent, canActivate: [SecurityGuard] },
            { path: 'econsultation', component: EconsultationComponent, canActivate: [SecurityGuard] },
            { path: 'user-upload', component: UserUploadComponent, canActivate: [SecurityGuard] },
            { path: 'user-upload/image-upload', component: ImageUploadComponent, canActivate: [SecurityGuard] },
            { path: 'smilebox-order', component: SmileboxOrderComponent, canActivate: [SecurityGuard] },
            { path: 'treatment-plan-processing', component: TreatmentPlanProcessingComponent, canActivate: [SecurityGuard] },
            { path: 'straightbox-order', component: StraightboxOrderComponent, canActivate: [SecurityGuard] },
            { path: 'clinical-management', component: ClinicalManagementComponent, canActivate: [SecurityGuard] },
            { path: 'invitations', component: InvitationsComponent, canActivate: [SecurityGuard] },
            { path: 'maintainer-plan', component: MaintainerPlanComponent, canActivate: [SecurityGuard] },
            { path: '**', redirectTo: '/login' },
        ] 
    }
];

@NgModule({ 
    imports: [RouterModule.forChild(routesUser)],
	exports: [RouterModule]
})
export class UserRoutingModule { }
