import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogMessageComponent } from '../shared/dialog-message/dialog-message.component'; 
import { DialogProgressStatusComponent } from '../shared/dialog-progress-status/dialog-progress-status.component'; 
import { HeaderComponent } from '../shared/header/header.component';
import { MessageReplyComponent } from '../shared/message-reply/message-reply.component'; 
import { FileUploadComponent } from '../shared/file-upload/file-upload.component'; 
import { SubMenuComponent } from '../shared/sub-menu/sub-menu.component'; 
import { AngularMaterialModule } from '../../angular-material.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomCoreModule } from 'src/app/custom-core.module';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { DialogMaintainerPlanComponent } from '../shared/dialog-maintainer-plan/dialog-maintainer-plan.component'; 

@NgModule({
  declarations: [
    DialogMessageComponent,
    DialogProgressStatusComponent,
    HeaderComponent,
    SubMenuComponent,
    MessageReplyComponent,
    FileUploadComponent,
    FilePreviewComponent,
    DialogMaintainerPlanComponent
  ],
  exports: [
    DialogMessageComponent,
    DialogProgressStatusComponent,
    HeaderComponent,
    SubMenuComponent,
    MessageReplyComponent,
    FileUploadComponent,
    FilePreviewComponent,
    DialogMaintainerPlanComponent
  ],
  imports: [ 
    CustomCoreModule,
    FormsModule, 
    FlexLayoutModule
  ]
})
export class SharedModule { }
