import { Component, OnInit, Inject } from '@angular/core'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITreatmentStatusRequest } from 'src/app/model/api/treatment-status'; 
import { IProgressStatusDialogData } from 'src/app/model/base/progress-status-dialog-data';
import { TreatementStatusV1 } from 'src/app/service/api/treatment-status.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { Helper } from 'src/app/util/helper.util';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UserStatus } from 'src/app/settings/user-status';
import * as moment from 'moment';
import { TreatmentProgressService } from 'src/app/service/api/treatment-progress.service';
import { TreatmentPercentageService } from 'src/app/service/api/treatment-percentage.service';
import { ITreatmentProgress } from 'src/app/model/api/treatment-progress';
import { ITreatmentPercentage } from 'src/app/model/api/treatment-percentage';
import { ReportUserInfoService } from 'src/app/service/api/report-user-info.service';
import { UserStatusHelper } from 'src/app/util/user-status-helper.util';


@Component({
  selector: 'app-dialog-progress-status',
  templateUrl: './dialog-progress-status.component.html',
  styleUrls: ['./dialog-progress-status.component.scss']
})
export class DialogProgressStatusComponent implements OnInit {

    treatmentStatusRequest: ITreatmentStatusRequest;
    userStatus = UserStatus;
    treatmentProgresses: ITreatmentProgress[];
    treatmentPercentages: ITreatmentPercentage[];
    moment: any = moment;
    matSnackBarConfig: MatSnackBarConfig = { duration: 3000, horizontalPosition: 'right', verticalPosition: 'top'};

    constructor(@Inject(MAT_DIALOG_DATA) public data: IProgressStatusDialogData,  public dialogRef: MatDialogRef<DialogProgressStatusComponent>, private _treatmentPercentageService: TreatmentPercentageService, private _reportUserInfoService: ReportUserInfoService, private _treatmentProgressService: TreatmentProgressService, private _treatementStatusV1: TreatementStatusV1, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar, public userStatusHelper: UserStatusHelper) {}

    ngOnInit(): void {
        this.treatmentStatusRequest = { user: this.data.user, description: '', percentage: '' };

        this.getTreatmentPercentage();
        this.getTreatmentProgress();
    }


    saveProgressStatus(): void {

        if(this._helper.isEmptyOrNull(this.treatmentStatusRequest.percentage)) {
            this._snackBar.open('Please select percentage.', 'Close', this.matSnackBarConfig); 
            return;
        }

        if(this._helper.isEmptyOrNull(this.treatmentStatusRequest.description)) {
            this._snackBar.open('Please enter text.', 'Close', this.matSnackBarConfig); 
            return;
        }            

        this._spinnerService.startSpinner();

        this._treatementStatusV1.saveTreatmentStatus(this.data.user, this.treatmentStatusRequest)
			.subscribe(response => { 

                if(response.success) {
                    this._reportUserInfoService.clearCache(); 
                    this.dialogRef.close(this.data);
                }
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
        
    }


    getTreatmentPercentage(): void { 
        this._spinnerService.startSpinner();

        this._treatmentPercentageService.index()
			.subscribe(response => {
				this.treatmentPercentages = response.results; 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getTreatmentProgress(): void { 
        if(this._helper.isNullOrUndefined(this.data) || this.data.user == 0)
            return; 

        this._spinnerService.startSpinner();

        this._treatmentProgressService.index({ user_id: this.data.user})
			.subscribe(response => {
				this.treatmentProgresses = response.results; 
                this._spinnerService.stopSpinner();  
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

}
