import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserPicture } from '../../../model/api/user-picture';
import { IUserQuestion } from '../../../model/api/user-question';
import { INavigationSettings } from '../../../model/settings'; 
import { MenuService } from '../../../service/state/menu.service';
import { SettingsService } from '../../../service/state/settings.service';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { Helper } from '../../../util/helper.util';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ColunmNumber } from '../../../settings/column-number';
import { UserPictureService } from '../../../service/api/user_picture.service';
import { UserQuestionService } from '../../../service/api/user-question.service';
import { UserDetailService } from '../../../service/api/user-detail.service';
import { SpinnerService } from '../../../service/spinner.service';
import * as moment from 'moment';
import { UserStatusName } from '../../../settings/user-status';
import { UserMessageService } from '../../../service/api/user-message.service';
import { IUserMessage } from '../../../model/api/user-message';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { forkJoin } from 'rxjs';
import { AdminFileService } from '../../../service/api/admin-file.service';
import { SmileboxFileService } from '../../../service/api/smilebox-file.service';
import { IFile } from '../../../model/api/file';
import { ISmileBoxFiles } from '../../../model/api/smile-box-files';
import { FileType } from '../../../settings/file-type';
import { UserType } from '../../../settings/user-type';
import { CustomLightboxService } from '../../../service/custom-lightbox.service';
import { UserService } from '../../../service/api/user.service';
import { UserStateService } from '../../../service/state/user-state.service';
 

@Component({
	selector: 'app-econsultation',
	templateUrl: './econsultation.component.html',
	styleUrls: ['./econsultation.component.scss']
})
export class EconsultationComponent extends DetailUserBaseComponent implements OnInit {
  
    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 103; 
    userMessages: IUserMessage[];
    userQuestions: IUserQuestion[];
    userPictures: IUserPicture[];
    imageBreakpoint: number;
    moment: any = moment;
    userStatusName = UserStatusName;

    files: IFile[]; 
    smileboxFiles: ISmileBoxFiles[];
    userType = UserType;

	constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _helper: Helper, private _spinnerService: SpinnerService, private _userPictureService: UserPictureService, private _userQuestionService: UserQuestionService, private _breakpointObserver: BreakpointObserver, private _userMessageService: UserMessageService, private _adminFileService: AdminFileService, private _smileboxFileService: SmileboxFileService, private _customLightboxService: CustomLightboxService) {
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
     }

	ngOnInit(): void { 
		super.ngOnInit();

       this._breakpointObserver.observe([Breakpoints.XSmall]).subscribe( (state: BreakpointState) => {
             this.imageBreakpoint = state.breakpoints[Breakpoints.XSmall] ? ColunmNumber.One : ColunmNumber.Four;             
          });
 
        this.getUserPictures();
        this.getUserQuestions();
        this.getUserMessage();
        this.getSmileBoxFiles();

	}

    getUserPictures(): void { 
        this._spinnerService.startSpinner(); 
        this._userPictureService.index({user: this.userId}) 
			.subscribe(userPictures => { 
                this.userPictures = userPictures.results;
                
                this.userPictures.forEach(userPicture => {
                    if(!userPicture.image)
                        return; 
                    userPicture.prettyName = this._helper.getPrettyName(userPicture.image);
                    userPicture.extension = this._helper.getExtension(userPicture.image);
                    userPicture.isImage = this._helper.isImage(userPicture.extension);
                    userPicture.isVideo = this._helper.isVideo(userPicture.extension);
                    userPicture.isAudio = this._helper.isAudio(userPicture.extension);
                    userPicture.isPDF = userPicture.extension === 'pdf';
 
                });

                this._spinnerService.stopSpinner(); 			 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}
 
    getUserQuestions(): void { 
        this._spinnerService.startSpinner(); 
        this._userQuestionService.index({user: this.userId})
			.subscribe(userQuestions => { 
                this.userQuestions = userQuestions.results; 
                this._spinnerService.stopSpinner(); 			 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}
    
    getUserMessage(): void { 
        this._spinnerService.startSpinner(); 

        this._userMessageService.index({user_id: this.userId })
			.subscribe(response => {
                if(response)
				    this.userMessages = response.results; 

                this._spinnerService.stopSpinner(); 			 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getSmileBoxFiles(deleteCache: boolean = false) {

        this._spinnerService.startSpinner();

        this._smileboxFileService.index({user_id : this.userId, file_type: FileType.EconsultationResults}, deleteCache).subscribe(smileboxFiles => {

            this.smileboxFiles = smileboxFiles.results; 
 

            this._spinnerService.stopSpinner(); 			 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }

    openLightbox(item) {
        this._customLightboxService.showLightbox(this.userPictures, item);
    }


}
