import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { IPermissionData } from '../../model/base/permission.data'

const CACHE_SIZE = 1;

@Injectable({
	providedIn: 'root'
})

export class PermissionService {

	private cache$: Observable<IPermissionData>;
	private _permissionUrl: string = this._config.api_url + '/admin-v02/permission';

	constructor(
		@Inject(APP_CONFIG) private _config, 
		private _http: HttpClient) { }

	get permission() {
		if (!this.cache$) {
			this.cache$ = this.getPermission().pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this.cache$;
	}

	getPermission() {
		return this._http.get<IPermissionData>(this._permissionUrl).pipe(
			map(response => response)
		);
	}

	async checkPermissions(needPermission) {
		let hasPermission = true;

		const permissions = (await this.permission.toPromise()).permission;

		needPermission.forEach(permission => {
			if (!permissions.includes(permission)){
				hasPermission = false;
				return false;
			}
		})

		return hasPermission;
	}
}