import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { IMessageResponse, LostAlignerTrackerResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';
import { ILostAlignerTracker } from 'src/app/model/api/lost-aligner-tracker';


@Injectable({
  	providedIn: 'root'
})
 
export class LostAlignerTrackerService extends BaseService<ILostAlignerTracker> {
    protected urlV2 = "/admin-v02/lost-aligner-tracker"; 
    protected urlPostLostAlignerTracker = "/admin/lost-aligner-tracker"; 
   /*  protected urlStraightbox = "/admin/straightboxorder"; */

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

    postLostAlignerTracker(newLostAlignerTracker: ILostAlignerTracker): Observable<LostAlignerTrackerResponse<ILostAlignerTracker>>  { 
        let urlFinal = `${this._config.api_url}${this.urlPostLostAlignerTracker}`; 
		return this._http.post<LostAlignerTrackerResponse<ILostAlignerTracker>>(urlFinal, newLostAlignerTracker).pipe(
			map(response => {  
                this._responseCache$.clear();
				return response as LostAlignerTrackerResponse<ILostAlignerTracker>;
			})
		);
	}

    putLostAlignerTracker(lostAlignerTracker: ILostAlignerTracker): Observable<LostAlignerTrackerResponse<ILostAlignerTracker>>  { 
        let urlFinal = `${this._config.api_url}${this.urlPostLostAlignerTracker}${lostAlignerTracker.id}`; 
		return this._http.put<LostAlignerTrackerResponse<ILostAlignerTracker>>(urlFinal, lostAlignerTracker).pipe(
			map(response => {  
                this._responseCache$.clear();
				return response as LostAlignerTrackerResponse<ILostAlignerTracker>;
			})
		);
	}

    
}
