import { Injectable } from '@angular/core'; 
import { ISeverityStatus } from '../model/severity-status';
import { UserSeverityStatus } from '../settings/user-severity-status';

@Injectable({
  providedIn: 'root'
})

export class SeverityStatusService {
  
    private severityStatuses: ISeverityStatus[];

    constructor( ) { 
    }   

    getSeverityStatuses(): ISeverityStatus[] {
        if(this.severityStatuses && this.severityStatuses.length > 0)
             return this.severityStatuses; 
  
         this.severityStatuses = (Object.keys(UserSeverityStatus) as Array<keyof typeof UserSeverityStatus>)
        .filter(userStatusName => typeof UserSeverityStatus[userStatusName] === "number" && UserSeverityStatus[userStatusName] > 0 ) 
        .map(userStatusName => ({ 
            id: UserSeverityStatus[userStatusName], 
            name: userStatusName 
        }));

         return this.severityStatuses;
     }
  
}
