import { Injectable}  from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs'; 
import { IFilterSettings, IApiSearchFilter, IUserFilterSettings } from '../../model/base/filter-settings';
import { Paging } from '../../model/base/paging';
import { Filter, IFilter } from '../../model/base/filter';
import { Helper } from '../../util/helper.util';
import { StatusService } from '../status.service';
import { SeverityStatusService } from '../severity-status.service'; 
import { IFile } from 'src/app/model/api/file';
import { FilterCategory } from 'src/app/settings/filter-category';
import { LocalStorageBaseService } from './local-storage-base.service';
import { IApiObject } from 'src/app/model/base/api-object';


@Injectable({
  providedIn: 'root'
}) 

export class LocalStorageUserFilterService extends LocalStorageBaseService<IUserFilterSettings> {
 
    constructor() {
        super();
    } 

    get(filterSettingsName: string): IUserFilterSettings {
        return JSON.parse(localStorage.getItem(filterSettingsName));
    }

    set(filterSettingsName: string, filterSettings: IUserFilterSettings): void { 
        localStorage[filterSettingsName] = JSON.stringify(filterSettings);
    }

} 