import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/app.config/app.config';
import { IClinicalIssue, IClinicalIssueRequest } from 'src/app/model/api/clinical-issue';  
import { BaseService } from '../base/base.service';
import { IUserResponse } from 'src/app/model/api/response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  	providedIn: 'root'
})
 
export class ClinicalIssueService extends BaseService<IClinicalIssue> {
 
    protected urlV2 =  "/admin-v02/clinical-issue"; 
    protected urlV1 = this._config.api_url + "/admin/clinical-issue"; 
 
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    

    postClinicalIssue<T>(userId: number, complaintRequest: IClinicalIssueRequest): Observable<IUserResponse<IClinicalIssue>> {
        let urlFinal = `${this.urlV1}?user_id=${userId}`; 
        return this._http.post(urlFinal, complaintRequest)
            .pipe(
                map((res: IUserResponse<IClinicalIssue>) => {
 
                    this._responseCache$.clear();
                    if (res) { 
                        return res;
                    }
                })
            );
    }


}
