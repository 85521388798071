import { Injectable}  from '@angular/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs'; 
import { Paging } from 'src/app/model/base/paging';
import { IApiFilter, IFilterSettings} from '../../model/base/filter-settings';
import { Helper } from '../../util/helper.util';


@Injectable({
  providedIn: 'root'
}) 

export class FilterStateService<T extends IFilterSettings>  {
    protected _currentFilterSettings: BehaviorSubject<T> = new BehaviorSubject(null);
    currentFilterSettings: Observable<T> = this._currentFilterSettings.asObservable();
    protected filterSettingsName = '';

    constructor(private _helper: Helper) { } 

    setCurrentFilterSettings(filterSettings: T): void { 
        this._currentFilterSettings.next(filterSettings); 
	}
 
    updateApiFilterPageing(paging: Paging): void { 
        let newFilterSettings: T = this._currentFilterSettings.value;
        newFilterSettings.paging = paging;
        this.setCurrentFilterSettings(newFilterSettings);
	} 

    setApiFilter(filterSettings: IFilterSettings, apiFilter: IApiFilter): IApiFilter {	

        if(!this._helper.isNullOrUndefined(apiFilter)) {
            apiFilter = [];
        } 

        if(!this._helper.isNullOrUndefined(filterSettings.paging)) {
            if(filterSettings.paging.pageIndex > 0) 
                apiFilter['page'] = filterSettings.paging.pageIndex;
             
            if(filterSettings.paging.pageSize > 0) 
                apiFilter['page_size'] = filterSettings.paging.pageSize;            
        }

        if(!this._helper.isEmptyOrNull(filterSettings.ordering)) {
            apiFilter['ordering'] = filterSettings.ordering;
        }

        if(this._helper.isNullOrUndefined(filterSettings.filter)) 
            return apiFilter;

        let filter = filterSettings.filter;  

        for (let key of Object.keys(filter)) {
           
            if(moment.isDate(filter[key])) {
                apiFilter[`${key}`] = moment(filter[key]).utc().format(); 
                continue;
            } 
            
            if(key == 'user_details__policy_breach_status' && filter[key]) {
                apiFilter['user_details__policy_breach_status__isnull'] = false; 
                continue;
            } 
            
            apiFilter[`${key}`] = filter[key]; 
            
                
            // apiFilter[`${key}`] = moment.isDate(filter[key]) ? moment(filter[key]).utc().format() : filter[key];
        }

        return apiFilter;
    }
      
} 