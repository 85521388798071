import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config/app.config';
import { IConversationMessageFile } from '../../model/api/conversation-message-file';  
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})
export class ConversationMessageFileService extends BaseService<IConversationMessageFile> {

    protected urlV2 = "/admin-v02/conversation-file";    

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
}
