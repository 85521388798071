import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG } from '../../app.config/app.config'; 
import { BaseService } from '../base/base.service';
import { SmileboxService } from './smilebox.service';
import { UserDetailService } from './user-detail.service';
import { IReminder } from '../../model/api/reminder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMessageResponse } from 'src/app/model/api/response';


@Injectable({
  	providedIn: 'root'
})
 
export class ReminderService extends BaseService<IReminder> {
    protected urlV2 = "/admin-v02/reminder";  
    protected urlV1 = "/admin/reminder";  

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient, private _smileboxService: SmileboxService, private _userDetailService: UserDetailService) {
        super(_config, _http)
    }

    saveReminder(reminder: IReminder): Observable<IReminder>  { 
        if(reminder != null && reminder.id > 0)
            return this.updateReminder(reminder);

        return this.createReminder(reminder);         
	}

    createReminder(reminder: IReminder): Observable<IReminder> { 
        let urlFinal = `${this._config.api_url}${this.urlV1}`; 
		return this._http.post<IReminder>(urlFinal, reminder).pipe(
			map(response => { 
                this._responseCache$.clear();
				return response as IReminder;
			})
		);
	}

    updateReminder(reminder: IReminder): Observable<IReminder> { 
        let urlFinal = `${this._config.api_url}${this.urlV1}`; 
		return this._http.put<IReminder>(urlFinal, reminder).pipe(
			map(response => { 
                this._responseCache$.clear();
				return response as IReminder;
			})
		);
	}

    createMultipleReminders(reminders: IReminder[]): Observable<IReminder[]> { 

        let urlFinal = `${this._config.api_url}${this.urlV1}`; 
		return this._http.post<IReminder[]>(urlFinal, reminders).pipe(
			map(response => { 
                this._responseCache$.clear();
				return response as IReminder[];
			})
		);
	}

    deleteReminder(reminder: IReminder): Observable<IMessageResponse> { 

        let urlFinal = `${this._config.api_url}${this.urlV1}`; 

        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: reminder
          };
          
		return this._http.delete<IMessageResponse>(urlFinal, options).pipe(
			map(response => { 
                this._responseCache$.clear();
				return response as IMessageResponse;
			})
		); 
 
	}
    
}