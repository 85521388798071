<div fxLayout="column" fxFlexFill fxLayoutGap="20px" >         
    <div fxFlex="100">
        <a mat-raised-button mat-button color="primary" (click)="fileUpload.click()">
            <span class="material-icons">add</span> {{ buttonTitle }}
            <input style="display: none;" type="file" multiple (change)="addFiles($event)" #fileUpload [accept]="allowedUploadExtensions" /> 
        </a>        
    </div>
    <div fxFlex="100">
        <div class="attachment-wrap">
            <div class="attachment" *ngFor="let fileToUpload of filesToUpload; let i = index">
                <img [attr.src]='fileToUpload.base'  *ngIf="fileToUpload.type === 'image'" class="image-preview"> 
                <div *ngIf="fileToUpload.type !== 'image'" class="file-box">
                    <span class="title" >{{fileToUpload.name.substr(0, 100)}}</span>
                    <mat-icon mat-list-icon *ngIf="fileToUpload.type === 'application'">topic</mat-icon> 
                    <mat-icon mat-list-icon *ngIf="fileToUpload.type === 'video'" >videocam</mat-icon> 
                </div>  
                <!-- <span *ngIf="fileToUpload.type !== 'image'" class="title">{{fileToUpload.name.substr(0, 75)}}</span>
                <mat-icon mat-list-icon *ngIf="fileToUpload.type !== 'image'" class="file-icon">topic</mat-icon>  -->
                <button class="btn btn-delete" (click)="deleteFile(i)">
                    <mat-icon mat-list-icon matTooltip="Delete">delete</mat-icon> 
                </button>
            </div>
        </div>
    </div>
</div>