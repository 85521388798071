export interface ISettings {
    isMobileOrTablet: boolean;
    userPermition: string;
    isUserLogged: boolean;
    navigationSettings: INavigationSettings; 
    isMobileTablet: boolean;
    matSideNavWidth: string;
}

export class Settings implements ISettings {
    isMobileOrTablet: boolean;
    userPermition: string;
    isUserLogged: boolean;
    navigationSettings: INavigationSettings; 
    isMobileTablet = true;
    matSideNavWidth = '300px'; 
}

export interface INavigationSettings {
    pageTitle?: string;
    pageUrl: string;
    pageId?: number; 
    url?: string;
    parentUrl?: string;
    userId?: number;
    parentId?: number;
    statusId?: number;
}
/* 
export class NavigationSettings implements INavigationSettings {
    pageId?: number; 
    pageTitle?: string;
    pageUrl: string;
    url?: string;
    parentUrl?: string;
    userId?: number;
    parentId?: number;

    public constructor(data?: Partial<INavigationSettings>) {
        Object.assign(this, data);
    } 
}*/