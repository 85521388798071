import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef, SimpleChanges } from '@angular/core'; 
import * as moment from 'moment';  
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service'; 
import { AppConstants } from '../../../settings/app-constants';
import { UserDetailService } from '../../../service/api/user-detail.service'; 
import { SpinnerService } from '../../../service/spinner.service';
import { StraightboxService } from '../../../service/api/straightbox.service';
import { IAlignerProgressStraightboxOptionRequest, IStraightbox } from '../../../model/api/straightbox';
import { ReminderService } from '../../../service/api/reminder.service';
import { IReminder, IReminderLastDate } from '../../../model/api/reminder';
import { IUserDetailsClinicalManagement, IUserDetailsPauseReminders, IUserDetailsRequest, IUserV1 } from '../../../model/api/user-profile';
import { IKeyValuePair } from '../../../model/base/key-value-pair';
import { forkJoin } from 'rxjs';
import { LostAlignerTrackerService } from '../../../service/api/lost-aligner-tracker.service';
import { ILostAlignerTracker } from '../../../model/api/lost-aligner-tracker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IStraightboxResponse, IUserResponse, } from '../../../model/api/response';
import { ClinicalIssueService } from '../../../service/api/clinical-issue.service';
import { IClinicalIssue, IClinicalIssueRequest } from '../../../model/api/clinical-issue'; 
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { MatDatepicker } from '@angular/material/datepicker'; 
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { takeUntil } from 'rxjs/operators';
import { UserStatusHelper } from '../../../util/user-status-helper.util';
import { UserService } from '../../../service/api/user.service';
import { UserStateService } from '../../../service/state/user-state.service';
import { HelperExtension } from 'src/app/util/helper.extension';
 

@Component({
  selector: 'app-clinical-management',
  templateUrl: './clinical-management.component.html',
  styleUrls: ['./clinical-management.component.scss']
})

export class ClinicalManagementComponent extends DetailUserBaseComponent implements OnInit {

    @ViewChild('datepickerFooter', {static: false}) datepickerFooter: ElementRef;
    @ViewChild('datepickerRemindAt', {static: false}) datepickerRemindAt: MatDatepicker<any>;
    menuId: number = 110;  
    moment: any = moment;
    reminders: IReminder[];
    selectedReminder: IReminder;
    reminderLastDate: IReminderLastDate;
    remindersMissedCount: number;
    alignerFirstWarning: IReminder;
    alignerSecondWarning: IReminder;
    alignerLastWarning: IReminder;
    straightbox: IStraightbox;
    lostAlignerTrackers: ILostAlignerTracker[];
    newLostAlignerTracker: ILostAlignerTracker;
    clinicalIssues: IClinicalIssue[];
    clinicalIssue: IClinicalIssue; 
    readonly SAVE_BUTTON: string = AppConstants.SAVE_BUTTON; 
    readonly MARK_AS_COMPLETE_BUTTON: string = AppConstants.MARK_AS_COMPLETE_BUTTON; 
    readonly REMOVE_BUTTON: string = AppConstants.REMOVE_BUTTON; 
    readonly SELECT_ALIGNER_SELECT_OPTION: string = AppConstants.SELECT_ALIGNER_SELECT_OPTION; 
    readonly ALIGNER_FIT_SELECT_OPTION: string = AppConstants.ALIGNER_FIT_SELECT_OPTION; 
    readonly UPLOAD: string = AppConstants.UPLOAD; 
    selectedDate: Date;
    allowedExtensions: string = this._helperExtension.imageAsExtensions();
    
    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _straightboxService: StraightboxService, private _lostAlignerTrackerService: LostAlignerTrackerService, private _clinicalIssueService: ClinicalIssueService, public _helper: Helper, private _cd: ChangeDetectorRef, private _spinnerService: SpinnerService, private _reminderService: ReminderService, private _snackBar: MatSnackBar, public _dialog: MatDialog, private userStatusHelper: UserStatusHelper, private _helperExtension: HelperExtension) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
        ///console.log('ClinicalManagementComponent ngOnInit()');
        super.ngOnInit();

        this.getReminders();
        //this.getStraightbox();
        this.getLostAlignerTracker();
        this.loadNewLostAligerTracker();
        this.getRemindersReview();
        // this.getRemindersReview(AppConstants.ALIGNER_SECOND_WARNING);
        // this.getRemindersReview(AppConstants.ALIGNER_LAST_WARNING);  
        this.getClinicalIssues(); 

        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => {
                this.reportUserInfo = reportUserInfo;
                this.getStraightbox();
            });
    }
 

    getRemindersReview(): void {  
        this._spinnerService.startSpinner(); 

        this.alignerFirstWarning = null;
        this.alignerSecondWarning = null;
        this.alignerLastWarning = null;

        this._reminderService.index({ user: this.userId, notified_user: false, page: 1, page_size: 1000 }) 
			.subscribe(reminders => { 

                reminders.results.forEach(reminder => {

                    if(reminder.reminder_type == AppConstants.ALIGNER_FIRST_WARNING)
                        this.alignerFirstWarning = reminder;

                    if(reminder.reminder_type == AppConstants.ALIGNER_SECOND_WARNING)
                        this.alignerSecondWarning = reminder;

                    if(reminder.reminder_type == AppConstants.ALIGNER_LAST_WARNING)
                        this.alignerLastWarning = reminder;
                });                
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getReminders(): void {  
        this._spinnerService.startSpinner(); 
        this.reminderLastDate = { date: null };
        this.remindersMissedCount = 0;
        let now: Date;

        this._reminderService.index({ user: this.userId, reminder_type: 'aligner_reminder', page: 1, page_size: 50 }) 
			.subscribe(reminders => { 

                if(reminders && reminders.results) {
                    this.reminders = reminders.results;

                    let reminderIsCompleted: boolean = false;

                    this.reminders.forEach(reminder => {
                        reminder.remind_at =  !this._helper.isNullOrUndefined(reminder.remind_at) ? moment.utc(reminder.remind_at).toDate() : null;

                        if(moment(reminder.remind_at).isAfter(moment(this.reminderLastDate.date)) || this.reminderLastDate.date == null )
                            this.reminderLastDate.date = reminder.remind_at; 
                        
                        reminderIsCompleted = reminder.completed || reminderIsCompleted ? true : false;
                        
                        if(moment(reminder.remind_at).isBefore(now) && !reminder.completed && !reminderIsCompleted) {                           
                            this.remindersMissedCount +=1;                            
                        } 
                    });
                } 
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getClinicalIssues(): void {  
        this._spinnerService.startSpinner(); 

        this.clinicalIssue = { id: null, user: this.userId, text: '' };

        this._clinicalIssueService.index({ user: this.userId, page: 1, page_size: 50 }) 
			.subscribe(response => { 

                if(response && response.results) {
                    this.clinicalIssues = response.results; 
                } 
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getStraightbox(): void { 
 
        if(!this.reportUserInfo || !this.userStatusHelper.isAlignersStarted(this.reportUserInfo.status))
            return; 
 
        this._spinnerService.startSpinner(); 
        this._straightboxService.detail(this.userId.toString()) 
			.subscribe(straightbox => {
                if(straightbox)
				    this.straightbox = straightbox; 

                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getLostAlignerTracker(): void {  
        this._spinnerService.startSpinner(); 
        this._lostAlignerTrackerService.index({ user_id: this.userId.toString() }) 
			.subscribe(lostAlignerTrackers => {
                if(lostAlignerTrackers)
				    this.lostAlignerTrackers = lostAlignerTrackers.results; 

                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}


    saveClinicalManagement(): void { 
        this._spinnerService.startSpinner(); 
        
        let userDetailsRequestClinicalManagement: IUserDetailsRequest<IUserDetailsClinicalManagement> = { 
            user_details: {
                aligner_progress_top: this.userDetail.aligner_progress_top, 
                aligner_progress_bottom: this.userDetail.aligner_progress_bottom,  
                in_refinement: this.userDetail.in_refinement,
                aligner_fit_upper: this.userDetail.aligner_fit_upper, 
                aligner_fit_lower: this.userDetail.aligner_fit_lower, 
                user: this.userId,
                treatment_notes: this.userDetail.treatment_notes
            }
        }; 
        
        let alignerProgressStraightboxOptionRequest: IAlignerProgressStraightboxOptionRequest = { 
            aligner_progress_options: this.straightbox.aligner_progress_options 
        };

        this.saveLostBrokenAlignerTracker();
        this.saveNewLostBrokenAlignerTracker();
        this.saveClinicalIssue();        
 
        forkJoin([this._userDetailService.putUserDetails(this.userId, userDetailsRequestClinicalManagement), this._straightboxService.saveAlignerProgressOption(this.userId, alignerProgressStraightboxOptionRequest)]) 
            .subscribe(items => {
                let userDetailsResponse = items[0] as IUserResponse<IUserV1> ;
                let straightboxResponse = items[1] as IStraightboxResponse<IStraightbox>;  
 
                if((userDetailsResponse && userDetailsResponse.success)) 
                    super.getUserDetail();

                if((straightboxResponse && straightboxResponse.success))   
                    this.getStraightbox(); 

                this._spinnerService.stopSpinner();                                
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
        
    }

    saveClinicalIssue(): void {  

        if(this._helper.isNullOrUndefined(this.clinicalIssue) || this._helper.isEmptyOrNull(this.clinicalIssue.text) )
            return;

        this._spinnerService.startSpinner(); 
         
        let clinicalIssueRequest: IClinicalIssueRequest = { 
            text: this.clinicalIssue.text,
            user: this.userId
        };
  
        this._clinicalIssueService.postClinicalIssue(this.userId, clinicalIssueRequest) 
            .subscribe(clinicalIssueResponse => {  
                if(!this._helper.isNullOrUndefined(clinicalIssueResponse)) 
                    this.getClinicalIssues();

                this._spinnerService.stopSpinner();                                
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
        
    }


    saveNewLostBrokenAlignerTracker(): void {
 
        if(this.newLostAlignerTracker.number_of_lost_or_broken_aligners == null || this.newLostAlignerTracker.number_of_lost_or_broken_aligners == 0) {
            this.loadNewLostAligerTracker();
            return;
        } 
 
        let errorMessage  = this.isValidLostAlignerTracker(this.newLostAlignerTracker);
        if(errorMessage) {
            this._snackBar.open(errorMessage, 'Close', this.matSnackBarConfig);
            return;
         }
                
        this.newLostAlignerTracker.user = this.userId;

        let now = new Date();
        if(this.newLostAlignerTracker.number_of_lost_or_broken_aligners > 0)
            this.newLostAlignerTracker.date_of_entry_lost_or_broken_aligners = moment(now).toDate();

        if(this.newLostAlignerTracker.number_of_free_replacements > 0)
            this.newLostAlignerTracker.date_of_entry_free_replacements = moment(now).toDate();

        this._lostAlignerTrackerService.postLostAlignerTracker(this.newLostAlignerTracker)
            .subscribe(response => {
                let lostAlignerTrackerResponse = response; 

                if(lostAlignerTrackerResponse && lostAlignerTrackerResponse.success)
                    this.getLostAlignerTracker(); 
                    this.loadNewLostAligerTracker();

                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }

    saveLostBrokenAlignerTracker(): void {

        if(!this.lostAlignerTrackers || this.lostAlignerTrackers.length == 0)
            return null;

        this.lostAlignerTrackers.forEach(lostAlignerTracker => { 

            let isEdited: boolean = lostAlignerTracker.date_of_entry_free_replacements == null && lostAlignerTracker.number_of_free_replacements != null;

            if(!isEdited)
                return; 

            let errorMessage  = this.isValidLostAlignerTracker(lostAlignerTracker);
            if(errorMessage) {
                this._snackBar.open(errorMessage, 'Close', this.matSnackBarConfig);
                return;
            }

            lostAlignerTracker.date_of_entry_free_replacements = moment().toDate();
            lostAlignerTracker.user = this.userId;

            this._lostAlignerTrackerService.putLostAlignerTracker(lostAlignerTracker)
                .subscribe(response => {
                    let lostAlignerTrackerResponse = response; 

                    if(lostAlignerTrackerResponse && lostAlignerTrackerResponse.success)
                        this.getLostAlignerTracker(); 

                    this._spinnerService.stopSpinner(); 
                },
                error =>{ error  
                    this._spinnerService.stopSpinner(); 
                }); 
        }); 
    }

    isValidLostAlignerTracker(lostAlignerTracker: ILostAlignerTracker): string {

        if(lostAlignerTracker.number_of_lost_or_broken_aligners == 0) {  
            lostAlignerTracker.number_of_lost_or_broken_aligners = null;
            return 'Nb reported should be greater than 0.';             
        } 

        if(lostAlignerTracker.number_of_free_replacements == 0) {  
            lostAlignerTracker.number_of_free_replacements = null;
            return 'Nb free replacement should be greater than 0.';
        } 

        if(lostAlignerTracker.number_of_free_replacements > lostAlignerTracker.number_of_lost_or_broken_aligners) {  
            lostAlignerTracker.number_of_free_replacements = null;
            return 'Nb free replacement can\'t be greater than Nb reported.'; 
        } 

        return null;
    }

    markAsComplete(reminder: IReminder): void {

        if(reminder.remind_at == null)
            return;
        
        this._spinnerService.startSpinner(); 

        reminder.remind_at = reminder.remind_at;
        reminder.completed = true;
        reminder.completed_at = new Date();
        reminder.upper_arch = this.userDetail.aligner_progress_top;
        reminder.lower_arch = this.userDetail.aligner_progress_bottom;
        reminder.aligner_fit_upper = this.userDetail.aligner_fit_upper;
        reminder.aligner_fit_lower = this.userDetail.aligner_fit_lower;
 
        this._reminderService.saveReminder(reminder)
            .subscribe(reminder => { 
                this._spinnerService.stopSpinner(); 
                this.ngOnInit(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }

    deleteReminder(reminder: IReminder): void {

        if(reminder.remind_at == null)
            return;
        
        this._spinnerService.startSpinner(); 

        this._reminderService.deleteReminder(reminder)
            .subscribe(reminder => { 
                if(reminder.success)  {       
                    this._snackBar.open(reminder.msg, 'Close', this.matSnackBarConfig);       
                    this.ngOnInit();  
                }
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }

    saveReminder(reminder?: IReminder, selectedDate?: Date): void  {
        
        if(this._helper.isNullOrUndefined(reminder) || !reminder.id)
            reminder = this.newReminder(); 
 
        let remind_at: Date = moment(reminder.remind_at).toDate();
 
        if(!this._helper.isNullOrUndefined(selectedDate)) {
            
            let newRemindAt = moment(selectedDate).set({hours: remind_at.getHours(), minutes: remind_at.getMinutes(), seconds: remind_at.getSeconds()});        
            newRemindAt = moment(newRemindAt).isBefore(moment(new Date())) ? moment(newRemindAt).set({hours: new Date().getHours() + 2}) : newRemindAt;
            reminder.remind_at =  moment(newRemindAt).toDate();
        }
        
        this._spinnerService.startSpinner(); 
 
        this._reminderService.saveReminder(reminder)
            .subscribe(reminder => {
                if(reminder) 
                    this.ngOnInit();
                 
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            });         
    }
 

    createMultipleReminders(): void  { 
        let reminderCount: number = 10;
        var reminders: IReminder[] = [];
 
        for(let month = 1; month <= reminderCount; month++) {
            let reminder: IReminder = this.newReminder(month); 
            reminders.push(reminder);
        } 

        this._spinnerService.startSpinner(); 

        this._reminderService.createMultipleReminders(reminders)
            .subscribe(reminders => {
                if(reminders && reminders.length > 0) 
                    this.ngOnInit(); 

                this._spinnerService.stopSpinner();                  
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            });         
    } 

    newReminder(month?: number): IReminder {
        let reminderDate: Date = this.reminderLastDate.date != null ? moment(this.reminderLastDate.date).toDate() : new Date();
        
        reminderDate.setHours(6, 59, 0, 0);

        var newMonth = month != undefined && month > 1 ? month : 1;
        reminderDate.setMonth(reminderDate.getMonth() + newMonth);

        return {
            id: null,
            reminder_type : 'aligner_reminder',
            conversation_template: 3,  
            user: this.userId,
            created_by: this.userId, // TODO: I am not sure if this is corret id. In StProv1 code is.  parseInt(SetUsr.get_item('user_id')),
            remind_at: reminderDate,
            completed: false
        };
    } 

    toggleReminderPause(isPaused: boolean): void  {
        this._spinnerService.startSpinner(); 

        let reminderRequestPause:IUserDetailsRequest<IUserDetailsPauseReminders> = {        
            user_details: {
                pause_reminders : isPaused, 
                user: this.userId
            }
        };        

        this._userDetailService.putUserDetails(this.userId, reminderRequestPause)
            .subscribe(reminder => { 
                this._spinnerService.stopSpinner(); 
                this.ngOnInit();                                     
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
        
    }
 
    loadNewLostAligerTracker() {
        this.newLostAlignerTracker = { id: null, number_of_lost_or_broken_aligners: null, date_of_entry_lost_or_broken_aligners: null, number_of_free_replacements: null, date_of_entry_free_replacements: null, user: null };
    }

    uploadReviewStatusImage(event: any, fileType: string): void {
        this._spinnerService.startSpinner(); 
        let uploadFiles: File[] = [];
        uploadFiles.push(event.target.files[0]);
 
		if(this._helper.isNullOrUndefined(uploadFiles) ) {       
            return;
        } 

		let formData: FormData = new FormData();
        
		uploadFiles.forEach(file => { 
			formData.append(fileType, file);
			} 
		); 

		this._straightboxService.saveReviewImage(this.userId, formData)
            .subscribe(response => { 
    
            if(response.success) {
                this.getStraightbox();
            }  

            this._spinnerService.stopSpinner(); 
        },
		error =>{ error  
            this._spinnerService.stopSpinner(); 
		}); 

	}

    today( ): void {
        this.saveReminder(this.selectedReminder, new Date());
        this.datepickerRemindAt.close();
    }

    openMatDatePicker(reminder: IReminder): void {
        this.selectedReminder = reminder;
        this.appendTodayButtonToDatePicker();
    }

    private appendTodayButtonToDatePicker() {
        const matCalendar = document.getElementsByClassName('mat-calendar')[0] as HTMLElement;
        matCalendar.appendChild(this.datepickerFooter.nativeElement);
    }

    finishTreatment(): void {
        this._spinnerService.startSpinner();  
  
        this._userDetailService.finishStatus(this.userId)
            .subscribe(response => { 
                if(response.success)  {
                    this._reportUserInfoService.clearCache(); 
                    this.ngOnInit();    
                } 
 
                this._spinnerService.stopSpinner(); 			 
                },
                error =>{ error  
                    this._spinnerService.stopSpinner(); 
                }); 
    }

    confirmFinishTreatment(): void {
        const dialogRef = this._dialog.open(DialogMessageComponent, {
            data: { title: 'FINISH TREATMENT', message: 'ARE YOU SURE YOU WANT TO FINISH TREATMENT FOR THIS USER?'}
          });
  
          dialogRef.afterClosed().subscribe(result => {  
              if(result) 
                  this.finishTreatment(); 
          });
    }

    alignerProgressOptionsForDropDown: IKeyValuePair[] =  [
        { key: 'CURR_ON_TR', value: 'Current stage on track' },
        { key: 'COURSE_CORR', value: 'Course correction' },
        { key: 'SLOW_BIO', value: 'Slower biological response detected' }
    ];  
   

    alignerFitGradeListForDropDown = [ 
        'A',
        'B',
        'C',
        'F'
    ]; 

    alignerListForDropdown = [  
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        'Retainer',
        'R1',
        'R2',
        'R3',
        'R4',
        'R5',
        'R6',
        'R7',
        'R8',
        'R9',
        'R10',
        'R11',
        'R12',
        'R13',
        'R14',
        'R15'
    ]; 
}

 