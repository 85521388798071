import { Injectable}  from '@angular/core';
import { IBaseObject } from 'src/app/model/base/base-object';


@Injectable({
  providedIn: 'root'
}) 

export class LocalStorageBaseService<T extends IBaseObject> {

    constructor() { }
 
    protected get(filterSettingsName: string): T {
        return;
     }

    protected save(filterSettingsName: string, item: T): void { }

  
} 