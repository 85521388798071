<h3>User Invited</h3>

<div *ngIf="invitations && user">   
    <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="20px">
        <div fxFlex="100">
            <div fxLayout="column"  *ngFor="let invitation of invitations; let i = index;" fxLayoutGap="20px">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"> 
                    <div fxFlex="15">Type</div>
                    <div fxFlex="85">{{ invitation.user__email == user.email ? 'INVITER' : 'REGISTER' }}</div> 
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"> 
                    <div fxFlex="15">Full name</div>
                    <div fxFlex="85">{{ invitation.user__email == user.email ? invitation.inviter__full_name : invitation.user__full_name  }}</div> 
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"> 
                    <div fxFlex="15">Email</div>
                    <div fxFlex="85">{{ invitation.user__email == user.email ? invitation.inviter__email : invitation.user__email  }}</div> 
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"> 
                    <div fxFlex="15">Created at</div>
                    <div fxFlex="85" *ngIf="invitation.created_at">{{ moment(invitation.created_at).format('DD.MM.Y HH:mm')}}</div> 
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"> 
                    <div fxFlex="100"> 
                        <a mat-raised-button mat-button color="primary" [routerLink]="['/admin/user/', invitation.user__email == user.email ? invitation.inviter : invitation.user, 'details']">Visit profile</a>
                    </div> 
                </div>
                <mat-divider [inset]="true" style="padding-bottom: 20px;"></mat-divider>
            </div>
        </div>
    </div> 
        
    
</div> 

 