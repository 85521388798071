import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { IUser } from '../../model/api/user'; 
import { APP_CONFIG} from '../../app.config/app.config';
import { BaseService } from '../base/base.service';

@Injectable({
	providedIn: 'root'
})

export class UserService extends BaseService<IUser> {

    protected urlV2 = "/admin-v02/user";
     
    constructor(@Inject(APP_CONFIG) public config, private http: HttpClient) { 
        super(config, http);
    } 
  
}

 