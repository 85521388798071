import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { IAppBannerNotification } from '../../../model/api/app-banner-notificaton';
import { IStatus } from '../../../model/status';
import { AppBannerNotificationService } from '../../../service/api/app-banner-notification.service';
import { SpinnerService } from '../../../service/spinner.service';
import { StatusService } from '../../../service/status.service';
import { AppConstants } from '../../../settings/app-constants';
import { UserStatus } from '../../../settings/user-status';
import { Helper } from '../../../util/helper.util';
import { DetailBaseComponent } from '../../inc/base/detail-base-componenty'; 
import { MenuService } from '../../../service/state/menu.service';
import { SettingsService } from '../../../service/state/settings.service';  
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort'; 
import { AppBannerNotificationFilterStateService } from '../../../service/state/app-banner-notification-filter-state.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAppBannerNotificationFilterSettings } from '../../../model/base/filter-settings';
import { ReportCurrentUserService } from '../../../service/api/report-current-user.service';
import { IApiResponse } from '../../../model/api/response';

@Component({
    selector: 'app-app-banner-notification',
    templateUrl: './app-banner-notification.component.html',
    styleUrls: ['./app-banner-notification.component.scss']
})
export class AppBannerNotificationComponent extends DetailBaseComponent implements OnInit {

	menuId: number = 201; 
    //appBannerNotifications: IAppBannerNotification[]; 
    appBannerNotifications: IApiResponse<IAppBannerNotification>; 
    dataSource = new MatTableDataSource<IAppBannerNotification>();
    moment: any = moment;     
    appBannerNotificationFilterSettings: IAppBannerNotificationFilterSettings;
    readonly ADD_NEW: string = AppConstants.ADD_NEW; 
    readonly ADD_NEW_LINK: string = AppConstants.ADD_NEW_LINK; 
    searchApiSubscription: Subscription;
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportCurrentUserService: ReportCurrentUserService, private _appBannerNotificationService: AppBannerNotificationService, protected _statusService: StatusService, private _appBannerNotificationFilterStateService: AppBannerNotificationFilterStateService, private _spinnerService: SpinnerService, public _helper: Helper, private _snackBar: MatSnackBar, private _cd: ChangeDetectorRef, private _zone: NgZone) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();        

        this._appBannerNotificationFilterStateService.currentFilterSettings
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async(filterSettings) => {
                this.appBannerNotificationFilterSettings = filterSettings as IAppBannerNotificationFilterSettings;  
 
                this.getAppBannerNotifications();
			});        
    }
 

    getAppBannerNotifications(): void {  
        this._spinnerService.startSpinner();  

        if (this.searchApiSubscription) 
            this.searchApiSubscription.unsubscribe();   

        this.searchApiSubscription = this._appBannerNotificationService.index(this.appBannerNotificationFilterSettings.apiFilter) 
			.subscribe(response => { 

                if(response && response.results) {
                    this.appBannerNotifications = response; 
                } 
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            },
            () => {                
                this.appBannerNotificationFilterSettings.paging = { pageIndex: this.appBannerNotificationFilterSettings.paging.pageIndex, pageSize: this.appBannerNotificationFilterSettings.paging.pageSize, length: this.appBannerNotifications.count, pagingStart: null, pagingEnd: null };
                this.dataSource.data = this.appBannerNotifications.results;
            }
        ); 
    }
 
    onPaginateChange(event){  
        this._appBannerNotificationFilterStateService.updateApiFilterPageing({ pageIndex: event.pageIndex +1, pageSize: event.pageSize });
    }

}
