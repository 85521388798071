<div id="imgModal" class="slide-modal" *ngIf="lightboxFiles">
    <span class="close cursor" (click)="closeModal()">&times;</span>
    <div class="slide-modal-content">
  
        <div class="slide" *ngFor="let file of lightboxFiles; let i = index">            
            <a href="{{file.path}}" class="file-box" target="_blank">
                <img *ngIf="file.isImage" src="{{file.path}}">
                <mat-icon *ngIf="file.isPDF" class="file-icon" mat-list-icon >topic</mat-icon>
                <mat-icon *ngIf="file.isVideo" class="file-icon" mat-list-icon >videocam</mat-icon>
                <mat-icon *ngIf="file.isAudio" class="file-icon" mat-list-icon >play_circle_filled</mat-icon>
                <mat-icon *ngIf="!file.isImage && !file.isPDF && !file.isVideo && !file.isAudio" class="file-icon" mat-list-icon >file_download</mat-icon>
            </a> 
        </div>
        
        <a class="prev" (click)="slideItem(-1)">&#10094;</a>
        <a class="next" (click)="slideItem(1)">&#10095;</a>
        
        <div class="footer">  
            <div class="image-title" *ngFor="let file of lightboxFiles; let i = index">
                <div *ngIf="file.date">{{ moment(file.date).format('DD.MM.Y HH:mm') }}</div>
            </div> 
            <span class="dots" *ngFor="let file of lightboxFiles; let i = index">
                <div class="dot cursor" (click)="currentSlide(i)"></div> 
            </span>
        </div>
    </div>
</div>
