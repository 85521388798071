import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { IUser } from '../../model/api/user'; 

@Injectable({
  	providedIn: 'root'
})


export class UserStateService {
    private _user: BehaviorSubject<IUser> = new BehaviorSubject(null);
    user: Observable<IUser> = this._user.asObservable();
	  
	constructor() { }

	setUser(newUser: IUser): void { 
        this._user.next(newUser);              
	}   

}
