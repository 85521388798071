import {InjectionToken} from "@angular/core";
import {environment} from '../../environments/environment';
export let APP_CONFIG = new InjectionToken("app.config");

export interface IAppConfig {
    host: string;
    api_url: string;
}

export const AppConfig: IAppConfig = { 
    host: environment.envUrl,
    api_url: environment.apiUrl
};
