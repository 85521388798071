<div class="main-search">
    <div class="search-col" *ngIf="userFilterSettings && userFilterSettings.filterCategories">
        <app-search-text></app-search-text>
        <button mat-raised-button mat-button [matMenuTriggerFor]="menu">
            <span class="material-icons">add_box</span> Filter
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let category of userFilterSettings.filterCategories" [value]="category.id" (click)="openDialog(category.id)">{{category.name}}</button> 
        </mat-menu>
    </div>

    <div class="chips-col">
        <mat-chip-list #chipList *ngIf="filterChips">            
            <mat-chip  *ngFor="let chip of filterChips" [selectable]="selectable" [removable]="removable" (removed)="chip.removeFunction(chip)"> 
                {{ chip.title }} 
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>     
        </mat-chip-list>
    </div>
</div> 



