import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IInvitation } from '../../model/api/invitation'; 
import { APP_CONFIG } from '../../app.config/app.config';
import { BaseService } from '../base/base.service';

@Injectable({
	providedIn: 'root'
})

export class InvitationService extends BaseService<IInvitation> {
    protected urlV2 = "/admin-v02/invitation";

    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }

}
