import { Injectable}  from '@angular/core';
import { IUserExportFilterSettings } from '../../model/base/filter-settings';
import { Helper } from '../../util/helper.util';
import { FilterStateService } from '../base/filter-state.service';


@Injectable({
  providedIn: 'root'
})

export class UserExportFilterStateService extends FilterStateService<IUserExportFilterSettings>   {
 
    protected filterSettingsName = 'userExportFilterSettings';

    constructor(private __helper: Helper) {
        super(__helper); 
        this._currentFilterSettings.next(this.getFilterSettings()); 
    }
 
 
    protected getFilterSettings(): IUserExportFilterSettings   {
        let filterSettings: IUserExportFilterSettings = this._currentFilterSettings.getValue();
        if(!this.__helper.isNullOrUndefined(filterSettings))
            return filterSettings;

        this.defaultFilterSettings.displayedColumns = this.defaultFilterSettings.defaultReportColumns[0].columns;   
 
        return this.defaultFilterSettings;
    }

    private defaultFilterSettings: IUserExportFilterSettings = { 
        id: null, 
        selectedColumns: [ 
            { name: "id", prettyName: "Id"},
            { name: "created_at", prettyName: "Created at"},
            { name: "is_processing", prettyName: "Is processing"}, 
            { name: "is_done", prettyName: "Is done"},
            { name: "link", prettyName: "Link"},
        ],
        defaultReportColumns: [ 
            { key: "UserExport", columns: [ 'id', 'created_at', 'is_processing', 'is_done', 'link'] } 
        ],
        displayedColumns: [],
        apiFilter: [],  
        paging: { pageIndex: 1, pageSize: 50, length: null, pagingStart: null, pagingEnd: null },
        ordering: '-id'
    } 
}
 