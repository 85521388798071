import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { LoginComponent } from './login/login.component';
import { AdminModule } from './admin/admin.module';
import { APP_CONFIG, AppConfig } from "./app.config/app.config";
import { SpinnerService } from './service/spinner.service';
import { SecurityService } from './service/security/security.service';
import { SecurityGuard } from "./service/security/security-guard.service";
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SnackBarService } from './service/snackbar.service';
import { SettingsService } from './service/state/settings.service';
import { CustomCoreModule } from './custom-core.module'; 


@NgModule({
	declarations: [
		AppComponent,        
		LoginComponent     
	],
	imports: [ 
		BrowserModule,		
		BrowserAnimationsModule,
        HttpClientModule, 
		CustomCoreModule,
        AppRoutingModule,
        AdminModule 
        
	],
	exports: [  
        //AngularMaterialModule
    ],
	providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{ provide: APP_CONFIG, useValue: AppConfig },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, 
        /* { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, */
		SecurityService,
		SecurityGuard,
		SpinnerService,
        SnackBarService,
        SettingsService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
