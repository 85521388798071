import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { IConversationMessage, IConversationMessageRequest, IConversationMessageResponse } from '../../model/api/conversation-message';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IApiResponse, IUserResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';
  
@Injectable({
  	providedIn: 'root'
})
export class ConversationMessageService extends BaseService<IConversationMessage> {

    protected urlV2 = "/admin-v02/conversation-message";  
    private url = this._config.api_url + "/admin/messages";

    //public cacheValidForSeconds = 360;
    public responseCache$ = new Map<string, IApiResponse<IConversationMessageResponse<IConversationMessage>>>();
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    
     
    post<T>(conversationMessageId: number, conversationMessageRequest: IConversationMessageRequest, files: any[]): Observable<IUserResponse<IConversationMessage>> {
        let urlFinal = `${this.url}/${conversationMessageId}`;     
 
        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) { 
            formData.append('file',  files[i].file, files[i].name);
        } 

        formData.append('text', conversationMessageRequest.text); 
 
  
        return this._http.post(urlFinal, formData)
            .pipe(
                map((res: IUserResponse<IConversationMessage>) => {

                    this.responseCache$.clear();

                    if (res && res.success == true) { 
                        return res;
                    }
                  }), 
                  catchError( err =>  { return this.handleError(err)})
            );
    }

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);

        /* let error = httpError.error;
        let errorMessage = error.detail || error.message || error.msg || 'An error occured!';
        return throwError(errorMessage); */
    }


}
