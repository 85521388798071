import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SecurityGuard } from 'src/app/service/security/security-guard.service';
import { MessageListComponent } from './message-list/message-list.component';


const routesMessage: Routes = [
    { path: '', redirectTo: '/admin/message/shrink-all', pathMatch: 'full' },
    { path: ':status', component: MessageListComponent, canActivate: [SecurityGuard] },
    { path: '**', redirectTo: '/login' }
];

@NgModule({ 
    imports: [RouterModule.forChild(routesMessage)],
	exports: [RouterModule]
})
export class MessagesRoutingModule { }
