import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ColorStatusService {

    constructor() { }

    // TODO add white color text for red, blue and purple

    getUserColorStatus(status: number) {
        return this.getColor(status);
    }


    private getColor(statusId: number) {

        switch(statusId) {
            
            case 2:
                return '#4caf50';
            case 3:
                return '#f44336';
            case 4:
                return '#d50000';
            case 5:
            case 501:
            case 510:
            case 511:
                return '#00acc1';
            case 6:
                return '#ba68c8';
            case 7:
                return '#0d47a1';
            case 8:
                return '#f50057';
            case 9:
                return '#2979ff';
            case 10:
                return '#90a4ae';
            case 301:
                return '#ea9999';
            case 502:
            case 512:
                return '#c27ba0';
            case 550:
                return '#e69138';
            default:
                return '#2bc49a';
        }

    }
  
}
