import { NgModule } from '@angular/core';
import { UserComponent } from '../user/user.component'; 
import { UserListComponent } from '../user/user-list/user-list.component';  
import { SearchUserComponent} from '../user/inc/search/search.component';
import { UserDialogComponent} from '../user/inc/search/dialog/dialog.component';
import { SearchTextComponent } from '../user/inc/search-text/search-text.component'; 
import { CountryService } from '../../service/api/country.service';
import { UserDetailsComponent } from '../user/user-details/user-details.component';
import { ConversationComponent } from '../user/conversation/conversation.component'; 
import { AddNewConversationComponent } from '../user/conversation/add-new/add-new.component';
import { ComplaintComponent } from '../user/complaint/complaint.component';
import { AddNewComplaintComponent } from '../user/complaint/add-new/add-new.component';
import { EconsultationComponent } from './econsultation/econsultation.component';
import { UserUploadComponent } from '../user/user-upload/user-upload.component';
import { ImageUploadComponent } from '../user/user-upload/image-upload/image-upload.component';
import { SmileboxOrderComponent } from '../user/smilebox-order/smilebox-order.component';
import { SmileboxService } from '../../service/api/smilebox.service';
import { TreatmentPlanProcessingComponent } from '../user/treatment-plan-processing/treatment-plan-processing.component';
import { DoctorService } from '../../service/api/doctor.service';
import { FileTypeService } from '../../service/api/file-type.service';
import { AdminFileService } from '../../service/api/admin-file.service';
import { DeleteFileDialogComponent } from '../user/treatment-plan-processing/delete-file-dialog/delete-file-dialog.component'; 
import { UserDetailService } from '../../service/api/user-detail.service';
import { UserListServiceV1 } from '../../service/api/user-v1.service';
import { UserPictureService } from '../../service/api/user_picture.service';
import { UserQuestionService } from '../../service/api/user-question.service';
import { DoctorPatientService } from '../../service/api/doctor-patient.service';
import { UserFileService } from '../../service/api/user-file.service';
import { ReportUserFileService } from '../../service/api/report-user-file.service';
import { SmileBoxAdminReviewService } from '../../service/api/smile-box-admin-review.service';
import { StraightboxService } from '../../service/api/straightbox.service';
import { StraightboxOrderComponent } from '../user/straightbox-order/straightbox-order.component';
import { ReminderService } from '../../service/api/reminder.service';
import { SmileboxFileService } from '../../service/api/smilebox-file.service';
import { InvitationsComponent } from '../user/invitations/invitations.component';
import { ClinicalManagementComponent } from '../user/clinical-management/clinical-management.component';
import { LostAlignerTrackerService } from '../../service/api/lost-aligner-tracker.service';
import { ClinicalIssueService } from '../../service/api/clinical-issue.service';
import { UserMessageService } from '../../service/api/user-message.service';
import { ConversationMessageFileService } from '../../service/api/conversation-message-file.service';
import { UserRoutingModule } from '../user/user-routing.module'
import { SharedModule } from '../shared/shared.module';
import { CustomCoreModule } from 'src/app/custom-core.module';
import { LinkyModule } from 'ngx-linky';
import { MatchesCountriesPipe } from '../../pipe/matches-countries.pipe';
import { MatchesStatusesPipe } from '../../pipe/matches-stuses.pipe';
import { MaintainerPlanComponent } from './maintainer-plan/maintainer-plan.component';

@NgModule({
    declarations: [
        UserComponent,   
        UserListComponent, 
        SearchUserComponent,
        UserDialogComponent,
        SearchTextComponent,  
        UserDetailsComponent,
        ConversationComponent, 
        AddNewConversationComponent,
        ComplaintComponent,
        AddNewComplaintComponent,
        EconsultationComponent,
        UserUploadComponent,
        ImageUploadComponent, 
        SmileboxOrderComponent, 
        TreatmentPlanProcessingComponent,
        DeleteFileDialogComponent, 
        StraightboxOrderComponent,
        InvitationsComponent,
        ClinicalManagementComponent,
        MatchesCountriesPipe,
        MatchesStatusesPipe,
        MaintainerPlanComponent
    ],
    exports:  [ ],
    imports: [
        CustomCoreModule,
        UserRoutingModule,
        SharedModule,
        LinkyModule
    ],
    providers: [ 
        CountryService,
        SmileboxService,
        DoctorService,
        FileTypeService,
        AdminFileService,
        UserDetailService,
        UserListServiceV1,
        UserPictureService,
        UserQuestionService,
        DoctorPatientService,
        UserFileService,
        ReportUserFileService,
        SmileBoxAdminReviewService,
        StraightboxService,
        ReminderService,
        SmileboxFileService,
        LostAlignerTrackerService,
        ClinicalIssueService, 
        ConversationMessageFileService,
        UserMessageService
    ]
})
export class UserModule { }
