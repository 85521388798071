import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { IComplaint, IComplaintRequest } from '../../../../model/api/complaint';
import { IComplaintCategory } from '../../../../model/api/complaint-category';
import { IUserProfile } from '../../../../model/api/user-profile';
import { INavigationSettings, ISettings } from '../../../../model/settings';
import { ComplaintCategoryService } from '../../../../service/api/complaint-category.service';
import { AppConstants } from '../../../../settings/app-constants';
import { MenuService } from '../../../../service/state/menu.service';
import { DetailUserBaseComponent } from '../../../inc/base/detail-user-base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../../util/helper.util';
import { SettingsService } from '../../../../service/state/settings.service';
import { ConversationService } from '../../../../service/api/conversation.service';
import { IConversation } from '../../../../model/api/conversation';
import { ComplaintService } from '../../../../service/api/complaint.service';
import { UserDetailService } from '../../../../service/api/user-detail.service';
import { ReportUserInfoService } from '../../../../service/api/report-user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportUserInfoStateService } from '../../../../service/state/report-user-info-state.service';
import { SpinnerService } from '../../../../service/spinner.service';
import { UserService } from '../../../../service/api/user.service';
import { UserStateService } from '../../../../service/state/user-state.service';

@Component({
    selector: 'app-add-new',
    templateUrl: './add-new.component.html',
    styleUrls: ['./add-new.component.scss']
})
export class AddNewComplaintComponent extends DetailUserBaseComponent  implements OnInit {

	complaintCategories: IComplaintCategory[];
	complaintRequest: IComplaintRequest;
    userProfile: IUserProfile;
	userId: number; 
    settings: ISettings;
    menuId: number = 1020;
    moment: any = moment;
    conversations: IConversation[];
    navigationSettings: INavigationSettings;
    conversationId: number;
    readonly CANCEL:string = AppConstants.CANCEL;
    readonly CONFIRM:string = AppConstants.CONFIRM;
    readonly ERROR_FILL_ALL_FIELDS:string = AppConstants.ERROR_FILL_ALL_FIELDS;
    readonly THREAD_CATEGORY:string = AppConstants.THREAD_CATEGORY;
    readonly FROM_EMAIL:string = AppConstants.FROM_EMAIL;
    
	
    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _complaintService: ComplaintService, private _complaintCategoryService: ComplaintCategoryService, private _conversationService: ConversationService, private _helper: Helper, private _snackBar: MatSnackBar, private _spinnerService: SpinnerService) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.conversationId = +this.route.snapshot.paramMap.get('conversationId');
        this.complaintRequest = { text: null, user: this.userId, category: null, received_at: moment().toDate(), conversation: null, is_from_mail: false };

        if(this.conversationId > 0) 
            this.complaintRequest.conversation = this.conversationId;
 
        this.getComplaintCategories();
        this.getConversations();

    }

    getComplaintCategories(): void { 
        this._spinnerService.startSpinner();

        this._complaintCategoryService.index()
			.subscribe(conversationTemplates => {  
                this.complaintCategories = conversationTemplates.results;
                this._spinnerService.stopSpinner(); 
			},
            error =>{  
                this._spinnerService.stopSpinner();  
            });
	}

    getConversations(): void { 
        this._spinnerService.startSpinner();

        this._conversationService.index({patient_id: this.userId, page: 1, page_size: 50})
			.subscribe(data => {
                this.conversations = data.results; 
                this._spinnerService.stopSpinner();  
			},
            error =>{  
                this._spinnerService.stopSpinner();  
            });
	}

    isConversationFromEmail(isConversationFromEmail: boolean): void {
        if(isConversationFromEmail)
            this.complaintRequest.conversation = null;
    }

    addNewComplaint(complaintRequest: IComplaint): void {

 
        if(this._helper.isNullOrUndefined(complaintRequest) || complaintRequest.category == null || complaintRequest.received_at == null || this._helper.isNullOrUndefined(complaintRequest.text) || (complaintRequest.conversation == null && !complaintRequest.is_from_mail)) { 
            this._snackBar.open(this.ERROR_FILL_ALL_FIELDS, 'Close', this.matSnackBarConfig);      
            return;
        }

        if(!this.reportUserInfo.has_complaint)
            this._reportUserInfoService._responseDetailCache$.clear();  

        this._spinnerService.startSpinner();

        let now: Date = moment().toDate();
        complaintRequest.received_at = moment(complaintRequest.received_at).add({hours: now.getHours(), minutes: now.getMinutes(), seconds: now.getSeconds()}).toDate();

        this._complaintService.post(this.userId, complaintRequest)
            .subscribe(response => { 
                     if(response) { 
                        this._router.navigateByUrl(this.navigationSettings.parentUrl);  
                    }  
                    this._spinnerService.stopSpinner();  
                },
                error =>{  
                    this._spinnerService.stopSpinner();  
                });

	}

}
