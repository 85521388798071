import { Pipe, PipeTransform } from '@angular/core';
import { ICountry } from '../model/api/country';

@Pipe({
  name: 'matchCountries'
})
export class MatchesCountriesPipe implements PipeTransform {

    transform(countries: ICountry[], countryCode: string[]): ICountry[] {
        /*
        console.log('--------------------')
        console.log('countries:');
        console.log(countries);
        console.log('countryCode:');
        console.log(countryCode); */
        let retCountries: ICountry[] = countries.filter(country => {
            if(countryCode.indexOf(country.code) > -1)
                return country;
        })
        //console.log('retCountries:');
        //console.log(retCountries);
        return retCountries;
    }

}
