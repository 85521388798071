import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class HelperExtension { 

    private _allExtensions: Array<string> = [];
    private _image: Array<string> = ['jpg', 'png', 'gif', 'webp', 'tiff', 'bmp', 'jpeg'];
    private _video: Array<string> = ['webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov'];
    private _file: Array<string> = ['doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt', 'csv'];
  
    getAsExtensions(extensions: Array<string>): string {
        return extensions.map(extension => `.${extension}`).join(',');
    } 
    all(): Array<string> {

        if(this._allExtensions?.length > 0) 
            return this._allExtensions;

        this._allExtensions.push(...this._image);
        this._allExtensions.push(...this._video);
        this._allExtensions.push(...this._file);

        return this._allExtensions;          
    }

    allAsExtensions(): string {
        return this.all().map(extension => `.${extension}`).join(',');
    }

    image(): Array<string> { 
        return this._image;
    }

    imageAsExtensions(): string {
        return this._image.map(extension => `.${extension}`).join(',');
    }

    video(): Array<string> { 
        return this._video;
    }

    videoAsExtensions(): string {
        return this._video.map(extension => `.${extension}`).join(',');
    }

    file(): Array<string> { 
        return this._file;
    }

    fileAsExtensions(): string {
        return this._file.map(extension => `.${extension}`).join(',');
    }
 
}