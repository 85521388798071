import { Component, OnInit, Inject } from '@angular/core'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { Helper } from '../../../../../util/helper.util';  
import { ISearchDialogData } from '../../../../../model/base/search-dialog-data';
import { CountryService}  from '../../../../../service/api/country.service';  
import { IUserFilterSettings } from '../../../../../model/base/filter-settings';
import { UserFilterStateService } from '../../../../../service/state/user-filter-state.service';
import { ICountry } from '../../../../../model/api/country';
import { StatusService } from '../../../../../service/status.service';
import { IStatus } from '../../../../../model/status';
import { SeverityStatusService } from '../../../../../service/severity-status.service';
import { ISeverityStatus } from '../../../../../model/severity-status';
import * as moment from 'moment'; 
import { takeUntil } from 'rxjs/operators';	
import { Subject } from "rxjs";
import { FilterCategory } from 'src/app/settings/filter-category'; 
import { IFilterUserSubmission } from 'src/app/model/base/filter-user-submission';



@Component({
  selector: 'app-post-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.less']
})
export class UserDialogComponent implements OnInit {

    countries: ICountry[]= []; 
    availebleCountries: ICountry[]= []; 
    statuses: IStatus[]= []; 
    selectedStatuses: IStatus[] = [];
    severityStatuses: ISeverityStatus[] = []; 
    selectedSeverityStatuses: ISeverityStatus[] = [];
    userFilterSettings: IUserFilterSettings;
    filterCategoryId: number;
    moment: any = moment;
    countrySearch: string;
    errorMessage: string;
    filterCategory = FilterCategory;
    private ngUnsubscribe: Subject<any> = new Subject();  
    filterUserSubmission: IFilterUserSubmission;
 
    constructor(private _userFilterStateService: UserFilterStateService, private countryService: CountryService, public _helper: Helper, public dialogRef: MatDialogRef<UserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ISearchDialogData, protected statusService: StatusService, protected severityStatusService: SeverityStatusService) {}

    ngOnInit() { 
 
        this.filterCategoryId = this.data.filterCategoryId;
         
        this._userFilterStateService.currentFilterSettings
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async(userFilterSettings) => {
                this.userFilterSettings = userFilterSettings as IUserFilterSettings;
			});        
        
        this.getData();  
 
    }
  
    getData():void  {   
        
        this.filterUserSubmission = { ...this.data.defaultFilter };

        if(this.filterCategoryId == FilterCategory.Country) {       
            this.countryService.index()
                .subscribe(response => { 
                    this.countries = response.results; 
                    this.availebleCountries = Object.assign(this.countries, {});
                },
                error => error
            );
        }

        if(this.filterCategoryId == FilterCategory.Status) {       
            this.statuses = this.statusService.getStatuses();
        } 

        if(this.filterCategoryId == FilterCategory.Score) {       
            this.severityStatuses = this.severityStatusService.getSeverityStatuses();
        }

        if(this.filterCategoryId == FilterCategory.SmileboxOrders && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__created_at__gte)) {
            this.filterUserSubmission.userorderssmilebox__created_at__gte = this.setDefaultFromDate();
        }
        
        if(this.filterCategoryId == FilterCategory.TreatmentPlanProcessing && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__impressions_received__gte)) {
            this.filterUserSubmission.userorderssmilebox__impressions_received__gte = this.setDefaultFromDate();
        }

        if(this.filterCategoryId == FilterCategory.StraightboxOrders && this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__payed_date__gte)) {
            this.filterUserSubmission.user_straigthbox__payed_date__gte = this.setDefaultFromDate();
        } 
        
    }  

    onClose():void {
        this.errorMessage = ''; 
 
 
        if(this.filterCategoryId == FilterCategory.Country && this._helper.isNullOrUndefined(this.filterUserSubmission.user_details__country__in)) {
            this.errorMessage = 'Please select item.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.Status && this._helper.isNullOrUndefined(this.filterUserSubmission.user_details__status_id__in)) {
            this.errorMessage = 'Please select item.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.Score && this._helper.isNullOrUndefined(this.filterUserSubmission.user_details__severity_status__in)) {
            this.errorMessage = 'Please select item.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.UserRegister && (this._helper.isNullOrUndefined(this.filterUserSubmission.created_at__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.created_at__lt))) {
            this.errorMessage = 'Please select date.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.SmileboxOrders && (this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__created_at__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__created_at__lt) && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__smilebox_shipped_date__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__smilebox_shipped_date__lt))) {
            this.errorMessage = 'Please select date.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.TreatmentPlanProcessing && (this._helper.isNullOrUndefined(this.filterUserSubmission.user_meta__smilebox_files_created_at__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_meta__smilebox_files_created_at__lt) && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__impressions_received__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.userorderssmilebox__impressions_received__lt) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__created_at__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__created_at__lt))) {
            this.errorMessage = 'Please select date.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.StraightboxOrders && (this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__payed_date__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__payed_date__lt) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__shipped_date__gte) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_straigthbox__shipped_date__lt))) {
            this.errorMessage = 'Please select date.';
            return;
        } 

        if(this.filterCategoryId == FilterCategory.AdditionalFilters && (this._helper.isNullOrUndefined(this.filterUserSubmission.user_details__is_vip) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_details__policy_breach_status) && this._helper.isNullOrUndefined(this.filterUserSubmission.user_details__is_sensitive_user) && this._helper.isNullOrUndefined(this.filterUserSubmission.maintainer_plan__active))) {
            this.errorMessage = 'Please select item.';
            return;
        } 
 
        if(this.filterCategoryId == FilterCategory.AdditionalFilters) {

           if(!this.filterUserSubmission.user_details__is_vip)
                this.filterUserSubmission.user_details__is_vip = null; 

            if(!this.filterUserSubmission.user_details__policy_breach_status)
                this.filterUserSubmission.user_details__policy_breach_status = null; 

            if(!this.filterUserSubmission.user_details__is_sensitive_user)
                this.filterUserSubmission.user_details__is_sensitive_user = null; 

            if(!this.filterUserSubmission.maintainer_plan__active)
                this.filterUserSubmission.maintainer_plan__active = null; 

            /* if(!this.filterUserSubmission.search_not_active)
                this.filterUserSubmission.search_not_active = null;  */
        } 

        this.setDefaultOrdering();

        this.data.defaultFilter = this.filterUserSubmission;
        this.dialogRef.close(this.data); 
    }
 
    onVeiwSet(): void { 
        this.userFilterSettings.filterCategoryId = this.filterCategoryId;
        this._userFilterStateService.changeDisplayedColumnsView(this.userFilterSettings);
    }
  
    onNoClick(): void { 
        this.dialogRef.close(this.data);
    }

    findSearchCountry() {   
        this.errorMessage = '';

        this.countrySearch = this.countrySearch.replace(/ +(?=)/g, ' '); 
 
        this.availebleCountries = this.countries.filter(country => {
            return country.name.toLowerCase().includes(this.countrySearch.toLowerCase());
        });

	}

    setDefaultOrdering() {

        /* if(![FilterCategory.UserRegister, FilterCategory.SmileboxOrders, FilterCategory.TreatmentPlanProcessing, FilterCategory.StraightboxOrders].includes(this.filterCategoryId))
            return; */
 
        switch(this.filterCategoryId) {
            case FilterCategory.UserRegister:
                this.data.ordering = '-created_at';
                break;
            case FilterCategory.SmileboxOrders:
                this.data.ordering = '-userorderssmilebox__created_at';
                break;
            case FilterCategory.TreatmentPlanProcessing:
                this.data.ordering = '-userorderssmilebox__impressions_received';
                break;
            case FilterCategory.StraightboxOrders:
                this.data.ordering = '-user_straigthbox__payed_date';
                break;
            default:
                this.data.ordering = '-id';
                break;
        }
 
    }
 
    setDefaultFromDate(): Date {
        return moment(moment.utc(new Date("01-01-2017"))).toDate();
    }

    ngOnDestroy(): any { 
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    } 
 

}
