export class AppConstants
{ 
    public static readonly EXPAND_ALL = 'Expand All';
    public static readonly SHRINK_ALL = 'Shrink all';
    public static readonly ADD_NEW = 'Add new';
    public static readonly EXPORT = 'Export';
    public static readonly NEW_MESSAGE_TO = 'New message to';
    public static readonly REPLY_MESSAGE_TO = 'Reply message to';
    public static readonly REPLY = 'Reply'; 
    public static readonly CANCEL = 'Cancel';
    public static readonly CONFIRM = 'Confirm';
    public static readonly ERROR_FILL_ALL_FIELDS = 'Please fill all fields!';
    public static readonly THREAD_CATEGORY = 'Thread category';
    public static readonly FROM_EMAIL = 'From email';
    public static readonly USER_PICTURES_MODEL = 'UserPictures';
    public static readonly CONVERSATION_FILES_MODEL = 'ConversationFiles'; 
    public static readonly ADD_BEFORE_FILE_TYPE = 'Before';
    public static readonly ADD_AFTER_FILE_TYPE = 'After';
    public static readonly UPLOAD = 'Upload';
    public static readonly UPLOAD_FILE = 'Upload file';
    public static readonly UPLOAD_FILES = 'Upload files';
    public static readonly UPLOAD_IMAGE = 'Upload image';
    public static readonly UPLOAD_IMAGES = 'Upload images';
    public static readonly ECONSULTATION_UPLOADS_VALUE = 'ECONSULTATION_UPLOADS'; 
	public static readonly CONVERSATION_UPLOADS_VALUE = 'CONVERSATION_UPLOADS'; 
    public static readonly SCAN_REFERRAL_DONE = 'SCAN REFERRAL DONE'; 
    public static readonly SHIPPED = 'SHIPPED'; 
    public static readonly IMPRESSIONS_RECEIVED = 'Impressions received';
    public static readonly DIGITAL_IMPRESSIONS_RECEIVED = 'Digital impressions received'; 
    public static readonly RECORDS_LEGACY_UPLOADS = 'Records - legacy uploads'; 
    public static readonly CLOSE_BUTTON = 'Close'; 
    public static readonly DELETE_BUTTON = 'Delete'; 
    public static readonly REJECT_BUTTON = 'Reject'; 
    public static readonly APPROVE_BUTTON = 'Approve'; 
    public static readonly SAVE_BUTTON = 'Save'; 
    public static readonly REMOVE_BUTTON = 'Remove';  
    public static readonly MARK_AS_COMPLETE_BUTTON = 'Mark as complete';    
    public static readonly CURRENT_STATUS = 'Current status'; 
    public static readonly ERROR_UPLOAD_MISSING_FILE = 'Please provide report file!'; 
    public static readonly NOT_ASSIGNED = 'NOT ASSIGNED'; 
    public static readonly CHANGE_IMAGE = 'Change image';
    public static readonly ADD_IMAGE = 'Add image';
    public static readonly ADD_FILE = 'Add file';
    public static readonly USER_PICTURES_ROTATION_MODEL = 'user_pictures';
    public static readonly CONVERSATION_FILES_ROTATION_MODEL = 'conversation_files';
    public static readonly ROTATE_IMAGE_LEFT = '90';
    public static readonly ROTATE_IMAGE_RIGHT = '-90';
    
    
    // Links
    public static readonly EXPAND_ALL_LINK = 'expand-all';
    public static readonly ADD_NEW_LINK = 'add-new';
    public static readonly IMAGE_UPLOAD_LINK = 'image-upload';
    public static readonly DETAIL_LINK = 'detail';
    // Conversation patterns
    public static readonly SMILEBOX_TRACKING_NUMBER = 'SMILEBOX_TRACKING_NUMBER';
    public static readonly STRAIGHTBOX_TRACKING_NUMBER = 'STRAIGHTBOX_TRACKING_NUMBER';
    public static readonly USER_NAME = 'USER_NAME';
    public static readonly USER_FIRST_NAME = 'USER_FIRST_NAME';
    public static readonly USER_LAST_NAME = 'USER_LAST_NAME';
    public static readonly PATIENT_NAME = 'PATIENT_NAME';
    public static readonly PATIENT_FIRST_NAME = 'PATIENT_FIRST_NAME';
    public static readonly PATIENT_LAST_NAME = 'PATIENT_LAST_NAME';
    public static readonly PATIENT_EMAIL = 'PATIENT_EMAIL';
    public static readonly PATIENT_PROFILE_URL = 'PATIENT_PROFILE_URL';
    public static readonly CURRENT_MONTH = 'CURRENT_MONTH';
    public static readonly USER_ID = 'USER_ID';
    public static readonly PATIENT_ID = 'PATIENT_ID'; 
    public static readonly ALIGNER_FIRST_WARNING = 'aligner_first_warning'; 
    public static readonly ALIGNER_SECOND_WARNING = 'aligner_second_warning'; 
    public static readonly ALIGNER_LAST_WARNING = 'aligner_last_warning'; 
    // Drop down select options
    public static readonly SELECT_ALIGNER_SELECT_OPTION = 'Select aligner'; 
    public static readonly ALIGNER_FIT_SELECT_OPTION = 'Aligner fit';  
}
 