import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core';

@Directive({
 selector: '[showAuthed]'
})

// https://thinkster.io/tutorials/building-real-world-angular-2-apps/authentication-based-directives
export class UserAccessDirective { 
    condition: boolean;
    constructor(private el: ElementRef, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }
 
    @Input() set showAuthed(condition: boolean) {
        this.condition = condition;
    }
 
    ngOnInit(): void {   
 
        // if (isAuthenticated && this.condition || !isAuthenticated && !this.condition) {
             this.viewContainer.createEmbeddedView(this.templateRef);
        // } else {
        //     this.viewContainer.clear();
        // } 
        //this.el.nativeElement.style.display = 'block'; 
    }
}