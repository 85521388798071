import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { IComplaint } from '../../model/api/complaint';  
import { APP_CONFIG  } from '../../app.config/app.config'; 
import { IUserResponse } from '../../model/api/response';  
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})

export class ComplaintService extends BaseService<IComplaint>  { 
     
    protected urlV2 = "/admin-v02/user-complaint";
    protected urlV1 =  this._config.api_url + "/admin/user-complaint";
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    

    post<T>(userId: number, complaintRequest: IComplaint): Observable<IUserResponse<IComplaint>> {
        let urlFinal = `${this.urlV1}?user_id=${userId}`; 
        return this._http.post(urlFinal, complaintRequest)
            .pipe(
                map((res: IUserResponse<IComplaint>) => {
 
                    this._responseCache$.clear();
                    if (res) { 
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);
 
    }


}
