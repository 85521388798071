import { NgModule } from '@angular/core';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { UserPermissionCheckDirective } from './directive/user-permission-check.directive';

@NgModule({ 
        declarations: [
	        UserPermissionCheckDirective    
	],
	imports: [  
                CommonModule,        
                AngularMaterialModule,        
                FormsModule,
                FlexLayoutModule
	],
	exports: [ 
                CommonModule,        
                AngularMaterialModule,        
                FormsModule,
                FlexLayoutModule,
                UserPermissionCheckDirective
        ] 
})
export class CustomCoreModule { }
