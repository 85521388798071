<div *ngIf="userMessages && userMessages.length > 0  && userMessages[0].text">
    <h3>Status message</h3>
    <div fxLayout="row" fxFlexFill  *ngFor="let userMessage of userMessages; let i = index;">
        <div fxFlex="100" fxLayout="column" fxLayoutGap="20px" fxFlexFill > 
            <div fxLayout="row"  fxLayout.xs="column"  fxLayoutGap="20px" >
                <div fxFlex="10"><strong>Status</strong></div>
                <div fxFlex="90">{{ userStatusName['status-' + userMessage.status] }}</div> 
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" >
                <div fxFlex="10"><strong>Date</strong></div>
                <div fxFlex="90" *ngIf="userMessage.created_at">{{ moment(userMessage.created_at).format('DD.MM.Y') }}</div> 
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"  >
                <div fxFlex="10"><strong>Message</strong></div>
                <div fxFlex="90">{{ userMessage.text }}</div> 
            </div>
        </div> 
    </div>
    <br />
    <mat-divider [inset]="true"></mat-divider>
</div>

<div *ngIf="userQuestions">    
    <div *ngIf="smileboxFiles && smileboxFiles.length > 0">
        <h3>E-consultation results</h3>
        <span *ngFor="let smileboxFile of smileboxFiles; let i = index">
            <a href="{{ smileboxFile.medical_documentation }}" target="_blank">
                <span class="material-icons">file_download</span>
            </a> 
        </span>
    </div>
   
    <h3>E-consultation answers</h3>
    <mat-list *ngFor="let userQuestion of userQuestions; let i = index;">
        <mat-list-item>
            <div fxLayout="column" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px" >    
                <div fxFlex="100"><div class="questions">{{ userQuestion.question__name }} <br />
                    <span class="accent-color">{{ userQuestion.answer }}</span></div>
                </div>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>

    <h3>Uploaded images</h3>
    <h4 *ngIf="userPictures && userPictures.length > 0">Submissions: {{ moment(userPictures[1].created_at).format('DD.MM.Y HH:mm') }}</h4>
    <mat-grid-list class="user-pictures" cols="{{ imageBreakpoint }}" rowHeight="160px" *ngIf="userPictures">
        <mat-grid-tile *ngFor="let userPicture of userPictures; let i = index;">
            <img src="{{ userPicture.image }}" (click)="openLightbox(i)">
        </mat-grid-tile>
    </mat-grid-list>
</div>