import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../service/state/menu.service';
import { IMenu } from '../../../model/menu';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util';
import { SettingsService } from '../../../service/state/settings.service';
import { ISettings } from '../../../model/settings';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {

    rootMenu: IMenu;
    settings: ISettings; 
    url: string;

    protected ngUnsubscribe: Subject<any> = new Subject();
    
	constructor(private _menuService: MenuService, private _settingsService: SettingsService, private route: ActivatedRoute, private router: Router, private _helper: Helper,  private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        combineLatest([this._settingsService.settings, this._menuService.menu])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(items => {
                this.settings = items[0];
                this.rootMenu = items[1];

                if(this.settings && this.settings.navigationSettings && this.settings.navigationSettings.userId > 0) {
                    this.url = `/admin/${this.rootMenu.url}/${this.settings.navigationSettings.userId}/`;
                    return;
                }

                this.url = `/admin/${this.rootMenu.url}/`;
            });
	}

    
    ngOnDestroy(): any { 
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    } 

}
