import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { IConversationMessageRequest, IConversationMessageResponse } from '../../model/api/conversation-message';  
import { IReportConversation } from '../../model/api/report-conversation';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IApiResponse, IUserResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';
  
@Injectable({
  	providedIn: 'root'
})
export class  ReportConversationService  extends BaseService<IReportConversation> {

    protected urlV2 = "/admin-v02/report-conversation";
 
    public responseCache$ = new Map<string, IApiResponse<IConversationMessageResponse<IReportConversation>>>();
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
    

}
