import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, shareReplay, tap } from 'rxjs/operators'; 
import { IConversation, IConversationRequest } from '../../model/api/conversation';  
import { APP_CONFIG } from '../../app.config/app.config'; 
import { IUserResponse } from '../../model/api/response'; 
import { BaseService } from '../base/base.service';

@Injectable({
  	providedIn: 'root'
})
export class ConversationService extends BaseService<IConversation>  {

    protected urlV2 = "/admin-v02/conversation";  
    private urlV1 = this._config.api_url + "/admin/conversation";
    private urlExportMessagesAsCsv = '/admin/messages-export';
 
    
    constructor(@Inject(APP_CONFIG) private _config, private _http: HttpClient) {
        super(_config, _http)
    }
 
    post<T>(userId: number, conversationRequest: IConversationRequest, files: any[]): Observable<IUserResponse<IConversation>> {
        let urlFinal = `${this.urlV1}/${userId}`; 

        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) { 
            formData.append('file',  files[i].file, files[i].name);
        } 
 
        formData.append('patient', userId.toString());
        formData.append('subject', conversationRequest.subject);
        formData.append('text', conversationRequest.text);
        formData.append('conversation_type', conversationRequest.conversation_type.toString());
   
        return this._http.post(urlFinal, formData)
            .pipe(
                map((res: IUserResponse<IConversation>) => {

                    this._responseCache$.clear();

                    if (res && res.success == true) { 
                        return res;
                    }
                }), 
                catchError( err =>  { return this.handleError(err)})
            );
    }

    getConversationsExport<T>(userId: number): Observable<any> {
        let urlFinal = `${this._config.api_url}${this.urlExportMessagesAsCsv}/${userId}`; 
   
        return this._http.get(urlFinal, { responseType: 'arraybuffer', observe: 'response' });
    } 

    private handleError(httpError:any) {
        let errorMessage = httpError; 
        return throwError(errorMessage);

        /* let error = httpError.error;
        let errorMessage = error.detail || error.message || error.msg || 'An error occured!';
        return throwError(errorMessage); */
    }


}
