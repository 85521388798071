import { Component, OnInit, Inject } from '@angular/core'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { IMaintainerPlan } from 'src/app/model/api/maintainer-plan';
import { IMaintainerPlanDialogData } from 'src/app/model/base/maintainer-plan-dialog-data';
import { MaintainerPlanService } from 'src/app/service/api/maintainer-plan.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { Helper } from 'src/app/util/helper.util';
import { UserStatusHelper } from 'src/app/util/user-status-helper.util';
import { IMessageDialogData } from '../../../model/base/message-dialog-data';


@Component({
  selector: 'app-dialog-maintainer-plan',
  templateUrl: './dialog-maintainer-plan.component.html',
  styleUrls: ['./dialog-maintainer-plan.component.scss']
})
export class DialogMaintainerPlanComponent implements OnInit {

    moment: any = moment;
    selectedDate: Date;
    maintainerPlan: IMaintainerPlan;
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: IMaintainerPlanDialogData,  public dialogRef: MatDialogRef<DialogMaintainerPlanComponent>, private _spinnerService: SpinnerService, private _helper: Helper, private _snackBar: MatSnackBar, public userStatusHelper: UserStatusHelper, private maintainerPlanService: MaintainerPlanService) {}

    ngOnInit(): void {
        this.maintainerPlan = this.data.maintainerPlan;
        this.setMaintainerPlan();
    }

    saveMaintainerPlanDate(): void {
        
        this.selectedDate = moment(this.selectedDate).set({hours: 12, minutes: 0, seconds: 0}).utc().toDate();

        let formData: FormData = new FormData();
        formData.append('active', 'true'); 
        formData.append('joined_at', moment(this.selectedDate).utc().format()); 
        formData.append('user', this.maintainerPlan.user.toString()); 

        this._spinnerService.startSpinner();

        let userId = this.maintainerPlan.active ? this.data.user : null;

        this.maintainerPlanService.save(this.maintainerPlan, formData, userId)
			.subscribe(response => { 

                if(response) { 
                    this.dialogRef.close(this.data);
                }
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 
        
    }
 
    setMaintainerPlan(): void {

        if(this._helper.isNullOrUndefined(this.maintainerPlan.created_at)) {
            this.selectedDate = moment(new Date()).toDate();
            return;
        } 

        this.selectedDate = this.maintainerPlan.joined_at;

    }

}


