import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DetailUserBaseComponent } from '../../inc/base/detail-user-base-component'; 
import * as moment from 'moment';  
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../util/helper.util'; 
import { INavigationSettings } from '../../../model/settings';
import { SettingsService } from '../../../service/state/settings.service';
import { MenuService } from '../../../service/state/menu.service'; 
import { ISmilebox } from '../../../model/api/smilebox';
import { AppConstants } from '../../../settings/app-constants';
import { SmileboxService } from '../../../service/api/smilebox.service'; 
import { IDoctor, IDoctorRequest } from '../../../model/api/doctor';
import { DoctorService } from '../../../service/api/doctor.service';
import { FileTypeService } from '../../../service/api/file-type.service';
import { IFileType } from '../../../model/api/file-type';
import { IFile, IFileDeleteDialog } from '../../../model/api/file';
import { AdminFileService } from '../../../service/api/admin-file.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../service/spinner.service'; 
import { UserDetailService } from '../../../service/api/user-detail.service';
import { DoctorPatientService } from '../../../service/api/doctor-patient.service';
import { IDoctorPatient } from '../../../model/api/doctor-patient';
import { ReportStaffService } from '../../../service/api/report-staff.service';
import { IReportStaf } from '../../../model/api/report-staff';
import { UserType } from '../../../settings/user-type'
import { SmileboxFileService } from '../../../service/api/smilebox-file.service';
import { ISmileBoxFiles } from '../../../model/api/smile-box-files';
import { FileType } from '../../../settings/file-type';
import { ReportUserInfoService } from '../../../service/api/report-user-info.service';
import { ReportUserInfoStateService } from '../../../service/state/report-user-info-state.service';
import { ISmileBoxAdminReview } from '../../../model/api/smile-box-admin-review';
import { SmileBoxAdminReviewService } from '../../../service/api/smile-box-admin-review.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { StraightboxService } from '../../../service/api/straightbox.service';
import { IStraightbox } from '../../../model/api/straightbox';
import { UserStatusHelper } from '../../../util/user-status-helper.util';
import { ConversationService } from '../../../service/api/conversation.service';
import { UserService } from '../../../service/api/user.service';
import { UserStateService } from '../../../service/state/user-state.service';
import { HelperExtension } from 'src/app/util/helper.extension';

@Component({
    selector: 'app-treatment-plan-processing',
    templateUrl: './treatment-plan-processing.component.html',
    styleUrls: ['./treatment-plan-processing.component.scss']
})
export class TreatmentPlanProcessingComponent extends DetailUserBaseComponent implements OnInit {

    moment: any = moment;  
    smilebox: ISmilebox; 
    straightbox: IStraightbox;
    doctors: IReportStaf[];
    seletedDoctor: IDoctor;
    userId: number;
    navigationSettings: INavigationSettings; 
    menuId: number = 107; 
    doctorPatient: IDoctorPatient;
    readonly IMPRESSIONS_RECEIVED:string = AppConstants.IMPRESSIONS_RECEIVED; 
    //readonly ADD_NEW:string = AppConstants.ADD_NEW; 
    //readonly ERROR_FILL_ALL_FIELDS:string = AppConstants.ERROR_FILL_ALL_FIELDS;
    //readonly RECORDS_LEGACY_UPLOADS: string = AppConstants.RECORDS_LEGACY_UPLOADS;
    readonly NOT_ASSIGNED: string = AppConstants.NOT_ASSIGNED;
    fileTypes: IFileType[];
    files: IFile[]; 
    smileboxFiles: ISmileBoxFiles[];
    FileType = FileType;
    userType = UserType;
    smileBoxAdminReviews: ISmileBoxAdminReview[];
    addNewTreatmentPlanDoctor: boolean = false;
    addNewTreatmentPlanAdmin: boolean = false;
    lastAdminApproved: boolean = false;
    lastAdminSeen: boolean = false;
    readonly CURRENT_STATUS:string = AppConstants.CURRENT_STATUS; 
    readonly DELETE_BUTTON:string = AppConstants.DELETE_BUTTON; 
    readonly REJECT_BUTTON:string = AppConstants.REJECT_BUTTON; 
    readonly APPROVE_BUTTON:string = AppConstants.APPROVE_BUTTON; 
    readonly ERROR_UPLOAD_MISSING_FILE:string = AppConstants.ERROR_UPLOAD_MISSING_FILE;
    allowedExtensions: string = this._helperExtension.allAsExtensions();

    constructor(private _settingsService: SettingsService, private _userService: UserService, private _userStateService: UserStateService, private _userDetailService: UserDetailService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportUserInfoService: ReportUserInfoService, private _reportUserInfoStateService: ReportUserInfoStateService, private _router: Router, private _reportStaffService: ReportStaffService, private _doctorService: DoctorService, private _smileboxService: SmileboxService, private _smileboxFileService: SmileboxFileService, private _fileTypeService: FileTypeService, private _doctorPatientService: DoctorPatientService, private _adminFileService: AdminFileService, private _helper: Helper, private _cd: ChangeDetectorRef, public _dialog: MatDialog, private _spinnerService: SpinnerService, private _smileBoxAdminReviewService: SmileBoxAdminReviewService, private _snackBar: MatSnackBar, private _straightboxService: StraightboxService, public userStatusHelper: UserStatusHelper, private _conversationService: ConversationService, private _helperExtension: HelperExtension) { 
        super(_settingsService, _userService, _userStateService, _userDetailService, _menuService, _route, _reportUserInfoService, _reportUserInfoStateService, _router, _spinnerService);
    }

    ngOnInit(): void {
		super.ngOnInit(); 

        this.getSmilebox();
        //this.getFilesAndFileTypes();
        this.getDoctors();
        this.getSmileBoxAdminReview();

        this._reportUserInfoStateService.reportUserInfo
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(reportUserInfo => {
                this.reportUserInfo = reportUserInfo;
 
                this.getStraightbox(); 
            });
    }

    getSmilebox(): void { 
        this._smileboxService.detail(this.userId.toString())
			.subscribe(smilebox => {
				this.smilebox = smilebox;  
                if(!this._helper.isNullOrUndefined(this.smilebox.doctor) && this.smilebox.doctor > 0 )
                    this.getDoctorPatient(this.userId, true);
			});
	}

    getStraightbox(): void {  

        if(!this.reportUserInfo || !this.userStatusHelper.isTreatmentPlanSent(this.reportUserInfo.status))
            return;

        this._spinnerService.startSpinner(); 
        this._straightboxService.detail(this.userId.toString()) 
			.subscribe(straightbox => {

                if(straightbox) {
                    this.straightbox = straightbox; 
                    this.straightbox.treatment_plan_message_sent_at = !this._helper.isNullOrUndefined(this.straightbox.treatment_plan_message_sent_at) ? moment.utc(this.straightbox.treatment_plan_message_sent_at).toDate() : null; 
                }
				    
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    getSmileBoxAdminReview(): void {  
        this._spinnerService.startSpinner(); 
        this._smileBoxAdminReviewService.index({user_id: this.userId})
			.subscribe(response => { 
                this.smileBoxAdminReviews = response.results; 
                this.smileBoxAdminReviews.forEach(smileBoxAdminReview => {
                    smileBoxAdminReview.created_at = !this._helper.isNullOrUndefined(smileBoxAdminReview.created_at) ? moment.utc(smileBoxAdminReview.created_at).toDate() : null;
            }); 
                this.setVisibility(); 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
	}

    createStraightbox() {
        this._spinnerService.startSpinner(); 
        this._straightboxService.createStraightbox(this.userId)
            .subscribe(straightboxResponse => { 

                if(straightboxResponse && straightboxResponse.success) {
                    this._reportUserInfoService.clearCache(); 
                    this.ngOnInit();  
                } 
                    
                this._spinnerService.stopSpinner();
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }
 
    getDoctors(): void { 
        this._reportStaffService.index({user_type: UserType.Doctor})
			.subscribe(response => {
                if(response)
				    this.doctors = response.results; 
			});
	}
    
    getDoctorPatient(userId, deleteCache: boolean = false): void {
        this._doctorPatientService.detail(userId, deleteCache)
        .subscribe(response => {
                if(response)
				    this.doctorPatient = response; 
            } 
        );
    }

    saveDoctor() {  

        if (!this._helper.isNullOrUndefined(this.doctorPatient)) {
            this.changeDoctor();
            return;
		}  

        this.addNewDoctor();
    }

    changeDoctor() {
        this._doctorService.putDoctor(this.userId, { doctor: this.seletedDoctor.id } )		
        	.subscribe(response => { 
                if(response.success)     
                    this.ngOnInit(); 
			}); 
    }

    addNewDoctor() {
        this._doctorService.postDoctor(this.userId, { doctor: this.seletedDoctor.id } ) 
        	.subscribe(response => { 
                if(response && response.success) { 
                    this._doctorPatientService.clearCache();
                    this.addDoctorPatientRelation(this.seletedDoctor.id) 
                } 
			}); 
    } 
    
    addDoctorPatientRelation(doctorId: number, ) {
        this._doctorPatientService.addDoctorPatientRelation(this.userId, { user: doctorId, patient: this.userId } )		
        	.subscribe(response => { 
                if(response.success) {
                    this._smileboxService.clearCache();
                    this._reportUserInfoService.clearCache();
                    this.ngOnInit(); 
                }
                    
			}); 
    }

    setVisibility():void { 
        this.addNewTreatmentPlanDoctor = true;

        if(this._helper.isNullOrUndefined(this.smileBoxAdminReviews) || this.smileBoxAdminReviews.length == 0) {
            this.addNewTreatmentPlanAdmin = true;
            return;
        }

        this.smileBoxAdminReviews.forEach(smileBoxAdminReview => {
            if(smileBoxAdminReview.admin_approved) {
                this.lastAdminApproved = smileBoxAdminReview.admin_approved;
                this.lastAdminSeen = smileBoxAdminReview.admin_seen;
            } 
        }); 

        this.addNewTreatmentPlanAdmin = this.lastAdminSeen;
        this.addNewTreatmentPlanDoctor = this.lastAdminApproved || !this.addNewTreatmentPlanAdmin ? false : !this.lastAdminApproved ? false : true;
 
    }

    uploadTreatmentPlanFile(event: any): void { 
        this._spinnerService.startSpinner(); 
        let uploadFiles: File[] = [];
        uploadFiles.push(event.target.files[0]);
 
		if(this._helper.isNullOrUndefined(uploadFiles) ) { 
            this._snackBar.open(this.ERROR_UPLOAD_MISSING_FILE, 'Close', this.matSnackBarConfig);
            return;
        } 

		let formData: FormData = new FormData();
        
		uploadFiles.forEach(file => { 
			formData.append('doctor_analysis', file);
			} 
		); 

		this._smileBoxAdminReviewService.uploadNewTreatmentPlan(this.userId, formData)
            .subscribe(response => { 
 
                this.getSmileBoxAdminReview();
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 

	}

    deleteTreatmentPlanFile(smileBoxAdminReview: ISmileBoxAdminReview) {
        this._spinnerService.startSpinner(); 
        this._smileBoxAdminReviewService.deleteTreatmentPlanFile(smileBoxAdminReview, this.userId)
			.subscribe(response => {  
                this.getSmileBoxAdminReview();
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    
    }

    approveTreatmentPlan(smileBoxAdminReview: ISmileBoxAdminReview, adminApproved: boolean) {
        this._spinnerService.startSpinner(); 
        let formData: FormData = new FormData();
           
        formData.append('admin_approved', adminApproved.toString());
         
        this._smileBoxAdminReviewService.updateTreatmentPlan(smileBoxAdminReview, formData)
			.subscribe(response => { 
                this.ngOnInit();
			    this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }

    treatmentPlanReviewMessage():void {
        this._spinnerService.startSpinner(); 
        this._straightboxService.sendTreatmentPlanMessage(this.userId, { treatment_plan_message_sent_manually: false })
			.subscribe(response => {
                if(response.success) {
                    this._conversationService.clearCache();
                    this.ngOnInit();
                }    				

                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }
    
    treatmentPlanReviewMessageSentManually(): void {
        this._spinnerService.startSpinner(); 
        this._straightboxService.sendTreatmentPlanMessage(this.userId, { treatment_plan_message_sent_manually: true })
			.subscribe(response => {
				if(response.success) {
                    this._conversationService.clearCache();
                    this.ngOnInit();
                }  

                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
    }
}
