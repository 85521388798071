import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IAppBannerNotification, IAppBannerNotificationData } from '../../../../model/api/app-banner-notificaton';
import { IStatus } from '../../../../model/status';
import { AppBannerNotificationService } from '../../../../service/api/app-banner-notification.service';
import { SpinnerService } from '../../../../service/spinner.service';
import { StatusService } from '../../../../service/status.service';
import { AppConstants } from '../../../../settings/app-constants';
import { UserStatus } from '../../../../settings/user-status';
import { Helper } from '../../../../util/helper.util';
import { DetailBaseComponent } from '../../../inc/base/detail-base-componenty'; 
import { MenuService } from '../../../../service/state/menu.service';
import { SettingsService } from '../../../../service/state/settings.service';  
import { ReportCurrentUserService } from 'src/app/service/api/report-current-user.service';
import { HelperExtension } from 'src/app/util/helper.extension';

@Component({
    selector: 'app-banner-notification-detail',
    templateUrl: './app-banner-notification-detail.component.html',
    styleUrls: ['./app-banner-notification-detail.component.scss']
})
export class AppBannerNotificationDetailComponent extends DetailBaseComponent implements OnInit {

    menuId: number = 2010;  
    appBannerNotification: IAppBannerNotification;
    moment: any = moment;
    statuses: IStatus[]= []; 
    selectedStatuses: IStatus[] = [];
    appBannerNotificationData: IAppBannerNotificationData;
    allowedExtensions: Array<string> = this._helperExtension.image();
    file_form:string[] = []; 
    appBannerNotificationId: string; 
    readonly SAVE_BUTTON: string = AppConstants.SAVE_BUTTON;
    readonly ADD_IMAGE: string = AppConstants.ADD_IMAGE;
    readonly CHANGE_IMAGE: string = AppConstants.CHANGE_IMAGE; 
    readonly CANCEL:string = AppConstants.CANCEL;
    readonly DELETE_BUTTON:string = AppConstants.DELETE_BUTTON;
    allowedUploadExtensions: string = this._helperExtension.imageAsExtensions();
    
    constructor(private _settingsService: SettingsService, private _menuService: MenuService, private _route: ActivatedRoute, private _reportCurrentUserService: ReportCurrentUserService, private _appBannerNotificationService: AppBannerNotificationService, protected _statusService: StatusService, private _spinnerService: SpinnerService, public _helper: Helper, private _snackBar: MatSnackBar, private _cd: ChangeDetectorRef, private _zone: NgZone, private _router: Router, private _helperExtension: HelperExtension) { 
        super(_settingsService, _menuService, _route, _reportCurrentUserService);
    }

    ngOnInit(): void { 
        super.ngOnInit();
 
        this.appBannerNotificationId = this.route.snapshot.paramMap.get('id');

        this.getAppBannerNotification();        
    }
 
    saveAppBannerNotification() {
        if(this._helper.isNullOrUndefined(this.appBannerNotification) || this._helper.isEmptyOrNull(this.appBannerNotification.title) || this._helper.isEmptyOrNull(this.appBannerNotification.text)  ) {
            this._snackBar.open('Enter App banner notification title and text, please.', 'Close', this.matSnackBarConfig);
            return;
        }
        
        this._spinnerService.startSpinner();
 
        this.appBannerNotification.visible_to_user = [];
        if(!this._helper.isEmptyOrNull(this.appBannerNotificationData.visible_to_user) ) {
            this.appBannerNotificationData.visible_to_user.split(',').forEach(visibleToUser => 
                this.appBannerNotification.visible_to_user.push(+visibleToUser)
            );
        } 

        let formData: FormData = new FormData();

        formData.append('id', this.appBannerNotification.id > 0 ? this.appBannerNotification.id.toString(): null) ;
        formData.append('title', this.appBannerNotification.title);
        formData.append('text', this.appBannerNotification.text);
        
        formData.append('visible_from_date', this.appBannerNotification.visible_from_date != null ? moment.utc(this.appBannerNotification.visible_from_date).format('yyyy-MM-DTHH:mm:ss') : '');
        formData.append('visible_to_date', this.appBannerNotification.visible_to_date != null ? moment.utc(this.appBannerNotification.visible_to_date).format('yyyy-MM-DTHH:mm:ss') : '');
 
        this.appBannerNotification.visible_to_user.forEach(visibleToUser => formData.append('visible_to_user', visibleToUser.toString()));

        this.selectedStatuses.forEach(selectedStatus => formData.append('visible_to_user_status', selectedStatus.id.toString()));
        
        formData.append('active', this.appBannerNotification.active ? this.appBannerNotification.active.toString() : 'false' );
  
        formData.append('is_country_uk', this.appBannerNotification.is_country_uk != null ? this.appBannerNotification.is_country_uk.toString() : 'null'); 
 
        
		if(this.appBannerNotificationData.image && this.appBannerNotificationData.image.file){ 
            formData.append('image', this.appBannerNotificationData.image.file); 
        }
    	
		this._appBannerNotificationService.save(this.appBannerNotification, formData)
            .subscribe(bannerNotification => { 
                if(bannerNotification) 
                    this._router.navigateByUrl(this.navigationSettings.parentUrl);

                this._spinnerService.stopSpinner(); 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            }); 

    }
 

    editAppBannerNotification(appBannerNotification: IAppBannerNotification) { 
        
        this.appBannerNotification = Object.assign({}, appBannerNotification);
  
        this.appBannerNotificationData = {visible_to_user: this.appBannerNotification.visible_to_user.join(',')};

        this.selectedStatuses = [];
        this.statuses = this._statusService.getStatuses().filter(status => status.id != UserStatus.RejectedUser);

        this.appBannerNotification.visible_from_date = !this._helper.isNullOrUndefined(this.appBannerNotification.visible_from_date) ? moment.utc(this.appBannerNotification.visible_from_date).toDate() : null; 
        this.appBannerNotification.visible_to_date = !this._helper.isNullOrUndefined(this.appBannerNotification.visible_to_date) ? moment.utc(this.appBannerNotification.visible_to_date).toDate() : null;

        this.appBannerNotification.visible_to_user_status.forEach(visibleToUserStatus => { 

            let foundStatus = this.statuses.find(status => status.id == visibleToUserStatus);
            if(foundStatus)
                this.selectedStatuses.push(foundStatus);
        });


    }

    addNewAppBannerNotification() {
        this.appBannerNotification = { id: null, text: '', title: '' };
        this.appBannerNotificationData = { image: null, visible_to_user: ''};
 
        this.statuses = this._statusService.getStatuses().filter(status => status.id != UserStatus.RejectedUser);

    }

    getAppBannerNotification(): void {  

        if(this._helper.isEmptyOrNull(this.appBannerNotificationId)) {
            this.addNewAppBannerNotification();
            return;
        }

        this._spinnerService.startSpinner();  

        this._appBannerNotificationService.detail(this.appBannerNotificationId)
			.subscribe(response => { 

                if(response) {
                    this.appBannerNotification = response; 
                    this.editAppBannerNotification(this.appBannerNotification);
                } 
 
                this._spinnerService.stopSpinner(); 
			},
            error =>{ error  
                this._spinnerService.stopSpinner(); 
            }); 
 
	}


    addAppBannerNotificationImage(input){ 
        this.processFiles(input.files);
    }

    processFiles(files){
 
        for (var iter = 0; iter < files.length; iter++) {  
            /* var fileExtension = files[iter].name.split('.').pop().toLowerCase();
            if(!this.allowedExtensions.find(x => x === fileExtension)) {               
                continue;
            }   */
 
            this.file_form.push(files[iter]);             
        }

        this.readFiles(files);
    }

    fileReadAndLoaded(result, index, files){
        let tmp_file = files[index];
        let tmp_file_type = tmp_file.type.split('/')[0];

        let base64_file = result.target.result;

        this.appBannerNotificationData.image = { "id": index,'base':base64_file,'name':tmp_file.name,'type':tmp_file_type,'file':tmp_file };

        this.readFiles(files, index+1);
    }

    readFiles(files, index=0){
        if (!(index in files)) {
            this._cd.detectChanges();
            return;
        }
 
        try{
            let FileReader: new() => FileReader = ((window as any).FileReader as any).__zone_symbol__OriginalDelegate;
            var reader = new FileReader();
        } catch(error){
            var reader = new FileReader();
        }
 
        let tmpFile = files[index];
        var fileExtension = tmpFile.name.split('.').pop().toLowerCase();
 
        if(!this.allowedExtensions.find(x => x === fileExtension)) { 
            this.readFiles(files, index+1); 
        }  else {
            reader.readAsDataURL(tmpFile);
        }

        reader.onload = (result) => {
            this._zone.run(() => {
                this.fileReadAndLoaded(result, index, files);
            });
        }; 
    }

    deleteAppBannerNotification() {
        if(this._helper.isNullOrUndefined(this.appBannerNotification))
            return;

        this._spinnerService.startSpinner();

        this.appBannerNotification.marked_deleted_at = moment(new Date()).toDate();

        this._appBannerNotificationService.save({ id: this.appBannerNotification.id, marked_deleted_at: this.appBannerNotification.marked_deleted_at })
            .subscribe(bannerNotification => { 
                if(bannerNotification) 
                    this._router.navigateByUrl(this.navigationSettings.parentUrl);
          
                this._spinnerService.stopSpinner(); 
            },
            error =>{ error 
                this._spinnerService.stopSpinner(); 
            });
        
    }
}
